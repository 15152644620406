import { ILocation } from '@dataplace.ai/types'
import { getCurrentCountryId } from '@dataplace.ai/functions/requests'
import { createFlashMessage } from '@dataplace.ai/functions/utils'

export const checkCountry = async (location:ILocation, countryCodes: string[],
  withFlashMessage?: boolean, currentCountryCode?: string):Promise<boolean> => {
  const countryCode = currentCountryCode || await getCurrentCountryId(location)
  if (countryCode && countryCodes.length && !countryCodes.includes(countryCode)) {
    if (withFlashMessage) {
      createFlashMessage({
        message: 'status.error.catchment.no_analyse_for_this_country',
      }) }

    return false
  }
  if (!countryCodes.length) {
    if (withFlashMessage) {
      createFlashMessage({
        message: 'status.error.catchment.no_country_code_list',
      })
    }
    return false
  }
  return true
}
