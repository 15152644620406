import React from 'react'
import styled from 'styled-components'

const LoaderWrapper = styled.div`
    margin: 0px 15px;
    @keyframes load-spin {
      to {
        transform: rotate(1turn);
      }
}
  `

const Spinner = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #362FB7);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: load-spin 1s infinite linear;
`

export const MiniLoader = () : JSX.Element => (
  <LoaderWrapper>
    <Spinner title='mini loader' />
  </LoaderWrapper>
)
