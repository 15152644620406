import styled from 'styled-components'

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(66, 58, 179, 0.05);
  padding: 0.8rem 1.5rem;
  border-radius: 16px;
  cursor: pointer;
`
