/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect, useContext, ReactNode, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AddIcon, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as LockIcon } from 'libs/shared/assets/src/lib/icons/lock.svg'
import { placemePlans, pricingPlans } from '@dataplace.ai/constants'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { PopupPosition } from 'reactjs-popup/dist/types'
import { ReactComponent as Check } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/check.svg'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import {
  addTileAction, deleteTileAction, saveIsMaxTilesNumberExceeded, setDemoModalId,
} from '../../../slice/analysisSlice'
import { ICombinedTile } from '../../../slice/@types/ITile'
import { ReactComponent as HelpCircle } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { ReactComponent as Exclamation } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/placeme/exclamation.svg'
import { RootState } from '../../../../../redux/store'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'

type WrapperProps = {
  isChecked?: boolean
  disabled: boolean
}

const Wrapper = styled.div<WrapperProps>(({
  theme, isChecked, disabled,
}) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    align-items: center;
    border: 1px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    background: ${disabled ? palette.disabled : palette.light.white};
    padding: 0.625rem;
    margin: 0.25rem 0;
    min-height: 1.5rem;
    cursor: ${disabled ? 'default' : 'pointer'};

    > span {
      flex-grow: 1;
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      color: ${palette.black};

      ::first-letter {
        text-transform: capitalize;
      }

      @media (max-width: 1200px){
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
      }
    }

    .leftIcon {
      margin-right: .3rem;
      display: ${isChecked || disabled ? 'block' : 'none'};
    }

    .helpCircle {
      display: none;
    }
    
    .exclamation{
      margin: 0 5px -0.2rem 0;
    }

    :hover {
      padding: 0.625rem 0.3rem;
      .leftIcon {
        display: block;
      }
      .helpCircle {
        display: block;
      }
    }

    ${isChecked
    && css`
      span {
        color: ${palette.black};
        font-size: ${typography.small.pt_13_medium.fontSize};
        font-weight: ${typography.small.pt_13_medium.fontWeight};
        line-height: ${typography.small.pt_13_medium.lineHeight};
      }
    `}

  `
})
const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-bottom: -0.2rem;

    > path {
      fill: ${palette.blue};
    }`
})

const DemoModalTextButton = styled.span(({ theme: { typography } }) => css`
  font-weight: ${typography.small.pt_13_medium.fontWeight};
  font-size: ${typography.small.pt_13_medium.fontSize};
  line-height: ${typography.small.pt_13_medium.lineHeight};
  text-decoration: underline;
  cursor: pointer;
`)

const DemoModalTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

// const LockWrapper = styled.div<{plan: string}>(({
//   theme: { palette }, plan,
// }) => css`
//   padding: 0 .25rem;
//   margin-right: .4rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: default;
//
//   svg {
//     path {
//       stroke: ${(() => {
//     switch (plan) {
//       case 'gold':
//         return palette.gold.normal
//       case 'bronze':
//         return palette.results.red.light
//       default:
//         return palette.dark.normal
//     }
//   })()};
//     }
//   }
// `)

const PriceWrapper = styled.div<{plan: string, currentPlan: string, isEnoughCredits: boolean}>(({
  theme: {
    palette, typography,
  }, plan, currentPlan, isEnoughCredits,
}) => css`
  padding: 0 .25rem;
  margin: 0 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border-radius: 1rem;
  > span {
    text-align: center;
    white-space: nowrap;
    margin: 0.1rem;
    font-size: ${typography.tiny.pt_10_regular.fontSize};
    color: ${() => {
    if (plan !== currentPlan) {
      switch (plan) {
        case 'bronze':
          return palette.prices.green
        case 'silver':
          return palette.prices.violet
        case 'gold':
          return palette.prices.gold
        default:
          return palette.prices.green
      }
    } else {
      return palette.prices.green
    }
  }};
  }
  background-color: ${() => {
    if (plan !== currentPlan) {
      switch (plan) {
        case 'bronze':
          return palette.pricesBackground.green
        case 'silver':
          return (!isEnoughCredits) ? palette.pricesBackground.violet : ((isEnoughCredits && currentPlan !== 'gold') ? palette.pricesBackground.violet : palette.pricesBackground.green)
        case 'gold':
          return palette.pricesBackground.gold
        default:
          return palette.pricesBackground.green
      }
    } else {
      return palette.pricesBackground.green
    }
  }};


`)

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

`

export interface IDropdownTopicProps {
  tile: ICombinedTile
  sectionTile: ISectionTile
  disabled: boolean
  disabledByPricingPlan?: boolean
}

export const TileSelect = ({
  tile,
  sectionTile,
  disabled,
  disabledByPricingPlan,
}: IDropdownTopicProps): JSX.Element => {
  // constants
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const {
    currentSubscriptionData, userType, value, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, canBeSave, creditsAmount, currentPlan, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const authContext = useContext(AuthContext)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  const tilesNumber = values?.map(item => item?.tiles)?.flat()?.length

  // states
  const [isExtraPaid, setIsExtraPaid] = useState(disabledByPricingPlan)
  const [isChecked, setIsChecked] = useState(false)
  const [isInScroll, setIsInScroll] = useState(false)

  // functions
  const ifEnoughCredits = () => {
    if (typeof currentSubscriptionData?.value?.credits === 'number' && (currentSubscriptionData?.value?.credits - 1 <= -1)) {
      return false
    } return true
  }

  const handleClick = () => {
    if (canBeSave) {
      if (!checksIfAnyTypeTile()) {
        if (tilesNumber < 25) {
          analytics?.track('Tile Selected', {
            tile: tile?.id,
          })
          dispatch(addTileAction(token, sectionTile, tile, isExtraPaid))
          setIsInScroll(true)
          setIsChecked(true)
        } else {
          dispatch(saveIsMaxTilesNumberExceeded(true))
        }
      } else {
        const tiles = values.find(category => category.id === sectionTile.id)?.tiles?.filter(t =>
          t.id.split('-')[0] === tile.id.split('-')[0])
        const tilesWithoutData = tiles?.filter(item => item?.data)
        if (!tilesWithoutData?.length && tiles?.[0]?.id) {
          dispatch(deleteTileAction(token, sectionTile.id, tiles?.[0]?.id, comparedAnalyseId || analyseId || ''))
        }
      }
    }
  }
  const checksIfAnyTypeTile = () => {
    let returnValue = false
    values.find(category => category.id === sectionTile.id)?.tiles.forEach(t => {
      if (t.id.split('-')[0] === tile.id.split('-')[0]) {
        returnValue = true
      }
    })
    return returnValue
  }

  const checkIfPeopleTileForeign = () => {
    if (tile.id.includes('people') && value?.country !== 'PL') return t(`${tile.labelForeign}`)
    return t(`${tile.label}`)
  }

  const TileSelectTooltip: React.FC<{children: ReactNode, content: string | JSX.Element,
    position: PopupPosition | PopupPosition[]}> = useCallback(({
    children, content, position,
  }) => {
    if ([placemePlans.trial, 'white']
      .find(item => item === currentSubscriptionData?.value?.planExact) && tile?.demoImageKey) {
      content = (
        <DemoModalTooltipWrapper>
          <span>{content}</span>

          <DemoModalTextButton onClick={() => dispatch(setDemoModalId(tile?.id))}>
            {t('placeme.analysis.sidebar.tooltip.show_demo')}
          </DemoModalTextButton>
        </DemoModalTooltipWrapper>
      ) }
    return (
      <Tooltip
        bufferArea='1.5rem'
        content={content}
        header={checkIfPeopleTileForeign()}
        position={position}
      >
        {children}
      </Tooltip>
    )
  }, [currentSubscriptionData?.value?.planExact])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    setIsChecked(!!values?.find(category => category.id === sectionTile.id)?.tiles?.find(t => t.id.split('-')[0] === tile.id))
  }, [values])

  useEffect(() => {
    if (tile.plan.id !== 'white') {
      // setIsExtraPaid(disabledByPricingPlan || ifEnoughCredits())
      if (ifEnoughCredits() && !disabledByPricingPlan) {
        setIsExtraPaid(false)
      }
      else { setIsExtraPaid(true) }
    } else {
      setIsExtraPaid(disabledByPricingPlan)
    }
  }, [creditsAmount, currentPlan, currentSubscriptionData?.value?.credits])

  // scrollIntoView
  useEffect(() => {
    const tileToScroll = values?.find(category => category.id === sectionTile.id)?.tiles?.find(t => t.id.split('-')[0] === tile.id)
    if (tileToScroll && isInScroll) {
      const section = document.querySelector(`#${tileToScroll?.id}`)
      if (section && tileToScroll?.id) {
        section.scrollIntoView()
        setIsInScroll(false)
      }
    }
  }, [values, isInScroll])

  return (
    <>
      {
        // potential big ben is available only for owner and admin
        (tile?.id === 'scoring_model_big_ben' && userType === 'user')
          ? null
          : (
            <Wrapper
              disabled={!canBeSave || ((currentSubscriptionData?.value?.plan === 'white' || currentSubscriptionData?.value?.plan === 'trial') && isExtraPaid === true)}
              isChecked={isChecked}
              onClick={!(!canBeSave || ((currentSubscriptionData?.value?.plan === 'white' || currentSubscriptionData?.value?.plan === 'trial') && isExtraPaid === true))
                ? handleClick
                : () => {}}
            >
              {checksIfAnyTypeTile() && !disabled
                ? (
                  <Check
                    className='leftIcon'
                    style={{
                      minWidth: '1rem',
                      padding: '0.25rem',
                    }}
                  />
                )
                : (!disabled || disabledByPricingPlan
                  ? (disabledByPricingPlan && canBeSave
                    ? ((!((currentSubscriptionData?.value?.plan === 'white' || currentSubscriptionData?.value?.plan === 'trial')
             && isExtraPaid === true))
                      ? (
                        <div>
                          <AddIcon
                            className='leftIcon'
                          />
                        </div>
                      )
                      : null
                    )
                    : canBeSave
                      ? (
                        <AddIcon
                          className='leftIcon'
                        />
                      )
                      : null)
                  : null)}
              <span>{checkIfPeopleTileForeign()}</span>
              {canBeSave && isExtraPaid && !checksIfAnyTypeTile()
                ? (
                  (currentSubscriptionData?.value?.plan !== 'white' && currentSubscriptionData?.value?.plan !== 'trial')
                    ? (
                      <TooltipWrapper>
                        <Tooltip
                          content={ifEnoughCredits()
                            ? t(`placeme.analyse_not_included.${tile.plan.id}`)
                            : (disabledByPricingPlan
                              ? t(`placeme.analyse_not_included.${tile.plan.id}`)
                              : t('placeme.analyse_not_included.no_credits'))}
                          position='bottom center'
                        >
                          <PriceWrapper
                            currentPlan={currentPlan}
                            isEnoughCredits={ifEnoughCredits()}
                            plan={tile?.plan?.id}
                          >
                            <span>{`+ ${pricingPlans.filter(t => t.id === tile?.plan?.id)[0]?.price} PLN`}</span>
                          </PriceWrapper>
                        </Tooltip>
                        {comparedLocation?.location
                         && tilesWithoutComparedLocation?.includes(tile?.id)
                        && (
                          <Tooltip
                            content={t('placeme.compared_location.this_tile_is_excluded.desc')}
                            header={t('placeme.compared_location.this_tile_is_excluded')}
                            position='right center'
                          >
                            <Exclamation className='helpCircle exclamation' />
                          </Tooltip>
                        )}
                        <TileSelectTooltip
                          content={t(`${tile.labelHint}`)}
                          position='right center'
                        >
                          <StyledHelpCircle
                            className='helpCircle'
                          />
                        </TileSelectTooltip>
                      </TooltipWrapper>
                    )
                    : (
                      <TileSelectTooltip
                        content={t('placeme.analyse_not_included')}
                        position='bottom center'
                      >
                        <LockIcon />
                      </TileSelectTooltip>
                    )
                )
                : canBeSave
                  ? (
                    <>
                      { comparedLocation?.location
                      && tilesWithoutComparedLocation?.includes(tile?.id)
                      && (
                        <Tooltip
                          content={t('placeme.compared_location.this_tile_is_excluded.desc')}
                          header={t('placeme.compared_location.this_tile_is_excluded')}
                          position='right center'
                        >
                          <Exclamation className='helpCircle exclamation' />
                        </Tooltip>
                      ) }
                      <TileSelectTooltip
                        content={t(`${tile.labelHint}`)}
                        position='right center'
                      >
                        <StyledHelpCircle
                          className='helpCircle'
                        />
                      </TileSelectTooltip>
                    </>
                  )
                  : null}
            </Wrapper>
          )
      }
    </>
  )
}
