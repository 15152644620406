import React, { useMemo } from 'react'
import { IRange } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isChosenRangeInDefaultRanges } from '../../clientRangesList'

const CustomCatchmentSpan = styled.span`
  font-weight: bold !important;
`

interface IRankingCustomCatchmentSpan {
  chosenRange: IRange | undefined
  tileId: string
  rangeTranslation: string
}

export const RankingCustomCatchmentSpan = ({
  chosenRange, tileId, rangeTranslation,
}: IRankingCustomCatchmentSpan): JSX.Element | null => {
  const { t } = useTranslation()
  const customCatchmentRankingSpan = useMemo(() => (isChosenRangeInDefaultRanges(chosenRange, tileId)
    ? null
    : (
      <CustomCatchmentSpan>
        {` ${t('placeme.big_ben.chain_ranking.custom_catchment.form_span_1')}: ${rangeTranslation.toLocaleLowerCase()}`}
      </CustomCatchmentSpan>
    )), [rangeTranslation, JSON.stringify(chosenRange)])
  return customCatchmentRankingSpan
}
