export const buildingsTableData1 = {
  labels: [
    'placeme.buildingtile.table_1_label_1',
    'placeme.buildingtile.table_1_label_2',
    'placeme.buildingtile.table_1_label_3',
    'placeme.buildingtile.table_1_label_4',
  ],
}

export const buildingsTableData2 = {
  labels: [
    'placeme.buildings_tile.category_2',
    'placeme.buildingtile.table_1_label_2',
    'placeme.buildingtile.table_1_label_3',
    'placeme.buildingtile.table_1_label_4',
  ],
}
