import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrDownIcon } from '../../../../../assets/src/lib/icons/arrows/arrDown.svg'
import { ISelectCaretProps } from './@types/ISelectCaret.props'

const CaretWrapper = styled.div<ISelectCaretProps>(({
  active, theme,
}) => {
  const { palette } = theme

  return css`

    svg {
      transition: transform 0.3s;
      width: 0.75rem;
      cursor: pointer;
    }

    ${active && css`
      svg {
        transform: rotate(180deg);
      }
    `}

    svg > path {
      stroke: ${palette.blue};
    }

    :hover svg > path {
      stroke: ${palette.dark.main};
      
    }
  `
})

export const SelectCaret: React.FC<ISelectCaretProps> = ({
  active, onClick,
}) => (
  <CaretWrapper
    active={active}
    onClick={onClick}
  >
    <ArrDownIcon />
  </CaretWrapper>
)
