import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton, Header } from '@dataplace.ai/ui-components/atoms'

const FrameWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    max-width: 850px;
`
const StyledSpan = styled.span(({ theme }) => {
  const { palette } = theme

  return css`
  width: 6px;
  background-color:${palette.product.location.main};
`
})
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`
const DataTopic = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #DDDDDD;
    text-transform: uppercase;
`
const ContentWrapper = styled.div(({ theme }) => css`
    display: flex;
    flex-direction:column;
    padding: 20px 20px 40px;
    background-color: ${theme.palette.light.main};
`)

export interface IDataTileWrapperProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
  handleDelete? : () => void
}

export function DataTileWrapper({
  title, children, handleDelete,
}:IDataTileWrapperProps):JSX.Element {
  return (
    <FrameWrapper>
      <StyledSpan />
      <Wrapper>
        <DataTopic>
          <Header type='h5'>{title}</Header>
          <IconButton
            iconAlt='trash icon'
            iconSrc='assets/icons/trashIcon.svg'
            onClick={handleDelete}
          />
        </DataTopic>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </Wrapper>
    </FrameWrapper>

  )
}

export default DataTileWrapper

