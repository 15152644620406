import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { pricingPlans } from '@dataplace.ai/constants'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import { CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Credits } from '../../../../../assets/src/lib/icons/placeme/credits.svg'
import { ReactComponent as Stats } from '../../../../../assets/src/lib/icons/placeme/stats.svg'

const CoinsButtonWrapper = styled.button<CoinsButtonProps>(
  ({
    theme, margin, disabled, isExtraPaid, isUnlimited,
  }) => {
    const {
      palette, typography,
    } = theme

    return css`
      margin: ${margin};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 5px 5px 15px;
      background-color: ${(isExtraPaid) ? palette.results.red.dark : palette.blue};
      border: 1px solid ${(isExtraPaid) ? palette.results.red.dark : palette.blue};
      box-sizing: border-box;
      border-radius: 6px;
      color: ${palette.light.white};
      transition-property: background-color, color, border;
      transition: 0.3s ease-in-out;

      p {
        padding: 3.45px 0;
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
        margin-right: ${isUnlimited ? '15px' : '0'};
      }

      .value-container {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 7px;
        border-radius: 1rem;
        background: ${palette.light.white};
        color: ${palette.results.red.dark};
      }

      .coins-container {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 3px 7px;
        border-radius: 1rem;
        background: ${disabled ? palette.light.main : '#C6EAE1'};
        color: ${disabled ? palette.dark.lightest : palette.blue};
        min-width: 45px;

        svg {
          margin-right: 7px;
          path {
            fill: ${disabled ? palette.dark.lightest : palette.blue};
          }
        }
      }

      :hover {
        background-color: ${(isExtraPaid) ? palette.results.red.darkest : palette.dark.main};
        border: 1px solid ${(isExtraPaid) ? palette.results.red.darkest : palette.blue};
        cursor: pointer;
      }
      :focus {
        background-color: ${(isExtraPaid) ? palette.results.red.dark : palette.dark.lightest};
      }
      :active {
        background-color: ${(isExtraPaid) ? palette.results.red.dark : palette.light.main};
        color: ${(isExtraPaid) ? palette.light.white : palette.blue};
        border: 1px solid #e1e2e3;
      }
      :disabled {
        background-color: ${(isExtraPaid) ? palette.results.red.opaque : palette.dark.lightest};
        border: 1px solid ${(isExtraPaid) ? palette.results.red.dark : '#e1e2e3'};
        color: white;
        cursor: default;
      }
    `
  },
)

type AnimatedCoinsProps ={
  isAnimate: boolean
  disabled?: boolean
}
const AnimatedCoins = styled.div<AnimatedCoinsProps>(({
  theme, isAnimate, disabled,
}) => {
  const { palette } = theme
  return css`

    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 7px;
    border-radius: 1rem;
    background: ${disabled ? palette.light.main : '#C6EAE1'};
    color: ${disabled ? palette.dark.lightest : palette.blue};
    min-width: 45px;

    svg {
      margin-right: 7px;
      path {
        fill: ${disabled ? palette.dark.lightest : palette.blue};
      }
    }
    position: absolute;
    //transform: translate(0px, 0px);
    transition: ${isAnimate ? 'all 1s' : 'none'};
    transform: ${isAnimate && 'translate(0px, -45px)'};
    opacity: ${isAnimate ? '0' : '1'};

  `
})

const ModalWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners, shadows,
  } = theme

  return css`
    background: ${palette.light.white};
    padding: 2.5rem;
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
    width: 40vw;
    max-width: 700px;

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.5rem;

      h4 {
        color: ${palette.black};
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
      }

      svg {
        cursor: pointer;
        padding: 10px;
        margin-top: -40px;
        margin-right: -20px;
      }
    }

    p {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 20px;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      
      .cancel-btn {
        background: none;
        border: none;
        color: ${palette.blue};
        font-size: ${typography.tiny.pt_12_medium.fontSize};
        font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_medium.lineHeight};
        cursor: pointer;
        margin-right: 1rem;
      }

      button {
        p {
          font-size: ${typography.small.pt_13_semibold.fontSize};
          font-weight: ${typography.small.pt_13_semibold.fontWeight};
          line-height: ${typography.small.pt_13_semibold.lineHeight};
          color: ${palette.light.white};
          margin-bottom: 0;
        }
      }
    }
  `
})

type CoinsButtonProps = {
  text?: string
  margin?: string
  value?: string | number
  disabled?: boolean
  onClick?: () => void
  subscription?: boolean
  isExtraPaid?: boolean
  tileId?: string | undefined
  isUnlimited?: boolean
}

export const CoinsButton: React.FC<CoinsButtonProps> = ({
  text,
  margin,
  value,
  disabled,
  onClick,
  subscription,
  isExtraPaid,
  tileId,
  isUnlimited,
}) => {
  const [isAnimate, setIsAnimate] = useState(false)
  const { tiles } = useSelector((state: RootState) => state.analysis)
  const { creditsAmount } = useSelector((state: RootState) => state.analysis)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)

  const handleClick = () => {
    setIsAnimate(true)
  }

  const retriveTileData = (tileId: string | undefined) => tiles?.map(tile => tile?.tiles?.map(item => item))
    .flat()
    .filter(item => tileId?.includes(item?.id))

  useEffect(() => {
    if (isAnimate) {
      setTimeout(() => {
        if (onClick) {
          onClick()
          setIsAnimate(false)
        }
      }, 1000)
    }
  }, [isAnimate])

  return (
    <>
      {!isExtraPaid && (
        <CoinsButtonWrapper
          disabled={disabled}
          isUnlimited={isUnlimited}
          margin={margin}
          onClick={handleClick}
        >
          <p>{text}</p>
          {!isUnlimited
         && (
           <div className='animated-container'>
             <AnimatedCoins
               disabled={disabled}
               isAnimate={isAnimate}
             >
               {subscription ? <Stats /> : <Credits />}
               <p>{disabled ? '0' : value}</p>
             </AnimatedCoins>
             <div className='coins-container'>
               {subscription ? <Stats /> : <Credits />}
               <p>{disabled ? '0' : value}</p>
             </div>
           </div>
         )}
        </CoinsButtonWrapper>
      )}
      {isExtraPaid && (
        <Popup
          modal
          onClose={closeModal}
          onOpen={() => {
            handleMenuModalClose()
            setOpen(true)
          }}
          open={open}
          overlayStyle={defaultTheme.overlay}
          trigger={(
            <CoinsButtonWrapper
              disabled={disabled}
              isExtraPaid={isExtraPaid}
              margin={margin}
            >
              <p>{text}</p>
              <div className='value-container'>
                <span>{`${pricingPlans.filter(t => t.id === retriveTileData(tileId)[0]?.plan?.id)[0]?.price} PLN`}</span>
              </div>
            </CoinsButtonWrapper>
          )}
        >

          <ModalWrapper>
            <div className='flex-wrapper'>
              <h4>{t('placeme.buy_analyse_modal.title')}</h4>
              <CloseIcon onClick={closeModal} />
            </div>

            <p>{creditsAmount ? (t('placeme.buy_analyse_modal.description')) : (t('placeme.buy_analyse_modal.description.no_credits'))}</p>

            <div className='buttons-wrapper'>
              <button
                className='cancel-btn'
                onClick={closeModal}
                type='button'
              >
                {t('generic.cancel')}
              </button>
              <CoinsButtonWrapper
                isExtraPaid={isExtraPaid}
                margin={margin}
                onClick={onClick}
              >
                <p>{t('placeme.buy_analyse_modal.button')}</p>
                <div className='value-container'>
                  <span>{`${pricingPlans.filter(t => t.id === retriveTileData(tileId)[0]?.plan?.id)[0]?.price} PLN`}</span>
                </div>
              </CoinsButtonWrapper>
            </div>
          </ModalWrapper>

        </Popup>
      )}
    </>
  ) }

CoinsButton.defaultProps = {
  subscription: true,
}
