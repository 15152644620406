/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormParagraph,
  TileFormRowWithData,
} from '@dataplace.ai/ui-components/atoms'
import { RangeCalendar } from '@dataplace.ai/ui-components/molecules'
import { format, subDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.span(
  () => css`
    display: flex;
    flex-direction: column;
  `,
)

const SelectedSection = styled.div<{ background: 'light' | 'dark' }>(
  ({
    theme, background,
  }) => {
    const {
      palette, typography, corners,
    } = theme

    return css`
      display: flex;
      flex-direction: column;
      border: 1.5px dashed ${palette.light.darkest};
      border-radius: ${corners.default.borderRadius};
      padding: 1rem;
      margin-top: 1rem;
      background-color: ${background === 'light'
    ? palette.light.white
    : palette.light.main};

      > span > svg {
        cursor: pointer;
      }

      > span {
        display: flex;
      }

      > span > span {
        flex-grow: 1;
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
      }

      > div {
        display: flex;
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
      }

      > div > span,
      > div > span > span {
        margin-right: 0.25rem;
      }
    `
  },
)

interface IDateRangeSelectorProps {
  title: string
  description: string
  onChange: (startDate?: Date, endDate?: Date) => void
  accepted: boolean
  setAccepted: React.Dispatch<React.SetStateAction<boolean>>
  value: { startDate?: Date; endDate?: Date }
  minDate?: Date
  maxDate?: Date
  min?: number,
}

export const DateRangeSelector = ({
  value,
  onChange,
  title,
  description,
  accepted,
  minDate,
  maxDate,
  min,
}: IDateRangeSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const updateWidth = () => setInnerWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Wrapper>
      {!accepted && (
        <>
          <TileFormRowWithData>
            <span>{title}</span>
          </TileFormRowWithData>
          <TileFormParagraph>{description}</TileFormParagraph>
        </>
      )}
      <SelectedSection background={accepted ? 'dark' : 'light'}>
        {!accepted && (
          <RangeCalendar
            endDate={maxDate || new Date()}
            maxDate={maxDate || new Date()}
            minDate={minDate || new Date(2020, 0, 1)}
            minDaysValue={min}
            months={innerWidth >= 1490 ? 2 : 1}
            onChange={onChange}
            startDate={min ? subDays(maxDate || new Date(), min - 1) : subDays(maxDate || new Date(), 6)}
          />
        )}
        {accepted && (
          <>
            <span>
              <span>{`${t('placeme.date_range_selector.settings')}:`}</span>
            </span>
            <div>
              <span>{`${t('placeme.date_range_selector.date_ranges')}:`}</span>
              {value?.startDate && (
                <span>{format(value?.startDate, 'dd.MM.yyyy')}</span>
              )}
              -
              {value?.endDate && (
                <span>{format(value?.endDate, 'dd.MM.yyyy')}</span>
              )}
            </div>
          </>
        )}
      </SelectedSection>
    </Wrapper>
  )
}
