import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Checkbox, Input } from '@dataplace.ai/ui-components/atoms'
import { debounce } from '@dataplace.ai/functions/utils'
import Popup from 'reactjs-popup'
import { ReactComponent as CloseIcon } from 'libs/shared/assets/src/lib/icons/close.svg'
import { SelectCaret } from '../../atoms/Icons/SelectCaret'

const Wrapper = styled.div<{width?: string}>(({ width }) => css`
    margin: 0.5rem 0;
    width: ${width || '100%'};
  `)

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > .label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const DropDownListContainer = styled.ul<{ isOpen: boolean, width?: number }>(({
  theme, isOpen, width,
}) => {
  const { palette } = theme
  return css`
    background-color: white;
    border: 1px solid ${palette.blue};
    box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    overflow: hidden;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    position: absolute;
    max-height: 50vh;
    overflow-y: scroll;
    ${width && `width: ${width}px;`}

    ${isOpen && css`
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  `
})
const SelectWithIconWrapper = styled.div`
  display: flex !important;
  cursor: pointer;
  align-items: center;
  width: 100%;
  svg{
    margin-right: 10px;
  }



  > :first-child {
      position: absolute;
      margin-left: 1.5rem;
    }

    > :last-child {
      position: absolute;
      right: 35%;
    }

`

const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 0.7rem;
    list-style: none;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    :hover {
      background-color: ${palette.results.green.opaque};
    }
  `
})

const NoItemsToDisplay = styled.p(({
  theme: {
    palette, typography,
  },
}) => css`
    padding: 0.75rem 2.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
`)

const StyledInput = styled(Input)<{isOpened: boolean}>(({
  theme: { palette }, isOpened,
}) => css`
  text-indent: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border-color: ${palette.blue};

  ${isOpened && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  :focus {
    border-width: 1px;
    box-shadow: none;
  }
  :hover {
    border-width: 1px;
    box-shadow: none;
  }
`)

const StyledPopup = styled(Popup)`
  &-overlay {
    display: none;
  }
`

const SelectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-evenly;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`

const SelectionBox = styled.div(({
  theme: {
    palette, corners, typography,
  },
}) => css`
  border-radius: ${corners.default.borderRadius};
  background-color: ${palette.blue};
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 0.6rem;
  padding: 0.2rem 0.6rem;

  span {
    font-size: ${typography.small.pt_13_regular.fontSize} !important;
    font-weight: ${typography.small.pt_13_regular.fontWeight} !important;
    line-height: ${typography.small.pt_13_regular.lineHeight} !important;
    color: ${palette.light.lighter} !important;
  }

  svg {
    cursor: pointer;
    width: 1rem;
    > path {
      stroke: ${palette.light.lighter};
    }
  }
`)

export interface IChoiceObject {
  id: string
  name: string
  checked: boolean
  listItemName: string | JSX.Element
  choices?: []
}

interface IMultipleChoiceDropdownProps {
  choices: IChoiceObject[]
  selectAll?: {
    text: string
  }
  onChange(areas: string[]): void
  width?: string
  label?: string
  subLabel?: string
  placeholder?: string
  icon?: JSX.Element
}

export const MultipleChoiceDropdown: React.FC<IMultipleChoiceDropdownProps> = ({
  choices, onChange, width, label, subLabel, icon, placeholder, selectAll,
}): JSX.Element => {
  // VARIABLES /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)

  // STATE /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [choicesArray, setChoicesArray] = useState(choices)
  const [selectedItems, setSelectedItems] = useState<IChoiceObject[]>(choices?.filter(choice => choice.checked))
  const [isOpened, setIsOpened] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [inputWidth, setInputWidth] = useState<number | undefined>(0)
  const [placeholderValue, setPlaceholderValue] = useState(placeholder)
  const [allItemsSelected, setAllItemsSelected] = useState(false)

  // FUNCTIONS /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const toggleChoice = (id: string) => {
    inputRef?.current?.focus()
    if (allItemsSelected) {
      setSelectedItems([])
      setAllItemsSelected(false)
    }
    const newChoice = choicesArray.find(choice => choice?.id === id)
    if (!newChoice) { return }
    const newChoices = selectedItems.find(item => item.id === newChoice.id)
      ? selectedItems.filter(choice => choice.id !== id)
      : [...selectedItems, newChoice]
    setSelectedItems(newChoices)
    setAllItemsSelected(newChoices?.length === choicesArray?.length)
    onChange(newChoices.map(choice => choice.id))
  }

  const toggleSelectAllItems = () => {
    if (!selectAll) { return }
    setAllItemsSelected(!allItemsSelected)
    setSelectedItems(allItemsSelected ? [] : choicesArray)
    onChange(choicesArray.map(choice => choice.id))
  }

  const handleInputChange = (value: string): void => {
    if (!isOpened) { setIsOpened(true) }
    setInputValue(value)
  }

  const handleDropdownOpen = () => {
    setIsOpened(true)
    setTimeout(() => inputRef?.current?.focus(), 10)
  }

  // USE_EFFECT ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!choices) { return }
    setChoicesArray(choices)
  }, [choices])

  useEffect(() => {
    setPlaceholderValue(allItemsSelected
      ? selectAll?.text
      : selectedItems?.length
        ? choicesArray?.filter(choice => selectedItems?.find(item => item.id === choice?.id))
          ?.map(choice => choice?.name)
          ?.join(', ')
        : placeholder)
  }, [selectedItems])

  useEffect(() => {
    const handleResize = () => setInputWidth(inputRef?.current?.clientWidth)
    handleResize()
    const handleResizeDebounced = debounce(handleResize, 10)
    window.addEventListener('resize', handleResizeDebounced)
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [])

  // JSX ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <Wrapper
      width={width}
    >
      <InputHeader>
        <span className='label'>{label}</span>
        <span>{subLabel}</span>
      </InputHeader>
      <StyledPopup
        arrow={false}
        on={['click']}
        onClose={() => setIsOpened(false)}
        onOpen={handleDropdownOpen}
        open={isOpened}
        position='bottom left'
        trigger={(
          <SelectWithIconWrapper>
            {icon && icon}
            <StyledInput
              ref={inputRef}
              isOpened={isOpened}
              onChange={e => handleInputChange(e?.target?.value)}
              placeholder={placeholderValue}
              value={inputValue}
            />
            <SelectCaret
              active={isOpened}
            />
          </SelectWithIconWrapper>
        )}
      >
        <DropDownListContainer
          isOpen={isOpened}
          width={inputWidth}
        >
          {selectAll && !inputValue && choicesArray?.length && (
            <ListItem
              key='_select_all_'
              onClick={toggleSelectAllItems}
            >
              <Checkbox
                key='_select_all_checkbox_'
                checked={allItemsSelected}
                id='_select_all_checkbox_'
                readOnly
              />
              {selectAll.text || t('generic.all')}
            </ListItem>
          )}
          {(() => {
            if (choicesArray?.length) {
              const filteredArray = choicesArray.filter(choice => choice?.name?.includes(inputValue))
              return filteredArray.length
                ? filteredArray.map((choice) => (
                  <ListItem
                    key={choice?.id}
                    onClick={() => toggleChoice(choice?.id)}
                  >
                    <Checkbox
                      key={choice?.id}
                      checked={!!selectedItems.find(item => item.id === choice.id)}
                      id={choice?.id}
                      readOnly
                    />
                    {choice?.listItemName}
                  </ListItem>
                ))
                : <NoItemsToDisplay>{t('generic.table.no_items_to_display')}</NoItemsToDisplay>
            }
            return null
          })()}
        </DropDownListContainer>
      </StyledPopup>
      <SelectionsWrapper>
        {allItemsSelected
          ? (
            <SelectionBox key='__all__'>
              <span>{selectAll?.text}</span>
              <CloseIcon onClick={toggleSelectAllItems} />
            </SelectionBox>
          )
          : selectedItems?.map(item => (
            <SelectionBox key={item.id}>
              <span>{item?.listItemName}</span>
              <CloseIcon onClick={() => toggleChoice(item.id)} />
            </SelectionBox>
          ))}
      </SelectionsWrapper>
    </Wrapper>
  )
}
