import React from 'react'
import styled, { css } from 'styled-components'
import { IParagraphProps } from './@types/IParagraphProps.props'
import { PProps } from './@types/PProps.props'

const StyledParagraph = styled.p<PProps>(({
  theme, size, spanColor, color, margin, textDecoration, maxWidth, fontStyle,
}) => css`
    margin: ${margin || '0'};
    color: ${color || theme.palette.dark.normal};
    font-size: ${() => {
    switch (size) {
      case 'small':
        return 'inherit' // 16px
      case 'verySmall':
        return '0.875rem' // 14px
      case 'big':
        return '2rem' // 32px
      default:
        return '1.125rem' // 18px
    }
  }};
  text-decoration:${textDecoration || 'none'} ;
  max-width: ${maxWidth || 'auto'};
  font-style: ${fontStyle || 'none'};

  span{
      font-weight: 600;
      color: ${spanColor || 'inherit'}
  }
`)

export const Paragraph = ({
  children, size, color, spanColor, margin, textDecoration, maxWidth, onClick, fontStyle,
}:IParagraphProps) :JSX.Element => (
  <>
    <StyledParagraph
      color={color}
      fontStyle={fontStyle}
      margin={margin}
      maxWidth={maxWidth}
      onClick={onClick}
      size={size}
      spanColor={spanColor}
      textDecoration={textDecoration}
    >
      {children}
    </StyledParagraph>
  </>
)
