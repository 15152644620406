import { createGlobalStyle, css } from 'styled-components'
import { ThemeType } from './defaultTheme'

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`

  html {
      font-size: ${typography.primary.fontSize};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    * {
      margin: 0;
      padding: 0;
    }

    body {
      display: flex;
      flex-direction: column;
      margin: 0;
      font-family: ${typography.primary.fontFamily};
      line-height: ${typography.primary.lineHeight};
      color: ${palette.dark.main};


      p {
        font-weight: 400;
        font-size: 16px;
      }
    }
  `
})

export default GlobalStyle
