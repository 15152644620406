/* eslint-disable max-lines */
import { CloseIcon, LinkWithIconDelete, RadioButton, TextButton, Tooltip, Button } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popup } from 'reactjs-popup'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { TextInput } from '@dataplace.ai/ui-components/molecules'
import { ReactComponent as HelpCircle } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { ITemplatesData } from '../../organisms/AnalyseTemplates/templatesData'
import { saveTemplateAction } from '../../../slice/analysisSlice'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
      border-radius: ${corners.default.borderRadius};
      background-color: ${palette.light.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2.5rem 2.5rem 1.5rem;
      width: 580px;
      max-height: 90vh;
    `
})

const Trigger = styled.div`
      display: flex;
      flex-direction: column;
      cursor: pointer;
  `

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1.25rem;
  
      h3 {
        display: flex;
        align-items: center;
        color: ${palette.black};
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};

        >svg{
            margin-right: .5rem;
            height: 2rem;
            width: 2rem;
        }
      }
    `
})

const DescHeader = styled.h4(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        margin: 1rem 0 0;
        color: ${palette.black};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        font-size: ${typography.main.pt_15_semibold.fontSize};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
        letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
        text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
    `
})

const DescPara = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        margin: 10px 0 20px;
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
    `
})

const List = styled.ul(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        color: ${palette.dark.normal};
        margin-top: .5rem;
        margin-left: 20px;

        >li{
            width:100%;
            margin-bottom: .5rem;
            font-size: ${typography.main.pt_15_regular.fontSize};
            font-weight: ${typography.main.pt_15_regular.fontWeight};
            line-height: ${typography.main.pt_15_regular.lineHeight};

            >div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width:100%;
                >div{
                    display: flex;
                    align-items: center;
                    >div{
                      display: flex;
                      align-items: center;
                        >svg {
                            width: 15px;
                            height: 15px;
                            margin-left: .5rem;
                            path {
                                fill: ${palette.blue};
                            }
                        }

                        .outside-plan-name{
                          color: #484E5E;
                          font-style: italic;
                          font-weight: 500;
                          font-size: 13px;
                        }
                        .outside-plan-lock{
                          margin-left: .5rem;
                          width: 10px;
                          height: 10px;
                          path {
                            fill: none;
                          }
                        }
                    }
                    >button{
                            margin-left: .5rem;
                    }

                    
                }
            }
        }
        ` })

const RadioButtonWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 1rem 0 2rem;
    color:${palette.black};
    display: flex;
    align-items: center;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  
    @media (max-width:1350px){
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-right: 15px;
    }
  `
})

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >button:first-of-type{
    margin-right: .5rem;
  }
`

const ScrollWrapper = styled.div`
  margin-right: .5rem;
  max-height: 550px;
`

export const tilesWithDoubleProperties = ['traffic_visualisation', 'customer_origin', 'traffic_visualisation_map', 'cannibalization', 'transport', 'potential_model', 'gravity_model', 'access_range', 'local_market_share']
export interface ITemplateTileModal {
  trigger: JSX.Element
  style?: React.CSSProperties | undefined
  templateTiles: ITemplatesData['tiles']
}

export const SaveTemplateModal = ({
  trigger, style, templateTiles,
}:ITemplateTileModal) :JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  const { tiles } = useSelector((state: RootState) => state.analysis)
  const [open, setOpen] = useState(false)
  const [chosenTiles, setChosenTiles] = useState(templateTiles)
  const [templateName, setTemplateName] = useState('')
  const [rememberRanges, setRememberRanges] = useState(true)

  // functions
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const getTileHint = (tileId: string) =>
    tiles?.find(cat => !!cat?.tiles?.find(tile => tile?.id === tileId))?.tiles?.find(tile =>
      tile?.id === tileId)?.labelHint

  const handleDeleteTile = (tileId: string) => {
    setChosenTiles(chosenTiles?.filter(tile => tile?.id !== tileId))
  }

  const handleCancel = () => {
    closeModal()
    setChosenTiles(templateTiles)
  }

  const handleChangeName = (name: string) => {
    setTemplateName(name)
  }

  const handleChangeRememberRange = () => {
    setRememberRanges(!rememberRanges)
  }

  const handleSave = useCallback(async () => {
    if (chosenTiles.length && templateName?.length) {
      const tiles = chosenTiles.map(item => ({
        id: item?.id,
        range: item?.suggestedRange && rememberRanges
          ? {
            length: item?.suggestedRange?.value,
            mode: item?.suggestedRange?.type,
          }
          : undefined,
      }))
      try {
        await dispatch(saveTemplateAction(token, templateName, tiles))
      }
      finally {
        closeModal()
      }
    }
  }, [token, chosenTiles, rememberRanges, templateName])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (templateTiles?.length) setChosenTiles(templateTiles)
  }, [templateTiles])

  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={() => {
        setOpen(false)
      }}
      onOpen={openModal}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger style={style}>{trigger}</Trigger>}
    >
      <>
        <Wrapper>
          <TopWrapper>
            <h3>
              {t('placeme.save_template.save_as')}
            </h3>
            <StyledCloseIcon onClick={handleCancel} />
          </TopWrapper>
          <TextInput
            label={t('placeme.template.save_template.name')}
            margin='0 0 1rem'
            name='templateName'
            onBlur={() => {}}
            onChange={(_, val) => handleChangeName(val)}
            placeholder={t(
              'placeme.template.save_template.name_placeholder',
            )}
            reset
            value={templateName}
          />
          <PerfectScrollbar
            className='scroll'
          >
            <ScrollWrapper>
              <DescHeader>{t('placeme.save_template.modal.desc_header')}</DescHeader>
              <DescPara>{t('placeme.save_template.modal.desc_para')}</DescPara>
              <List>
                {chosenTiles?.map(tile => (
                  <li key={tile?.id}>
                    <div>
                      <div>
                        {t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}
                        <Tooltip
                          content={t(getTileHint(tile?.id?.split('-')?.[0]) || '')}
                          maxWidth='300px'
                          position='right center'
                        >
                          <HelpCircle className='help-circle' />
                        </Tooltip>
                      </div>
                      <div>
                        <LinkWithIconDelete
                          focusStyle={false}
                          hideLabel
                          onClick={() => handleDeleteTile(tile?.id)}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </List>
            </ScrollWrapper>
          </PerfectScrollbar>
          <RadioButtonWrapper
            onClick={handleChangeRememberRange}
          >
            <RadioButton
              checked={rememberRanges}
              onChange={handleChangeRememberRange}
            />
            {t('placeme.templates.save_template.remember_ranges')}
          </RadioButtonWrapper>
          <BottomWrapper>
            <TextButton
              onClick={handleCancel}
              type='button'
            >
              <p>{t('generic.cancel')}</p>
            </TextButton>
            <Button
              disabled={!chosenTiles?.length || !templateName?.length}
              onClick={handleSave}
            >
              {t('placeme.save_template.save')}
            </Button>
          </BottomWrapper>
        </Wrapper>
      </>
    </Popup>
  )
}
