import React, { useRef } from 'react'
import ReactGlider, { GliderMethods } from 'react-glider'
import styled, { css } from 'styled-components'
import { IGliderProps } from '@dataplace.ai/ui-components/molecules/Glider/@types/IGlider.props'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    .glider {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .glider::-webkit-scrollbar {
      display: none;
    }

    .glider-slide {
      background-color: ${palette.light.white};
    }

    .glider-dots {
      display: flex;
      flex-direction: row;
      width: unset;
      justify-content: flex-start;
    }

    .glider-dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${palette.gray.light};
    }

    .glider-dot.active {
      background-color: ${palette.gray.dark};
    }
  `
})

export const Glider = ({
  slides, slidesToShow,
}: IGliderProps): JSX.Element => {
  const gliderRef = useRef<GliderMethods>(null)
  return (
    <Wrapper>
      <ReactGlider
        ref={gliderRef}
        draggable
        hasDots={slides && slides.length > 1}
        rewind
        slidesToScroll={1}
        slidesToShow={slidesToShow || 1}
      >
        {slides}
      </ReactGlider>
    </Wrapper>
  )
}
