interface IPlacemePlans {
  white: null
  trial: string,
  bronzeEco: string,
  bronze: string,
  bronzeUnlimited: string,
  silverEco: string,
  silver: string,
  silverUnlimited: string,
  goldEco: string,
  gold: string,
  goldUnlimited: string,
  bronzeGroup: string[],
  silverGroup: string[],
  goldGroup: string[],
  unlimitedGroup: string[],
}

export const placemePlans: IPlacemePlans = {
  white: null,
  trial: 'trial',
  bronzeEco: 'bronze_eco',
  bronze: 'bronze',
  bronzeUnlimited: 'bronze_unlimited',
  silverEco: 'silver_eco',
  silver: 'silver',
  silverUnlimited: 'silver_unlimited',
  goldEco: 'gold_eco',
  gold: 'gold',
  goldUnlimited: 'gold_unlimited',
  bronzeGroup: [],
  silverGroup: [],
  goldGroup: [],
  unlimitedGroup: [],
}

placemePlans.bronzeGroup = [placemePlans.bronzeEco, placemePlans.bronze, placemePlans.bronzeUnlimited]
placemePlans.silverGroup = [placemePlans.silverEco, placemePlans.silver, placemePlans.silverUnlimited]
placemePlans.goldGroup = [placemePlans.goldEco, placemePlans.gold, placemePlans.goldUnlimited]
placemePlans.unlimitedGroup = [placemePlans.bronzeUnlimited, placemePlans.silverUnlimited, placemePlans.goldUnlimited]
