import React from 'react'
import styled, { css } from 'styled-components'
import { ILinkWithIconInterface } from '@dataplace.ai/ui-components/atoms/LinksWithIcon/@types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NoteIcon } from 'libs/shared/assets/src/lib/icons/dataplace/file.svg'
import { ReactComponent as PlusIcon } from 'libs/shared/assets/src/lib/icons/plusIcon.svg'

const Button = styled.button<{focusStyle: boolean}>(({
  theme, focusStyle,
}) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    cursor: pointer;
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    > svg > path {
      stroke: ${palette.blue};
    }
    
    :hover {
      background-color: ${palette.light.darker};
    }

    ${focusStyle && css`
      :focus {
        color: ${palette.light.white};
        background-color: ${palette.product.location.main};
      }
    `}

    > span {
      margin-left: 0.25rem;
    }
  `
})

export const LinkWithIconAddNote = ({
  onClick, focusStyle = true, icon,
}: ILinkWithIconInterface): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button
      focusStyle={focusStyle}
      onClick={onClick}
    >
      {(() => {
        switch (icon) {
          case 'plus':
            return <PlusIcon />
          default:
            return <NoteIcon />
        }
      })()}
      <span>{t('generic.add_note')}</span>
    </Button>
  ) }
