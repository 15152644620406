
import React, { useContext, useEffect, useState, memo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  CoinsButton,
  // Tooltip
} from '@dataplace.ai/ui-components/atoms'
import { AuthContext } from '@dataplace.ai/features'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { Tile } from '../Tile'
import { HintsGlider } from '../HintsGlider'
import { RootState } from '../../../../../redux/store'
import { ITile } from '../../../slice/@types/ITile'
import { tilesData } from '../../../utils/tilesData'
import { addTileAction, saveIsMaxTilesNumberExceeded } from '../../../slice/analysisSlice'
import { AddAnotherRange } from '../../atoms'
// import { ReactComponent as Help } from
//  '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'

const { v4: uuidv4 } = require('uuid')

interface IAccList {
  0: string[]
  1: ITile[]
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`

const Header = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    align-items: center;
    display: flex;
    color: ${palette.black};
    width: 100%;
    padding: 1rem 2.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid ${palette.light.darker};
    border-top: 1px solid ${palette.light.darker};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};

    > svg {
      margin-right: 0.5rem;
    }
  `
})

const TilesWrapper = styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;

    @media (max-width: 1200px){
      padding: 1rem;
    }
  `,
)

const TileWrapper = styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
  `,
)

const StyledTileWrapper = styled(TileWrapper)`
  margin-bottom:5rem;
  `
const AddAnotherLeftWrapper = styled.div`
      margin-top:0;
      transition: 0.4s border-color;
      :hover{
        border-color: #234596;
      }
      svg{
        margin-right:0.5rem;
      }
  `

const HintsAndAskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  align-items: flex-end;
`

// const TriggerWrapper = styled.a(({ theme }) => {
//   const { palette } = theme
//   return css`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 2.5rem;
//     height: 2.5rem;
//     background-color: ${palette.blue};
//     border-radius: 50%;
//     cursor: pointer;

//     > svg {
//       width: 20px;
//       height: 20px;
//       border-radius: 50%;
//       background-color: ${palette.light.white};
//       ;
//       > path {
//         fill: ${palette.blue};
//       }
//     }
//   `
// })

interface ISectionTileProps {
  sectionTile: ISectionTile
  userId: string
  staticImage?: string
}

const tilesWithoutAddAnother = ['land_use_plan', 'look_alike_big_ben', 'scoring_model_big_ben', 'satellite', 'cities_population', 'municipality_population']

export const SectionTile: React.FC<ISectionTileProps> = memo(({
  sectionTile, userId, staticImage,
}) => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    label, tiles, icon,
  } = sectionTile

  const {
    showHints, canBeSave, comparedLocation, values,
  } = useSelector((state: RootState) => state.analysis)
  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)
  const authContext = useContext(AuthContext)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  const tilesNumber = values?.map(item => item?.tiles)?.flat()?.length

  // functions
  const fetchTileScheme = (categoryId:string, tileId: string) => {
    // eslint-disable-next-line prefer-destructuring
    const schemeTileId = tileId.split('-')[0]
    return tilesData.find(cat =>
      cat.id === categoryId)?.tiles.find(tile => tile.id === schemeTileId) as ITile
  }

  const handleAddTile = (tilesType: string) => {
    if (tilesNumber < 25) dispatch(addTileAction(token, sectionTile, fetchTileScheme(sectionTile.id, tilesType)))
    else dispatch(saveIsMaxTilesNumberExceeded(true))
  }

  const handleSortTypeGroups = (tiles: ITile[]) => {
    const tilesTypes: string[] = []
    tiles.forEach(tile => !tilesTypes.includes(tile.id.split('-')[0]) && tilesTypes.push(tile.id.split('-')[0]))

    return tilesTypes.map((tilesType) => {
      const componentsArray = (tiles.filter(tile => tile.id.split('-')[0] === tilesType).reduce((acc, curr) => {
        if (acc[0].includes(curr.id)) {
          return acc
        }
        acc[0].push(curr.id)
        acc[1].push(curr)
        return acc
      }, [[], []] as IAccList)[1].map(item =>
        (
          <TileWrapper key={item.id}>
            <Tile
              key={item.id}
              sectionTile={sectionTile}
              staticImage={staticImage}
              tile={item}
              userId={userId}
            />
            <HintsAndAskWrapper>
              {!!item?.hints?.length && showHints && <HintsGlider hints={item.hints} />}
              {/* <Tooltip
                content={t('placeme.ask_circle_menu.knowledge_base')}
                position='left top'
              >
                <TriggerWrapper
                  href='https://knowledge.dataplace.ai/knowledge'
                  target='_blank'
                >
                  <Help viewBox='1 1 14 14' />
                </TriggerWrapper>
              </Tooltip> */}
            </HintsAndAskWrapper>
          </TileWrapper>
        ))
      )
      if (tiles.filter(tile =>
        (tile.id.split('-')[0] === tilesType && !tilesWithoutAddAnother.includes(tile.id.split('-')[0]))).slice(-1)[0]?.data?.value && canBeSave) {
        componentsArray.push(
          <StyledTileWrapper key={uuidv4()}>
            <AddAnotherRange
              onClick={() => handleAddTile(tilesType)}
              style={{
                width: '74%',
                padding: '8px',
                backgroundColor: '#FAFBFC',
              }}
            >
              <AddAnotherLeftWrapper>
                <span>{`${['transport', 'cannibalization'].includes(tilesType) ? t('placeme.tiles.add_another_localization') : t('placeme.tiles.add_another_range')}: ${t(`${tiles.filter(tile => tile.id.split('-')[0] === tilesType)[0].label}`)}`}</span>
                <span>{['transport', 'cannibalization'].includes(tilesType) ? t('placeme.tiles.add_another_localization_description') : t('placeme.tiles.add_another_range_description')}</span>
              </AddAnotherLeftWrapper>
              {/* <ButtonWrapper> */}
              <CoinsButton
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                onClick={() => {}}
                subscription
                text={t('placeme.add_another_tile')}
                tileId={tiles.filter(tile => tile.id.split('-')[0] === tilesType)[0].id ?? ''}
                value={checkComparedCoinsValue(
                  comparedLocation?.generatedFromNow,
                  !!comparedLocation?.location,
                  !tilesWithoutComparedLocation.includes(sectionTile?.id),
                )}
              />
            </AddAnotherRange>
          </StyledTileWrapper>,
        )
      }
      return componentsArray
    })
  }

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  return (
    <Wrapper>
      <Header>
        {icon}
        <span>{t(`${label}`)}</span>
      </Header>
      <TilesWrapper>
        { handleSortTypeGroups(tiles)}
      </TilesWrapper>
    </Wrapper>
  )
})
