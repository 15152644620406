import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { addSeconds } from 'date-fns'
import { IRangeFilter } from './@types/IRangeFilter'

dayjs.extend(customParseFormat)

export const formatISOdate = (date: string): string => {
  const endIndex = date.indexOf('T')
  return endIndex === -1
    ? date
    : date.substring(0, endIndex).split('-')
      .reverse()
      .join('.')
}

export const getDayjs = (date: string | Date): dayjs.Dayjs => {
  if (typeof date === 'string') {
    const fixedDate = dayjs(date.trim().replace(/\D/g, '-'), 'DD-MM-YYYY', true)
    return fixedDate
  }
  const dayJSObj = dayjs(date)
  if (dayJSObj.isValid()) {
    return dayJSObj
  }
  return dayJSObj
}

export const validateDateField = (date: string | Date, minDate: null | Date = null, maxDate: null | Date = null):
boolean => {
  if (!date) { return true }
  const dayjsObj = getDayjs(date)
  const isValid = dayjsObj.isValid()
  const dayjsDate = dayjsObj.toDate()
  if (minDate && maxDate) { return isValid && minDate <= dayjsDate && dayjsDate <= maxDate }
  if (minDate) { return isValid && minDate <= dayjsDate }
  if (maxDate) { return isValid && dayjsDate <= maxDate }
  return dayjsObj.isValid()
}

export const filterByDate = (date: string | Date, filter: IRangeFilter | string): boolean => {
  if (!date) { return false }
  if (typeof filter === 'string') { return false }
  const {
    from, to,
  } = filter
  const elementDate = new Date(date)
  if (from && to) {
    return elementDate >= getDayjs(from).toDate() && elementDate <= addSeconds(getDayjs(to).toDate(), 86399)
  } if (from) {
    return elementDate >= getDayjs(from).toDate()
  }
  return elementDate <= addSeconds(getDayjs(to).toDate(), 86399)
}
