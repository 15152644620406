import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Checkbox, TextButton } from '@dataplace.ai/ui-components/atoms'
import { IStructuredUsageData } from 'apps/account/src/features/UserAccount/components/pages/UsageHistory/UsageHistory'
import { sortByText } from '@dataplace.ai/functions/utils'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'
import { ITextFilter } from '../TextFilter'

const CheckboxesOutsideWrapper = styled.div(({ theme: { palette } }) => css`
  position: relative;
  max-height: 15rem;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-top: 1px solid ${palette.gray.light};
  border-bottom: 1px solid ${palette.gray.light};
`)

const CheckboxesWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-top: 1rem;
  }

  > div:last-of-type {
    margin-bottom: 1rem;
  }

  label {
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    cursor: pointer;
  }
`)

const TextButtonComponent: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => <TextButton {...props} />

const StyledTextButton = styled(TextButtonComponent)(({
  theme: {
    palette, typography,
  },
}) => css`
  color: ${palette.blue};
  margin-top: .5rem;
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

export interface IPeopleFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: string
  }
}

interface IPeopleFilterProps {
  data: IStructuredUsageData[];
  filter: ITextFilter,
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
}

export const PeopleFilter = ({
  setFilterValue, header, filter, clearable, data, checkboxes,
}: IPeopleFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const [currentUser, setCurrentUser] = useState<null | string>(null)
  const suggestions = data.length
    ? [...new Map(data.map(item => item.user)
      .map(user => [user.email, user])).values()]
    : []
  const [showAllCheckBoxes, setShowAllCheckboxes] = useState(false)
  const [checkboxFilter, setCheckboxFilter] = useState('')
  const [filteredSuggestions,
    setFilteredSuggestions] = useState<{firstAndLastName: string, email: string}[]>(suggestions)
  const checkboxArray = showAllCheckBoxes
    ? sortByText(filteredSuggestions, 'firstAndLastName', 'ASC')
    : sortByText(filteredSuggestions, 'firstAndLastName', 'ASC').slice(0, 3)
  const [selectedPeople, setSelectedPeople] = useState<string[] | string>(filter[header.name]?.rule || [])
  const [showOnlyMineChecked, setShowOnlyMineChecked] = useState(false)

  useEffect(() => {
    if (authContext?.userData?.user?.email) {
      setCurrentUser(authContext.userData.user.email)
    }
  }, [authContext])

  const setPersonCheckboxFilterValue = (value: string) => {
    if (!value) {
      setCheckboxFilter('')
      setShowAllCheckboxes(false)
      setFilteredSuggestions(suggestions)
    } else {
      setCheckboxFilter(value)
      const tempSuggestions = suggestions.filter(item => {
        let nameIncludes = false
        let emailIncludes = false
        if (item.firstAndLastName) {
          nameIncludes = !!item.firstAndLastName.toLowerCase().match(new RegExp(`(.)*${value}(.)*`, 'i'))
        }
        if (item.email) {
          emailIncludes = !!item.email.toLowerCase().match(new RegExp(`(.)*${value}(.)*`, 'i'))
        }
        return nameIncludes || emailIncludes
      })
      setFilteredSuggestions(tempSuggestions)
      setShowAllCheckboxes(true)
    }
  }

  const clearCheckboxFilter = () => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[header.name]
    setCheckboxFilter('')
    setFilteredSuggestions(suggestions)
    setShowAllCheckboxes(false)
    setShowOnlyMineChecked(false)
    setSelectedPeople([])
    setFilterValue(newFilter)
  }

  const handleCheckboxChange = (checked: boolean, person :string, onlyMine = false) => {
    let newSelectedPeople
    if (onlyMine) {
      newSelectedPeople = [person]
    } else {
      setShowOnlyMineChecked(false)
      if (checked) {
        newSelectedPeople = [...selectedPeople, person]
      } else {
        newSelectedPeople = [...selectedPeople].filter(selectedPerson => selectedPerson !== person)
      }
    }
    const newFilter = {
      ...filter,
    }
    if (!newSelectedPeople.length) {
      delete newFilter[header.name]
    } else {
      newFilter[header.name] = {
        type: 'person',
        rule: newSelectedPeople,
      }
    }
    setSelectedPeople(newSelectedPeople)
    setFilterValue(newFilter)
  }

  useEffect(() => {
    if (!filter[header.name]?.rule) {
      setSelectedPeople([])
      setShowOnlyMineChecked(false)
    }
    if (filter[header.name]?.rule && currentUser) {
      setShowOnlyMineChecked(filter[header.name].rule.length === 1 && filter[header.name].rule[0] === currentUser)
    }
  }, [filter, currentUser])

  const handleShowOnlyMineToggle = () => {
    if (currentUser) {
      setShowOnlyMineChecked(!showOnlyMineChecked)
      if (!showOnlyMineChecked) {
        handleCheckboxChange(true, currentUser, true)
      } else {
        handleCheckboxChange(false, currentUser)
      }
    }
  }

  return (
    <FilterSection
      clear={clearCheckboxFilter}
      clearable={clearable}
      header={t(header.label)}
      headerSwitch={{
        label: t('account.usage_history.table.people_filter.show_only_mine'),
        onToggle: handleShowOnlyMineToggle,
        checked: showOnlyMineChecked,
      }}
    >
      <Input
        onChange={e => {
          setPersonCheckboxFilterValue(e.target.value)
        }}
        placeholder={t('account.find_user')}
        value={checkboxFilter}
      />
      {checkboxes && (
        <>
          <CheckboxesOutsideWrapper>
            <CheckboxesWrapper>
              {checkboxArray.map(person => (
                <Checkbox
                  key={person.email}
                  checked={selectedPeople.includes(person.firstAndLastName) || selectedPeople.includes(person.email)}
                  id={person.email}
                  label={(
                    <label htmlFor={person.email}>
                      {person.firstAndLastName || person.email}
                    </label>
                  )}
                  onChange={(e) => handleCheckboxChange(e.target.checked, person.firstAndLastName || person.email)}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxesOutsideWrapper>
          {filteredSuggestions.length > 3 && (
            <StyledTextButton
              onClick={() => setShowAllCheckboxes(!showAllCheckBoxes)}
            >
              {showAllCheckBoxes ? t('generic.hide') : t('generic.show_more')}
            </StyledTextButton>
          )}
        </>
      )}

    </FilterSection>
  )
}

PeopleFilter.defaultProps = {
  clearable: false,
  checkboxes: false,
}
