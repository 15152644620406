import styled, { css } from 'styled-components'
import { ReactComponent as Close } from '../../../../../assets/src/lib/icons/close.svg'

export const CloseIcon = styled(Close)(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    border-radius: ${corners.default.borderRadius};
    cursor: pointer;
    
    > path {
      stroke: ${palette.blue};
    }

    :active {
      > path {
        stroke: ${palette.blue};
      }
    }

    :hover {
      background-color: ${palette.light.darker};
      > path {
        stroke: ${palette.blue};
      }
    }

    :focus {
      outline: none;
      background-color: ${palette.dark.lightest};
      > path {
        stroke: ${palette.light.white};
      }
    }
  `
})
