import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ReactComponent as SpinnerIcon } from 'libs/shared/assets/src/lib/icons/spinner.svg'
import { IButtonProps } from './@types/IButton.props'

// TODO other styles

const StyledButton = styled.button<IButtonProps>(({
  theme, small, margin, deleteButton, maxWidth, loading,
}) => {
  const {
    palette, corners, typography, shadows,
  } = theme
  return css`
    margin: ${margin || '0'};
    background: ${deleteButton ? palette.results.red.light : palette.blue};
    border-radius: ${corners.default.borderRadius};
    outline: none;
    border: 1px solid ${deleteButton ? palette.results.red.dark : palette.dark.main};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${small ? '5px 15px' : '10px 20px'};
    min-width: ${maxWidth || (small ? '119px' : '180px')};
    max-height: 40px;
    color: ${palette.light.white};
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    transition: all .2s ease-in-out;

    :hover {
      background: ${deleteButton ? palette.light.darker : palette.product.location.main};
      box-shadow: ${shadows.hover.boxShadow};
      cursor: pointer;
      color: ${deleteButton && !loading ? palette.results.red.dark : 'white'};
      border-color: ${deleteButton ? palette.light.darker : palette.dark.main}
    }

    :focus{
      background: ${deleteButton ? palette.results.red.light : palette.product.location.light};
    }

    :active {
      border: 1px solid #E1E2E3;;
      background-color:${palette.light.main};
      color:${palette.blue};
    }

    :disabled{
      background: ${palette.dark.lightest};
      border: none;
    }


    ${loading && `
      display: grid;
      grid-template-columns: auto 1.3rem;
      align-items: center;

      :disabled {
        background-color: ${palette.light};
      }

      > span {
        margin-left: 2.6rem;
        margin-right: 1.3rem;
      }
    `}

  `
})

const Spinner = styled(SpinnerIcon)`
  width: 1.3rem;
  height: 1.3rem;
  opacity: .5;

  animation: rotation 0.8s infinite linear;
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
  }
`

export const ButtonProduct: React.FC<IButtonProps> = ({
  children, onClick, as, to, small, margin, maxWidth, loading, loadingText, ...restProps
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading) { setIsLoading(true) }
    if (onClick) { onClick(e) }
  }
  return (
    <>
      {as === 'navLink' && to
        ? (
          <NavLink to={to}>
            <StyledButton
              {...restProps}
              margin={margin}
              maxWidth={maxWidth}
              onClick={onClick}
              small={small}
            >
              {children}
            </StyledButton>
          </NavLink>
        )
        : (
          <StyledButton
            disabled={restProps?.disabled || !!isLoading}
            loading={loading && isLoading ? 1 : 0}
            margin={margin}
            maxWidth={maxWidth}
            onClick={handleClick}
            small={small}
            {...restProps}
          >
            {loading && isLoading
              ? (
                <>
                  <span>{loadingText || t('generic.loading')}</span>
                  <Spinner />
                </>
              )
              : children}
          </StyledButton>
        )}
    </>
  )
}
