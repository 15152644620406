import React from 'react'

import styled from 'styled-components'

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
interface ILoader {
  // eslint-disable-next-line react/require-default-props
  product?: 'auth' | 'placeme' | 'target';
}
export const LoadingPage = ({ product }:ILoader): JSX.Element => {
  const handleProductData = (product: ILoader['product']) => {
    switch (product) {
      case 'auth':
        return 'assets/loaders/auth/big.svg'
      case 'placeme':
        return 'assets/loaders/placeme/big-violet.svg'
      case 'target':
        return 'assets/loaders/target/big.svg'
      default: return 'assets/loaders/auth/big.svg'
    }
  }
  return (
    <LoaderWrapper>
      <object
        data={handleProductData(product)}
        type='image/svg+xml'
        width='650'
      >
        svg-animation
      </object>
    </LoaderWrapper>
  ) }
