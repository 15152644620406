
import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { tilesData } from '../../../utils/tilesData'
import { IAnalyseInfoModalNavProps } from './@types/IAnalyseInfoModalNav.props'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  padding: 0 2.5rem;
  border-bottom: 1px solid ${palette.light.darker};
`
})

interface INavItemProps {
  active?: boolean;
}

const NavItem = styled.span<INavItemProps>(({
  theme, active,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 0 0.75rem;
    cursor: pointer;
    padding-bottom: 1rem;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};

    ${active && css`
      color: ${palette.product.location.main};
      border-bottom: 2px solid ${palette.product.location.main};
    `}
    :first-child {
      margin-left: 0;
    }
  `
})

export const AnalyseInfoModalNav = ({
  activeSection, setActiveSection, setSearchPhrase,
}: IAnalyseInfoModalNavProps): JSX.Element => {
  const { t } = useTranslation()

  const handleClick = (selected: string) => {
    setActiveSection(selected)
    setSearchPhrase('')
  }

  return (
    <Wrapper>
      <NavItem
        active={!activeSection}
        onClick={() => handleClick('')}
      >
        {t('placeme.analyse_info_modal.all')}
      </NavItem>
      {tilesData.map(tile => (
        <NavItem
          key={`nav-${tile.id}`}
          active={activeSection === tile.id}
          onClick={() => handleClick(tile.id)}
        >
          {t(`placeme.tile.${tile.id}`)}
        </NavItem>
      ))}
    </Wrapper>
  )
}
