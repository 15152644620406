import React from 'react'
import styled, { css } from 'styled-components'

export type Result = 'positive' | 'negative' | 'average'
interface IWrapperProps {
  state: Result
}

const Wrapper = styled.span<IWrapperProps>(({
  theme, state,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    padding: 0.375rem 1rem;
    border-radius: 2.5rem;
    align-items: center;
    margin-bottom: 0.375rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize} !important;
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight} !important;
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight} !important;
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing} !important;
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform} !important;

    ${state === 'positive'
    && css`
      color: ${palette.results.green.dark} !important;
      border: 1px solid ${palette.results.green.dark};
      background-color: ${palette.results.green.opaque};
    `}
    ${state === 'negative'
    && css`
      color: ${palette.results.red.dark} !important;
      border: 1px solid ${palette.results.red.dark};
      background-color: ${palette.results.red.opaque};
    `}
    ${state === 'average'
    && css`
      color: ${palette.results.yellow.dark} !important;
      border: 1px solid ${palette.results.yellow.dark};
      background-color: ${palette.results.yellow.opaque};
    `}
  `
})

export interface ResultsProps {
  value: string
  state: Result
}

export const Results = ({
  value, state,
}: ResultsProps): JSX.Element => (
  <Wrapper state={state}>{value}</Wrapper>
)
