import axios, { AxiosResponse } from 'axios'
import { firebaseConfig } from '@dataplace.ai/services'

export const checkSessionRequest = ():Promise<AxiosResponse<string>> =>
  axios.post<string>(`${firebaseConfig.AUTH_SESSION_ENDPOINT}/check`, null, {
    withCredentials: true,
  })

export const createSessionRequest = (idToken: string):Promise<AxiosResponse<string>> =>
  axios.post<string>(`${firebaseConfig.AUTH_SESSION_ENDPOINT}/login`, {
    idToken,
  }, {
    withCredentials: true,
  })

export const destroySessionRequest = ():Promise<AxiosResponse<string>> =>
  axios.post<string>(`${firebaseConfig.AUTH_SESSION_ENDPOINT}/logout`, null, {
    withCredentials: true,
  })

