import React from 'react'
import styled, { css } from 'styled-components'
import { CardWrapperProps } from './@types/CardWrapper.props'
import { ICardProps } from './@types/ICard.props'

const CardWrapper = styled.div<CardWrapperProps>(({
  width, height, margin, padding,
}) => css`
    margin:${margin || 0};
    width: ${`${width}px` || 'auto'};
    height: ${height ? `${height - 64}px` : 'auto'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: ${padding || '2rem 1.6rem'};
    border-radius: 1rem;
    background-color: rgba(153, 153, 153, 0.1);
    overflow: hidden;
`)

const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const BottomWrapper = styled.div`
 display: flex;
    flex-direction: column;
`

export const Card = ({
  width, topChildren, bottomChildren, height, margin, padding,
}: ICardProps) :JSX.Element => (
  <CardWrapper
    height={height}
    margin={margin}
    padding={padding}
    width={width}
  >
    <TopWrapper>
      {topChildren}
    </TopWrapper>
    <BottomWrapper>{bottomChildren}</BottomWrapper>

  </CardWrapper>

)
