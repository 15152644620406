import { createFlashMessage, getAxios } from '.'
import { localStorageWorkspaceId, refreshWorkspaceId } from './storage'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFirstWorkspaceId = async (idToken: string) => {
  let permissions

  try {
    const axiosInstance = await getAxios(process.env.NX_PLACEME_API_URL, idToken)
    const response = await axiosInstance.get('permission')
    if (Array.isArray(response.data)) {
      permissions = response.data
    } else {
      permissions = []
      createFlashMessage({
        message: 'status.error.workspace.no_permissions',
      })
    }
  } catch (e) {
    permissions = []
    createFlashMessage({
      message: e?.error || 'status.error.workspace.no_permissions',
    })
  }

  if (permissions.length > 0) {
    window.localStorage.setItem('workspaceId', permissions?.[0]?.workspaceId)
  } else {
    createFlashMessage({
      message: 'status.error.workspace.no_permissions',
    })
  }
  window.dispatchEvent(new CustomEvent('refreshWorkspaceId'))
}

export const getValidWorkspace = async (idToken: string, currentWorkspaceId: string):Promise<boolean> => {
  let permissions

  try {
    const axiosInstance = await getAxios(process.env.NX_PLACEME_API_URL, idToken)
    const response = await axiosInstance.get('permission')
    if (Array.isArray(response.data)) {
      permissions = response.data
    } else {
      permissions = []
      createFlashMessage({
        message: 'status.error.workspace.no_permissions',
      })
    }
  } catch (e) {
    permissions = []
    createFlashMessage({
      message: e?.error || 'status.error.workspace.no_permissions',
    })
  }

  if (!(permissions.length > 0)) {
    createFlashMessage({
      message: 'status.error.workspace.no_permissions',
    })
    return false
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!permissions.find(workspace => workspace.workspaceId === currentWorkspaceId)) { // if current workspace is not in permissions
    window.localStorage.setItem(localStorageWorkspaceId, permissions?.[0]?.workspaceId)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    return true
    // eslint-disable-next-line
  } else {
    window.localStorage.setItem(localStorageWorkspaceId, currentWorkspaceId)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    return true
  }
}

