import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextButton } from '../../molecules'

const SectionHeader = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    padding-bottom: 6px;

    > .h-s-label {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
      letter-spacing: initial;
      text-transform: none;
      cursor: pointer;
      margin-left: .6rem;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 1.2rem;
      height: .7rem;
      margin-left: .3rem;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${palette.dark.lightest};
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 0.5rem;
      width: .5rem;
      left: .1rem;
      bottom: .1rem;
      background-color: white;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: ${palette.results.green.light};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      transform: translateX(.5rem);
    }

    .slider {
      border-radius: 1rem;
    }

    .slider:before {
      border-radius: 50%;
    } 
  `
})

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 2px solid ${palette.light.darker};
  padding: 12px 0 18px 0;
`
})

const SectionWrapper = styled.div(() => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`)

const HeaderWrapper = styled.div(() => css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`)
export interface IFilterSectionProps {
  header: string;
  clearable?: boolean;
  children: ReactNode;
  clear?(): void;
  headerSwitch?: {
    label: string
    onToggle(...args: any[]): void
    checked: boolean
  }
}

export const FilterSection = ({
  header, children, clear, clearable = false, headerSwitch,
}: IFilterSectionProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <HeaderWrapper>
        <SectionHeader>
          {header}
          {headerSwitch && (
            <>
              <label
                className='h-s-label'
                htmlFor={header}
              >
                {headerSwitch.label}
              </label>
              <label
                className='switch'
                htmlFor={header}
              >
                <input
                  checked={headerSwitch.checked}
                  id={header}
                  onClick={headerSwitch.onToggle}
                  readOnly
                  type='checkbox'
                />
                <span className='slider' />
              </label>
            </>
          )}
        </SectionHeader>
        {clearable && <TextButton onClick={clear}>{t('generic.clear_filter')}</TextButton>}
      </HeaderWrapper>
      <SectionWrapper>
        {children}
      </SectionWrapper>
    </Wrapper>
  ) }
