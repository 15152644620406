import { config } from '../../config'
import { getCookieWithoutJSON, localStorageWorkspaceId, refreshWorkspaceId } from '../utils'
import { getAxios } from '../utils/axios'
import { createFlashMessage } from '../utils/createFlashMessage'

export const inviteUsers = async (email: string, workspaceId: string, token: string) : Promise<void> => {
  const body = {
    email,
    workspaceId,
    language: getCookieWithoutJSON('language') || 'pl',
  }

  try {
    const axiosInstance = await getAxios(config.API_URL, token)
    await axiosInstance.post('invitation', body)
  }
  // eslint-disable-next-line no-empty
  finally {}
}

export const addWorkspace = async (
  newWorkspaceName: string,
  token: string,
  emails: string[],
  successHandler?: ()=>void,
) : Promise<void> => {
  const body = {
    name: newWorkspaceName,
    userLimit: 33,
  }
  let saveData: { value?: {id: string} ; loading?: boolean; error?: string }
  try {
    const axiosInstance = await getAxios(config.API_URL, token)
    const response = await axiosInstance.post('workspace', body)
    saveData = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    saveData = {
      loading: false,
      error: e.error,
    }
  }

  if (saveData?.value?.id) {
    createFlashMessage({
      message: 'status.success.workspace.created',
    })
    if (emails.length) {
      emails.forEach(email => inviteUsers(email, saveData?.value?.id as string, token))
    }

    if (successHandler) successHandler()

    window?.localStorage.setItem(localStorageWorkspaceId, saveData?.value?.id)
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
  }
}
