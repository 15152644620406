import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { IRange } from '@dataplace.ai/types'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ReactComponent as HelpCircle } from 'libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { Loader, TileFormParagraph, TileFormRowWithData, TileFormSection, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { TileFooter } from 'apps/placeme/src/features/Analyse/components/atoms'
import { ChosenRangeExistPopup, PointsCategorySelector } from 'apps/placeme/src/features/Analyse/components/molecules'
import { deleteTileAction, fetchRangesAction, saveNewRangeAction, saveTemporaryCatchment } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { RangeTile } from 'apps/placeme/src/features/Analyse/components/organisms'
import { IRangeAndCategoryTile } from 'apps/placeme/src/features/Analyse/components/molecules/RangeAndCategoryTile/@types/IRangeAndCategoryTile.props'
import { handleMaxRanges } from 'apps/placeme/src/functions/handleMaxRanges'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { tilesWithoutComparedLocation } from '../AddAnotherLocationModal/constants'
import { IGravityModelTileData } from '../../organisms/Tiles/Potential/GravityModel/@types/IGravityModelTileData'

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-bottom: -0.2rem;

    > path {
      fill: ${palette.blue};
    }`
})

const RangeSelectionWrapper = styled.div(() => css`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  padding: 1.25rem;
`)

const TileFormParagraphWithTooltip = styled(TileFormParagraph)(() => css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`)

export const RangeAndCategoryTile = ({
  userId, category, tile, maxRanges, accepted, setAccepted, isExtraPaid, handleCategoryChange, categoryValue,
  tileType,
}: IRangeAndCategoryTile) : JSX.Element => {
  // VARIABLES /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    ranges, values, creditsAmount, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const { analytics } = useContext(AnalyticsContext)
  const {
    currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)

  // STATE /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [chosenRange, setChosenRange] = useState<IRange>()
  const [scope, setScope] = useState<IRange[]>()
  const [isWarningModalOpen, toggleWarningModalOpen] = useState(false)
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [isCatchmentRequested, setIsCatchmentRequested] = useState(false)

  // FUNCTIONS /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChosenRangeChange = (range?: IRange) => {
    setChosenRange(handleMaxRanges(ranges.value, maxRanges).find(x =>
      x?.type === range?.type && x?.value === range?.value && range?.type !== 'custom') || range)
  }

  const checksIfRangeAndCategoryAreAlreadyChosen = (value: number, type: string) => {
    let areRangeAndCategoryAlreadyChosen = false
    const tilesOfType = values?.find(cat => cat.id === category)?.tiles?.filter(t => t.id.split('-')[0] === tile.split('-')[0])
    if (tilesOfType?.length) {
      for (const t of tilesOfType) {
        const dataValue = t?.data as IGravityModelTileData
        const shopCategory = dataValue?.value?.category
        if (t?.chosenRange?.value === value && t?.chosenRange?.type === type
          && shopCategory === categoryValue?.id
          && t?.chosenRange?.type !== 'custom') {
          areRangeAndCategoryAlreadyChosen = true
          break
        }
      }
    }

    return areRangeAndCategoryAlreadyChosen
  }

  const handleSave = () => {
    if (chosenRange
      && !checksIfRangeAndCategoryAreAlreadyChosen(chosenRange.value, chosenRange.type) && token?.length) {
      setIsCatchmentRequested(true)
      dispatch(saveNewRangeAction(token, userId, category, tile, chosenRange))
      dispatch(saveTemporaryCatchment(undefined))
    } else if (chosenRange) {
      toggleWarningModalOpen(true)
    }
  }

  const handleDeleteTile = () => {
    analytics?.track('Tile Cancel Button Clicked', {
      tile: tile?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction(token, category, tile, comparedAnalyseId || analyseId || ''))
  }

  const handleSubmit = () => {
    if (creditsAmount) {
      analytics?.track('Tile Data Generated', {
        tile: tile?.split('-')[0],
        range: {
          type: chosenRange?.type,
          value: chosenRange?.value,
        },
      })
      setLoading(true)
      handleSave()
    }
  }

  // USE_EFFECT ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token?.length && !ranges.value.length) dispatch(fetchRangesAction(token, userId))
  }, [token])

  useEffect(() => {
    if (values.length) {
      setChosenRange(values?.find(c => c?.id === category)?.tiles
        ?.find(t => t.id === tile)?.chosenRange || handleMaxRanges(ranges?.value, maxRanges)?.[0] || {})
    }
  }, [JSON.stringify(values), ranges?.value])

  useEffect(() => {
    if (values?.length && ranges?.value) {
      const fetchedMaxRanges = handleMaxRanges(ranges?.value, maxRanges)
      const rangeFromStore = values?.find(c => c?.id === category)?.tiles
        ?.find(t => t.id === tile)?.chosenRange
      if (rangeFromStore) {
        fetchedMaxRanges.push(rangeFromStore)
      }
      setScope(fetchedMaxRanges)
    }
  }, [values, ranges?.value])

  // JSX ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {(ranges?.value?.length && !isCatchmentRequested && scope)
        ? (
          <>
            <ChosenRangeExistPopup
              close={() => toggleWarningModalOpen(false)}
              isOpen={isWarningModalOpen}
              tileType={tileType}
            />
            <RangeSelectionWrapper>
              {!!ranges.value.length && (
                <RangeTile
                  chosenRange={chosenRange || handleMaxRanges(ranges?.value, maxRanges)[0]}
                  handleChosenRangeChange={handleChosenRangeChange}
                  maxRanges={maxRanges}
                  ranges={scope}
                  tile={tile}
                />
              )}
              <TileFormSection>
                <TileFormRowWithData>
                  <span>{t('placeme.gravity_model_tile.row_with_data_1_span_1')}</span>
                </TileFormRowWithData>
                <TileFormParagraphWithTooltip>
                  {t('placeme.gravity_model_tile.paragraph_1')}
                  <Tooltip
                    content={t('placeme.local_market_share_tile.tooltip')}
                    position='right center'
                  >
                    <StyledHelpCircle />
                  </Tooltip>
                </TileFormParagraphWithTooltip>
              </TileFormSection>
              <PointsCategorySelector
                accepted={accepted}
                onChange={handleCategoryChange}
                setAccepted={setAccepted}
                value={categoryValue}
              />
            </RangeSelectionWrapper>
            <TileFooter
              disabled={!chosenRange || !categoryValue?.id}
              isExtraPaid={isExtraPaid}
              isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
              label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
              onAccept={handleSubmit}
              onCancel={() => handleDeleteTile()}
              tile={tile}
              value={checkComparedCoinsValue(
                comparedLocation?.generatedFromNow,
                !!comparedLocation?.location,
                !tilesWithoutComparedLocation.includes(tile?.split('-')[0]),
              ).toString()}
            />
          </>
        )
        : (loading && <Loader />) }

    </>
  )
}
