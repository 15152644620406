// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import styled, { css } from 'styled-components'
import { ResourceSpan } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as CheckIcon } from '../../../assets/src/lib/icons/checkMark.svg'

const GreenBox = styled.div<{selected: boolean, small: boolean}>(({
  theme, selected, small,
}) => {
  const {
    palette, shadows, corners, typography,
  } = theme
  return css`
    box-shadow: ${shadows.tiny.boxShadow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    position: relative;
    padding: ${small ? '0.5rem 1.5rem' : '1rem 1.5rem'};
    margin-right: ${small ? '0.5rem' : '0.25rem'};
    cursor: pointer;
    outline: none;

    > ${ResourceSpan} {
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    :focus {
      border: 1px solid ${palette.product.location.main};
    }

    :hover {
      border: 1px solid ${palette.product.location.light};
    }

    :active {
      border-color: ${palette.results.green.dark};
    };

    ${selected && css`
      border: 2px solid ${palette.results.green.dark} !important;
      :after {
        content: '';
        z-index: 1;
        width: 22px;
        height: 19px;
        background-color: ${palette.results.green.dark};
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        border-top-right-radius: ${corners.default.borderRadius};
        border-bottom-left-radius: ${corners.default.borderRadius};
        background-image: url('assets/icons/checkMark.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
      }

      > ${ResourceSpan} {
        font-size: ${typography.main.pt_15_medium.fontSize};
        font-weight: ${typography.main.pt_15_medium.fontWeight};
        line-height: ${typography.main.pt_15_medium.lineHeight};
      }

    `};
  `
})

const RoundedBox = styled.div<{selected: boolean}>(({
  theme, selected,
}) => {
  const { palette } = theme
  return css`
    min-width: 79px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px 5px 7px;
    cursor: pointer;
    outline: none;

    background: #FFFFFF;
    border: 1px solid #E1E2E3;
    box-sizing: border-box;
    border-radius: 6px;
    ${selected && css`
      background: ${palette.results.green.dark};
      border: 1px solid ${palette.results.green.dark};
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      color: white;

      svg {
        path{

          fill: white;
        }
      }

    `}
  `
})
const SquaredBlueBox = styled.div<{selected: boolean}>(({
  theme, selected,
}) => {
  const {
    palette, typography, shadows,
  } = theme
  return css`
    min-width: 79px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 10px;
    cursor: pointer;
    outline: none;
    color: ${palette.black};
    background: #FFFFFF;
    border: 1px solid #E1E2E3;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: ${shadows.hover.boxShadow};
    margin-right: 15px;
    
    p {
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
    }
    
    ${selected && css`
      background: ${palette.blue};
      border: 1px solid ${palette.blue};
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      color: white;

      svg {
        width: 13px;
        height: 13px;
        path{
          fill: white;
        }
      }

    `}
  `
})
const CircleBox = styled.div<{selected: boolean}>(({
  theme, selected,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    p {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_medium.fontSize};
      font-weight: ${typography.main.pt_15_medium.fontWeight};
      line-height: ${typography.main.pt_15_medium.lineHeight};
      margin-left: 13px;
    }

    .circle{
      display: flex;
      align-items: center;
      justify-content: center;
      width:14px;
      height: 14px;
      border-radius: 20px;
      border: 1.5px solid ${palette.blue};
    }
    .inner-circle{
      display: none;
      width:12px;
      height:12px;
      border-radius: 20px;
      background-color:${palette.results.green.dark};
    }
    ${selected && css`
      .circle{
        border: 1.5px solid ${palette.results.green.dark};
      }
      .inner-circle{
        display: flex;
      }

    `}
  `
})
const BeanBox = styled.div<{selected: boolean}>(({
  theme, selected,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #E1E2E3;
    box-sizing: border-box;
    border-radius: 100px;
    
    p {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_medium.fontSize};
      font-weight: ${typography.tiny.pt_12_medium.fontWeight};
      line-height: ${typography.tiny.pt_12_medium.lineHeight};
    }
    ${selected && css`
      background: ${palette.results.green.dark};
      border: 1px solid ${palette.results.green.dark};
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      p {
        color: white;
      }
      
      svg {
        margin-right: 5px;
        width: 13px;
        height: 13px;
      }

    `}
  `
})

const CustomRangeBox = styled.div<{selected: boolean}>(({
  theme, selected,
}) => {
  const { palette } = theme
  return css`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
    outline: none;
    background: ${palette.dark.main};
    border-top: 1px solid ${palette.product.location.main};
    border-bottom: 1px solid ${palette.product.location.main};
    box-sizing: border-box;
    margin:0 !important;

    svg {
        path{
          fill: white;
        }
      }
    
    :first-child{
      border: 1px solid ${palette.product.location.main};
      border-radius: 6px 0 0 6px;
    }

    :last-child{
      border: 1px solid ${palette.product.location.main};
      border-radius: 0 6px 6px 0;
    }

    ${selected && css`
      background: ${palette.results.green.dark};
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      color: white;
    `}
  `
})

interface ResourceWrapperProps {
  shouldAllBeSelected?: number,
  selected: boolean;
  children: React.ReactNode;
  type?: 'bean'| 'green' | 'connected' | 'rounded' |'squaredBlue' | 'circle';
  onClick: () => void;
  small?: boolean
}

export const ResourceWrapper: React.FC<ResourceWrapperProps> = ({
  selected, children, onClick, type, small,
}) => {
  const renderResults = () => {
    switch (type) {
      case 'bean':
        return (
          <BeanBox selected={selected}>
            {selected && <CheckIcon />}
            {children}
          </BeanBox>
        )
      case 'squaredBlue':
        return (
          <SquaredBlueBox selected={selected}>
            {selected && <CheckIcon />}
            {children}
          </SquaredBlueBox>
        )
      case 'rounded':
        return (
          <RoundedBox selected={selected}>
            {children}
          </RoundedBox>
        )
      case 'connected':
        return (
          <CustomRangeBox selected={selected}>
            {children}
          </CustomRangeBox>
        )
      case 'circle':
        return (
          <CircleBox selected={selected}>
            <div className='circle'><div className='inner-circle' /></div>
            <p>{children}</p>
          </CircleBox>
        )
      default:
        return (
          <GreenBox
            selected={selected}
            small={small || false}
          >
            {children}
          </GreenBox>
        )
    }
  }
  return (
    <div
      onClick={onClick}
      tabIndex={0}
    >
      {renderResults()}
    </div>
  )
}
