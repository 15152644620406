import React from 'react'
import styled, { css } from 'styled-components'

const Bar = styled.span<IVerticalDividerProps>(({
  theme, height, margin,
}) => {
  const { palette } = theme
  return css`
    width: 1px;
    height: ${height || '100%'};
    background-color: ${palette.gray.light};
    margin: ${margin || '0 2rem'};
  `
})

interface IVerticalDividerProps{
  height?: string,
  margin?: string
}
export const VerticalDivider: React.FC< IVerticalDividerProps> = ({
  margin, height,
}) => (
  <Bar
    height={height}
    margin={margin}
  />
)
