import React, { useRef } from 'react'
import styled from 'styled-components'

const RollUpButtonWrapper = styled.div`
    display:flex;
    align-items: center;
    justify-content:center;
    width: 24px;
    height: 24px;
    background-color: #DDDDDD;
    border-radius: 20px;
    cursor: pointer;

    span{
        font-size: 13px; 
    }

    :hover{
        background-color:  #999999;
        color: white;
    }
`

/* eslint-disable-next-line */
export interface IRollUpButtonProps {
  handleRollUpButtonClick: () => void;
  isShown?: boolean
}

export function RollUpButton({
  handleRollUpButtonClick, isShown,
}: IRollUpButtonProps):JSX.Element {
  const rollUpButtonRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (rollUpButtonRef.current) {
      if (isShown) {
        rollUpButtonRef.current.style.transform = 'rotate(180deg)'
      }
      else {
        rollUpButtonRef.current.style.transform = 'rotate(0deg)'
      }
    }
    handleRollUpButtonClick()
  }
  return (
    <RollUpButtonWrapper
      ref={rollUpButtonRef}
      onClick={handleClick}
    >
      <span>〱</span>
    </RollUpButtonWrapper>
  )
}

export default RollUpButton
