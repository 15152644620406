import React from 'react'
import styled, { css } from 'styled-components'
import { VerticalDivider } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as DataplaceAi } from '../../../assets/src/lib/icons/placeme/dataplaceAi.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 2rem;

  > svg {
    height: 100%;
    width: auto;
  }
`

const PageName = styled.h3(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};
  `
})

interface LogoWithPageIndicatorProps {
  pageName: string;
}

export const LogoWithPageIndicator: React.FC<LogoWithPageIndicatorProps> = ({ pageName }) => (
  <Wrapper>
    <DataplaceAi />
    <VerticalDivider />
    <PageName>
      {pageName}
    </PageName>
  </Wrapper>
)

export default LogoWithPageIndicator
