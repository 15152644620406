/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { useTranslation } from 'react-i18next'
import { TileFormSectionTitle, TileFormRowWithData, TileFormSpan, TitleFormSectionSubTitle, TileFormRow, TileFormColumn, DoughnutChart, RangeChart, BarChart } from '@dataplace.ai/ui-components/atoms'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { getAxios, getRangeTranslationFromRangeId } from '@dataplace.ai/functions/utils'
import { config } from 'apps/placeme/src/config'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import NumberFormat from 'react-number-format'
import { bigBenCategoryId, bigBenChainRankingParameters } from '@dataplace.ai/constants'
import { IRange } from '@dataplace.ai/types'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { ITrafficTile, ITrafficTileData } from './@types/ITrafficTileData'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import AddMoreData from '../../../../molecules/AddMoreData/AddMoreData'
import { RankingCustomCatchmentSpan } from '../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
  border: 1px solid ${palette.light.darkest};
  border-radius: ${corners.default.borderRadius};
  padding: 20px;
  margin-bottom: 30px;
  `
})

const HoursWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    margin-bottom: 1rem;
  `
})

const Hours = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
    width: 40%;
    margin-right: 10%;

    > span:nth-child(1) {
      margin-bottom: 1rem;
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }

    > span:nth-child(2) {
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
    }
  `
})

const TileFormRowWithDataExt = styled(TileFormRowWithData)(({ theme }) => {
  const { typography } = theme

  return css`
  > span:nth-child(2) {
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }
  > span:nth-child(3) {
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
  }
`
})

export const TrafficTile: React.FC<{ data: ITrafficTileData, tileId: string }> = ({
  data, tileId,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const isMobileDevice = useCheckForMobileDevice()
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const rangeTranslation = useMemo(() =>
    getRangeTranslationFromRangeId(data?.value?.catchmentMode),
  [JSON.stringify(data?.value?.catchmentMode)])

  // states
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [initialData, setInitialData] = useState<ITrafficTile | undefined>(undefined)
  const [catchmentId, setCatchmentId] = useState<undefined | string>(undefined)
  const [chosenRange, setChosenRange] = useState<undefined | IRange>(undefined)

  // functions
  const getCatchmentId = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const getChosenRange = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange

  const getTileType = (id: string) => id.split('-')[0]

  const fetchData = useCallback(async (addData?: ITrafficTile) => {
    if (token.length) {
      const catchmentId = values?.find(c => c.id
        === bigBenCategoryId)?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

      let body: {
        [key: string]: unknown
      } = {
        catchmentId,
      }
      if (addData) {
        body = {
          ...body,
          extra: true,
        }
      } else {
        body = {
          ...body,
          rankingParameter: [
            bigBenChainRankingParameters.daily_average_unique_traffic,
          ],
        }
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData

      try {
        const axiosInstance = await getAxios(config.API_URL, token)
        const response = await axiosInstance.post(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        if (!addData) {
          dispatch(saveTileData('big_ben', tileId, saveData))
        } else {
          dispatch(saveTileData('big_ben', tileId, {
            ...data,
            value: {
              ...addData,
              additionalData: saveData?.value?.additionalData,
            },
          }))
        }
      }
    }
  }, [token])

  // hooks
  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (!data?.value) fetchData()
  }, [fetchData, !data?.value])

  useEffect(() => {
    setCatchmentId(getCatchmentId())
    setChosenRange(getChosenRange())
  }, [values])

  useEffect(() => {
    if (data?.value) {
      if (data?.value?.additionalData) {
        setInitialData(undefined)
      }
      else {
        setInitialData(data?.value)
      }
    }
  }, [data])

  // JSX ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return !data || data?.loading
    ? (
      <Loader />
    )
    : (
      <Wrapper>
        <Section>
          <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_1')}</TileFormSectionTitle>
          <TileFormRow width='100%'>
            <TileFormColumn>
              <TileFormRowWithData>
                <span>{t('placeme.big_ben_traffic_tile.row_with_data_1_span_1')}</span>
                <NumberFormat
                  displayType='text'
                  thousandSeparator=' '
                  value={(data?.value?.traffic?.dailyAverageUniqueTraffic)}
                />
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.big_ben_traffic_tile.form_span_1_normal_1')}
              </TileFormSpan>
            </TileFormColumn>
          </TileFormRow>

          <TitleFormSectionSubTitle>{t('placeme.big_ben_traffic_tile.section_sub_title_1')}</TitleFormSectionSubTitle>
          <TileFormSpan>
            {t('placeme.big_ben_traffic_tile.form_span_1_normal_2')}
            <RankingCustomCatchmentSpan
              chosenRange={chosenRange}
              rangeTranslation={rangeTranslation}
              tileId={tileId}
            />
          </TileFormSpan>
          <RangeChart
            label='generic.place'
            max={data?.value?.totalNumberOfShops}
            modal={{
              catchmentId,
              parameter: bigBenChainRankingParameters.daily_average_unique_traffic,
              rangeTranslation,
            }}
            tooltip1='placeme.big_ben_traffic.ranking.tooltip1'
            tooltip1Value={data?.value?.traffic?.highestDailyAverageUniqueTraffic}
            tooltip2='placeme.big_ben_traffic.ranking.tooltip2'
            tooltip2Value={data?.value?.traffic?.lowestDailyAverageUniqueTraffic}
            value={data?.value?.traffic?.dailyAverageUniqueTrafficRank}
          />

        </Section>

        <Section>
          <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_2')}</TileFormSectionTitle>
          <TileFormRow
            width='100%'
          >
            <TileFormColumn
              width={!isMobileDevice ? '60%' : '100%'}
            >
              <TileFormRowWithDataExt legend='#362FB7'>
                <img
                  alt='onFoot'
                  src='assets/icons/rangeIcons/onFoot.svg'
                  width='15px'
                />
                <span>{t('placeme.big_ben_traffic_tile.row_with_data_2_span_1')}</span>
                <span>{`${parseFloat((data?.value?.traffic?.transportModeStructure?.onFoot * 100).toFixed(1))}%`}</span>
              </TileFormRowWithDataExt>
              <TileFormSpan>
                {t('placeme.big_ben_traffic_tile.form_span_2')}
              </TileFormSpan>
              <TileFormRowWithDataExt legend='#7E7AD2'>
                <img
                  alt='car'
                  src='assets/icons/rangeIcons/car.svg'
                  width='20px'
                />
                <span>{t('placeme.big_ben_traffic_tile.row_with_data_2_span_2')}</span>
                <span>{`${parseFloat((data?.value?.traffic?.transportModeStructure?.byCar * 100).toFixed(1))}%`}</span>
              </TileFormRowWithDataExt>
              <TileFormSpan>
                {t('placeme.big_ben_traffic_tile.form_span_3')}
              </TileFormSpan>
            </TileFormColumn>
            <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
              <DoughnutChart
                data={[data?.value?.traffic?.transportModeStructure?.onFoot * 100,
                  data?.value?.traffic?.transportModeStructure?.byCar * 100]
                  .map(number => parseFloat(number.toFixed(1)))}
                innerLabels
                labels={[t('placeme.big_ben_traffic_tile.doughnut_chart_1_label_1'),
                  t('placeme.big_ben_traffic_tile.doughnut_chart_1_label_2')]}
                valueType='percentages'
                width={230}
              />
            </TileFormColumn>
          </TileFormRow>
        </Section>
        {!data?.value?.additionalData
               && canBeSave
        && (
          <AddMoreData
            loading={loading}
            onClick={() => {
              if (initialData) {
                setLoading(true)
                fetchData(initialData)
              }
            }}
            tiles={['placeme.big_ben_traffic_tile.section_title_3', 'placeme.big_ben_traffic_tile.section_title_4', 'placeme.big_ben_traffic_tile.section_title_5', 'placeme.big_ben_traffic_tile.section_title_6']}
          />
        )}
        {data?.value?.additionalData && (
          <>
            <Section>
              <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_3')}</TileFormSectionTitle>
              <TileFormRow
                width='100%'
              >
                <TileFormColumn
                  width={!isMobileDevice ? '60%' : '100%'}
                >
                  <TileFormRowWithData legend='#362FB7'>
                    <span>{t('placeme.big_ben_traffic_tile.row_with_data_3_span_1')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.visitorsStructure?.passingByTheArea * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.big_ben_traffic_tile.form_span_4')}
                  </TileFormSpan>
                  <TileFormRowWithData legend='#7E7AD2'>
                    <span>{t('placeme.big_ben_traffic_tile.row_with_data_3_span_2')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.visitorsStructure?.stayingInTheArea * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.big_ben_traffic_tile.form_span_5')}
                  </TileFormSpan>
                </TileFormColumn>
                <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                  <DoughnutChart
                    data={[data?.value?.additionalData?.visitorsStructure?.passingByTheArea * 100,
                      data?.value?.additionalData?.visitorsStructure?.stayingInTheArea * 100]
                      .map(number => parseFloat(number.toFixed(1)))}
                    innerLabels
                    labels={[t('placeme.big_ben_traffic_tile.doughnut_chart_2_label_1'),
                      t('placeme.big_ben_traffic_tile.doughnut_chart_2_label_2')]}
                    valueType='percentages'
                    width={230}
                  />
                </TileFormColumn>
              </TileFormRow>
            </Section>
            <Section>
              <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_4')}</TileFormSectionTitle>
              <TileFormRow width='100%'>
                <TileFormColumn>
                  <TileFormRowWithData>
                    <span>{t('placeme.big_ben_traffic_tile.row_with_data_4_span_1')}</span>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={(data?.value?.additionalData?.dailyAverage?.averageTrafficPerWeek)}
                    />
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.big_ben_traffic_tile.form_span_4_normal_1')}
                  </TileFormSpan>
                </TileFormColumn>
              </TileFormRow>

              <TitleFormSectionSubTitle>{t('placeme.big_ben_traffic_tile.section_sub_title_4')}</TitleFormSectionSubTitle>
              <TileFormSpan>
                {t('placeme.big_ben_traffic_tile.form_span_4_normal_2')}
                <RankingCustomCatchmentSpan
                  chosenRange={chosenRange}
                  rangeTranslation={rangeTranslation}
                  tileId={tileId}
                />
              </TileFormSpan>
              <RangeChart
                label='generic.place'
                max={data?.value?.totalNumberOfShops}
                modal={{
                  catchmentId,
                  parameter: bigBenChainRankingParameters.average_traffic_per_week,
                  rangeTranslation,
                }}
                tooltip1='placeme.big_ben_traffic.ranking.tooltip1'
                tooltip1Value={data?.value?.additionalData?.dailyAverage?.highestAverageTrafficPerWeek}
                tooltip2='placeme.big_ben_traffic.ranking.tooltip2'
                tooltip2Value={data?.value?.additionalData?.dailyAverage?.lowestAverageTrafficPerWeek}
                value={data?.value?.additionalData?.dailyAverage?.averageTrafficPerWeekRank}
              />

            </Section>
            <Section>
              <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_5')}</TileFormSectionTitle>
              <TileFormRow width='100%'>
                <TileFormColumn>
                  <TileFormRowWithData>
                    <span>{t('placeme.big_ben_traffic_tile.row_with_data_5_span_1')}</span>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={(data?.value?.additionalData?.dailyAverage?.averageTrafficPerWeekend)}
                    />
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.big_ben_traffic_tile.form_span_5_normal_1')}
                  </TileFormSpan>
                </TileFormColumn>
              </TileFormRow>

              <TitleFormSectionSubTitle>{t('placeme.big_ben_traffic_tile.section_sub_title_5')}</TitleFormSectionSubTitle>
              <TileFormSpan>
                {t('placeme.big_ben_traffic_tile.form_span_5_normal_2')}
                <RankingCustomCatchmentSpan
                  chosenRange={chosenRange}
                  rangeTranslation={rangeTranslation}
                  tileId={tileId}
                />
              </TileFormSpan>
              <RangeChart
                label='generic.place'
                max={data?.value?.totalNumberOfShops}
                modal={{
                  catchmentId,
                  parameter: bigBenChainRankingParameters.average_traffic_per_weekend,
                  rangeTranslation,
                }}
                tooltip1='placeme.big_ben_traffic.ranking.tooltip1'
                tooltip1Value={data?.value?.additionalData?.dailyAverage?.highestAverageTrafficPerWeekend}
                tooltip2='placeme.big_ben_traffic.ranking.tooltip2'
                tooltip2Value={data?.value?.additionalData?.dailyAverage?.lowestAverageTrafficPerWeekend}
                value={data?.value?.additionalData?.dailyAverage?.averageTrafficPerWeekendRank}
              />

            </Section>
            <Section>
              <TileFormSectionTitle>{t('placeme.big_ben_traffic_tile.section_title_6')}</TileFormSectionTitle>
              <HoursWrapper>
                <Hours>
                  <span>{t('placeme.big_ben_traffic_tile.hours_1')}</span>
                  <span>{`${data?.value?.additionalData?.visitingHours?.busyMorningHours?.start}:00 - ${data?.value?.additionalData?.visitingHours?.busyMorningHours?.end}:00`}</span>
                </Hours>
                <Hours>
                  <span>{t('placeme.big_ben_traffic_tile.hours_2')}</span>
                  <span>{`${data?.value?.additionalData?.visitingHours?.busyNightHours?.start}:00 - ${data?.value?.additionalData?.visitingHours?.busyNightHours?.end}:00`}</span>
                </Hours>
              </HoursWrapper>
              <TitleFormSectionSubTitle>{t('placeme.big_ben_traffic_tile.section_sub_title_6')}</TitleFormSectionSubTitle>
              <BarChart
                axisLabels={{
                  xAxis: t('placeme.visiting_hours.x_axis'),
                }}
                beginsAtZero
                dataset={[
                  {
                    label: '%',
                    data: (data?.value?.additionalData?.visitingHours?.visitingHours || []).map(vh =>
                      Math.round(vh?.percent * 100)),
                  },
                ]}
                labels={(data?.value?.additionalData?.visitingHours?.visitingHours || []).map(vh =>
                  (vh?.hour === 0 ? '24:00' : `${vh?.hour.toString()}:00`))}
                valueType='percentages'
              />
            </Section>
          </>
        )}
      </Wrapper>
    )
}

export default TrafficTile
