import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { CounterButtonProps } from './@types/ICounter.props'

const Wrapper = styled.div(() => css`
    display: grid;
    min-width: 10rem;
    grid-auto-flow: column;
    grid-template-columns: 25% 50% 25%;
    min-height: 2.5rem;
  `)

const CounterValue = styled.div(() => css`
    border: 0.065rem solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15rem;
  `)

const CounterButton = styled.button<CounterButtonProps>(({ position }) => css`
    ${position === 'left' && css`
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
    `}
    ${position === 'right' && css`
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    `}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0.065rem solid #DDDDDD;
    cursor: pointer;
  `)

export interface CounterProps {
  onChange: (counter: number) => void;
}

export const Counter: React.FC<CounterProps> = ({ onChange }) => {
  const [counter, setCount] = useState(1)

  useEffect(() => {
    onChange(counter)
  }, [counter])

  const handleCounter = (adder: boolean): void => {
    if (adder) {
      setCount(counter + 1)
    } else if (counter > 1) {
      setCount(counter - 1)
    }
  }

  return (
    <Wrapper>
      <CounterButton
        onClick={() => handleCounter(false)}
        position='left'
      >
        <img
          alt='minus icon'
          src='assets/icons/minusIcon.svg'
        />
      </CounterButton>
      <CounterValue>
        {counter}
      </CounterValue>
      <CounterButton
        onClick={() => handleCounter(true)}
        position='right'
      >
        <img
          alt='plus black icon'
          src='assets/icons/plusBlackIcon.svg'
        />
      </CounterButton>
    </Wrapper>
  )
}
