import { IRange } from '@dataplace.ai/types'

const { v4: uuidv4 } = require('uuid')

export const customRangesList: Record<string, {loading: boolean, error: string, value: IRange[]}> = {
  pois_big_ben: {
    loading: false,
    error: '',
    value: [
      {
        id: `walk5${uuidv4()}`,
        type: 'walk',
        value: 5,
      },
      {
        id: `car5${uuidv4()}`,
        type: 'car',
        value: 5,
      },
      {
        id: `walk10${uuidv4()}`,
        type: 'walk',
        value: 10,
      },
      {
        id: `car10${uuidv4()}`,
        type: 'car',
        value: 10,
      },
      {
        id: `walk15${uuidv4()}`,
        type: 'walk',
        value: 15,
      },
      {
        id: `line500${uuidv4()}`,
        value: 500,
        type: 'line',
      },
      {
        id: `line100${uuidv4()}`,
        value: 100,
        type: 'line',
      },
    ],
  },
  population_big_ben: {
    loading: false,
    error: '',
    value: [
      {
        id: `walk5${uuidv4()}`,
        type: 'walk',
        value: 5,
      },
      {
        id: `car5${uuidv4()}`,
        type: 'car',
        value: 5,
      },
      {
        id: `walk10${uuidv4()}`,
        type: 'walk',
        value: 10,
      },
      {
        id: `car10${uuidv4()}`,
        type: 'car',
        value: 10,
      },
      {
        id: `walk15${uuidv4()}`,
        type: 'walk',
        value: 15,
      },
      {
        id: `line500${uuidv4()}`,
        value: 500,
        type: 'line',
      },
      {
        id: `line100${uuidv4()}`,
        value: 100,
        type: 'line',
      },
    ],
  },
  traffic_big_ben: {
    loading: false,
    error: '',
    value: [
      {
        id: `walk5${uuidv4()}`,
        type: 'walk',
        value: 5,
      },
      {
        id: `car5${uuidv4()}`,
        type: 'car',
        value: 5,
      },
      {
        id: `walk10${uuidv4()}`,
        type: 'walk',
        value: 10,
      },
      {
        id: `car10${uuidv4()}`,
        type: 'car',
        value: 10,
      },
      {
        id: `walk15${uuidv4()}`,
        type: 'walk',
        value: 15,
      },
      {
        id: `line500${uuidv4()}`,
        value: 500,
        type: 'line',
      },
      {
        id: `line100${uuidv4()}`,
        value: 100,
        type: 'line',
      },
      {
        id: `line50${uuidv4()}`,
        value: 50,
        type: 'line',
      },
    ],
  },
  traffic_visualisation_big_ben: {
    loading: false,
    error: '',
    value: [
      {
        id: `walk5${uuidv4()}`,
        type: 'walk',
        value: 5,
      },
      {
        id: `car5${uuidv4()}`,
        type: 'car',
        value: 5,
      },
      {
        id: `walk10${uuidv4()}`,
        type: 'walk',
        value: 10,
      },
      {
        id: `car10${uuidv4()}`,
        type: 'car',
        value: 10,
      },
      {
        id: `walk15${uuidv4()}`,
        type: 'walk',
        value: 15,
      },
      {
        id: `line500${uuidv4()}`,
        value: 500,
        type: 'line',
      },
      {
        id: `line100${uuidv4()}`,
        value: 100,
        type: 'line',
      },
      {
        id: `line50${uuidv4()}`,
        value: 50,
        type: 'line',
      },
    ],
  },
}

export const isChosenRangeInDefaultRanges = (chosenRange: undefined | IRange, tileId: string): boolean => {
  const resp = !!customRangesList[tileId.split('-')?.[0] as keyof typeof customRangesList]?.value
    ?.find(range => range.value === chosenRange?.value && range.type === chosenRange?.type)
  return resp
}

