import React from 'react'
import styled, { css } from 'styled-components'
import { IDividerProps } from './@types/IDivider.props'

const StyledDivider = styled.span<IDividerProps>(({
  theme, margin, color,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    width: 100%;
    height: 1px;
    margin: ${margin || '0.5rem 0'};
    background-color: ${color || palette.dark.normal};
  `
})

export const Divider: React.FC<IDividerProps> = (props) => <StyledDivider {...props} />
