import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from '@dataplace.ai/ui-components/atoms'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATHS } from 'apps/placeme/src/constants/paths'
import { ReactComponent as CalendarIcon } from 'libs/shared/assets/src/lib/icons/calendar.svg'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    color: ${palette.black};
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-top: -4rem;
    margin-bottom: 4rem;

    > span {
      margin: 0 5px;
    }
  `
})

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

export const DataSetUpdateInfoPageLink = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CalendarIcon />
      <span>{t('placeme.analyse.data_update_date')}</span>
      <StyledNavLink to={PATHS.DATA_UPDATE_INFO}>
        <Link>
          {t('generic.see')}
        </Link>
      </StyledNavLink>
    </Wrapper>
  )
}
