import { useEffect, useState, useRef, Dispatch, MutableRefObject } from 'react'

export function useComponentVisible(initialIsVisible: boolean): {
  ref: React.MutableRefObject<HTMLDivElement>;
  isComponentVisible: boolean;
  setIsComponentVisible: Dispatch<React.SetStateAction<boolean>>;
} {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible)
  const ref = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current !== null) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsComponentVisible(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  }
}
