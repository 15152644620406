import React from 'react'
import styled, { css } from 'styled-components'
import { TableRow } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'

const { v4: uuidv4 } = require('uuid')

const Container = styled.div(() => css`
       .last{
      display: flex !important;
      align-items: flex-end;
    }
  `)

const GridHeader = styled.div<{headerTemplate: string, gap?: string, headerTextAlign?:string,
  buildings?: boolean, customer?: boolean}>(({
  theme, headerTemplate, gap, headerTextAlign, buildings, customer,
}) => {
  const { typography } = theme
  return css`
    grid-template-columns: ${headerTemplate};
    grid-gap: ${gap};
    display: grid;
    padding: 0.5rem;
    text-align: ${headerTextAlign || 'right'};
    color: #646464;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    > span:first-child {
      text-align: left;
    }
    
    ${buildings && css`
    >div:first-child{
      text-align: left;
    }`
}
${customer && css`
    >div:last-child{
      text-align: right;
    }`
}
  `
})

const NoItems = styled.h4(({ theme }) => {
  const { typography } = theme
  return css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${typography.main.pt_15_medium.fontSize};
  font-weight: ${typography.main.pt_15_medium.fontWeight};
  line-height: ${typography.main.pt_15_medium.lineHeight};
  margin-top: 2rem;
`
})

export interface ITableProps {
  labels: JSX.Element[];
  content: JSX.Element[][];
  expandable?: JSX.Element[][][];
  expandableComponent?: JSX.Element[];
  headerTemplate: string;
  rowTemplate: string;
  gap?: string;
  subRow?: JSX.Element[][] | null;
  whiteSpaces?: string;
  headerTextAlign?: string
  pois?: boolean
  expandableRowTemplate?: string
  buildings?: boolean
  customer?: boolean
}

export const Table = ({
  labels, content, headerTemplate, rowTemplate, gap, expandable, expandableComponent, subRow, whiteSpaces,
  headerTextAlign, pois, expandableRowTemplate, buildings, customer,
}: ITableProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container>
      <GridHeader
        buildings={buildings}
        customer={customer}
        gap={gap}
        headerTemplate={headerTemplate}
        headerTextAlign={headerTextAlign}
      >
        {labels.map(label => (
          <div key={uuidv4()}>
            {label}
          </div>
        ))}
      </GridHeader>
      {content?.map((element, index) => (
        <TableRow
          key={uuidv4()}
          content={element}
          expandable={expandable?.length ? expandable[index] : null}
          expandableComponent={expandableComponent?.length ? expandableComponent[index] : null}
          expandableRowTemplate={expandableRowTemplate}
          gap={gap}
          pois={pois}
          rowTemplate={rowTemplate}
          subRow={subRow?.length ? subRow[index] : null}
          whiteSpaces={whiteSpaces}
        />
      ))}
      {!content?.length && <NoItems>{t('generic.table.no_items_to_display')}</NoItems>}
    </Container>
  )
}
