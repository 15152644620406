import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Checkbox, TextButton } from '@dataplace.ai/ui-components/atoms'
import { IStructuredUsageData } from 'apps/account/src/features/UserAccount/components/pages/UsageHistory/UsageHistory'
import styled, { css } from 'styled-components'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'
import { FilterSection } from '../FilterSection'
import { ITextFilter } from '../TextFilter'

const CheckboxesOutsideWrapper = styled.div(({ theme: { palette } }) => css`
  position: relative;
  max-height: 15rem;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-top: 1px solid ${palette.gray.light};
  border-bottom: 1px solid ${palette.gray.light};
`)

const CheckboxesWrapper = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div {
    margin-top: 1rem;
  }

  > div:last-of-type {
    margin-bottom: 1rem;
  }

  label {
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    cursor: pointer;
  }
`)

const TextButtonComponent: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => <TextButton {...props} />

const StyledTextButton = styled(TextButtonComponent)(({
  theme: {
    palette, typography,
  },
}) => css`
  color: ${palette.blue};
  margin-top: .5rem;
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

export interface ITileFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: string
  }
}

interface ITileFilterProps {
  data: IStructuredUsageData[];
  filter: ITextFilter,
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  checkboxes?: boolean
}

export const TileFilter = ({
  setFilterValue, header, filter, clearable, data, checkboxes,
}: ITileFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const suggestions = data.length
    ? [...new Map(data.map(item => item.tileName)
      .map(tile => [tile, tile])).values()]
    : []
  const [showAllCheckBoxes, setShowAllCheckboxes] = useState(false)
  const [checkboxFilter, setCheckboxFilter] = useState('')
  const [filteredSuggestions,
    setFilteredSuggestions] = useState<string[]>(suggestions)
  const checkboxArray = showAllCheckBoxes
    ? filteredSuggestions.sort((a, b) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1))
    : filteredSuggestions.sort((a, b) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1)).slice(0, 3)
  const [selectedTiles, setSelectedTiles] = useState<string[] | string>(filter[header.name]?.rule || [])

  const setTileCheckboxFilterValue = (value: string) => {
    if (!value) {
      setCheckboxFilter('')
      setFilteredSuggestions(suggestions)
      setShowAllCheckboxes(false)
    } else {
      setCheckboxFilter(value)
      const tempSuggestions = suggestions.filter(item => item.toLocaleLowerCase().includes(value))
      setFilteredSuggestions(tempSuggestions)
      setShowAllCheckboxes(true)
    }
  }

  const clearCheckboxFilter = () => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[header.name]
    setCheckboxFilter('')
    setFilteredSuggestions(suggestions)
    setShowAllCheckboxes(false)
    setSelectedTiles([])
    setFilterValue(newFilter)
  }

  const handleCheckboxChange = (checked: boolean, person :string, onlyMine = false) => {
    let newselectedTiles
    if (onlyMine) {
      newselectedTiles = [person]
    } else if (checked) {
      newselectedTiles = [...selectedTiles, person]
    } else {
      newselectedTiles = [...selectedTiles].filter(selectedPerson => selectedPerson !== person)
    }
    const newFilter = {
      ...filter,
    }
    if (!newselectedTiles.length) {
      delete newFilter[header.name]
    } else {
      newFilter[header.name] = {
        type: 'tile',
        rule: newselectedTiles,
      }
    }
    setSelectedTiles(newselectedTiles)
    setFilterValue(newFilter)
  }

  useEffect(() => {
    if (!filter[header.name]?.rule) {
      setSelectedTiles([])
    }
  }, [filter])

  return (
    <FilterSection
      clear={clearCheckboxFilter}
      clearable={clearable}
      header={t(header.label)}
    >
      <Input
        onChange={e => {
          setTileCheckboxFilterValue(e.target.value)
        }}
        placeholder={t('account.usage_history.filter.find_tile')}
        value={checkboxFilter}
      />
      {checkboxes && (
        <>
          <CheckboxesOutsideWrapper>
            <CheckboxesWrapper>
              {checkboxArray.map(tile => (
                <Checkbox
                  key={tile}
                  checked={selectedTiles.includes(tile)}
                  id={tile}
                  label={(
                    <label htmlFor={tile}>
                      {tile}
                    </label>
                  )}
                  onChange={(e) => handleCheckboxChange(e.target.checked, tile)}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxesOutsideWrapper>
          {filteredSuggestions.length > 3 && (
            <StyledTextButton
              onClick={() => setShowAllCheckboxes(!showAllCheckBoxes)}
            >
              {showAllCheckBoxes ? t('generic.hide') : t('generic.show_more')}
            </StyledTextButton>
          )}
        </>
      )}

    </FilterSection>
  )
}

TileFilter.defaultProps = {
  clearable: false,
  checkboxes: false,
}
