import React from 'react'
import styled from 'styled-components'

const StyledIconButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  
  :hover{
      cursor: pointer;
  }

  :focus{
    border: none;
    outline: none;
  }
`

interface IIconButtonProps {
  iconAlt?: string;
  iconSrc?: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[]
}

export const IconButton = ({
  iconAlt, iconSrc, onClick, children,
}: IIconButtonProps):JSX.Element => {
  switch (true) {
    case !!children: {
      return (
        <StyledIconButton
          onClick={onClick}
        >
          {children}
        </StyledIconButton>
      )
    }
    default: {
      return (
        <StyledIconButton
          onClick={onClick}
        >
          <img
            alt={iconAlt}
            src={iconSrc}
          />
        </StyledIconButton>
      )
    }
  }
}
