import React from 'react'
import styled, { css } from 'styled-components'
import { IFormSectionProps } from '@dataplace.ai/ui-components/molecules/FormSection/@types/IFormSection.props'
import { SectionWrapperProps } from './@types/FormSection.props'

const SectionWrapper = styled.div<SectionWrapperProps>(({ centered }) => css`
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    ${centered && css`
      align-items: center;
    `}
  `)

export const SectionTitle = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};

    margin-bottom: 1rem;
  `
})

export const FormSection = ({
  children, header, centered,
}: IFormSectionProps): JSX.Element => {
  const isTextTitle = header && typeof header === 'string'
  const isReactNode = header && React.isValidElement(header)

  return (
    <SectionWrapper centered={centered}>
      {isTextTitle && (
        <SectionTitle>
          {header}
        </SectionTitle>
      )}
      {isReactNode && header}
      {children}
    </SectionWrapper>
  )
}

