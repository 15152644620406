import React, { useEffect, useState } from 'react'
import { Pagination } from '@dataplace.ai/ui-components/molecules'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { sortByDate, sortByText, sortByNumber } from '@dataplace.ai/functions/utils'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { SortableTableHeader } from './atoms'
import { TableFilter } from './molecules'
import { ISortableTableHeaderProps } from './@types'

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export interface IFilterableSortableTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  headers: ISortableTableHeaderProps[]
  itemsPerPage?: number
  gap?: string
  loading?: boolean
  columnTemplate?: string
}

export const FilterableSortableTable = ({
  data, headers, itemsPerPage, gap, loading, columnTemplate,
}: IFilterableSortableTableProps): JSX.Element => {
  const { t } = useTranslation()
  const [filteredData, setFilteredData] = useState(data)
  const [tableData, setTableData] = useState(data)
  const [sorter, setSorter] = useState({
    name: '',
    direction: '',
    type: '',
  })
  const filterable = !!headers.find(element => !!element.filter?.enabled) && data.length
  const sortingFunctions = {
    text: sortByText,
    date: sortByDate,
    number: sortByNumber,
    tile: sortByText,
  }
  const toggleSortingDirection = () => {
    let newDirection
    switch (sorter.direction) {
      case 'ASC':
        newDirection = 'DESC'
        break
      case 'DESC':
        newDirection = ''
        break
      default:
        newDirection = 'ASC'
    }
    setSorter({
      ...sorter,
      direction: newDirection,
    })
  }
  const toggleSorter = (name: string, type: string) => {
    if (name === sorter.name) {
      toggleSortingDirection()
    } else {
      setSorter({
        name,
        direction: 'ASC',
        type,
      })
    }
  }
  const sortData = () => {
    let sorted
    if (sorter.type === 'person') {
      const isAscending = sorter.direction === 'ASC'
      sorted = [...filteredData].sort((a, b) => {
        if (!a.user.firstAndLastName && !b.user.firstAndLastName) { return 0 }
        if (!a.user.firstAndLastName) { return isAscending ? -1 : 1 }
        if (!b.user.firstAndLastName) { return isAscending ? 1 : -1 }
        const aName = a.user.firstAndLastName.toLowerCase()
        const bName = b.user.firstAndLastName.toLowerCase()
        return isAscending
          ? (aName > bName ? 1 : -1)
          : (aName > bName ? -1 : 1)
      })
    } else {
      const sortingFunction = sortingFunctions[sorter.type as keyof typeof sortingFunctions]
      sorted = sortingFunction(filteredData, sorter.name, sorter.direction)
    }
    setTableData(sorted)
  }

  useEffect(() => {
    if (!sorter.direction) {
      setTableData(filteredData)
    } else {
      sortData()
    }
  }, [filteredData, sorter])
  interface ITableElementProps {
    tableData: string[][]
  }
  const TableElement = ({ tableData }: ITableElementProps): JSX.Element => (
    <Table
      content={(tableData as string[][] | {firstAndLastName: string, email: string}[][])
        .map(row => Object.entries(row).map(([key, value], index) => (
          <span
            key={key}
            style={{
              textAlign: index === 0 ? 'left' : 'right',
            }}
          >
            {headers[index].type === 'date'
              ? (dayjs(value).isValid()
                ? dayjs(value).format('DD.MM.YYYY')
                : '-')
              : (headers[index].type === 'person'
                ? value.firstAndLastName
                : value)}
          </span>
        )))}
      gap={gap}
      headerTemplate={columnTemplate || '1fr '.repeat(headers.length)}
      labels={headers.map((header, index) => {
        if (header.sortable) {
          return (
            <SortableTableHeader
              key={header.name}
              currentSorter={sorter}
              index={index}
              label={header.label}
              name={header.name}
              setSorter={toggleSorter}
              type={header.type}
            />
          )
        }
        return (
          <span key={header.label}>{header.label}</span>
        )
      })}
      rowTemplate={columnTemplate || '1fr '.repeat(headers.length)}
    />
  )
  return (
    <>
      {loading
        ? (
          <SpinnerWrapper>
            <object
              data='assets/loaders/account/small.svg'
              type='image/svg+xml'
              width='110'
            >
              placeme loader
            </object>
          </SpinnerWrapper>
        )
        : (
          <>
            {filterable
              ? (
                <TableFilter
                  data={data}
                  headers={headers}
                  setData={setFilteredData}
                />
              )
              : null}
            {itemsPerPage
              ? (
                <Pagination
                  data={tableData}
                  dataLimit={itemsPerPage}
                  renderComponent={(data) => (
                    <TableElement tableData={data as string[][]} />
                  )}
                  textSeparator={t('account.history_of_credits.pagin_separator')}
                />
              )
              : <TableElement tableData={tableData} />}
          </>
        )}
    </>
  )
}

FilterableSortableTable.defaultProps = {
  itemsPerPage: 0,
  loading: false,
}
