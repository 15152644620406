import React, { useEffect, useRef, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useComponentVisible } from '@dataplace.ai/functions/hooks'
import { IDropdownButtonProps } from './@types/IDropdownButton.props'
import { Caret } from '../../Caret'
import { DropdownContent } from './components'

const Wrapper = React.memo(styled.div<{minWidth?: string, disabled?: boolean}>(({
  theme, minWidth, disabled,
}) => {
  const { palette } = theme
  return css`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: ${minWidth};
  border:  ${disabled ? 'none' : `1px solid ${palette.dark.normal}`}; 
  border-radius: 6px;
` }))

const MainButtonWrapper = React.memo(styled.div`
  display: flex;
`)

const DropdownWrapper = styled.div<{caretActive:boolean, height: number, width: number}>(({
  theme, caretActive, height, width,
}) => {
  const { shadows } = theme
  return css`
  position: absolute;
  transform: ${height && `translate(0px, ${height + 1}px)`};
  box-shadow: ${shadows.hover.boxShadow};
  display: ${caretActive ? 'flex' : 'none'};
  flex-direction: column;
  width: ${`${width}px`};
  background-color: white;
  border: none;
  border-radius: 0 0 6px 6px;
`
})

const MainButton = React.memo(styled.button(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    background: ${palette.blue};
    border-radius: 5px 0 0 5px;
    outline: none;
    border: none;
    padding: 10px 10px 10px 20px;

    transition: all 0.5s;
    flex:1;
    cursor: pointer;
   
    >span{
      color: ${palette.light.white};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
    }
     
    :disabled {
        background-color: ${palette.dark.lightest};
        border: 1px solid #e1e2e3;
        border-right: none;
        color: white;
        cursor: default;
    }

   
  `
}))

const DropdownCaret = styled.button<{caretActive:boolean}>(({
  theme, caretActive,
}) => {
  const { palette } = theme
  return css`
  display: flex;
  align-items: center;
  background: ${palette.blue};
  justify-content: center;
  padding: 10px;
  padding-left: 0;
  min-height: 100%;
  flex: 0;
  outline: none;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  .dropdown-button-caret{
    path{
      stroke: white;
    }
    transform: none;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;

    ${caretActive && css`
      transform: rotate(180deg);
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
    `}
  }
  :disabled {
        background-color: ${palette.dark.lightest};
        border: 1px solid #e1e2e3;
        border-right: none;
        border-left: none;
        color: white;
        cursor: default;
    }
 
` })

const Separator = React.memo(styled.span<{disabled?: boolean}>(({ disabled }) => css`
  border-right: 1px solid #001D62;
  border-right:  ${disabled ? 'none' : '1px solid #001D62'}; 
  height: 100%;
  margin-right: 10px;
`))

export const DropdownButton: React.FC<IDropdownButtonProps> = ({
  minWidth, mainButton, mainButtonOnClick, dropdownContent, disabled,
}) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)
  const memoizedDropdownContent = useMemo(() => dropdownContent, [dropdownContent])
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const toggleDropdown = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  useEffect(() => {
    if (headerRef.current) {
      setHeight(headerRef.current.offsetHeight)
      setWidth(headerRef.current.offsetWidth)
    }
  }, [])

  return (
    <Wrapper
      ref={ref}
      disabled={disabled}
      minWidth={minWidth}
    >
      <MainButtonWrapper ref={headerRef}>
        <MainButton
          disabled={disabled}
          onClick={mainButtonOnClick}
        >
          {mainButton}
        </MainButton>
        <DropdownCaret
          caretActive={isComponentVisible}
          disabled={disabled}
          onClick={toggleDropdown}
        >
          <Separator disabled={disabled} />
          <Caret className='dropdown-button-caret' />
        </DropdownCaret>
      </MainButtonWrapper>
      <DropdownWrapper
        caretActive={isComponentVisible}
        height={height}
        width={width}
      >
        <DropdownContent dropdownContent={memoizedDropdownContent} />
      </DropdownWrapper>
    </Wrapper>
  )
}

