import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ITile } from '../../../slice/@types/ITile'
import { DataSetUpdateInfoTileRow } from '../../atoms/DataSetUpdateInfoTileRow'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(() => css`
  padding: 0 2.5rem;
`)

const Header = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWieght};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
  `
})

interface IDataSetUpdateInfoTileProps {
  tile: ITile
}

export const DataSetUpdateInfoTile = ({ tile }: IDataSetUpdateInfoTileProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Header>{t(`${tile.label}`)}</Header>
      {tile.dataSetUpdates?.map(row => (
        <DataSetUpdateInfoTileRow
          key={uuidv4()}
          row={row}
        />
      ))}
    </Wrapper>
  )
}
