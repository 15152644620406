
import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { tilesData } from '../../../utils/tilesData'
import { IAnalyseInfoModalContentProps } from './@types/IAnalyseInfoModalContent.props'
import { AnalyseInfoModalSelector } from '../AnalyseInfoModalSelector'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
    padding: 0 1rem 0 3rem;
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  `
})

const SectionWrapper = styled.div(() => css`
    display: flex;
    margin: 1.5rem 0 0;
    flex-direction: column;
  `)

const SectionHeader = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    margin: 0 0 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};

    > svg {
      margin-right: 0.5rem;
    }
  `
})

export const AnalyseInfoModalContent = ({
  activeSection, searchSection,
}: IAnalyseInfoModalContentProps): JSX.Element => {
  const { t } = useTranslation()
  const selectedSections = !activeSection ? tilesData : tilesData.filter(tile => tile.id === activeSection)
  const sectionsToDisplay = searchSection.length ? searchSection : selectedSections

  return (
    <Wrapper>
      {sectionsToDisplay.map(section => (
        <SectionWrapper key={section.id}>
          <SectionHeader>
            {section.icon}
            {t(`placeme.section.${section.id}`)}
          </SectionHeader>
          {section.tiles.map(tile => (
            <AnalyseInfoModalSelector
              key={`modal-${tile.id}`}
              section={section}
              tile={tile}
            />
          ))}
        </SectionWrapper>
      ))}
    </Wrapper>
  )
}
