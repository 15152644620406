
export const getProperPlanName = (name: string) :string => {
  if (name.includes('white')) {
    return 'essential'
  } if (name.includes('trial')) {
    return 'trial'
  } if (name.includes('bronze')) {
    return 'standard'
  } if (name.includes('silver')) {
    return 'plus'
  } if (name.includes('gold')) {
    return 'pro'
  }
  return 'essential'
}

export const getHigherPricingPlan = (currentPlanId: string): string => {
  const currentPlan = getProperPlanName(currentPlanId)
  if (currentPlan === 'standard') {
    return 'silver'
  } if (currentPlan === 'plus') {
    return 'gold'
  } if (currentPlan === 'pro') {
    return 'unlimited'
  } if (currentPlan === 'essential') {
    return 'bronze'
  } if (currentPlan === 'trial') {
    return 'bronze'
  }
  return 'trial'
}

