import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { Button } from '@dataplace.ai/ui-components/atoms'

const StyledForm = styled(Form)`
    display: flex;
    width: 34em;
    justify-content: space-between;
    align-items: flex-end;
`

const StyledField = styled(Field)`
  width: 23em;
  height: 2.5em;
  padding: 0 10px;
  border: 1.5px solid black;
  border-radius: 7px;

  :focus {
    outline: none;
    border: 2px solid black;
  }
`

export interface IOneInputFormProps {
  buttonText: string;
  handleSubmit: (location: string) => void
}

export const OneInputForm = ({
  buttonText,
  handleSubmit,
}: IOneInputFormProps) :JSX.Element => (
  <Formik
    initialValues={{
      address: '',
    }}
    onSubmit={(values) => {
      handleSubmit(values.address)
    }}
  >
    {() => (
      <StyledForm>
        <StyledField
          name='address'
          type='text'
        />
        <Button
          type='submit'
        >
          {buttonText}
        </Button>
      </StyledForm>
    )}
  </Formik>
)
