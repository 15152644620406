import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  DropdownCaret,
  IDropdownCaretProps,
} from '@dataplace.ai/ui-components/atoms'
import { IDropdownProps } from './@types/IDropdown.props'

const DropdownContent = styled.div<IDropdownCaretProps>(
  ({ active }) => css`
    height: ${active ? 'auto' : 0};
    overflow: hidden;
    margin-left: calc(20px + 2rem);
    margin-top: 0.5rem;
    margin-bottom: ${active ? '1rem' : '0'};
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  `,
)

const DropdownHeader = styled.div<IDropdownCaretProps>(
  ({ active }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    div {
      margin: 0 1rem 0 0;
    }

    span {
      /* font-weight: ${active ? 700 : 400}; */
      text-shadow: ${active
    ? '-0.5px -0.5px 0 #001D62, 0.5px -0.5px 0 #001D62, -0.5px 0.5px 0 #001D62, 0.5px 0.5px 0 #001D62'
    : '0'};
      font-size: 1.125rem;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }
  `,
)

export const Dropdown: React.FC<IDropdownProps> = ({
  header,
  content,
  style,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const toggleDropdown = () => {
    setOpen(!isOpen)
  }

  return (
    <div style={style}>
      <DropdownHeader
        active={isOpen}
        onClick={toggleDropdown}
      >
        <DropdownCaret active={isOpen} />
        <span>{header}</span>
      </DropdownHeader>
      <DropdownContent active={isOpen}>{content}</DropdownContent>
    </div>
  )
}
