import React from 'react'
import styled, { css } from 'styled-components'
import ReactCodeInput from 'react-verification-code-input'
import { ICodeInputProps } from './@types/ICodeInput.props'

const CodeInputWrapper = styled.div<Partial<ICodeInputProps>>(() => css`
  > div{
    width: 510px !important;
    display: flex;
  }
  > div > div{
    display: flex;
  }
  > div > div input{
    color: #262626;
    margin-right: 10px;
    border-radius: 6px;
    border: 1px solid #234596;
    font-family: 'Inter',sans-serif;
  }
  > div > div > input:nth-child(n+1){
    border: 1px solid #234596;
  }
`)

export const CodeInput: React.FC<ICodeInputProps> = ({ onChange }) => (
  <CodeInputWrapper>
    <ReactCodeInput
      autoFocus
      fieldHeight={40}
      fields={6}
      fieldWidth={75}
      onChange={onChange}
      type='number'
    />
  </CodeInputWrapper>
)
