import React from 'react'
import styled, { css } from 'styled-components'

const ToggleSwitch = styled.div(({ theme }) => {
  const { palette } = theme

  return css`
    position: relative;
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    
    .toggle-switch-checkbox {
      display: none;
    }
    
    .toggle-switch-label {
      display: block;
      height: 16px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 20px;
      margin: 0;
      background: ${palette.grey};
    }

    .toggle-switch-inner {
      display: block;
      height: 16px;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
    }

    .toggle-switch-switch {
      display: block;
      width: 12px;
      height: 12px;
      background: ${palette.blue};
      position: absolute;
      top: 2px;
      right: 16px;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
      background: ${palette.light.white};
    }

    .toggle-switch-checkbox:checked + .toggle-switch-label {
      .toggle-switch-inner {
        margin-left: 0;
        background: ${palette.blue};
      }
      .toggle-switch-switch {
        right: 2px;
      }
    } 
  `
})

interface ISwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
}

export const Switch: React.FC<ISwitchProps> = ({ ...props }) => (
  <ToggleSwitch>
    <input
      {...props}
      className='toggle-switch-checkbox'
      id='toggleSwitch'
      name='toggleSwitch'
      type='checkbox'
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label
      className='toggle-switch-label'
      htmlFor='toggleSwitch'
    >
      <span className='toggle-switch-inner' />
      <span className='toggle-switch-switch' />
    </label>
  </ToggleSwitch>
)
