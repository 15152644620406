import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { Loader, TileFormRowWithData } from 'libs/shared/ui-components/src/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { IMunicipalityPopulationTileData } from './@types/IMunicipalityPopulationTileData'
import { ComparedLocationHeader } from '../../../../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin: 0.5rem 0 1rem;
  `
})

const Label = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    width: 33%;
    text-align: right;
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      padding-left: 0.75rem;
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      padding-right: 0.75rem;
      margin-right: 0;
    }
  `
})

const Row = styled.span(({ theme }) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-right: ${corners.default.borderRadius};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
      text-align: right;
      margin: 0 1.25rem;
      width: 33%;
    }

    > span:first-child {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-left: 0;
      text-align: left;
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      > span {
        width: max-content;
      }
    }
  `
})

const Description = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
 
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 10px;
    color: ${palette.black};
    margin-bottom: 2rem;
  
`
})

const labels = [
  'placeme.municipality_tile.table_label_1',
  'placeme.municipality_tile.table_label_2',
  'placeme.municipality_tile.table_label_3',
]

export const MunicipalityPopulationTile: React.FC<{ data: IMunicipalityPopulationTileData }> = ({ data }) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const sumInhabitants = (isComparedLocation: boolean) => {
    let sum = 0
    if (!isComparedLocation) {
      if (data?.value?.municipalityPopulation?.length) {
        data?.value?.municipalityPopulation?.forEach(value => { sum += value?.populationNumber })
      }
    } else if (data?.value?.comparedLocation?.municipalityPopulation?.length) {
      data?.value?.comparedLocation?.municipalityPopulation?.forEach(value => { sum += value?.populationNumber })
    }
    return sum
  }

  const getComparisonDiff = () => {
    if (data?.value?.comparedLocation) {
      return ((sumInhabitants(true) - sumInhabitants(false)) / sumInhabitants(true)) * 100
    }
    return 0
  }

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <TileFormRowWithData>
          <span>{t('placeme.municipality_population.number_of_inhabitants')}</span>
          <span>{formatNumber(sumInhabitants(false))}</span>
          {data?.value?.comparedLocation?.municipalityPopulation && (
            <ComparedLocationBean
              comparedAddress={comparedLocation?.location?.address}
              comparedValue={formatNumber(sumInhabitants(true))}
              difference={parseInt(getComparisonDiff().toFixed(1), 10)}
              mainAddress={value?.address}
              mainValue={formatNumber(sumInhabitants(false))}
            />
          )}
        </TileFormRowWithData>
        <Description>{t('placeme.municipality_population.number_of_inhabitants.desc')}</Description>
        {data?.value?.comparedLocation?.municipalityPopulation
        && (
          <ComparedLocationHeader>
            <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
            {' '}
            <span>{value?.address}</span>
          </ComparedLocationHeader>
        )}
        <Header>
          {labels.map((label) => (
            <Label key={label}>{t(label)}</Label>
          ))}
        </Header>
        {data?.value?.municipalityPopulation?.map((value) => (
          <Row key={value?.municipalityId}>
            <span>{value?.cityName}</span>
            <span>{formatNumber(value?.populationNumber)}</span>
            <span>{value?.municipalityName}</span>
          </Row>
        ))}
        <>
          {data?.value?.comparedLocation?.municipalityPopulation
         && (
           <>
             <ComparedLocationHeader second>
               <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
               {' '}
               <span>{comparedLocation?.location?.address}</span>
             </ComparedLocationHeader>
             <Header>
               {labels.map((label) => (
                 <Label key={label}>{t(label)}</Label>
               ))}
             </Header>
             {data?.value?.comparedLocation?.municipalityPopulation?.map((value) => (
               <Row key={value?.municipalityId}>
                 <span>{value?.cityName}</span>
                 <span>{formatNumber(value?.populationNumber)}</span>
                 <span>{value?.municipalityName}</span>
               </Row>
             ))}
           </>
         )}
        </>
      </Wrapper>
    )
}
