import styled, { css } from 'styled-components'

export const TileFormWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    background-color: ${palette.light.white};

    > div:last-child > span {
      margin-bottom: 0;
    }

    > div:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  `
})
