/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormSection,
  TileFormWrapper,
  TileFormRowWithData,
  TileFormParagraph, TitleFormSectionSubTitle, CloseIcon, RadioButton, TextButton,
} from '@dataplace.ai/ui-components/atoms'
import { Trans, useTranslation } from 'react-i18next'

import { TextInput, GeosuggestForm, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { ILocation, IRange } from '@dataplace.ai/types'
import { Loader } from 'libs/shared/ui-components/src/atoms'

import { DropZone, ResourcesSelector } from '@dataplace.ai/ui-components/organisms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { createFlashMessage, formatNumber } from '@dataplace.ai/functions/utils'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { ComparedLocationHeader, LocalizationSelectorWrapper, TileFooter } from '../../../../atoms'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { compareLocationCatchmentAndDataAction, deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { ITransportTileData, IWorkspacePoint } from './@types/ITransportTileData'
import { RootState } from '../../../../../../../redux/store'
import { ReactComponent as Car } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/car.svg'
import { ReactComponent as SmallTruck } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/smallTruck.svg'
import { ReactComponent as BigTruck } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/rangeIcons/bigTruck.svg'
import { ReactComponent as TrashIcon } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/trashIcon.svg'
import { ReactComponent as Edit } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/edit.svg'
import { getFormatTime } from '../../../../molecules'

const StyledTileFormWrapper = styled(TileFormWrapper)<{isData: boolean}>(({
  theme, isData,
}) => {
  const { palette } = theme
  return css`
    background-color: ${isData ? 'white' : palette.light.main};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin: 1.5rem 0 20px;
`

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const ContentWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
)
const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 0.5rem 0;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  `
})

const Item = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    padding: 0.375rem 0.5rem;
    flex-direction: row;
    margin: 0.25rem 0;
    align-items: center;
    background-color: ${palette.light.main};
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    :hover > span {
      color: ${palette.blue};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }
  `
})

const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`

const RadioButtonWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color:${palette.black};
  display: flex;
  align-items: center;
  font-size: ${typography.main.pt_15_regular.fontSize};
  font-weight: ${typography.main.pt_15_regular.fontWeight};
  line-height: ${typography.main.pt_15_regular.lineHeight};
  margin-right: 25px;

  @media (max-width:1350px){
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-right: 15px;
  }
`
})

const TransportTypeItem = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  `
})

const LoadPointsWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >div{
      width: 70%;
    }
    >button{
        color:${palette.blue};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        border: none;
        margin-left: 24px;
        background-color: white;
    }
  `
})

const UploadedFileLabelWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  display: flex;
  align-items: center;
  margin: 5px 0 10px;
  
  >p{
    color: ${palette.black};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
  }

  >span{
    margin-left: 1rem;
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  }

  .trash {
    cursor: pointer;
    margin-left: 7px;
    path{
      stroke: #F08F7F;
    }
  }

  .edit{
    cursor: pointer;
    margin-left: 7px;
    path{
      fill: #F08F7F;
    }
  }
`
})
const SelectedSection = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    background: ${palette.light.main};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;

    > span > svg {
      cursor: pointer;
    }

    > span {
      display: flex;
    }

    > span > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }

    > div {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: column;
      color: ${palette.dark.normal};
      >p{ 
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_semibold.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
         line-height: ${typography.tiny.pt_12_semibold.lineHeight};
        >span{ 
          color: ${palette.dark.normal};
          font-size: ${typography.tiny.pt_12_regular.fontSize};
          font-weight: ${typography.tiny.pt_12_regular.fontWeight};
          line-height: ${typography.tiny.pt_12_regular.lineHeight};
        }
      }
    }

    > div > span,
    > div > span > span {
      margin-right: 0.25rem;
    }
  `
})

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin: 1.5rem 0 1rem;
  `
})

const Row = styled.span(({ theme }) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-right: ${corners.default.borderRadius};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
      text-align: right;
      margin: 0 1.25rem;
      width: 30%;
    }

    > span:first-child {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-left: 0;
      text-align: left;
      width: 40%
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      > span {
        width: max-content;
      }
    }
  `
})

const Label = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    width: 33%;
    text-align: right;
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      padding-left: 0.75rem;
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      padding-right: 0.75rem;
      margin-right: 0;
    }
  `
})

const ChangeFileNameWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  >div:first-of-type{
    width: 80%;
  }

  >button{
    margin-left: 1rem;
    margin-bottom: 20px;
  }

`

const TransportError = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const transportOptions = [{
  id:'car',
  content:
  <TransportTypeItem>
    <Car />
    <p><Trans>placeme.transport_type.car</Trans></p>
  </TransportTypeItem>,
}, {
  id:'truck',
  content:
  <TransportTypeItem>
    <SmallTruck />
    <p><Trans>placeme.transport_type.truck</Trans></p>
  </TransportTypeItem>,
}, {
  id:'heavy_lorry',
  content:
  <TransportTypeItem>
    <BigTruck />
    <p><Trans>placeme.transport_type.heavy_lorry</Trans></p>
  </TransportTypeItem>,
}]

const labels = [
  'placeme.transport_tile.table_label_1',
  'placeme.transport_tile.table_label_2',
  'placeme.transport_tile.table_label_3',
]

const typeAddress = 'type_address'
const loadPoints = 'load_points'
const chooseFromList = 'choose_from_list'

export const TransportTile:
React.FC<{data: ITransportTileData, tileId: string, isExtraPaid?: boolean, }> = ({
  data, tileId, isExtraPaid,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  // [state]
  // transport
  const [chosenTransport, setChosenTransport] = useState<string>('car')
  const [transportTypeError, setTransportTypeError] = useState<undefined|string>(undefined)
  // options
  const options = [typeAddress, loadPoints, chooseFromList]
  const [chosenOption, setChosenOption] = useState(typeAddress)
  // option 1 - type localizations
  const [localizations, setLocalizations] = useState<ILocation[]>([])
  // option 2 - uploaded file
  const [uploadedFile, setUploadedFile] = useState<{file: File }| undefined>(undefined)
  const [setName, toggleSetName] = useState(false)
  const [fileName, setFileName] = useState('')
  const [accepted, setAccepted] = useState(false)

  // option 3 - choose from list
  const [workspacePointsList, setWorkspacePointsList] = useState<ResourceWithId[]>()
  const [selectedItemsFromList, setSelectedItemsFromList] = useState<ResourceWithId[]>(
    data?.value?.transport?.listOfFiles?.map(item => ({
      id: item?.id,
      content: <Trans>{item?.name}</Trans>,
    })) || [],
  )

  const catchmentId = values.find(cat => cat.id === 'access_logistics')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId

  // functions

  const uploadFile = (file: File) => {
    setUploadedFile({
      file,
    })
    setFileName(file?.name?.split('').reverse()
      .splice(5)
      .reverse()
      .join(''))
  }

  const returnFileSize = (num: number) => {
    if (num < 1024) {
      return `${num}bytes`
    } if (num >= 1024 && num < 1048576) {
      return `${(num / 1024).toFixed(1)}KB`
    } if (num >= 1048576) {
      return `${(num / 1048576).toFixed(1)}MB`
    }
    return num
  }

  const handleAdd = (geo: ILocation) => {
    if (geo && !localizations?.find(i => i.placeId === geo.placeId)) {
      analytics?.track('Cannibalization - Address Added', {
        location: {
          addres: geo.address,
          lat: geo.lat,
          lng: geo.lng,
        },
        tile: tileId,
      })
      setLocalizations([...localizations, geo])
    }
  }

  const deletePointFromList = (id: string) => {
    setSelectedItemsFromList(selectedItemsFromList.filter(item => item?.id !== id))
  }

  const handleDelete = (selectedValue: ILocation) => {
    setLocalizations(localizations.filter((item) => item.address !== selectedValue.address))
  }

  const handleDeleteTile = () => dispatch(deleteTileAction(token, 'access_logistics', tileId, comparedAnalyseId || analyseId || ''))

  const handleTransportTypeError = () => {
    if (window?.localStorage?.getItem('transportTypeError')) {
      setTransportTypeError(window?.localStorage?.getItem('transportTypeError') || '')
    } else {
      setTransportTypeError(undefined)
    }
  }

  const handleSave = () => {
    if (token && !values.find(cat => cat.id === 'access_logistics')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId) {
      setLoading(true)
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'access_logistics', tileId, {
        id: `${tileId}-250-line`,
        value: 250,
        type: 'line',
      }))
    }
    if (token && values.find(cat => cat.id === 'access_logistics')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId && !data?.value) {
      setLoading(true)
    }
    setAccepted(true)
  }

  // requests

  const fetchExampleFile = useCallback(async () => {
    let saveData
    try {
      const axiosInstance = await getAxios(config.API_URL, token)
      const response = await axiosInstance.get(ENDPOINTS.TRANSPORT_TILE_EXAMPLE_FILE)
      saveData = response
    }
    catch (e) {
      createFlashMessage({
        message: 'status.error.transport.example_file.error',
      })
    }

    finally {
      if (saveData?.data) {
        const url = saveData?.data
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'test.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      }
    }
  }, [token])

  const fetchWorkspacesFiles = useCallback(async () => {
    let saveData
    try {
      const axiosInstance = await getAxios(config.API_URL, token)
      const response = await axiosInstance.get<IWorkspacePoint>(ENDPOINTS.TRANSPORT_TILE)

      saveData = {
        loading: false,
        error: null,
        value: response.data,
      }
    }
    catch (e) {
      saveData = {
        loading: false,
        error: e.message,
        value: null,
      }
    }
    finally {
      if (saveData?.value) {
        const filesToDisplay = saveData?.value?.files?.map(item => ({
          id: item?.id,
          content: <Trans>{item?.name}</Trans>,
        }))
        setWorkspacePointsList(filesToDisplay)
      }
    }
  }, [token])

  const fetchData = useCallback(async () => {
    const catchmentId = values.find(cat => cat.id === 'access_logistics')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId
    if (catchmentId) {
      // reset transport type error
      window?.localStorage?.removeItem('transportTypeError')
      window?.dispatchEvent(new CustomEvent('transportTypeError'))

      let body
      const formData = new FormData()
      if (chosenOption === loadPoints) {
        const name = fileName?.toLowerCase().split('')
          .reverse()
          .join('')
          .replace('.', '+++')
          .replace(/\./g, '_')
          .replace('+++', '.')
          .split('')
          .reverse()
          .join('')
        const blob = uploadedFile?.file as unknown as Blob
        formData.append('file_from_the_user', blob, `${name}.xlsx`)
        formData.append('catchment_id', catchmentId)
        formData.append('transport_mode', chosenTransport)

        body = {
          catchmentId,
          transportMode: chosenTransport,

        }
      } else {
        body = chosenOption === typeAddress
          ? {
            catchmentId,
            transportMode: chosenTransport,
            addresses: localizations?.map(l => ({
              id: l.placeId,
              address: l.address,
              lat: l.lat,
              lng: l.lng,
            })),
          }
          : {
            catchmentId,
            transportMode: chosenTransport,
            listOfFiles: selectedItemsFromList?.map(item => item.id),
          }
      }

      let saveData
      try {
        const axiosInstance = await getAxios(config.API_URL, token, undefined, formData ? catchmentId : undefined)
        const response = await axiosInstance.post<ITileData>(
          ENDPOINTS.TRANSPORT_TILE, chosenOption === loadPoints ? formData : body,
        )

        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      catch (e) {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        setLoading(false)
        if (saveData?.value) {
          dispatch(saveTileData('access_logistics', tileId, saveData))
          setTimeout(() => dispatch(fetchWorkspaceUsageValue(token)), 2000)
        }
        setAccepted(false)
      } }
  }, [token, values, chosenOption, chosenTransport, localizations, selectedItemsFromList, uploadedFile, fileName])

  const handleFetchComparedData = useCallback(async () => {
    if (accepted) {
      // reset transport type error
      window?.localStorage?.removeItem('transportTypeError')
      window?.dispatchEvent(new CustomEvent('transportTypeError'))

      const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
      try {
      // post project - with compared location - create compared analyse
        if (!comparedAnalyseId) await dispatch(createNewAnalyse(token, analyseId, comparedLocation?.location))
      }
      finally {
        const customerOriginTile = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
          t.id === tileId)
        if (chosenOption === loadPoints) {
          const formData = new FormData()
          const name = fileName?.toLowerCase().split('')
            .reverse()
            .join('')
            .replace('.', '+++')
            .replace(/\./g, '_')
            .replace('+++', '.')
            .split('')
            .reverse()
            .join('')
          const blob = uploadedFile?.file as unknown as Blob
          formData.append('file_from_the_user', blob, `${name}.xlsx`)
          formData.append('main_catchment_id', catchmentId || '')

          try {
            await dispatch(compareLocationCatchmentAndDataAction(
              token,
              catchmentId || '',
              customerOriginTile?.chosenRange as IRange,
              customerOriginTile?.section || '',
              customerOriginTile?.id || '',
              currentSubscriptionData?.value?.subscriptionId || '',
              comparedLocation?.location as ILocation,
              {
                transport_mode: chosenTransport,
              },
              formData,
            ))
          }
          finally {
            setAccepted(false)
            setLoading(false)
          }
        } else {
          try {
            await dispatch(compareLocationCatchmentAndDataAction(
              token,
              catchmentId || '',
              customerOriginTile?.chosenRange as IRange,
              customerOriginTile?.section || '',
              customerOriginTile?.id || '',
              currentSubscriptionData?.value?.subscriptionId || '',
              comparedLocation?.location as ILocation,
              chosenOption === typeAddress
                ? {
                  transportMode: chosenTransport,
                  addresses: localizations?.map(l => ({
                    id: l.placeId,
                    address: l.address,
                    lat: l.lat,
                    lng: l.lng,
                  })),
                }
                : {
                  transportMode: chosenTransport,
                  listOfFiles: selectedItemsFromList?.map(item => item.id),
                },
            ))
          }
          finally {
            setAccepted(false)
            setLoading(false)
          }
        }
      } }
  }, [catchmentId, !data?.value, accepted, chosenOption, chosenTransport, localizations, selectedItemsFromList,
    uploadedFile, fileName])

  // hooks

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token && !data?.value && !workspacePointsList) {
      fetchWorkspacesFiles()
    }
  }, [token, workspacePointsList])

  useEffect(() => {
    setLocalizations(localizations)
  }, [localizations])

  // compared location or fetch basic data (fetchData)
  useEffect(() => {
    if (catchmentId && !data?.value && accepted) {
      const transportTile = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
        t.id === tileId)
      // compared location comparison trigger
      if (comparedLocation?.generatedFromNow && transportTile?.chosenRange?.type !== 'custom') {
        const transportTile = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
          t.id === tileId)
        const catchmentId = transportTile?.chosenRange?.catchmentId

        // compared location
        if (transportTile
              && catchmentId
              && transportTile?.chosenRange?.type !== 'custom'
              && comparedLocation?.location) {
          handleFetchComparedData()
        }
      } else {
        fetchData()
      }
    }
  }, [catchmentId, !data?.value, accepted])

  useEffect(() => {
    if (data?.value) {
      setLoading(false)
      if (!data?.value?.transport?.fileFromTheUser?.id
        && !data?.value?.transport?.listOfFiles
        && data?.value?.transport?.addresses?.length) {
        setChosenOption(typeAddress)
      }
      else if (data?.value?.transport?.listOfFiles?.length && data?.value?.transport?.addresses?.length) {
        setChosenOption(chooseFromList)
      }
      else {
        setChosenOption(loadPoints)
      }
      setChosenTransport(data?.value?.transport?.transportMode)
    }
  }, [data])

  useEffect(() => {
    // detect transport type error change
    window.addEventListener('transportTypeError', handleTransportTypeError)
    // remove listener
    return () => window.removeEventListener('transportTypeError', handleTransportTypeError)
  }, [values])

  return (
    <>
      { ((!workspacePointsList && !data?.value) || loading)
        ? <Loader />
        : (
          <StyledTileFormWrapper isData={!!data?.value?.transport}>
            <TileFormSection
              style={{
                borderBottom: 'none',
              }}
            >
              {/* description */}
              <TileFormRowWithData>
                <span>{t('placeme.transport_tile.row_with_data_1_span_1')}</span>
              </TileFormRowWithData>
              <TileFormParagraph>
                { `${t('placeme.transport_tile.paragraph_1')} ${value?.address}, ${t('placeme.transport_tile.paragraph_2')}`}
              </TileFormParagraph>
              {/* choose location */}
              { !data?.value?.transport && workspacePointsList
             && (
               <>
                 <StyledTitleFormSectionSubTitle>{t('placeme.transport_tile.localization_selector_title')}</StyledTitleFormSectionSubTitle>
                 <LocalizationSelectorWrapper>
                   <NormalSpan>{t('placeme.transport_tile.localization_selector_no_items')}</NormalSpan>
                   <RadioButtonsWrapper>
                     {options?.map(o => (
                       <RadioButtonWrapper key={o}>
                         <RadioButton
                           checked={o === chosenOption}
                           onChange={() => {
                             setChosenOption(o)
                           }}
                         />
                         {t(`placeme.transport.options.${o}`)}
                       </RadioButtonWrapper>
                     ))}
                   </RadioButtonsWrapper>
                   <ContentWrapper>
                     {/* type address */}
                     {chosenOption === typeAddress && (
                       <>
                         {localizations.map((item) => (
                           <Item key={item.address}>
                             <span>{item.address}</span>
                             <CloseIcon onClick={() => handleDelete(item)} />
                           </Item>
                         ))}
                         <Title>{t('placeme.localization_selector.list_selector_title')}</Title>
                         <GeosuggestForm
                           buttonText='+'
                           handleSubmit={handleAdd}
                           instantAdd
                         />
                       </>
                     )}
                     {/* load points from file */}
                     {chosenOption === loadPoints
                  && (
                    <>
                      {uploadedFile
                       && (
                         <>
                           <NormalSpan>{t('placeme.transport_tile.loaded_files_list')}</NormalSpan>
                           <UploadedFileLabelWrapper>
                             <p>{`${fileName}.xlsx`}</p>
                             <span>{returnFileSize(uploadedFile?.file?.size)}</span>
                             <TrashIcon
                               className='trash'
                               onClick={() => setUploadedFile(undefined)}
                             />
                             <Edit
                               className='edit'
                               onClick={() => toggleSetName(!setName)}
                             />
                           </UploadedFileLabelWrapper>
                           {setName && (
                             <ChangeFileNameWrapper>
                               <TextInput
                                 label={t('placeme.transport.change_file_name')}
                                 name='fileName'
                                 onBlur={() => {}}
                                 onChange={(_, val) => setFileName(val)}
                                 placeholder={t('generic.type_name')}
                                 value={fileName}
                               />

                               <TextButton
                                 onClick={() => toggleSetName(false)}
                               >
                                 <p>{t('generic.save')}</p>
                               </TextButton>

                             </ChangeFileNameWrapper>
                           )}
                         </>
                       )}
                      <LoadPointsWrapper>
                        <div>
                          <DropZone
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            handleFileSave={uploadFile}
                          />
                        </div>
                        <TextButton onClick={fetchExampleFile}>{t('placeme.transport.load_example_file')}</TextButton>
                      </LoadPointsWrapper>
                    </>
                  )}
                     {/* load points from list */}
                     {chosenOption === chooseFromList
                            && (
                              <NestedSelectWrapper>
                                <NestedSelect
                                  deleteItemFromList={deletePointFromList}
                                  name=''
                                  onChange={setSelectedItemsFromList}
                                  options={workspacePointsList}
                                  placeholder={t('placeme.transport.choose_from_list')}
                                  removableBlueBeans
                                  selected={selectedItemsFromList}
                                  width='90%'
                                />
                              </NestedSelectWrapper>
                            )}
                   </ContentWrapper>
                 </LocalizationSelectorWrapper>
                 <StyledTitleFormSectionSubTitle>{t('placeme.transport_tile.transport_type')}</StyledTitleFormSectionSubTitle>
                 <LocalizationSelectorWrapper>
                   <NormalSpan
                     style={{
                       marginBottom: '15px',
                     }}
                   >
                     {t('placeme.transport_tile.transport_type.description')}
                   </NormalSpan>
                   { transportTypeError && <TransportError>{`${t('placeme.transport_type.error_1')} ${t(`placeme.transport_type.error.${transportTypeError}`)}. ${t('placeme.transport_type.error_2')}`}</TransportError>}
                   <ResourcesSelector
                     maxWidth='100%'
                     onChange={(resource) =>
                       setChosenTransport(resource[0])}
                     resources={transportOptions}
                     value={[chosenTransport]}
                     wrapperType='rounded'
                   />

                 </LocalizationSelectorWrapper>

               </>
             )}
              {!data?.value && (
                <TileFooter
                  disabled={!(localizations.length || selectedItemsFromList.length || uploadedFile)}
                  isExtraPaid={isExtraPaid}
                  isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                  label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                  onAccept={handleSave}
                  onCancel={handleDeleteTile}
                  value={checkComparedCoinsValue(
                    comparedLocation?.generatedFromNow,
                    !!comparedLocation?.location,
                    true,
                  ).toString()}
                />
              )}
              {/* data */}
              { data?.value?.transport
         && (
           <>
             {data?.value?.comparedLocation?.transport
            && (
              <ComparedLocationHeader>
                <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                {' '}
                <span>{value?.address}</span>
              </ComparedLocationHeader>
            )}
             <SelectedSection>
               <span>
                 <span>{t('generic.settings')}</span>
               </span>
               {chosenOption === typeAddress
               && (
                 <div>
                   <p>
                     {t('placeme.transport.points_addresses_added')}
                     :
                     {' '}
                     <span>{data?.value?.transport?.addresses?.map(l => `${l?.address}; `)}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
               {chosenOption === loadPoints
               && (
                 <div>
                   <p>
                     {t('placeme.transport.loaded_points')}
                     :
                     {' '}
                     <span>{data?.value?.transport?.fileFromTheUser?.name}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
               {chosenOption === chooseFromList
               && (
                 <div>
                   <p>
                     {t('placeme.transport.points_list_added')}
                     :
                     {' '}
                     <span>{data?.value?.transport?.listOfFiles?.map(item => `${item?.name}; `)}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
             </SelectedSection>
             <TileFormRowWithData>
               <span>{`${data?.value?.transport?.numberOfAddresses} ${t('placeme.transport_tile.points_amount.description')}`}</span>
             </TileFormRowWithData>
             {data?.value?.transport?.numberOfAddresses && data?.value?.transport?.numberOfAddresses < 10 && (
               <TileFormParagraph>
                 {t('placeme.transport_tile.less_then_10.description')}
               </TileFormParagraph>
             )}
             <Header>
               {labels.map((label, index) => (
                 <Label key={label}>{index === 0 ? `${t(label)}` : `${`${t(label)} ${value?.address}`} )`}</Label>
               ))}
             </Header>
             {data?.value?.transport?.numberOfAddresses !== 0
               ? data?.value?.transport?.addresses?.map((item) => (
                 <Row key={item?.id}>
                   <span>{item?.address}</span>
                   <span>{`${formatNumber(item?.distance, 1)} km`}</span>
                   <span>{getFormatTime(item?.time)}</span>
                 </Row>
               ))
               : (
                 <TileFormParagraph
                   style={{
                     textAlign: 'center',
                   }}
                 >
                   {t('placeme.transport_tile.no_items.description')}
                 </TileFormParagraph>
               )}
           </>
         )}
              {/* compared location data */}
              { data?.value?.comparedLocation?.transport
         && (
           <>
             {data?.value?.comparedLocation?.transport
            && (
              <ComparedLocationHeader second>
                <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                {' '}
                <span>{comparedLocation?.location?.address}</span>
              </ComparedLocationHeader>
            )}
             <SelectedSection>
               <span>
                 <span>{t('generic.settings')}</span>
               </span>
               {chosenOption === typeAddress
               && (
                 <div>
                   <p>
                     {t('placeme.transport.points_addresses_added')}
                     :
                     {' '}
                     <span>{data?.value?.comparedLocation?.transport?.addresses?.map(l => `${l?.address}; `)}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.comparedLocation?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
               {chosenOption === loadPoints
               && (
                 <div>
                   <p>
                     {t('placeme.transport.loaded_points')}
                     :
                     {' '}
                     <span>{data?.value?.comparedLocation?.transport?.fileFromTheUser?.name}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.comparedLocation?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
               {chosenOption === chooseFromList
               && (
                 <div>
                   <p>
                     {t('placeme.transport.points_list_added')}
                     :
                     {' '}
                     <span>{data?.value?.comparedLocation?.transport?.listOfFiles?.map(item => `${item?.name}; `)}</span>
                   </p>
                   <p>
                     {t('placeme.transport.transport_type')}
                     :
                     {' '}
                     <span>{t(`placeme.transport_type.${data?.value?.comparedLocation?.transport?.transportMode}`)}</span>
                   </p>
                 </div>
               )}
             </SelectedSection>
             <TileFormRowWithData>
               <span>{`${data?.value?.comparedLocation?.transport?.numberOfAddresses} ${t('placeme.transport_tile.points_amount.description')}`}</span>
             </TileFormRowWithData>
             {data?.value?.comparedLocation?.transport?.numberOfAddresses
             && data?.value?.comparedLocation?.transport?.numberOfAddresses < 10 && (
               <TileFormParagraph>
                 {t('placeme.transport_tile.less_then_10.description')}
               </TileFormParagraph>
             )}
             <Header>
               {labels.map((label, index) => (
                 <Label key={label}>{index === 0 ? `${t(label)}` : `${`${t(label)} ${value?.address}`} )`}</Label>
               ))}
             </Header>
             {data?.value?.comparedLocation?.transport?.numberOfAddresses !== 0
               ? data?.value?.comparedLocation?.transport?.addresses?.map((item) => (
                 <Row key={item?.id}>
                   <span>{item?.address}</span>
                   <span>{`${formatNumber(item?.distance, 1)} km`}</span>
                   <span>{getFormatTime(item?.time)}</span>
                 </Row>
               ))
               : (
                 <TileFormParagraph
                   style={{
                     textAlign: 'center',
                   }}
                 >
                   {t('placeme.transport_tile.no_items.description')}
                 </TileFormParagraph>
               )}
           </>
         )}
            </TileFormSection>
          </StyledTileFormWrapper>
        ) }
    </>
  )
}
