export const bigBenCategoryId = 'big_ben'

export const bigBenChainRankingParameters = {
  population: 'population',
  purchasing_power: 'purchasing_power',
  spending: 'spending',
  average_age: 'average_age',
  density_value: 'density_value',
  daily_average_unique_traffic: 'daily_average_unique_traffic',
  average_traffic_per_week: 'average_traffic_per_week',
  average_traffic_per_weekend: 'average_traffic_per_weekend',
}
