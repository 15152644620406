/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Select from 'react-select'

const countryList = require('react-select-country-list')

const Wrapper = styled.div<{width?: string}>(({
  width, theme,
}) => {
  const { palette } = theme
  return css`
    margin: 0.5rem 0;
    width: ${width || '100%'};
    max-height: 100px;

    .basic-single{
        border: 1px solid ${palette.dark.lightest};
        border-radius: 0.25rem;
        height: 2.5rem;
        font-weight: 400;
        font-size: 1rem;
        background: #ffffff;
        margin: 1px;
        width:100%;

        :hover {
            box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
            border-color: ${palette.blue};
        }

        :focus {
            margin: 0;
            box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
            border: 2px solid ${palette.blue};
            outline: none;
        }

        > div {
            border-width: 0;
            :focus {
            margin: 0;
            box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14) ;
            border: 2px solid ${palette.blue};
            outline: none;
        }

        }
    }
  ` })

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const InputError = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
      font-size: 0.75rem;
      color: ${palette.results.red.dark};
  `
})

interface ICountrySelector {
  setValue:React.Dispatch<React.SetStateAction<string>>
  error?: string
  name: string
  width?: string
  label?: string
  setISO: React.Dispatch<React.SetStateAction<string>>
  placeholder?: string
}

export const CountrySelector = ({
  setValue, error, name, width, label, setISO, placeholder,
}: ICountrySelector) :JSX.Element => {
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = (val:{value: string, label: string}) => {
    setISO(val?.value)
    setValue(val?.label)
  }

  return (
    <Wrapper width={width}>
      <InputHeader>
        <label htmlFor={name}>{label}</label>
      </InputHeader>
      <Select
        className='basic-single'
        classNamePrefix='select'
        isSearchable
        name={name}
        onChange={(val) => changeHandler(val as {value: string, label: string})}
        options={options}
        placeholder={placeholder}
      />
      <InputError>{error}</InputError>
    </Wrapper>
  )
}

export default CountrySelector
