export const tilesWithoutComparedLocation = [
  'scoring_model_big_ben',
  'look_alike_big_ben',
  'pois_big_ben',
  'traffic_big_ben',
  'population_big_ben',
  'local_market_share',
  'potential_model',
  'traffic_visualisation_big_ben',
]
