import { ReactNode } from 'react'

export const redirectToSignOut = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = `${window.location.origin.replace(/placeme|account|target/gi, 'auth')}/sign-out`
    window.location.replace(link)
  } else {
    window.location.replace('http://localhost:4200/sign-out')
  }
}

export const redirectToSignIn = ():void => {
  const fetchIdFromUrl = () => {
    const location = window.location.pathname.split('/')
    let id = ''
    for (const [index, elem] of location.entries()) {
      if (elem === 'invitation') {
        id = location?.[index + 1]
        break
      }
      id = ''
    }
    return id
  }

  const invitationId = fetchIdFromUrl()

  if (!window.location.origin.includes('localhost')) {
    let link = `${window.location.origin.replace(/placeme|account|target/gi, 'auth')}/sign-in`
    if (invitationId) link += `?invitation=${invitationId}`
    window.location.replace(link)
  } else {
    let link = 'http://localhost:4200/sign-in'
    if (invitationId) link += `?invitation=${invitationId}`
    window.location.replace(link)
  }
}

export const redirectToSignUp = (invitationId = ''):void => {
  if (!window.location.origin.includes('localhost')) {
    let link = `${window.location.origin.replace(/placeme|account|target/gi, 'auth')}/sign-up`
    if (invitationId) link += `/${invitationId}`
    window.location.replace(link)
  } else {
    let link = 'http://localhost:4200/sign-up'
    if (invitationId) link += `/${invitationId}`
    window.location.replace(link)
  }
}

export const redirectToAccount = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(link)
  } else {
    window.location.replace('http://localhost:4201')
  }
}

export const redirectToAccountUsers = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/workspace/users`)
  } else {
    window.location.replace('http://localhost:4201/workspace/users')
  }
}
export const redirectToAccountPayments = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/workspace/payments`)
  } else {
    window.location.replace('http://localhost:4201/workspace/payments')
  }
}
export const redirectToAccountPackages = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/workspace/packages`)
  } else {
    window.location.replace('http://localhost:4201/workspace/packages')
  }
}
export const redirectToUsage = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/workspace/usage-history`)
  } else {
    window.location.replace('http://localhost:4201/workspace/usage-history')
  }
}
export const redirectToAccountSettings = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/workspace/settings`)
  } else {
    window.location.replace('http://localhost:4201/workspace/settings')
  }
}

// pricelist

export const redirectToAccountPricelist = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/placeme|auth|target/gi, 'account')
    window.location.replace(`${link}/purchase/pricelist`)
  } else {
    window.location.replace('http://localhost:4201/purchase/pricelist')
  }
}

export const redirectToPlaceme = ():void => {
  if (!window.location.origin.includes('localhost')) {
    const link = window.location.origin.replace(/account|auth|target/gi, 'placeme')
    window.location.replace(link)
  } else {
    window.location.replace('http://localhost:4202')
  }
}

export const redirectToPlacemeAnalyse = (analyseId: string):void => {
  if (!window.location.origin.includes('localhost')) {
    const link = `${window.location.origin.replace(/account|auth|target/gi, 'placeme')}/analyse/${analyseId}`
    window.location.replace(link)
  } else {
    window.location.replace(`http://localhost:4202/analyse/${analyseId}`)
  }
}

export interface IRedirect {
  workspaceId?: ReactNode | string
  status?: string;
}

export const redirectToPlacemeWithInvitationStatus = ({
  workspaceId, status,
}: IRedirect):void => {
  if (workspaceId) {
    if (!window.location.origin.includes('localhost')) {
      const link = `${window.location.origin.replace(/auth|account|target/gi, 'placeme')}/choose-location?accepted=true`
      window.location.replace(link)
    } else {
      window.location.replace('http://localhost:4201/user?accepted=true')
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!window.location.origin.includes('localhost')) {
      const link = `${window.location.origin.replace(/auth|account|target/gi, 'placeme')}/choose-location?status=${status}`
      window.location.replace(link)
    } else {
      window.location.replace(`http://localhost:4201/user?status=${status}`)
    }
  }
}

export const redirectToInvitation = (invitationId?: string): void => {
  if (!window.location.origin.includes('localhost')) {
    const link = `${window.location.origin.replace(/auth|placeme|target/gi, 'account')}/invitation/${invitationId}`
    window.location.replace(link)
  } else {
    window.location.replace(`http://localhost:4201/invitation/${invitationId}`)
  }
}

export const redirectToSignInWithInvitation = (invitationId?: string): void => {
  if (!window.location.origin.includes('localhost')) {
    const link = `${window.location.origin.replace(/account|placeme|target/gi, 'auth')}/sign-in?invitation=${invitationId}`
    window.location.replace(link)
  } else {
    window.location.replace(`http://localhost:4200/sign-in?invitation=${invitationId}`)
  }
}

