/* eslint-disable max-lines */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react'
import {
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRow,
  TileFormColumn,
  DoughnutChart,
  TileFormRowWithData,
  TitleFormSectionSubTitle,
  MapTile,
  LinkWithIconCheckOnMap,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { AddMapView, ComparedLocationBean, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { tilesData } from 'apps/placeme/src/features/Analyse/utils/tilesData'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { subDays } from 'date-fns'
import { ITrafficVisualisationTileData } from './@types/ITrafficVisualisationTileData'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { RangeAndDateTile } from '../../../../molecules'
import { SettingsBox } from '../../../../molecules/SettingsBox'
import { ComparedLocationHeader } from '../../../../atoms'

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

interface IExtendedFeatureCollection extends IFeatureCollection {
  data:{
    features:{
      type: string;
      geometry: {
        coordinates: number[];
        type: string;
      };
      properties: {
        title?: string | HTMLElement,
        percent?: number;
        pinnedItem?: {
          class: string;
          html: string;
        };
        id?: string,
        style:{
          color: string,
          fillColor:string,
          weight:number,
        }

      };
    }[];
  }}

export const TrafficVisualisationBigBenTile: React.FC<{
  data: ITrafficVisualisationTileData, tileId: string, userId: string, isExtraPaid?: boolean}> = ({
  data, tileId, userId, isExtraPaid,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobileDevice = useCheckForMobileDevice()
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const [mapVisible, setMapVisible] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const [accepted, setAccepted] = useState<boolean>(false)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const handleAddMap = () => {
    setMapVisible(true)
  }

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  useEffect(() => {
    if (!data) {
      setAccepted(false)
    }
    if (data?.value) setAccepted(true)
  }, [data])

  useEffect(() => {
    if (data?.value?.map && mapLocation) {
      dispatch(saveTileData('big_ben', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  const getCatchmentId = values?.find(c => c.id === 'big_ben')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const getLayers = (compared: boolean) => {
    if (data?.value) {
      const items = compared ? data?.value?.comparedLocation : data?.value
      const features : IExtendedFeatureCollection['data']['features'] = []
      const tile = values?.find(c => c.id === 'big_ben')?.tiles?.find(t =>
        t.id === tileId)
      const rangeCoords = compared
        ? tile?.comparedChosenRange?.geoJSON?.coordinates
        : tile?.chosenRange?.geoJSON?.coordinates
      items?.map?.features?.forEach(feature => {
        features.push({
          geometry: feature?.geometry,
          properties: {
            ...feature?.properties,
            title: feature?.properties?.percent
              ? `<div>
              <span>
                ${t('placeme.traffic_visualisation_big_ben.feature_traffic_ranking')}: ${formatNumber(feature?.properties?.percent * 100, 2)}
              </span>
            </div>`
              : undefined,
          },
          type: 'Feature',
        }) })

      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = []

      // range layer
      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'traffic_range_compared' : 'traffic_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      layers.push({
        id: `traffic_layer_${getCatchmentId}`,
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'traffic_layer_map',
          },
        },
      })

      return layers
    }
    return undefined
  }

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>

      {(!accepted || (!accepted && data && !data?.loading) || !data)
        ? (
          <RangeAndDateTile
            accepted={accepted}
            category='big_ben'
            isExtraPaid={isExtraPaid}
            maxDate={subDays(new Date(), 3)}
            maxRanges={tilesData.find(cat => cat.id === 'big_ben')?.tiles.find(tile => tile.id === tileId.split('-')[0])?.maxRanges}
            minDate={new Date(2021, 10, 15)}
            setAccepted={setAccepted}
            tile={tileId}
            userId={userId}
          />
        )
        : (!data || data?.loading
          ? <Loader />
          : (
            <>
              <SettingsBox
                noEdit
                sectionTile='big_ben'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  date: {
                    startDate: data?.value?.dataRanges?.startDate,
                    endDate: data?.value?.dataRanges?.endDate,
                  },
                }}
              />
              <TileFormSection>
                <TileFormSectionTitle>{t('placeme.traffic_visualisation_tile.section_title_1')}</TileFormSectionTitle>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_1_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.dailyAverageUniqueTraffic)}</span>
                  {data?.value?.comparedLocation?.dailyAverage?.dailyAverageUniqueTraffic && (
                    <ComparedLocationBean
                      comparedAddress={comparedLocation?.location?.address}
                      comparedValue={
                        formatNumber(data?.value?.comparedLocation?.dailyAverage?.dailyAverageUniqueTraffic)
                      }
                      difference={data?.value?.comparedLocation?.dailyAverage?.dailyAverageUniqueTrafficDiff}
                      mainAddress={value?.address}
                      mainValue={formatNumber(data?.value?.dailyAverage?.dailyAverageUniqueTraffic)}
                    />
                  )}
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_1')}
                </TileFormSpan>
                <TitleFormSectionSubTitle>{t('placeme.traffic_visualisation_tile.section_sub_title_1')}</TitleFormSectionSubTitle>
                <TileFormRow
                  width='100%'
                  wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
                >
                  <TileFormColumn
                    align='start'
                    width={!isMobileDevice ? '60%' : '100%'}
                  >
                    <TileFormRowWithData legend='#362FB7'>
                      <span>{t('placeme.traffic_visualisation_tile.row_with_data_2_span_1')}</span>
                      <span>{`${parseFloat((data?.value?.visitorsStructure?.stayingInTheArea * 100).toFixed(1))}%`}</span>
                      {data?.value?.comparedLocation?.visitorsStructure?.stayingInTheArea && (
                        <ComparedLocationBean
                          comparedAddress={comparedLocation?.location?.address}
                          comparedValue={`${
                            parseFloat((data?.value?.comparedLocation?.visitorsStructure?.stayingInTheArea * 100).toFixed(1))}%`}
                          difference={data?.value?.comparedLocation?.visitorsStructure?.stayingInTheAreaDiff}
                          mainAddress={value?.address}
                          mainValue={`${parseFloat((data?.value?.visitorsStructure?.stayingInTheArea * 100).toFixed(1))}%`}
                        />
                      )}
                    </TileFormRowWithData>
                    <TileFormSpan>
                      {t('placeme.traffic_visualisation_tile.form_span_2')}
                    </TileFormSpan>
                    <TileFormRowWithData legend='#7E7AD2'>
                      <span>{t('placeme.traffic_visualisation_tile.row_with_data_3_span_1')}</span>
                      <span>{`${parseFloat((data?.value?.visitorsStructure?.passingByTheArea * 100).toFixed(1))}%`}</span>
                      {data?.value?.comparedLocation?.visitorsStructure?.passingByTheArea && (
                        <ComparedLocationBean
                          comparedAddress={comparedLocation?.location?.address}
                          comparedValue={`${parseFloat((data?.value?.comparedLocation?.visitorsStructure?.passingByTheArea * 100).toFixed(1))}%`}
                          difference={data?.value?.comparedLocation?.visitorsStructure?.passingByTheAreaDiff}
                          mainAddress={value?.address}
                          mainValue={`${parseFloat((data?.value?.visitorsStructure?.passingByTheArea * 100).toFixed(1))}%`}
                        />
                      )}
                    </TileFormRowWithData>
                    <TileFormSpan>
                      {t('placeme.traffic_visualisation_tile.form_span_3')}
                    </TileFormSpan>
                  </TileFormColumn>
                  <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                    <DoughnutChart
                      data={[data?.value?.visitorsStructure?.stayingInTheArea * 100,
                        data?.value?.visitorsStructure?.passingByTheArea * 100]
                        .map(number => parseFloat(number.toFixed(1)))}
                      innerLabels
                      labels={[t('placeme.traffic_visualisation_tile.doughnut_chart_1_label_1'),
                        t('placeme.traffic_visualisation_tile.doughnut_chart_1_label_2')]}
                      valueType='percentages'
                      width={230}
                    />
                  </TileFormColumn>
                </TileFormRow>
              </TileFormSection>
              <TileFormSection>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_4_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.averageTrafficPerWeek)}</span>
                  {data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeek && (
                    <ComparedLocationBean
                      comparedAddress={comparedLocation?.location?.address}
                      comparedValue={formatNumber(data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeek)}
                      difference={data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeekDiff}
                      mainAddress={value?.address}
                      mainValue={formatNumber(data?.value?.dailyAverage?.averageTrafficPerWeek)}
                    />
                  )}
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_4')}
                </TileFormSpan>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_5_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.averageTrafficPerWeekend)}</span>
                  {data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeekend && (
                    <ComparedLocationBean
                      comparedAddress={comparedLocation?.location?.address}
                      // eslint-disable-next-line max-len
                      comparedValue={formatNumber(data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeekend)}
                      difference={data?.value?.comparedLocation?.dailyAverage?.averageTrafficPerWeekendDiff}
                      mainAddress={value?.address}
                      mainValue={formatNumber(data?.value?.dailyAverage?.averageTrafficPerWeekend)}
                    />
                  )}
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_5')}
                </TileFormSpan>
              </TileFormSection>
              {data?.value?.transportModeStructure && (
                <TileFormSection>
                  <TitleFormSectionSubTitle>{t('placeme.traffic_visualisation_tile.section_sub_title_2')}</TitleFormSectionSubTitle>
                  <TileFormRow
                    width='100%'
                    wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
                  >
                    <TileFormColumn
                      align='start'
                      width={!isMobileDevice ? '60%' : '100%'}
                    >
                      <TileFormRowWithData legend='#362FB7'>
                        <span>{t('placeme.traffic_visualisation_tile.row_with_data_6_span_1')}</span>
                        <span>{`${parseFloat((data?.value?.transportModeStructure?.onFoot * 100).toFixed(1))}%`}</span>
                        {data?.value?.comparedLocation?.transportModeStructure?.onFoot && (
                          <ComparedLocationBean
                            comparedAddress={comparedLocation?.location?.address}
                            comparedValue={`${parseFloat((data?.value?.comparedLocation?.transportModeStructure?.onFoot * 100).toFixed(1))}%`}
                            difference={data?.value?.comparedLocation?.transportModeStructure?.onFootDiff}
                            mainAddress={value?.address}
                            mainValue={`${parseFloat((data?.value?.transportModeStructure?.onFoot * 100).toFixed(1))}%`}
                          />
                        )}
                      </TileFormRowWithData>
                      <TileFormSpan>
                        {t('placeme.traffic_visualisation_tile.form_span_6')}
                      </TileFormSpan>
                      <TileFormRowWithData legend='#7E7AD2'>
                        <span>{t('placeme.traffic_visualisation_tile.row_with_data_7_span_1')}</span>
                        <span>{`${parseFloat((data?.value?.transportModeStructure?.byCar * 100).toFixed(1))}%`}</span>
                        {data?.value?.comparedLocation?.transportModeStructure?.byCar && (
                          <ComparedLocationBean
                            comparedAddress={comparedLocation?.location?.address}
                            comparedValue={`${parseFloat((data?.value?.comparedLocation?.transportModeStructure?.byCar * 100).toFixed(1))}%`}
                            difference={data?.value?.comparedLocation?.transportModeStructure?.byCarDiff}
                            mainAddress={value?.address}
                            mainValue={`${parseFloat((data?.value?.transportModeStructure?.byCar * 100).toFixed(1))}%`}
                          />
                        )}
                      </TileFormRowWithData>
                      <TileFormSpan>
                        {t('placeme.traffic_visualisation_tile.form_span_7')}
                      </TileFormSpan>
                    </TileFormColumn>
                    <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                      <DoughnutChart
                        data={[data?.value?.transportModeStructure?.onFoot * 100,
                          data?.value?.transportModeStructure?.byCar * 100]
                          .map(number => parseFloat(number.toFixed(1)))}
                        innerLabels
                        labels={[t('placeme.traffic_visualisation_tile.doughnut_chart_2_label_1'),
                          t('placeme.traffic_visualisation_tile.doughnut_chart_2_label_2')]}
                        valueType='percentages'
                        width={230}
                      />
                    </TileFormColumn>
                  </TileFormRow>
                  <TileFormRowWithData>
                    <span>{t('placeme.traffic_visualisation_tile.row_with_data_8_span_1')}</span>
                    <span>{formatNumber(data?.value?.transportModeStructure?.averageNumberOfCarPassingByTheArea)}</span>
                    {data?.value?.comparedLocation?.transportModeStructure?.averageNumberOfCarPassingByTheArea && (
                      <ComparedLocationBean
                        comparedAddress={comparedLocation?.location?.address}
                        comparedValue={formatNumber(
                          data?.value?.comparedLocation?.transportModeStructure?.averageNumberOfCarPassingByTheArea,
                        )}
                        difference={
                          data?.value?.comparedLocation?.transportModeStructure?.averageNumberOfCarPassingByTheAreaDiff
                        }
                        mainAddress={value?.address}
                        mainValue={formatNumber(
                          data?.value?.transportModeStructure?.averageNumberOfCarPassingByTheArea,
                        )}
                      />
                    )}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.traffic_visualisation_tile.form_span_8')}
                  </TileFormSpan>
                </TileFormSection>
              )}
              <TileFormSection>
                {(!mapVisible) && (
                  <AddMapView
                    buttonValue={1}
                    description='placeme.add_traffic_visualisation_map.description'
                    onChange={handleAddMap}
                    title='placeme.add_traffic_visualisation_map.title'
                  />
                )}
                {mapVisible && (
                  <>
                    {data?.value?.comparedLocation
                    && (
                      <ComparedLocationHeader
                        style={{
                          padding: '0',
                        }}
                      >
                        <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                        {' '}
                        <span>{value?.address}</span>
                      </ComparedLocationHeader>
                    )}
                    <TitleFormSectionSubTitle>
                      <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                      <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                    </TitleFormSectionSubTitle>
                    <MapTile
                      dragging
                      height='300px'
                      layers={getLayers(false)}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={`${t('generic.chosen_location')}:`}
                      popupParagraph={value?.address}
                      setMapLocation={setMapLocation}
                      showScaleControl
                      width='100%'
                      zoom={15}
                      zoomControl
                    />
                    {isMapDisplayed && (
                      <MapVisualizationModal
                        isDisplayed={isMapDisplayed}
                        layers={getLayers(false)}
                        location={value}
                        mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'big_ben')?.tiles?.find(t => t.id === 'traffic_visualization_big_ben')?.chosenRange?.catchmentId}`}
                        setIsDisplay={setIsMapDisplayed}
                        zoom={15}
                      />
                    )}
                    {data?.value?.comparedLocation?.map
                    && (
                      <>
                        <ComparedLocationHeader second>
                          <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                          {' '}
                          <span>{comparedLocation?.location?.address}</span>
                        </ComparedLocationHeader>
                        <TitleFormSectionSubTitle>
                          <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                          <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                        </TitleFormSectionSubTitle>
                        <MapTile
                          dragging
                          height='300px'
                          layers={getLayers(true)}
                          location={comparedLocation?.location}
                          mapId='example-map-data-tile'
                          pinDisplayed
                          popupHeading={`${t('generic.chosen_location')}:`}
                          popupParagraph={comparedLocation?.location?.address}
                          setMapLocation={setComparedMapLocation}
                          showScaleControl
                          width='100%'
                          zoom={15}
                          zoomControl
                        />
                      </>
                    )}
                  </>
                )}

                {isComparedMapDisplayed && data?.value?.comparedLocation?.map && (
                  <MapVisualizationModal
                    isDisplayed={isComparedMapDisplayed}
                    layers={getLayers(true)}
                    location={comparedLocation?.location}
                    mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'big_ben')?.tiles?.find(t => t.id === 'traffic_visualization_big_ben')?.chosenRange?.catchmentId}_compared`}
                    setIsDisplay={setIsComparedMapDisplayed}
                    zoom={15}
                  />
                )}

              </TileFormSection>
            </>
          ))}

    </StyledTileFormWrapper>
  )
}

export default TrafficVisualisationBigBenTile
