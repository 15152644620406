import React from 'react'
import styled from 'styled-components'
import { Navlink } from '@dataplace.ai/ui-components/atoms'
import { IBreadcrumbsProps } from './@type/IBreadcrumbs.props'

const BreadcrumbsWrapper = styled.div`
  display: flex;
`

export const Breadcrumbs = ({ links }: IBreadcrumbsProps) :JSX.Element => (
  <BreadcrumbsWrapper>
    {links.map((link) => (
      <Navlink
        key={link.to}
        to={link.to}
      >
        {link.content}
      </Navlink>
    ))}
  </BreadcrumbsWrapper>
)
