export const layersContent = [
  {
    id: 'zoning_plan',
    label: 'Plan zagospodarowania przestrzennego',
  },
  {
    id: 'populationDensity',
    label: 'Gęstość zaludnienia',
  },
]
