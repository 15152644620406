/* eslint-disable max-lines */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button,
  IconButton,
  CreditsCounter,
  AddIcon,
  Tag,
  Tooltip,
  DropdownButton,
} from '@dataplace.ai/ui-components/atoms'
import { MiniMapTile } from '@dataplace.ai/ui-components/atoms/MiniMapTile/MiniMapTile'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { resetLocationState, saveComparedAnalyseId } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { LanguageSelector, StatusInfoLabel } from '@dataplace.ai/ui-components/molecules'
import { placemePlans } from '@dataplace.ai/constants'
import { IRange } from '@dataplace.ai/types'
import { ReactComponent as Download } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/download.svg'
import { ReactComponent as TrashIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/trashIcon.svg'
import { RootState } from '../../../../../redux/store'
import { ExitFromAnalysePopup, SaveAnalyseModal } from '../../molecules'
import {
  resetAnalysisState, saveComparedLocation, toggleIsEdit,
} from '../../../slice/analysisSlice'
import { PATHS } from '../../../../../constants/paths'
import { DownloadAnalyseModal } from '../../molecules/DownloadAnalyseModal/DownloadAnalyseModal'
import { tilesPdfList } from '../../../utils/tilesPdfList'
import { AddAnotherLocationModal } from '../../molecules/AddAnotherLocationModal'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'
import { SaveTemplateModal } from '../../molecules/SaveTemplateModal'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    height: 8rem;
    width: 100%;
    border-bottom: 1px solid ${palette.light.darker};
    padding: 1rem 2.5rem;
    box-sizing: border-box;
    background-color: white;
    z-index: 500;
    transition: all 1s;

    @media (max-width: 1200px) {
      padding: 1rem 1.5rem;
    }

    @media (max-width: 960px) {
      align-items: flex-start;
      overflow: hidden;
    }

    > div:first-child {
      @media (max-width: 960px) {
        margin-top: 0.25rem;
      }
    }

    > div:last-child {
      @media (max-width: 960px) {
        margin-top: 1.25rem;
      }
    }
  `
})

const LocationWrapper = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    margin: 1.25rem;
    flex-grow: 1;

    @media (max-width: 960px) {
      align-items: start;
      margin: 0 1.25rem;
      overflow-y: scroll;
      height: 100%;
    }

    > span {
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;
    }

    > div:nth-of-type(1) > span {
      width: fit-content;
      max-width: 80%;
      margin-right: 0.25rem;
      color: ${palette.black};
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};

      @media (max-width: 1200px) {
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeight};
      }

      @media (max-width: 960px) {
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        max-width: 100%;
      }
    }
  `
})

const ActionsWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;

    > button {
      margin: 0 0.25rem;
    }
  `,
)

const CreditsCounterWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 2.8vw;
  z-index: 600;
  transition: all 1s;
`

const DownloadButton = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    cursor: pointer;
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

    :disabled {
      opacity: 0.5;
      cursor: default;
    }

    > span {
      margin-left: 0.25rem;
    }
  `
})

const AddAnotherLocation = styled.div<{disabled?: boolean}>(({
  theme, disabled,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
  display: flex;
  align-items: center;
  width: fit-content;

  :hover{
    cursor: pointer;
  }

  > span {
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

  >svg{
      margin-right: 4px;
  } 

  .trash-icon{
    cursor: pointer;
    margin-left: 5px;
    width: 15px;
    height: 15px;
    path{
      stroke: #F08F7F;
    }
  }

  ${disabled && css`
      :hover{
        cursor:default;
        >svg{
        background-color: transparent;
        cursor:default;
        path {
            stroke: ${palette.light.darkest};
          }
        }
      }

      > span {
        color: ${palette.dark.lightest};
      }

      >svg{
        path {
            stroke: ${palette.light.darkest};
          }
      }
  `}
`
})

export const AnalysePageHeader = (): JSX.Element => {
  // constants
  const {
    analysisDbInfo, creditsAmount, canBeSave, values, plans, comparedLocation, comparisonState,
    synchronousTilesLoading, isEdit,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const address = value?.address || ''
  const navigationRef = useRef<HTMLDivElement>(null)
  const counterRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const history = useNavigate()
  const dispatch = useAppDispatch()

  // state
  const [compareLocationLoading, setCompareLocationLoading] = useState(false)
  const [compareLocationStatusLabel, setCompareLocationStatusLabel] = useState(false)
  const [errorCompareLocation, setErrorCompareLocation] = useState(false)
  const [errorTilesList, setErrorTilesList] = useState<string[]>()
  const [tilesToTemplateSave, setTilesToTemplateSave] = useState<{
    id: string,
    suggestedRange?: {type: IRange['type'], value: number}
  }[]>([])

  const authContext = useContext(AuthContext)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  // functions
  const checkIfAnyTileSave = () => {
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const checkIfCanBeInPdf = () => {
    if (currentSubscriptionData?.value?.planExact === placemePlans.trial) {
      return false
    }
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value && tilesPdfList.some(key => new RegExp(`${key}.*`, 'g').test(tile.id))) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const handleDelete = () => {}

  const handleDeleteCompared = () => {
    window.localStorage.removeItem('comparedLocation')
    window.localStorage.removeItem('comparedAnalyseId')
    dispatch(saveComparedLocation(undefined))
    dispatch(saveComparedAnalyseId(undefined))
  }

  const getMaxNumber = () => {
    if (typeof currentSubscriptionData?.value?.credits === 'number'
      && typeof currentSubscriptionData?.value?.usage === 'number') {
      return currentSubscriptionData?.value?.usage + currentSubscriptionData?.value?.credits
    }
    return currentSubscriptionData?.value?.credits
  }

  const getTemplateTiles = useCallback(() => {
    if (values.length) {
      const tiles: {id: string, suggestedRange?: {type: IRange['type'], value: number}}[] = []
      values?.forEach(cat => cat.tiles.forEach(tile => {
        if (tile?.data?.value) {
          tiles.push({
            id: tile.id.split('-')[0],
            suggestedRange: tile?.chosenRange
              ? {
                type: tile?.chosenRange?.type,
                value: tile?.chosenRange?.value,
              }
              : undefined,
          })
        }
      }))
      setTilesToTemplateSave(tiles)
    }
  }, [values])

  // hooks
  useEffect(() => {
    // fetches user token id from authContext
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    let prevScrollPos = window.pageYOffset
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset
      if (
        currentScrollPos > 100
        && navigationRef.current
        && counterRef.current
      ) {
        if (prevScrollPos > currentScrollPos) {
          navigationRef.current.style.top = '0'
          navigationRef.current.style.opacity = '1'
          counterRef.current.style.top = '0'
          counterRef.current.style.opacity = '1'
        } else {
          navigationRef.current.style.top = '-8rem'
          navigationRef.current.style.opacity = '0'
          counterRef.current.style.top = '-8rem'
          counterRef.current.style.opacity = '0'
        }
      }
      prevScrollPos = currentScrollPos
    }
    if (prevScrollPos === 0
      && navigationRef.current
      && counterRef.current) {
      navigationRef.current.style.top = '0'
      navigationRef.current.style.opacity = '1'
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [])

  useEffect(() => {
    if (counterRef.current) {
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [creditsAmount])

  useEffect(() => {
    if (values) getTemplateTiles()
  }, [values])

  useEffect(() => {
    // handle loading
    if (comparisonState?.filter(item => item?.loading === true).length) {
      setCompareLocationLoading(true)
    } else {
      setCompareLocationLoading(false)
    }
    // handle error
    const errorTiles = comparisonState?.filter(item => item?.error?.length)
    if (errorTiles?.length) {
      setErrorCompareLocation(true)
      const errorTilesLabels : string[] = []

      errorTiles?.forEach(tile => values?.forEach(cat => {
        const tileLabel = cat?.tiles?.find(t =>
          t?.id === tile?.tile)?.label as string
        if (tileLabel) {
          errorTilesLabels?.push(tileLabel)
        }
      }))
      setErrorTilesList(errorTilesLabels)
    }
  }, [comparisonState])

  const DownloadButtonComponent = () => (
    <DownloadButton
      disabled={
        !checkIfCanBeInPdf() || synchronousTilesLoading || !currentSubscriptionData?.value || compareLocationLoading
      }
      type='button'
    >
      <Download />
      <span>{t('generic.download')}</span>
    </DownloadButton>
  )
  return (
    <>
      {compareLocationStatusLabel && (
        <StatusInfoLabel
          error={errorCompareLocation}
          errorParagraph={t('placeme.compare_location.error_para')}
          errorSpan={`${t('placeme.compare_location.error_span')}: ${errorTilesList?.map((item, index) =>
            ` ${t(item)}${index === errorTilesList?.length - 1 ? '.' : ''}`)}`}
          errorSpan2={t('placeme.compared_location.error_span2')}
          handleClose={() => {
            setErrorCompareLocation(false)
            setCompareLocationStatusLabel(false)
          }}
          loading={compareLocationLoading}
          loadingParagraph={t('placeme.compare_location.loading_para')}
          loadingSpan={t('placeme.compare_location.loading_span')}
          successParagraph={t('placeme.compare_location.success_para')}
          successSpan={t('placeme.compare_location.success_span')}
        />
      )}
      <CreditsCounterWrapper ref={counterRef}>
        <CreditsCounter
          currentPlan={currentSubscriptionData?.value?.planExact || ''}
          maxNumber={getMaxNumber()}
          number={currentSubscriptionData?.value?.credits || 0}
          plans={plans}
        />
      </CreditsCounterWrapper>

      <Wrapper ref={navigationRef}>
        <MiniMapTile
          comparedLocation={comparedLocation?.location}
          location={value}
          mapId='currentLocation'
          shouldMapStyleBeSave
        />
        <LocationWrapper>
          <span>{t('placeme.page_header.location_analyse')}</span>
          <div>
            <span>{address}</span>

            {checkIfAnyTileSave()
              ? (
                <ExitFromAnalysePopup
                  handleDelete={() => {
                    const isProjectSaved = analysisDbInfo?.visible
                    dispatch(resetAnalysisState())
                    dispatch(resetLocationState())
                    handleDeleteCompared()
                    setTimeout(() => history(isProjectSaved ? `/${PATHS.ANALYSE_REPORTS}` : `/${PATHS.CHOOSE_LOCATION}`, {
                      replace: true,
                    }), 200)
                  }}
                  trigger={
                    !analysisDbInfo?.visible
                      ? (
                        <IconButton
                          iconAlt='edit'
                          iconSrc='assets/icons/dataplace/edit.svg'
                        />
                      )
                      : <></>
                  }
                />
              )
              : canBeSave && (
                <IconButton
                  iconAlt='edit'
                  iconSrc='assets/icons/dataplace/edit.svg'
                  onClick={() => {
                    const isProjectSaved = analysisDbInfo?.visible
                    dispatch(resetAnalysisState())
                    dispatch(resetLocationState())
                    handleDeleteCompared()
                    setTimeout(() => history(isProjectSaved ? `/${PATHS.ANALYSE_REPORTS}` : `/${PATHS.CHOOSE_LOCATION}`), 200)
                  }}
                />
              )}
          </div>
          {!comparedLocation?.location
            ? (canBeSave
              ? (
                <AddAnotherLocationModal
                  setCompareLocationStatusLabel={setCompareLocationStatusLabel}
                  trigger={(() => {
                    const isTrial = currentSubscriptionData?.value?.planExact === placemePlans.trial
                    const button = (
                      <AddAnotherLocation disabled={isTrial}>
                        <AddIcon />
                        <span>{t('placeme.compare_with_another_location')}</span>
                      </AddAnotherLocation>
                    )
                    return isTrial
                      ? (
                        <Tooltip
                          content={t('placeme.compare_with_another_location.disabled_for_trial')}
                          position='right top'
                        >
                          {button}
                        </Tooltip>
                      )
                      : button
                  })()}
                />
              )
              : (() => {
                const isTrial = currentSubscriptionData?.value?.planExact === placemePlans.trial
                const button = (
                  <AddAnotherLocation disabled={isTrial || !canBeSave}>
                    <AddIcon />
                    <span>{t('placeme.compare_with_another_location')}</span>
                  </AddAnotherLocation>
                )
                return isTrial
                  ? (
                    <Tooltip
                      content={t('placeme.compare_with_another_location.disabled_for_trial')}
                      position='right top'
                    >
                      {button}
                    </Tooltip>
                  )
                  : button
              })())
            : (!comparedAnalyseId
              ? (
                <AddAnotherLocation>
                  <span>{t('placeme.compared_location.comparing_with')}</span>
                  <Tag
                    color='#EDF0F5'
                    margin='0 5px'
                  >
                    <span>{comparedLocation?.location?.address}</span>
                  </Tag>
                  <AddAnotherLocationModal
                    setCompareLocationStatusLabel={setCompareLocationStatusLabel}
                    trigger={(
                      <IconButton
                        iconAlt='edit'
                        iconSrc='assets/icons/dataplace/edit.svg'
                      />
                    )}
                  />
                  <TrashIcon
                    className='trash-icon'
                    onClick={handleDeleteCompared}
                  />
                </AddAnotherLocation>
              )
              : (
                <AddAnotherLocation>
                  <span>{t('placeme.compared_location.comparing_with')}</span>
                  <Tag
                    color='#EDF0F5'
                    margin='0 0 0 5px'
                  >
                    <span>{comparedLocation?.location?.address}</span>

                  </Tag>
                </AddAnotherLocation>
              )
            )}

        </LocationWrapper>
        <ActionsWrapper>
          <DownloadAnalyseModal
            analyseId={analyseId || ''}
            trigger={synchronousTilesLoading || !currentSubscriptionData?.value || compareLocationLoading
              ? (
                <Tooltip
                  content={t('placeme.download_button.disabled')}
                  position='left top'
                >
                  <DownloadButtonComponent />
                </Tooltip>

              )
              : (
                currentSubscriptionData?.value?.planExact === placemePlans.trial
                  ? (
                    <Tooltip
                      content={t('placeme.download_button.disabled_for_trial')}
                      position='left top'
                    >
                      <DownloadButtonComponent />
                    </Tooltip>
                  )
                  : <DownloadButtonComponent />
              )}
          />
          <SaveAnalyseModal
            analyseId={analyseId || ''}
            handleDelete={handleDelete}
            trigger={((currentSubscriptionData?.value?.planExact
              && ['white', 'trial'].includes(currentSubscriptionData?.value?.planExact))
            && !analysisDbInfo?.visible
              ? <Button disabled={!canBeSave || !checkIfAnyTileSave()}>{t('generic.save_analyse')}</Button>
              : <></>)}
          />
          {!!(!analysisDbInfo?.visible
          && (currentSubscriptionData?.value?.planExact
          && !['white', 'trial'].includes(currentSubscriptionData?.value?.planExact))
          && tilesToTemplateSave?.length)
          && (
            <DropdownButton
              disabled={!canBeSave || !checkIfAnyTileSave()}
              dropdownContent={[
                {
                  id: 'saveTemplate',
                  button: (
                    <span>
                      <SaveTemplateModal
                        templateTiles={tilesToTemplateSave}
                        trigger={<span>{t('placeme.sidebar.save_as_template')}</span>}
                      />
                    </span>),
                },
              ]}
              mainButton={(
                <span>
                  <SaveAnalyseModal
                    analyseId={analyseId || ''}
                    handleDelete={handleDelete}
                    trigger={<>{t('generic.save_analyse')}</>}
                  />

                </span>
              )}
            />
          ) }
          {isEdit && (
            <Button
              disabled={synchronousTilesLoading}
              onClick={() => {
                const isProjectSaved = analysisDbInfo?.visible
                exitFromAnalyseAction(dispatch, isEdit)
                setTimeout(() => history(isProjectSaved ? `/${PATHS.ANALYSE_REPORTS}` : `/${PATHS.CHOOSE_LOCATION}`, {
                  replace: true,
                }), 200)
              }}
            >
              {t('generic.finish_editing')}

            </Button>
          )}
          {!canBeSave && (
            <Button
              disabled={synchronousTilesLoading}
              onClick={() => {
                window.localStorage.setItem('isEdit', 'true')
                dispatch(toggleIsEdit(true))
              }}
            >
              {t('generic.edit')}
            </Button>
          )}
          <LanguageSelector
            noArrow
            noBorder
            transparent
          />
        </ActionsWrapper>
      </Wrapper>
    </>
  )
}

