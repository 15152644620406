/* eslint-disable object-curly-newline */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { useTranslation } from 'react-i18next'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { getRadarChartData, getRadarChartLabels, RadarChart, TileFormWrapper } from '@dataplace.ai/ui-components/atoms'
import { labels,
  // SalesPotentialLabels
} from 'apps/placeme/src/features/Analyse/components/organisms/Tiles/Potential/SalesPotential/data'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import {
  // camelToSnake,
  getAxios } from '@dataplace.ai/functions/utils'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { config } from 'apps/placeme/src/config'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { DataType, ISalesPotentialTileData, ModelParams } from './@types/ISalesPotentialTileData'
import { TypeAndRangeTile } from '../../../../molecules/TypeAndRangeTile'
import { ITypeRange } from '../../../../molecules/TypeRangeSelector'
import { SettingsBox } from '../../../../molecules/SettingsBox/SettingsBox'
// import { ManagePotentialModelData, ManagePotentialModelParams }
// from '../../../../molecules/ManagePotentialModelParams'
import { EstimationsBox } from '../../../../molecules/EstimationsBox'

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};

    h4 {
      font-weight: 500;
      font-size: ${typography.main.pt_15_semibold.fontSize};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
      color: ${palette.black};
      margin: 15px 0;

      > div {
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
    
    > div:last-of-type {
      margin-bottom: 0;
      margin-top: 1.25rem;
    }

    > span {
      color: ${palette.black};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      font-size: ${typography.main.pt_15_regular.fontSize};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: 10px;
      
      .big {
        font-weight: ${typography.big.pt_24_semibold.fontWeight};
        font-size: ${typography.big.pt_24_semibold.fontSize};
        line-height: ${typography.big.pt_24_semibold.lineHeight};
      }
    }

    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
      text-transform: uppercase;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

export const SalesPotentialTile: React.FC<{
  data: ISalesPotentialTileData,
  tileId: string,
  userId: string,
  isExtraPaid: boolean }> = ({
  data,
  tileId, isExtraPaid,
}) => {
  // variables
  const authContext = useContext(AuthContext)
  const {
    values, creditsAmount,
  } = useSelector((state: RootState) => state.analysis)
  const {
    analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const { analytics } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // states
  const [token, setToken] = useState('')
  const [accepted, setAccepted] = useState<boolean>(false)
  const [typeRanges, setTypeRanges] = useState<ITypeRange>({})
  // const [modelParams, setModelParams] = useState<ModelParams>({})
  const [isWarningModalOpen, toggleWarningModalOpen] = useState(false)
  const [canBeRequest, setCanBeRequest] = useState(false)
  // const [canBeReset, setCanBeReset] = useState(false)
  // const [buttonLoading, setButtonLoading] = useState(false)
  const [changed, setChanged] = useState<string[]>([])

  const fetchTileRange = values.find(cat => cat.id === 'potential')?.tiles.find(t => t.id === tileId)?.chosenRange

  const handleSave = () => {
    fetchData()
  }

  const handleComparedLocation = (comparedArea: number) => {
    fetchData(undefined, undefined, comparedArea)
  }

  const handleSubmit = () => {
    if (creditsAmount) {
      analytics?.track('Tile Data Generated', {
        tile: tileId?.split('-')[0],
      })
      setCanBeRequest(true)
    }
  }

  const handleDeleteTile = () => {
    dispatch(deleteTileAction(token, 'potential', tileId, comparedAnalyseId || analyseId || ''))
  }

  const fetchData = useCallback(async (
    modelParams?: ModelParams, previous?: boolean, comparedLocationArea?: number,
  ) => {
    const catchmentId = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange?.catchmentId
    if (canBeRequest && catchmentId) {
      setAccepted(true)

      const body = previous
        ? {
          catchmentId,
          previous_data: true,
        }
        : (modelParams
          ? {
            catchmentId,
            cost: typeRanges?.cost,
            shop_area: comparedLocationArea || typeRanges?.area,
            round_the_clock: typeRanges?.roundTheClock,
            hours_per_week: typeRanges?.hoursPerWeek,
            hours_on_saturday: typeRanges?.hoursOnSaturday,
            hours_on_sunday: typeRanges?.hoursOnSunday,
            data: {
              ...mapModelParamsData(modelParams),
              changed,
            },
          }
          : {
            catchmentId,
            cost: typeRanges?.cost,
            shop_area: comparedLocationArea || typeRanges?.area,
            round_the_clock: typeRanges?.roundTheClock,
            hours_per_week: typeRanges?.hoursPerWeek,
            hours_on_saturday: typeRanges?.hoursOnSaturday,
            hours_on_sunday: typeRanges?.hoursOnSunday,
          })

      let saveData
      const getTileType = (id: string) => id.split('-')[0]
      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]

      try {
        const axiosInstance = await getAxios(config.API_URL, token)
        const response = await axiosInstance.post<ITileData>(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setAccepted(false)
        setCanBeRequest(false)
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        // if (previous) { setCanBeReset(false) }
        if (modelParams || previous) {
          setChanged([])
          // setButtonLoading(false)
        }
        if (saveData?.value) { dispatch(saveTileData('potential', tileId, saveData)) }
        setTimeout(() => dispatch(fetchWorkspaceUsageValue(token)), 2000)
        if (saveData?.value) setAccepted(true)
      }
    }
  }, [canBeRequest, token, values, changed])

  const mapModelParamsData = (data: ModelParams): Record<DataType, number> => {
    let mappedData = {}

    for (const key in data) {
      mappedData = ({
        ...mappedData,
        [key]: data[key].number,
      })
    }
    return mappedData
  }

  // const getManagePotentialModelData = (
  //   data: ModelParams,
  //   labels: SalesPotentialLabels,
  // ): ManagePotentialModelData[] =>
  //   data && Object.entries(data)?.map((entry) => ({
  //     key: entry[0] as DataType,
  //     label: labels?.find((label) => label?.key === entry[0])?.value || 'Value',
  //     value: entry[1]?.number,
  //     unit: labels?.find((label) => label?.key === entry[0])?.unit || '',
  //     step: labels?.find((label) => label?.key === entry[0])?.step || 10,
  //   }))

  // const manipulateModelParams = (key: DataType, sign: boolean | number, step?: number): number => {
  //   if (sign === false && modelParams[key].number - (step || 0) >= 0) {
  // return modelParams[key].number - (step || 0) }
  //   if (sign === false && modelParams[key].number - (step || 0) < 0) { return 0 }
  //   if (typeof sign === 'number') { return sign }
  //   return modelParams[key].number + (step || 0)
  // }

  // const relatedValues: Record<DataType, {item: DataType, step: number}> = {
  //   footTraffic: {
  //     item:'carTraffic',
  //     step: 5,
  //   },
  //   carTraffic: {
  //     item: 'footTraffic',
  //     step: 5,
  //   },
  // }

  // const onModelParamsChange = (key: DataType, sign: boolean | number, step?: number) => {
  //   setCanBeReset(true)
  //   setCanBeRequest(true)
  //   const related = relatedValues[key]?.item

  //   if (related) {
  //     setModelParams({
  //       ...modelParams,
  //       [key]: {
  //         ...modelParams[key],
  //         number: manipulateModelParams(key, sign, step),
  //       },
  //       [related]: {
  //         ...modelParams[related],
  //         number: manipulateModelParams(related, !sign, step),
  //       },
  //     })
  //   } else {
  //     setModelParams({
  //       ...modelParams,
  //       [key]: {
  //         ...modelParams[key],
  //         number: manipulateModelParams(key, sign, step),
  //       },
  //     })
  //   }

  //   const changedKey = camelToSnake(key)

  //   if (!changed.includes(changedKey)) setChanged([...changed, changedKey])
  // }

  // const onModelParamsSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   fetchData(modelParams)
  //   setButtonLoading(true)
  //   setCanBeReset(false)
  // }

  // const onReset = () => {
  //   fetchData({}, true)
  //   setButtonLoading(true)
  // }

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // generates catchment
  useEffect(() => {
    if (!values?.find(c => c.id === 'potential')?.tiles?.find(t => t.id === tileId)?.data?.value && token && canBeRequest) {
      if (!values.find(cat => cat.id === 'potential')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId) {
        dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'potential', tileId, {
          id: `${tileId}-250-line`,
          value: 250,
          type: 'line',
        })) }
    }
  }, [values, token, canBeRequest])

  useEffect(() => {
    if (fetchTileRange && !data?.value && canBeRequest) {
      handleSave()
    }
  }, [values, data])

  useEffect(() => {
    if (!data) {
      setAccepted(false)
    } else {
      // setModelParams(data?.value?.potentialModel?.data)
      setTypeRanges({
        cost: data?.value?.potentialModel?.cost,
        area: data?.value?.potentialModel?.shopArea,
        areaCompared: data?.value?.comparedLocation?.potentialModel?.shopArea,
        hoursOnSaturday:  data?.value?.potentialModel?.hoursOnSaturday,
        hoursOnSunday:  data?.value?.potentialModel?.hoursOnSunday,
        hoursPerWeek:  data?.value?.potentialModel?.hoursPerWeek,
        roundTheClock:  data?.value?.potentialModel?.roundTheClock,
      })
    }
  }, [data])

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>
      {((!accepted && !canBeRequest) && !data?.value)
        ? (
          <>
            <TypeAndRangeTile
              accepted={accepted}
              handleDeleteTile={handleDeleteTile}
              handleSubmit={handleSubmit}
              isExtraPaid={isExtraPaid}
              isWarningModalOpen={isWarningModalOpen}
              setTypeRanges={setTypeRanges}
              tile={tileId}
              toggleWarningModalOpen={toggleWarningModalOpen}
              typeRanges={typeRanges}
            />
          </>
        )
        : (!data?.value || data?.loading
          ? <Loader />
          : (
            <>
              <h4>
                {t('placeme.sales_potential_tile.header')}
              </h4>
              <SettingsBox
                handleComparedLocation={handleComparedLocation}
                noEdit
                sectionTile='potential'
                tile={tileId}
                typeRanges={typeRanges}
              />
              <EstimationsBox
                value1={data?.value?.potentialModel?.estimatedNumberOfTransactions}
                value1Compared={data?.value?.comparedLocation?.potentialModel?.estimatedNumberOfTransactions}
                value2={data?.value?.potentialModel?.estimatedIncome}
                value2Compared={data?.value?.comparedLocation?.potentialModel?.estimatedIncome}
              />
              <h4>{t('placeme.sales_potential_tile.radar.title')}</h4>
              <RadarChart
                data={getRadarChartData(data?.value?.potentialModel?.data)}
                height={450}
                labels={getRadarChartLabels(data?.value?.potentialModel?.data, labels)}
                secondData={data?.value?.comparedLocation?.potentialModel?.data
                  ? getRadarChartData(data?.value?.comparedLocation?.potentialModel?.data)
                  : undefined}
              />
              {/* <ManagePotentialModelParams
                boxTitle='placeme.sales_potetial.params.box.title'
                boxValue={data?.value?.potentialModel?.estimatedIncome?.yearly}
                buttonLoading={buttonLoading}
                canBeReset={canBeReset}
                data={getManagePotentialModelData(modelParams, labels)}
                onSubmit={onModelParamsSubmit}
                onValueChange={onModelParamsChange}
                reset={onReset}
                unit='PLN'
              /> */}
            </>
          ))}
    </StyledTileFormWrapper>
  )
}

