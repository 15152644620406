import styled, { css } from 'styled-components'

export const Input = styled.input<{ error?: string }>(({
  theme, error,
}) => {
  const {
    palette, corners, typography, shadows,
  } = theme
  return css`
    height: 2.5rem;
    box-sizing: border-box;
    border: 1px solid ${palette.dark.lightest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    padding: 0.75rem;
    outline: none;
    width: 100%;

    ::placeholder {
      color: ${palette.dark.lightest};
    }

    ${error && css`
      border: 1px solid ${palette.results.red.dark} !important;
    `}

    :hover {
      box-shadow: ${shadows.hover.boxShadow};
      border-color: ${palette.blue};
    }

    :focus {
      box-shadow: ${shadows.hover.boxShadow};
      border: 2px solid ${palette.blue};
    }

    :disabled {
      background-color: ${palette.main.light};
      border: 1px solid ${palette.gray.lighter};
    }
  `
})
