import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrDownIcon } from '../../../../../assets/src/lib/icons/arrows/arrDown.svg'
import { IDropdownCaretProps } from './@types/IDropdownCaret.props'

const CaretWrapper = styled.div<IDropdownCaretProps>(({
  theme, active,
}) => {
  const { palette } = theme
  return css`
    min-width: 1.25rem;
    min-height: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: ${active ? palette.gray.dark : palette.gray.lighter};
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;

    ${active && css`
      transform: none;
    `}
    svg {
      width: 0.625rem;
    }

    svg > path {
      stroke: white;
    }
  `
})

export const DropdownCaret: React.FC<IDropdownCaretProps> = ({ active }) => (
  <CaretWrapper active={active}>
    <ArrDownIcon />
  </CaretWrapper>
)
