import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { RootState } from '../../../../../../../redux/store'
import { ICitiesPopulation } from './@types/ICitiesPopulation'
import { ComparedLocationHeader } from '../../../../atoms'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

const MapWrapper = styled.div(({ theme }) => {
  const {
    palette, shadows, corners,
  } = theme
  return css`
  .city-pop-container {
   width: 22px;
   height: 22px;
   background-color: ${palette.results.green.dark};
   border-radius: 22px;
   overflow: hidden;
   display: flex;
   flex-direction: column;

 }

  .city-pop-container-zoomed {
    width: 145px;
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 11px;

    .city-name {
      width: 100%;
      padding: 5px;
      color: #fff;
      background-color: ${palette.results.green.dark};
    }

    .city-pop {
      width: 100%;
      padding: 5px;
      font-weight: 400;
      background-color: white;
      color: ${palette.black};
    }
  
}

 .city-tooltip{
    background-color: rgba(21, 24, 31, 0.8);
    padding: 5px 10px 5px 7px;
    display: flex;
    flex-direction: column;
    color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
 }
`
})

export const CitiesPopulationTile: React.FC<{ data : ICitiesPopulation, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const [layers, setLayer] = useState<IMapTile['layers']>()
  const [comparedLayers, setComparedLayer] = useState<IMapTile['layers']>()
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 11,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 11,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  const getLayers = (compared: boolean) => {
    if (data?.value) {
      const isZoomEnough = compared ? comparedMapLocation?.zoom >= 12 : mapLocation?.zoom >= 12
      const items = compared ? data?.value?.comparedLocation : data?.value
      const features : IFeatureCollection['data']['features'] = []

      if (items?.citiesPopulation?.length) {
        items?.citiesPopulation?.forEach(item => {
          features.push({
            geometry: {
              coordinates: [item?.lng, item?.lat],
              type: 'Point',
            },
            properties: {
              title: `<div style="display: flex; flex-direction: column;" ><span>${item?.label}</span><span>${item?.number}</span></div>`,
              pinnedItem: {
                class: 'cities-span',
                html: isZoomEnough
                  ? ` <span class='city-pop-container-zoomed' style='background-color: white'>
                        <span class='city-name' >${item?.label}</span>
                        <span class='city-pop' >${item?.number}</span>
                      </span>`
                  : '<span class=\'city-pop-container\' style="width: 22px; height: 22px; background-color: green; border-radius: 22px; overflow: hidden; display: flex; flex-direction: column;"></span> ',
              },
            },
            type: 'Feature',
          })
        }) }

      return [{
        id: 'cities-population-layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'cities-population',
          },
        },
      }]
    }
    return undefined
  }

  useEffect(() => {
    if (data?.value) {
      setLayer(getLayers(false))
      if (data?.value?.comparedLocation) {
        setComparedLayer(getLayers(true))
      }
    }
  }, [data?.value, mapLocation, comparedMapLocation])

  useEffect(() => {
    if (data?.value?.citiesPopulation?.length && mapLocation) {
      dispatch(saveTileData('visualization', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  return !data || data?.loading
    ? ((<Loader />))
    : (
      <TileFormWrapper>
        {data?.value?.comparedLocation
           && (
             <ComparedLocationHeader>
               <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
               {' '}
               <span>{value?.address}</span>
             </ComparedLocationHeader>
           )}

        <StyledTitleFormSectionSubTitle>
          <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
        </StyledTitleFormSectionSubTitle>
        <MapWrapper>
          <MapTile
            dragging
            height='500px'
            layers={layers}
            location={value}
            mapId='example-map-data-tile'
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            popupParagraph={value?.address}
            setMapLocation={setMapLocation}
            showScaleControl
            width='100%'
            zoom={mapLocation.zoom}
            zoomControl
          />
          {isMapDisplayed && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={layers}
              location={value}
              mapId='visualization-tile-cities_population'
              setIsDisplay={setIsMapDisplayed}
              setMapLocation={setMapLocation}
              zoom={mapLocation.zoom}
            />
          )}
        </MapWrapper>
        {data?.value?.comparedLocation
        && (
          <>
            <ComparedLocationHeader second>
              <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
              {' '}
              <span>{comparedLocation?.location?.address}</span>
            </ComparedLocationHeader>
            <StyledTitleFormSectionSubTitle>
              <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              <MapTile
                dragging
                height='500px'
                layers={comparedLayers}
                location={comparedLocation?.location}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={comparedLocation?.location?.address}
                setMapLocation={setComparedMapLocation}
                showScaleControl
                width='100%'
                zoom={comparedMapLocation?.zoom}
                zoomControl
              />
              {isComparedMapDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isComparedMapDisplayed}
                  layers={comparedLayers}
                  location={comparedLocation?.location}
                  mapId='visualization-tile-cities_population_compared'
                  setIsDisplay={setIsComparedMapDisplayed}
                  setMapLocation={setComparedMapLocation}
                  zoom={comparedMapLocation?.zoom}
                />
              )}
            </MapWrapper>
          </>
        )}
      </TileFormWrapper>
    )
}

export default CitiesPopulationTile
