export const poisTableData = {
  nonGroupedLabels: [
    '',
    'placeme.pois_tile.table_label_1',
    'placeme.pois_tile.table_label_2',
  ],
  groupedLabels: [
    '',
    'placeme.pois_tile.table_label_3',
    'placeme.pois_tile.table_label_4',
  ],
}

export const investmentsTableData = {
  labelsInvestments: [
    'placeme.investmentstile.tablelabel_1',
    'placeme.investmentstile.tablelabel_2',
    'placeme.investmentstile.tablelabel_3',
    ' ',
  ],
}
