import React, { useRef, useEffect } from 'react'
import { FeatureGroup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import L from 'leaflet'
import { IRange } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { createFlashMessage } from '@dataplace.ai/functions/utils'

const { v4: uuidv4 } = require('uuid')
const classifyPoint = require('robust-point-in-polygon')
const geolib = require('geolib')

type DrawRangeProps = {
  location: {address: string, lat: number, lng: number},
  handleDraw: (drawRange: IRange) => void,
  removingDrawRangeLayers: boolean,
  // eslint-disable-next-line react/require-default-props
  setDrawRangeChoose?: React.Dispatch<React.SetStateAction<boolean>>
  // eslint-disable-next-line react/require-default-props
  maxRange?: number
  drawPolygon(): void
}

interface CustomFeatureGroup extends L.FeatureGroup {
  _layers: L.Layer[];
}

export const DrawRangeTool = ({
  location, handleDraw, removingDrawRangeLayers, setDrawRangeChoose, maxRange, drawPolygon,
}:DrawRangeProps):JSX.Element => {
  const featureGroupRef = useRef<CustomFeatureGroup>(null)
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  L.drawLocal.draw.handlers.polygon.tooltip.start = t('placeme.drawing_tool.click_to_start_drawing')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  L.drawLocal.draw.handlers.polygon.tooltip.cont = t('placeme.drawing_tool.click_to_continue_drawing')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  L.drawLocal.draw.handlers.polygon.tooltip.end = t('placeme.drawing_tool.click_first_point_to_close_shape')

  const removeLayers = () => {
    if (featureGroupRef.current) {
      const layersContainer = featureGroupRef.current
      const layers = layersContainer?._layers
      const layersIds = typeof layers === 'object' ? Object.keys(layers) : []

      layersIds.forEach(id => {
        layersContainer.removeLayer(layers[Number(id)])
      })
    }
  }

  const onCreate = (e: { layer: {
    _latlngs: { lat: number; lng: number }[][],
    intersects: () => boolean,
  } }) => {
    if (e?.layer?.intersects()) {
      createFlashMessage({
        message: 'placeme.drawing_tool.crossing_lines_not_allowed',
      })
      removeLayers()
      setTimeout(drawPolygon, 100) // instant draw fails to do its job
      return
    }
    const coords: [lng : number, lat: number][] = []
    e.layer._latlngs[0].forEach((x : {lat : number, lng: number}) => {
      coords.push([x.lng, x.lat])
    })
    coords.push(coords[0])
    const area = geolib.getAreaOfPolygon(coords)
    if (location) {
      if (area < 31342) {
        removeLayers()
        createFlashMessage({
          message: 'placeme.drawing_tool.area_too_small',
        })
        if (setDrawRangeChoose) {
          setDrawRangeChoose(false)
        }
      }
      else if (area <= (maxRange || 517000000)) {
        if (classifyPoint(coords, [location.lng, location.lat]) !== 1) {
          if (handleDraw) {
            handleDraw({
              id: `custom${uuidv4()}`,
              value: 0,
              type: 'custom',
              geoJSON: {
                type: 'Polygon',
                coordinates: [coords],
              },
            }) }
        } else {
          removeLayers()
          createFlashMessage({
            message: 'placeme.drawing_tool.drawing_should_contain_location',
          })
          if (setDrawRangeChoose) {
            setDrawRangeChoose(false)
          }
        }
      } else {
        removeLayers()
        createFlashMessage({
          message: 'placeme.drawing_tool.area_too_large',
        })
        if (setDrawRangeChoose) {
          setDrawRangeChoose(false)
        }
      }
    }
  }

  const onDeleted = () => {

  }

  const onEditVertex = () => {

  }

  useEffect(() => {
    if (removingDrawRangeLayers) {
      removeLayers()
    }
  }, [removingDrawRangeLayers])
  return (
    <div>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          draw={{
            polygon: {
              shapeOptions: {
                color: '#423AB3',
                fillRule: 'nonzero',
              },
              allowIntersection: false,
              drawError: {
                color: '#fc2945', // Color the shape will turn when intersects
                message: `<strong>${t('placeme.drawing_tool.crossing_lines_not_allowed')}</strong>`, // Message that will show when intersect
              },
            },
          }}
          onCreated={onCreate}
          onDeleted={onDeleted}
          onEditVertex={onEditVertex}
          position='bottomleft'
        />
      </FeatureGroup>
    </div>
  )
}
