import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from '@dataplace.ai/ui-components/atoms'
import { IAnalyseInfoModalFooterProps } from './@types/IAnalyseInfoModalFooter.props'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, shadows,
  } = theme
  return css`
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 2.5rem;
    flex-direction: row;
    height: 100px;
    min-height: 100px;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    border-top: 1px solid ${palette.light.darker};
    box-shadow: ${shadows.hover.boxShadow};;
  `
})

export const AnalyseInfoModalFooter = ({ close }: IAnalyseInfoModalFooterProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Button onClick={close}>{t('placeme.analyse_info_modal.go_to_analyse')}</Button>
    </Wrapper>
  )
}
