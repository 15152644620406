import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import i18next from 'i18next'
import dayjs from 'dayjs'
import { getCookieWithoutJSON, setCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { MainDropdown } from '../MainDropdown'
import { ILanguageSelector } from './@types/ILanguageSelector'
import { ReactComponent as PlFlag } from '../../../../assets/src/lib/icons/flags/pl.svg'
import { ReactComponent as EngFlag } from '../../../../assets/src/lib/icons/flags/eng.svg'
// import { ReactComponent as DeFlag } from '../../../../assets/src/lib/icons/flags/de.svg'
// import { ReactComponent as FrFlag } from '../../../../assets/src/lib/icons/flags/fr.svg'

require('dayjs/locale/pl')
require('dayjs/locale/en')

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin: 10px 0;
  }

  > * {
    cursor: pointer;
  }
`

export const LanguageSelector = ({
  transparent, noArrow, noBorder,
}: ILanguageSelector) :JSX.Element => {
  const [chosenLanguage, setLanguage] = useState(getCookieWithoutJSON('language') || 'pl')
  useEffect(() => {
    setCookieWithoutJSON('language', chosenLanguage)
    i18next.changeLanguage(chosenLanguage)
    dayjs.locale(chosenLanguage)
  }, [chosenLanguage])

  const getLangIcon = (language: string) => {
    switch (language) {
      case 'pl':
        return (
          <PlFlag />
        )
      case 'en':
        return (
          <EngFlag />
        )
      // case 'de':
      //   return (
      //     <DeFlag />
      //   )
      // case 'fr':
      //   return (
      //     <FrFlag />
      //   )
      default:
        return (
          <PlFlag />
        )
    }
  }

  return (
    <MainDropdown
      content={(
        <Wrapper>
          <PlFlag
            onClick={() => setLanguage('pl')}
          />
          <EngFlag
            onClick={() => setLanguage('en')}
          />
          {/* <DeFlag
            onClick={() => setLanguage('de')}
          />
          <FrFlag
            onClick={() => setLanguage('fr')}
          /> */}
        </Wrapper>
      )}
      header={getLangIcon(chosenLanguage)}
      noArrow={noArrow}
      noBorder={noBorder}
      transparent={transparent}
    />
  ) }
