import { lazy } from 'react'
import { HasToBeAuthenticated } from '@dataplace.ai/features/guards'
import { PATHS } from './constants/paths'

// Target components
const DashboardTarget = lazy(
  () => import('./features/Dashboard/components/pages/DashboardTarget'),
)

const AnalysisTarget = lazy(
  () => import('./features/Analysis/components/pages/AnalysisTarget'),
)

// Target routes
const targetRoutes = {
  id: 'TargetRoot',
  rootPath: '/',
  redirect: PATHS.DASHBOARD,
  guard: HasToBeAuthenticated,
  children: [
    {
      childPath: PATHS.DASHBOARD,
      name: 'Dashboard Target',
      component: DashboardTarget,
    },
    {
      childPath: `${PATHS.ANALYSE}/:analyseId`,
      name: 'Analyse Target',
      component: AnalysisTarget,
    },
  ],
}

export const routes = [targetRoutes]
