/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const MAX_SCREEN_SIZES = {
  desktop: 9999,
  desktop_medium: 1440,
  mobile: 1024,
}

export const MIN_SCREEN_SIZES = {
  desktop: 1025,
  mobile: 0,
}

const SIZES = {
  min: MIN_SCREEN_SIZES,
  max: MAX_SCREEN_SIZES,
}

interface IAccumulator {
  [key: string]: any
}

const getMedia = (size: string) => {
  const sizes = SIZES[size as keyof typeof SIZES]
  return (Object.keys(sizes).reduce((acc, mediaType) => {
    acc[mediaType] = (...args: any) => css`
      @media (${size}-width: ${sizes[mediaType as keyof typeof sizes]}px) {
        ${css([...args] as any)}
      }
    `
    return acc
  }, {} as IAccumulator))
}

export const useCheckForMobileDevice = (): boolean => useMediaQuery(`(max-width: ${MAX_SCREEN_SIZES.mobile}px)`)
export const useCheckForDesktopMediumDevice = (): boolean => useMediaQuery(`(max-width: ${MAX_SCREEN_SIZES.desktop_medium}px)`)

export const mediaMin = getMedia('min')
export const mediaMax = getMedia('max')
