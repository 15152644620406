import React from 'react'
import styled, { css } from 'styled-components'
import { TogglerProps } from '@dataplace.ai/ui-components/atoms/Toggler/@types/Toggler.props'

const Slider = styled.span(() => css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
    :before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 3px;
      bottom: 3px;
      background-color: rgba(21,24,31,.9);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
}
  `)

const Switch = styled.label(({ theme }) => {
  const { palette } = theme
  return css`
    position: relative;
    display: inline-block;
    width: 26px;
    height: 18px;

    > input {
      opacity: 0;
      width: 0;
      height: 0;
    }
   > input:checked + span {
    background-color: ${palette.results.green.light};
  }

  > input:focus + span {
    box-shadow: 0 0 1px ${palette.results.green.light};
  }

  > input:checked + span:before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
  }
  
  `
})

export const Toggler = ({
  value, onChange,
}: TogglerProps): JSX.Element => (
  <Switch>
    <input
      checked={value}
      onChange={onChange}
      type='checkbox'
    />
    <Slider />
  </Switch>
)
