import { AnyAction, CombinedState, ThunkDispatch } from '@reduxjs/toolkit'
import { IAnalysesAndReportsState } from '../../AnalyseAndReports/slice/@types/IAnalysesAndReportsState'
import { IChooseLocationSlice } from '../../ChooseLocationReport/@types/IChooseLocationSlice'
import { resetLocationState, saveComparedAnalyseId } from '../../ChooseLocationReport/chooseLocationSlice'
import { IAnalysisState } from '../slice/@types/IAnalysisState'
import { resetAnalysisState, saveComparedLocation, toggleIsEdit } from '../slice/analysisSlice'

export const exitFromAnalyseAction = (
  dispatch: ThunkDispatch<CombinedState<{
    location: IChooseLocationSlice;
    analysis: IAnalysisState;
    analysesAndReports: IAnalysesAndReportsState;
  }>, undefined, AnyAction>,
  isEdit?: boolean,
  lastAnalyseId?: string,
) : void => {
  // reset analysis state
  dispatch(resetAnalysisState())
  dispatch(resetLocationState())
  // reset comparison
  window.localStorage.removeItem('comparedLocation')
  window.localStorage.removeItem('comparedAnalyseId')
  dispatch(saveComparedLocation(undefined))
  dispatch(saveComparedAnalyseId(undefined))
  // reset editing
  if (isEdit) { dispatch(toggleIsEdit(false)) }
  window.localStorage.removeItem('isEdit')
  window.localStorage.removeItem('isEditCompare')
  // last analyseId -> for choosing right folder at analysis list
  if (lastAnalyseId) {
    window.localStorage.setItem('lastAnalyseId', lastAnalyseId)
    window.dispatchEvent(new CustomEvent('lastAnalyseId'))
  }
}
