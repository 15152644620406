import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Header } from '@dataplace.ai/ui-components/atoms'
import { DataSetUpdateInfoCategoryTile } from '../../molecules'
import { tilesData } from '../../../utils/tilesData'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(() => css`
  width: 100%;
`)

const HeaderWrapper = styled.div(() => css`
  width: 100%;
  > * {
    margin-bottom: 1rem;
  }
`)

const HeaderText = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.main.pt_15_regular.fontSize};
  `
})

const YellowBox = styled.div(({ theme }) => {
  const {
    typography, corners, palette,
  } = theme
  return css`
    background-color: #DEB00D;
    border-radius: ${corners.default.borderRadius};
    padding: 0.5rem 1rem;
    width: fit-content;

    > span {
      color: ${palette.light.white};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    }
  `
})

export const DataSetUpdateInfoPageBody = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header type='h3'>{t('placeme.data_update_info.header.label')}</Header>
        <HeaderText>{t('placeme.data_update_info.header.text')}</HeaderText>
        <YellowBox><span>{t('placeme.data_update_info.header.yellow_box')}</span></YellowBox>
      </HeaderWrapper>
      {tilesData.map(tile => (
        <DataSetUpdateInfoCategoryTile
          key={uuidv4()}
          tile={tile}
        />
      ))}
    </Wrapper>
  )
}
