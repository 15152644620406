/* eslint-disable react/require-default-props */
import React from 'react'
import { ReactComponent as HelpIcon } from '../../../../../assets/src/lib/icons/dataplace/help-circle.svg'

type IHelp = {
  className?: string,
  style?: React.CSSProperties,
}
export const Help = ({
  className, style,
}: IHelp): JSX.Element => (
  <HelpIcon
    className={className}
    height='16'
    style={style}
    width='16'
  />
)
