/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { config } from '../../config'

export default class Geocoder {
  reverseGeocode(lat : number, lng : number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        axios.defaults.headers.common = {}

        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.GEOCODE_KEY}`)
          .then((response) => {
            if (response.data.results && response.data.results.length > 0) {
              resolve(response.data.results)
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('No results')
            }
          })
          .catch((error) => {
            reject(error)
          })
      }, 1000)
    })
  }
}
