import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { TextInput } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { IAnalyseInfoModalSearch } from './@types/IAnalyseInfoModalSearch.props'
import { tilesData } from '../../../utils/tilesData'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { ITile } from '../../../slice/@types/ITile'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    position: relative;
    padding: 0 2.5rem;
    width: 60%;
    margin: 1rem 0 0;

    > svg {
      position: absolute;
      right: 0;
      min-width: 1.25rem;
      min-height: 1.25rem;
      padding: 1rem;
      cursor: pointer;

      > path {
        fill: ${palette.dark.lightest};
      }
    }
  `
})

export const AnalyseInfoModalSearch = ({
  setSearchSection, searchPhrase, setSearchPhrase, setActiveSection,
}: IAnalyseInfoModalSearch): JSX.Element => {
  const { t } = useTranslation()

  const filterSections = () => tilesData.reduce((acc: ISectionTile[], cur: ISectionTile) => {
    const reducedTiles = cur.tiles.reduce((acc2: ITile[], cur2: ITile) => {
      const reducedHelpers = cur2.helpers.filter(helper => helper.toLowerCase().includes(searchPhrase.toLowerCase()))

      return reducedHelpers.length
        ? [...acc2, {
          ...cur2,
          helpers: reducedHelpers,
        }]
        : acc2
    }, [])

    return reducedTiles.length
      ? [...acc, {
        ...cur,
        tiles: reducedTiles,
      }]
      : acc
  }, [])

  useEffect(() => {
    if (searchPhrase) {
      setActiveSection('')
      setSearchSection(filterSections())
    } else {
      setSearchSection([])
    }
  }, [searchPhrase])

  return (
    <Wrapper>
      <TextInput
        name='search'
        onBlur={() => {
        }}
        onChange={(_: string, value: string) => setSearchPhrase(value)}
        placeholder={t('placeme.analyse_info_modal.find')}
        value={searchPhrase}
      />
      {/* <SearchButton /> */}
    </Wrapper>
  )
}
