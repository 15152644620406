/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useState } from 'react'
import styled from 'styled-components'
import { MapTile } from '@dataplace.ai/ui-components/atoms'
import { IMiniMapTileProps } from '@dataplace.ai/ui-components/atoms/MiniMapTile/@types/IMiniMapTile.props'
import { useTranslation } from 'react-i18next'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { ReactComponent as Pin } from '../../../../../../../libs/shared/assets/src/lib/icons/pinIcon.svg'

const { v4: uuidv4 } = require('uuid')

type MiniMapTileWrapperProps = {
  small?: boolean
}
const MiniMapTileWrapper = styled.div<MiniMapTileWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ small }) => (small ? '100px' : '150px')};
  height: ${({ small }) => (small ? '60px' : '80px')};
  border-radius: 5px;

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: rgba(66, 58, 179, 1);
  }

  :hover {
    cursor: pointer;
  }
`

const TextAndPinLayer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999;
`

export function MiniMapTile({
  notext,
  nopin,
  small,
  location,
  mapId,
  comparedLocation,
  shouldMapStyleBeSave,
}: IMiniMapTileProps): JSX.Element {
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <>
      {isOpen && (
        <MapVisualizationModal
          comparedLocation={comparedLocation}
          isDisplayed={isOpen}
          location={location}
          mapId={`only-location-map-${uuidv4()}`}
          setIsDisplay={setOpen}
          shouldMapStyleBeSave={shouldMapStyleBeSave}
        />
      )}
      <MiniMapTileWrapper
        onClick={() => setOpen(true)}
        small={small}
      >
        <MapTile
          height='100px'
          location={location}
          mapId={`${mapId}mini-map`}
          mapTypeDropdown={false}
          pinDisplayed={false}
          popupHeading={`${t('generic.chosen_location')}:`}
          popupParagraph={location?.address}
          width='150px'
        />
        <TextAndPinLayer>
          {!nopin
            ? (
              <Pin
                height='24'
                width='17.14'
              />
            )
            : null}
          {!notext ? <p>{t('generic.see_on_map')}</p> : null}
        </TextAndPinLayer>
      </MiniMapTileWrapper>

    </>

  )
}

export default MiniMapTile
