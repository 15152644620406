import styled, { css } from 'styled-components'

export const TileFormSectionTitle = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 1.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium_upper.fontSize};
    font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
    line-height: ${typography.main.pt_15_medium_upper.lineHeight};
    letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
    text-transform: ${typography.main.pt_15_medium_upper.textTransform};

    > span:first-child {
      flex-grow: 1;
    }
  `
})
