import { IRangeFilter } from './@types/IRangeFilter'
import { isNumber, parseNumber } from '../sorting'

export const filterByNumber = (number: string | number, filter: IRangeFilter | string): boolean => {
  if (number === null || number === '' || number === undefined || typeof filter === 'string') { return false }
  const parsedNumber = +(parseNumber(number))
  if (!isNumber(parsedNumber)) { return false }
  const {
    from, to,
  } = filter
  if (from && to) {
    return parsedNumber >= +(from) && parsedNumber <= +(to)
  } if (from) {
    return parsedNumber >= +(from)
  }
  return parsedNumber <= +(to)
}
