import React from 'react'
import styled, { css } from 'styled-components'
import { IHeaderProps } from './@types/IHeaderProps.props'
import { HProps } from './@types/HProps.props'

const H1 = styled.h1<HProps>((props) => css`
  font-size: 2.6rem; //42px
  ${commonStyles(props)}
`)

const H2 = styled.h2<HProps>((props) => css`
  font-size: 2.3rem; //37px
  ${commonStyles(props)}
`)

const H3 = styled.h3<HProps>((props) => css`
  font-size: 1.75rem; //28px
  ${commonStyles(props)}
`)

const H4 = styled.h4<HProps>((props) => css`
  font-size: 1.315rem; //21px
  ${commonStyles(props)}
`)

const H5 = styled.h5<HProps>((props) => css`
  font-size: 1.125rem; //18px
  ${commonStyles(props)}
`)

const H6 = styled.h6<HProps>((props) => css`
    font-size: 1rem; //16px
  ${commonStyles(props)}
`)

const commonStyles = (props: HProps) => {
  const {
    margin, color, weight, uppercase, spanColor,
  } = props
  return css`
    margin: ${margin || 0};
    color: ${color || 'inherit'};
    font-weight: ${weight || '600'};
    text-transform: ${uppercase ? 'uppercase' : 'none'};

    span {
      font-weight: 600;
      color: ${spanColor || 'inherit'}
    }
  `
}

export const Header = (props: IHeaderProps): JSX.Element => {
  const {
    children, ...restProps
  } = props

  switch (props.type) {
    case 'h1': {
      return (
        <H1 {...restProps}>
          {children}
        </H1>
      )
    }
    case 'h2': {
      return (
        <H2 {...restProps}>
          {children}
        </H2>
      )
    }
    case 'h3': {
      return (
        <H3 {...restProps}>
          {children}
        </H3>
      )
    }
    case 'h4': {
      return (
        <H4 {...restProps}>
          {children}
        </H4>
      )
    }
    case 'h5': {
      return (
        <H5 {...restProps}>
          {children}
        </H5>
      )
    }
    case 'h6':
    {
      return (
        <H6 {...restProps}>
          {children}
        </H6>
      )
    }
    default:
    {
      return (
        <H1 {...restProps}>
          {children}
        </H1>
      )
    }
  }
}
