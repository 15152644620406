import React from 'react'
import styled, { css } from 'styled-components'
import { IButtonProps } from '../Button/@types/IButton.props'

const StyledDashedButton = styled.button<IButtonProps>(({
  minWidth, minHeight,
}) => css`
  border: none;
  background-color: transparent;
  color: unset;
  border-radius: 0.25rem;
  outline: none;
  min-width: ${minWidth || '120px'};
  min-height: ${minHeight || 'auto'};
  padding: 0.5rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23DDDDDDFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  //to customize dashed border visit https://kovart.github.io/dashed-border-generator/
  :disabled {
    cursor: default;
  }

  transition: all .2s ease-in-out;
`)

export const DashedButton: React.FC<IButtonProps> = ({
  children, minWidth, minHeight, as, to, ...restProps
}) => (
  <StyledDashedButton
    minHeight={minHeight}
    minWidth={minWidth}
    {...restProps}
  >
    {children}
  </StyledDashedButton>

)
