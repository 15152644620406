import React from 'react'
import styled, { css } from 'styled-components'
import { INumberInputProps } from './@types/INumberInput.props'

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
`
const Error = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-top: 5px;
    color:${palette.results.red.light};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};`
})

const Input = styled.div<{error?: string, noArrows?: boolean }>(({
  theme, error, noArrows,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    position: relative;

    > input {
      border: ${error ? '1px solid red' : '1px solid #E1E2E3'};
      box-sizing: border-box;
      border-radius: 6px;
      margin-top: 0.5rem;
      display: block;
      height: 2.5rem;
      width: 100%;
      padding: 0 2.75rem 0 10px;
    }
    
    > input:invalid {
      border: 1px solid red;
    }

    ${noArrows && css`
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
    `}

    > span {
      position: absolute;
      right: 0.5rem;
      top: 1rem;
      font-size: ${typography.main.pt_15_semibold.fontSize};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      color: ${palette.dark.lightest};
    }
` })

export const NumberInput: React.FC<INumberInputProps> = ({
  name,
  onChange,
  value,
  unit,
  step,
  error,
  noArrows,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value.replace(',', '.'))
    }
  }

  return (
    <Wrapper>
      <Input
        error={error}
        noArrows={noArrows}
      >
        <input
          lang='en'
          min={0}
          name={name}
          onBlur={(e) => {
            if (!e.target.value.replace(',', '.').length) {
              if (onChange) {
                onChange('0')
              }
            } }}
          onChange={handleChange}
          step={step}
          type='number'
          value={value}
        />
        {unit && <span>{unit}</span>}
      </Input>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}
