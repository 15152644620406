import React, { useContext, useEffect, useState } from 'react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import { AuthContext } from '..'

interface IAnalyticsContext {
  analytics: Analytics | undefined
}

export const AnalyticsContext = React.createContext<IAnalyticsContext>({
  analytics: undefined,
})

export const AnalyticsProvider = ({ children }: {children: React.ReactNode}): JSX.Element => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  const [writeKey] = useState<string | undefined>(process.env.NX_SEGMENT_WRITE_KEY)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    const loadAnalytics = async () => {
      if (writeKey) {
        const [response] = await AnalyticsBrowser.load({
          writeKey,
        })
        setAnalytics(response)
      }
    }
    loadAnalytics()
  }, [writeKey])

  useEffect(() => {
    if (authContext?.userData?.user?.uid) {
      analytics?.identify(authContext.userData.user.uid, {
        email: authContext.userData.user.email,
        name: authContext.userData.user.displayName,
      })
    }
  }, [authContext, analytics])

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
