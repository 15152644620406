/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { SelectCaret } from '@dataplace.ai/ui-components/atoms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { IPlacemeSelectProps } from './@types/IPlacemeSelect.props'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div<{width?: string}>(({ width }) => css`
    margin: 0.5rem 0;
    width: ${width || '100%'};
  `)

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const StyledDropDown = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    position: relative;
    color: ${palette.black};
    border: 1px solid ${palette.dark.lightest};
    border-radius: 0.25rem;
    height: 2.5rem;
    padding-left: 0.5rem;
    padding-right: 2rem;
    font-weight: 400;
    font-size: 1rem;
    background: #ffffff;
    display: flex;
    align-items: center;

    > :first-child {
      position: absolute;
      right: 0;
      margin: 0 0.75rem;
    }

    :hover {
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
      border-color: ${palette.blue};
    }

    :focus {
      margin: 0;
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
      border: 2px solid ${palette.blue};
      outline: none;
    }
  `
})

const DropDownListContainer = styled.ul(() => css`
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    overflow: hidden;
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    left: 0;
    top: 3.5rem;
    z-index: 1;
    margin-bottom: 0.5rem;
  `)

const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    margin: 0 0.5rem;
    padding: 0.5rem;
    list-style: none;
    color: ${palette.black};
    border-radius: ${corners.default.borderRadius} ;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    
    :first-child {
      margin-top: 0.5rem;
    }

    :last-child {
      margin-bottom: 0.5rem;
    }
    
    :hover {
      background-color: ${palette.results.green.opaque};
      color: ${palette.results.green.dark};
    }
  `
})

const Placeholder = styled.span(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    padding: 0.5rem;
    list-style: none;
    color: ${palette.dark.lightest};
    border-radius: ${corners.default.borderRadius} ;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  `
})

export const PlacemeSelect: React.FC<IPlacemeSelectProps> = ({
  options, selected, onChange, width, label, additionalInfo, name, placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: ResourceWithId) => () => {
    setIsOpen(false)
    onChange(name, value)
  }

  return (
    <Wrapper width={width}>
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <StyledDropDown
        onBlur={() => {
          setIsOpen(false)
        }}
        onClick={toggling}
        tabIndex={0}
      >
        <SelectCaret active={isOpen} />
        {!selected?.id && <Placeholder>{placeholder}</Placeholder>}
        {selected?.content}
        {isOpen && (
          <DropDownListContainer>
            {options?.map((option) => (
              <ListItem
                key={uuidv4()}
                onClick={onOptionClicked(option)}
              >
                {option?.content}
              </ListItem>
            ))}
          </DropDownListContainer>
        )}
      </StyledDropDown>
    </Wrapper>

  )
}
