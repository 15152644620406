import { getAxios } from 'libs/shared/functions/src/utils'

export interface IResponseDataObject {
  loading: boolean
  error: unknown,
  value: unknown,
}

export const getResponseDataObject = async (
  token: string,
  endpoint: string,
  body: unknown,
  config: {
    API_URL: string | undefined,
    [key: string]: unknown
  }): Promise<IResponseDataObject> => {
  try {
    const axiosInstance = await getAxios(config.API_URL, token)
    const response = await axiosInstance.post(endpoint, body)
    return {
      loading: false,
      error: '',
      value: response.data,
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      loading: false,
      error: e.error,
      value: null,
    }
  }
}
