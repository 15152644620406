
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, Loader, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAxios } from '@dataplace.ai/functions/utils'
import { config } from 'apps/placeme/src/config'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { compareLocationCatchmentAndDataAction, saveNewRangeAction, saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { createNewAnalyse } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { ILocation, IRange } from '@dataplace.ai/types'
import { RootState } from '../../../../../../../redux/store'
import { ISatellite } from './@types/ISatellite'
import { ComparedLocationHeader } from '../../../../atoms'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

export const SatelliteTile:
React.FC<{data: ISatellite, tileId: string}> = ({
  data, tileId,
}) => {
  // constants
  const { t } = useTranslation()
  const {
    value, currentSubscriptionData, analyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const dispatch = useAppDispatch()

  const authContext = useContext(AuthContext)

  // states
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }

  useEffect(() => {
    dispatch(saveTileData('visualization', tileId, {
      ...data,
      mapLocation,
      comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
    }))
  }, [mapLocation, comparedMapLocation])
  // functions
  const fetchTileRange = () => values.find(cat => cat.id === 'visualization')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async () => {
    const catchmentId = values.find(cat => cat.id === 'visualization')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId
    if (accepted)
    {
      const body = {
        catchmentId,
      }

      let saveData
      try {
        const axiosInstance = await getAxios(config.API_URL, token)
        const response = await axiosInstance.post<ITileData>(ENDPOINTS.SATELLITE_TILE, body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
          mapLocation,
          comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      finally {
        dispatch(saveTileData('visualization', tileId, saveData))
      } }
  }, [token, accepted])

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(token, analyseId, comparedLocation?.location))
    }
    finally {
      const satelliteTile = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
        t.id === tileId)
      dispatch(compareLocationCatchmentAndDataAction(
        token,
        fetchTileRange() || '',
        satelliteTile?.chosenRange as IRange,
        satelliteTile?.section || '',
        satelliteTile?.id || '',
        currentSubscriptionData?.value?.subscriptionId || '',
        comparedLocation?.location as ILocation,
        {},
      ))
    }
  }, [fetchData, fetchTileRange(), !data?.value, accepted])

  // hooks

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token && !fetchTileRange()) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'visualization', tileId, {
        id: `${tileId}-250-line`,
        value: 250,
        type: 'line',
      })) }
  }, [token])

  useEffect(() => {
    if (fetchTileRange()) setAccepted(true)
  }, [fetchTileRange()])

  useEffect(() => {
    if (fetchTileRange() && !data?.value && accepted) {
      const satelliteTile = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
        t.id === tileId)
      // compared location comparison trigger
      if (comparedLocation?.generatedFromNow && satelliteTile?.chosenRange?.type !== 'custom') {
        const satelliteTile = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
          t.id === tileId)
        const catchmentId = satelliteTile?.chosenRange?.catchmentId

        // compared location
        if (satelliteTile
              && catchmentId
              && satelliteTile?.chosenRange?.type !== 'custom'
              && comparedLocation?.location) {
          handleFetchComparedData()
        }
      } else {
        fetchData()
      }
    }
  }, [fetchData, data?.value])

  return (
    <>
      {!data || data?.loading
        ? (<Loader />)
        : (
          <TileFormWrapper>
            <>
              {data?.value?.comparedLocation
                 && (
                   <ComparedLocationHeader>
                     <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                     {' '}
                     <span>{value?.address}</span>
                   </ComparedLocationHeader>
                 )}
              <StyledTitleFormSectionSubTitle>
                <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
              </StyledTitleFormSectionSubTitle>
              <MapTile
                dragging
                height='500px'
                layers={[]}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                style={{
                  id: 'satellite',
                  name: 'placeme.map_satellite_type',
                  style: 'ck72206dr091e1ipfj5zu6js1',
                }}
                width='100%'
                zoom={14}
                zoomControl
              />
              {isMapDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isMapDisplayed}
                  layers={[]}
                  location={value}
                  mapId='visualization-tile-satellite'
                  setIsDisplay={setIsMapDisplayed}
                  style={{
                    id: 'satellite',
                    name: 'placeme.map_satellite_type',
                    style: 'ck72206dr091e1ipfj5zu6js1',
                  }}
                  zoom={14}
                />
              )}
            </>
            {data?.value?.comparedLocation
            && (
              <>
                <ComparedLocationHeader
                  second
                  style={{
                    paddingTop: '2rem',
                  }}
                >
                  <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                  {' '}
                  <span>{comparedLocation?.location?.address}</span>
                </ComparedLocationHeader>
                <StyledTitleFormSectionSubTitle>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                </StyledTitleFormSectionSubTitle>
                <MapTile
                  dragging
                  height='500px'
                  layers={[]}
                  location={comparedLocation?.location}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={comparedLocation?.location?.address}
                  setMapLocation={setComparedMapLocation}
                  showScaleControl
                  style={{
                    id: 'satellite',
                    name: 'placeme.map_satellite_type',
                    style: 'ck72206dr091e1ipfj5zu6js1',
                  }}
                  width='100%'
                  zoom={14}
                  zoomControl
                />
                {isComparedMapDisplayed && (
                  <MapVisualizationModal
                    isDisplayed={isComparedMapDisplayed}
                    layers={[]}
                    location={comparedLocation?.location}
                    mapId='visualization-tile-satellite_compared'
                    setIsDisplay={setIsComparedMapDisplayed}
                    style={{
                      id: 'satellite',
                      name: 'placeme.map_satellite_type',
                      style: 'ck72206dr091e1ipfj5zu6js1',
                    }}
                    zoom={14}
                  />
                )}
              </>
            )}
          </TileFormWrapper>
        )}
    </>
  ) }

export default SatelliteTile
