import React from 'react'
import styled, { css } from 'styled-components'
import { IAcceptInputProps } from '@dataplace.ai/ui-components/molecules/AcceptInput/@types/IAcceptInput.props'
import { InputWrapperProps } from '@dataplace.ai/ui-components/molecules/AcceptInput/@types/InputWrapperProps'

const AcceptButton = styled.button(({ theme }) => {
  const { palette } = theme
  return css`
    border: none;
    border-radius: 10px;
    position: absolute;
    background-color: ${palette.dark.main};
    right: 2.5rem;
    width: 4rem;
    height: 2.2rem;
    cursor: pointer;
    background-image: url('assets/icons/checkMark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30%;
  `
})

const InputWrapper = styled.div<InputWrapperProps>(({
  theme, width, minHeight,
}) => {
  const { palette } = theme
  return css`
    display: flex;
    position: relative;
    align-items: center;
    width: ${width || 'auto'};

    input {
      min-height: ${minHeight || 'auto'};
      padding: 1.25rem 8rem 1.25rem 2.5rem;
      width: 100%;
      border: 1.5px solid ${palette.dark.main};
      border-radius: 10px;
      font-size: 1.125rem;
      :focus {
        outline: none;
      }
    }
  `
})

export const AcceptInput = ({
  value, placeholder, onChange, onAccept, width, minHeight,
}: IAcceptInputProps): JSX.Element => {
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }

  const handleClick = () => {
    onAccept()
  }
  return (
    <InputWrapper
      minHeight={minHeight}
      width={width}
    >
      <input
        onChange={handleOnChange}
        placeholder={placeholder}
        value={value}
      />
      <AcceptButton onClick={handleClick} />
    </InputWrapper>
  )
}

