import React from 'react'
import styled, { css } from 'styled-components'
import 'react-sweet-progress/lib/style.css'
import { IProgressBar } from './@types/IProgresBar.props'

const { Progress } = require('react-sweet-progress')

const StyledProgress = styled(Progress)(({ theme }) => css`

  .react-sweet-progress-line-inner{
    min-height: 4px;
  }

  .react-sweet-progress-symbol{
    display: none;
  }
`)

export const ProgressBar = ({
  value, status,
}: IProgressBar):JSX.Element => (
  <StyledProgress
    percent={value}
    status={status}
    theme={{
      active: {
        symbol: ' ',
        color: 'rgba(198, 234, 225, 1)',
        trailColor: 'rgba(237, 240, 245, 1)',
      },
    }}
  />
)
