import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { CloseIcon, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { getAxios } from '@dataplace.ai/functions/utils'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { config } from 'apps/placeme/src/config'
import { AuthContext } from '@dataplace.ai/features'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    max-height: 99vh;
    min-width: 33rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
  `
})

const Header = styled.div(({ theme: { palette } }) => css`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${palette.light.darkest};
  gap: 1rem;
`)

const HeaderTextWrapper = styled.div(({
  theme: {
    typography, palette,
  },
}) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  > .header_main {
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};
  }

  > .header_sub {
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    color: ${palette.blue};
  }

`)

const Content = styled.div`
  overflow-y: scroll;
`

const ContentHeader = styled.div(({ theme: { typography } }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem 0.5rem 2rem;

  > span {
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
  }
`)

const AddressList = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 2rem;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    background-color: ${palette.light.main};

     > .content_address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
     }

     > .content_score {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
     }
  }

  > div:nth-child(2n) {
    background-color: ${palette.light.white};
  }
`)

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`

interface IBigBenChainRankingData {
  id: string
  address: string
  result: number
}

interface IBigBenRangeChartModalProps {
  catchmentId: string | undefined
  parameter: string
  opened: boolean
  setOpened(opened: boolean): void
  rangeTranslation: string
}

export function BigBenRangeChartModal({
  catchmentId, parameter, opened, setOpened, rangeTranslation,
}: IBigBenRangeChartModalProps): JSX.Element {
  const { t } = useTranslation()
  const [rankingData, setRankingData] = useState<IBigBenChainRankingData[]>([])
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const getRankingData = async () => {
    if (catchmentId && !rankingData.length) {
      const axiosInstance = await getAxios(config.API_URL, token)
      const response = await axiosInstance.post(ENDPOINTS.CHAIN_RANKING_BIG_BEN_TILE, {
        catchmentId,
        parameter: [parameter],
      })
      if (response) { setRankingData(response?.data?.ranking) }
    }
  }

  return (
    <PopupWithOverlay
      closeOnDocumentClick={false}
      onClose={() => setOpened(false)}
      onOpen={getRankingData}
      open={opened}
    >
      <Wrapper>
        {rankingData && rankingData.length
          ? (
            <>
              <Header>
                <HeaderTextWrapper>
                  <span className='header_main'>{t('placeme.big_ben.range_chart_modal.header.main')}</span>
                  <span className='header_sub'>{`${t('generic.analysis').toUpperCase()}: ${t(`placeme.big_ben.range_chart_modal.header.sub.${parameter}`).toUpperCase()}, ${rangeTranslation}`}</span>
                </HeaderTextWrapper>
                <CloseIcon onClick={() => setOpened(false)} />
              </Header>
              <ContentHeader>
                <span>{t('placeme.big_ben.range_chart_modal.content.header.address')}</span>
                <span>{t('placeme.big_ben.range_chart_modal.content.header.score')}</span>
              </ContentHeader>
              <Content>
                <AddressList>
                  {rankingData.map((location: IBigBenChainRankingData, index) => (
                    <div key={location.id}>
                      <span className='content_address'>{`${index + 1}. ${location.address}`}</span>
                      <span className='content_score'>{location.result}</span>
                    </div>
                  ))}
                </AddressList>
              </Content>
            </>
          )
          : (
            <LoaderWrapper>
              <object
                data='assets/loaders/placeme/small.svg'
                type='image/svg+xml'
                width='130'
              >
                placeme loader
              </object>
            </LoaderWrapper>
          )}
      </Wrapper>
    </PopupWithOverlay>
  )
}
