import React from 'react'
import styled from 'styled-components'
import { Paragraph } from './Paragraph'

type IconAndTextLabelWrapperProps = {
  textColor?: string|null
}
const IconAndTextLabelWrapper = styled.div<IconAndTextLabelWrapperProps>`
  display: flex;

  p{
    font-weight: 600;
    color: ${({ textColor }) => (textColor || '#2a2a2c')};
  }

  :hover{
    cursor: pointer;
  }
`
const Icon = styled.img`
  margin-right: 10px;
  width: ${({ width }) => (width || 'auto')};
`
export interface IIconAndTextLabelProps {
  iconAlt: string;
  iconSrc: string;
  text: string;
  textColor?: string;
  iconWidth?: string
}

export function IconAndTextLabel({
  iconAlt, iconSrc, text, textColor, iconWidth,
}: IIconAndTextLabelProps):JSX.Element {
  return (
    <IconAndTextLabelWrapper textColor={textColor}>
      <Icon
        alt={iconAlt}
        src={iconSrc}
        width={iconWidth}
      />
      <Paragraph size='verySmall'>{text}</Paragraph>
    </IconAndTextLabelWrapper>
  )
}

export default IconAndTextLabel
