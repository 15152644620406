/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import styled, { css } from 'styled-components'
import {
  IconButton,
  TitleFormSectionSubTitle,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation, Trans } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { PlacemeSelect } from '../PlacemeSelect'
import { IPointsCategorySelectorProps } from './@types/IPointsCategorySelector.props'
import { ReactComponent as Edit } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/edit.svg'

const Selector = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    background: ${palette.light.white};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 0.5rem;

    > span {
      width: 100%;
      padding: 0 3rem 0 1rem;
      color: ${palette.dark.normal};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
    }
  `
})

const ValueWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    align-items: center;
    background: ${palette.light.main};
    border: 1px dashed ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;

    > span:nth-child(1) {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > span:nth-child(2) {
      padding: 0 1rem;
      color: ${palette.blue};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }
  `
})

const categories: ResourceWithId[] = [
  {
    id: 'pharmacy',
    content: <Trans>placeme.pois_tile.category.pharmacy</Trans>,
  },
  {
    id: 'cafe',
    content: <Trans>placeme.pois_tile.category.cafe</Trans>,
  },
  {
    id: 'bank',
    content: <Trans>placeme.pois_tile.category.bank</Trans>,
  },
  {
    id: 'bakery',
    content: <Trans>placeme.pois_tile.category.bakery</Trans>,
  },
  {
    id: 'drugstore',
    content: <Trans>placeme.pois_tile.category.drugstore</Trans>,
  },
  {
    id: 'grocery store',
    content: <Trans>placeme.pois_tile.category.grocery-store</Trans>,
  },
  {
    id: 'restaurant',
    content: <Trans>placeme.pois_tile.category.restaurant</Trans>,
  },
  {
    id: 'butcher',
    content: <Trans>placeme.pois_tile.category.butcher</Trans>,
  },
]

export const PointsCategorySelector = ({
  setAccepted,
  value,
  onChange,
  accepted,
}: IPointsCategorySelectorProps): JSX.Element => {
  const { t } = useTranslation()

  const handleChange = (_: string, value: ResourceWithId) => {
    onChange(value)
  }

  return (
    <>
      {!(value && accepted) && (
        <>
          <TitleFormSectionSubTitle>
            {t('placeme.points_category_selector.title')}
          </TitleFormSectionSubTitle>
          <Selector>
            <span>{t('placeme.points_category_selector.description')}</span>
            <PlacemeSelect
              name=''
              onChange={handleChange}
              options={categories}
              placeholder={t('placeme.points_category_selector.placeholder')}
              selected={value}
            />
          </Selector>
        </>
      )}
      {(value && accepted) && (
        <ValueWrapper>
          <span>{t('generic.settings')}</span>
          <span>{value?.content}</span>
          <IconButton
            onClick={() => {
              onChange({
                id: '',
                content: null,
              })
              setAccepted(false)
            }}
          >
            <Edit />
          </IconButton>
        </ValueWrapper>
      )}
    </>
  )
}
