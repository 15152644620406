import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Menu } from '../../../../assets/src/lib/icons/dataplace/menu.svg'

const Button = styled.button(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.625rem;
    height: 1.625rem;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    position: relative;

    > svg {
      width: 0.875rem;
    }
  `,
)

const Popup = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 1rem;
    position: absolute;
    z-index: 2;
    background: ${palette.black};
    box-shadow: ${shadows.hover.boxShadow};
    top: 2rem;
    border-radius: ${corners.default.borderRadius};
    right: 0;
    color: white;
    display: flex;
    flex-direction: column;

  `
})

interface IOnClickProps {
  children: JSX.Element | JSX.Element[]
}

export const MenuButton = ({ children }: IOnClickProps): JSX.Element => {
  const [isOpen, toggle] = useState<boolean>(false)

  return (
    <Button onClick={() => toggle(!isOpen)}>
      <Menu />
      {isOpen && (
        <Popup>
          {children}
        </Popup>
      )}
    </Button>
  )
}
