/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */

import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AddMapView, MapVisualizationModal, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { saveTileData, saveTiles } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { buildingsTableData1, buildingsTableData2 } from './data'
import { RootState } from '../../../../../../../redux/store'
import { IBuildingsFunctions, IBuildingsTileData, IBuildingsTypes } from './@types/IBuildingsTileData'
import { ComparedLocationHeader } from '../../../../atoms'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    display: flex;
    margin: 1rem 0;
    width: 100%;
    height: 336px;
  `,
)

const MainLabel = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color: ${palette.black};
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
`
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)
const StyledSpan = styled.span`
  :nth-child(5n+1){
    justify-content: flex-start !important;
    text-align: start;
  }
`

const TableWrapper = styled.div`
  margin: 30px 0;
`

const LegendWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 10px;
   >div{
     display: flex;
     align-items: center;
     
     >p{
       color: ${palette.black};
       font-size: 9px;
        white-space: nowrap;
     }
   }
 `
})

const LegendColor = styled.span<{color: string}>(({ color }) => css`
      background-color: ${color};
      width: 10px;
      height:10px;
      border-radius: 2px;
      margin-right: 5px;
    `)

const categories: ResourceWithId[] = [
  {
    id: 'types',
    content: 'placeme.buildings_category.types',
  },
  {
    id: 'functions',
    content: 'placeme.buildings_category.functions',
  },
]

export const BuildingsTile: React.FC<{data: IBuildingsTileData, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [val, setValue] = useState<ResourceWithId[]>(categories)
  const { labels: labels1 } = buildingsTableData1
  const { labels: labels2 } = buildingsTableData2
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [comparedMapVisible, setComparedMapVisible] = useState<boolean>(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const catchmentId = values?.find(c => c.id === 'surroundings')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  // hooks
  useEffect(() => {
    if (data?.value && mapLocation) {
      dispatch(saveTileData('surroundings', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (data?.value) {
      // save categories for pdf
      const toExclude = categories?.filter(cat =>
        !val?.map(item => item?.id).includes(cat?.id)).map(item => item?.id)
      if (catchmentId && toExclude?.length) {
        const pdfTiles = values?.map(cat => {
          if (cat?.id === 'surroundings') {
            return {
              ...cat,
              tiles: cat?.tiles.map(tile => {
                if (tile?.chosenRange?.catchmentId === catchmentId) {
                  return {
                    ...tile,
                    pdfExcluded: toExclude,
                  }
                } return tile
              }),

            }
          }
          return cat
        })
        dispatch(saveTiles(pdfTiles))
      }
    }
  }, [val])

  // functions

  const getLayers = (compared: boolean) => {
    const tile = values?.find(c => c.id === 'surroundings')?.tiles?.find(t =>
      t.id === tileId)
    const rangeCoords = compared
      ? tile?.comparedChosenRange?.geoJSON?.coordinates
      : tile?.chosenRange?.geoJSON?.coordinates
    if (rangeCoords) {
      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        {
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'buildings_range_compared' : 'buildings_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        },
      ]
      return layers
    }
    return undefined
  }

  const handleMapOpen = (compared: boolean) => {
    if (compared) { setIsComparedMapDisplayed(!isComparedMapDisplayed) }
    else { setIsMapDisplayed(!isMapDisplayed) }
  }
  const handleAddMap = (compared: boolean) => {
    if (compared) { setComparedMapVisible(true) }
    else { setMapVisible(true) }
  }

  const typesLabels = () => {
    const newArray = labels1.map((label, index) => {
      if (index === 0) return <MainLabel key={label}>{t(label)}</MainLabel>
      return <span key={label}>{t(label)}</span>
    })
    newArray.push(<span />)
    return newArray
  }

  const functionsLabels = () => {
    const newArray = labels2.map((label, index) => {
      if (index === 0) return <MainLabel key={label}>{t(label)}</MainLabel>
      return <span key={label}>{t(label)}</span>
    })
    newArray.push(<span />)
    return newArray
  }

  const functionsMainContent = (compared: boolean) => {
    if (data && data.value) {
      const functions = (compared
        ? data?.value?.comparedLocation?.functions
        : data.value.functions)as unknown as IBuildingsTypes[]
      return functions.map((item) => {
        const values = item.values.slice(0, 3).map((val) => (
          <span
            key={uuidv4() + val}
            style={{
              textAlign: 'right',
            }}
          >
            {val}
          </span>
        ))
        values.unshift(
          <MainLabel
            key={item.label}

          >
            {t(`placeme.buildings.functions.${item.label.toLocaleLowerCase().split(/[_ -]+/)
              .join('_')}`)}
          </MainLabel>,
        )
        return values
      })
    }
    return []
  }

  const typesMainContent = (compared: boolean) => {
    if (data && data.value) {
      const types = (compared ? data.value?.comparedLocation?.types : data.value.types) as unknown as IBuildingsTypes[]
      return types.map((item) => {
        const values = item.values.slice(0, 3).map((val) => (
          <span
            key={uuidv4() + val}
            style={{
              textAlign: 'right',
            }}
          >
            {val}
          </span>
        ))
        values.unshift(
          <MainLabel
            key={item.label}
          >
            {t(`placeme.buildings.types.${item.label.toLocaleLowerCase().split(/[_ -]+/)
              .join('_')}`)}
          </MainLabel>,
        )
        return values
      })
    }
    return []
  }

  const typesSubContent = (compared: boolean) => {
    if (data && data.value) {
      const types = (compared ? data.value?.comparedLocation?.types : data.value.types) as unknown as IBuildingsTypes[]
      const returnArray: JSX.Element[][][] = []
      types.forEach(item => {
        const array = item.subtypes.map(subitem => {
          const values = subitem.values.slice(0, 3).map(val => (
            <StyledSpan
              key={uuidv4() + val}
              style={{
                textAlign: 'right',
              }}
            >
              {val}
            </StyledSpan>
          ))
          values.unshift(
            <StyledSpan
              key={item.label}
            >
              {t(`placeme.buildings.subtypes.${subitem.label.toLocaleLowerCase().split(/[_ -]+/)
                .join('_')}`)}
            </StyledSpan>,
          )
          values.push(<StyledSpan key={uuidv4()}> </StyledSpan>)
          return values
        })
        returnArray.push(array)
      })
      return returnArray
    }
    return []
  }
  const functionsSubContent = (compared: boolean) => {
    if (data && data.value) {
      const functions = (compared
        ? data?.value?.comparedLocation?.functions
        : data?.value?.functions) as unknown as IBuildingsFunctions[]
      const returnArray: JSX.Element[][][] = []
      functions.forEach(item => {
        const array = item.subfunctions.map(subitem => {
          const values = subitem.values.slice(0, 3).map(val => (
            <StyledSpan
              key={uuidv4() + val}
              style={{
                textAlign: 'right',
              }}
            >
              {val}
            </StyledSpan>
          ))
          values.unshift(
            <StyledSpan
              key={item.label}
            >
              {t(`placeme.buildings.subfunctions.${subitem.label.toLocaleLowerCase().split(/[_ -]+/)
                .join('_')}`)}
            </StyledSpan>,
          )
          values.push(<StyledSpan key={uuidv4()}> </StyledSpan>)
          return values
        })
        returnArray.push(array)
      })
      return returnArray
    }
    return []
  }
  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <NestedSelectWrapper>
          <span>{t('placeme.buildings_tile.categories')}</span>
          <NestedSelect
            name=''
            onChange={setValue}
            options={categories?.map(item => ({
              id: item?.id,
              content: t(`placeme.buildings_category.${item?.id}`),
            }))}
            selected={val?.map(item => ({
              id: item?.id,
              content: t(`placeme.buildings_category.${item?.id}`),
            }))}
            width='50%'
          />
        </NestedSelectWrapper>
        {data?.value?.comparedLocation && (
          <ComparedLocationHeader
            style={{
              marginTop: '2rem',
            }}
          >
            <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
            {' '}
            <span>{value?.address}</span>
          </ComparedLocationHeader>
        )}
        {val.map(v => v.id).includes('types')
       && (
         <TableWrapper>
           <Table
             buildings
             content={typesMainContent(false)}
             expandable={typesSubContent(false)}
             expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
             gap='1rem'
             headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
             headerTextAlign='right'
             labels={typesLabels()}
             rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
           />
         </TableWrapper>
       )}

        {val.map(v => v.id).includes('functions')
        && (
          <TableWrapper>
            <Table
              buildings
              content={functionsMainContent(false)}
              expandable={functionsSubContent(false)}
              expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              gap='1rem'
              headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              headerTextAlign='right'
              labels={functionsLabels()}
              rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
            />
          </TableWrapper>
        )}

        {(!mapVisible) && (
          <AddMapView
            buttonValue={0}
            description='placeme.add_buildings_map.description'
            onChange={() => handleAddMap(false)}
            title='placeme.add_buildings_map.title'
          />
        )}
        {(val.length > 0 && mapVisible) && (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.buildings_tile.points_on_map')}</span>
              <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              {data?.value?.legend?.length
              && (
                <>
                  <LegendWrapper>
                    {data?.value?.legend?.map((item) => (
                      <div key={uuidv4()}>
                        <LegendColor color={item?.color} />
                        <p>{item?.label}</p>
                      </div>
                    ))}
                  </LegendWrapper>
                </>
              )}
              <MapTile
                dragging
                height='100%'
                layers={getLayers(false)}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                setMapLocation={setMapLocation}
                showScaleControl
                styleLayer={{
                  id: data?.value?.buildingsMap?.id,
                  styleId: data?.value?.buildingsMap?.styleId,
                  user: data?.value?.buildingsMap?.user,
                }}
                width='100%'
                zoom={16}
                zoomControl
              />
            </MapWrapper>
          </>
        ) }
        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={getLayers(false)}
            legend={data?.value?.legend?.length
              ? (
                <LegendWrapper>
                  {data?.value?.legend?.map((item) => (
                    <div key={uuidv4()}>
                      <LegendColor color={item?.color} />
                      <p>{item?.label}</p>
                    </div>
                  ))}
                </LegendWrapper>
              )
              : undefined}
            location={value}
            mapId={`buildings-tile-${values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === 'buildings')?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
            styleLayer={{
              id: data?.value?.buildingsMap?.id,
              styleId: data?.value?.buildingsMap?.styleId,
              user: data?.value?.buildingsMap?.user,
            }}
            zoom={17}
          />
        )}
        {data?.value?.comparedLocation
        && (
          <>
            <ComparedLocationHeader
              second
              style={{
                marginTop: '2rem',
              }}
            >
              <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
              {' '}
              <span>{comparedLocation?.location?.address}</span>
            </ComparedLocationHeader>
            {val.map(v => v.id).includes('types')
       && (
         <TableWrapper>
           <Table
             buildings
             content={typesMainContent(true)}
             expandable={typesSubContent(true)}
             expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
             gap='1rem'
             headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
             headerTextAlign='right'
             labels={typesLabels()}
             rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
           />
         </TableWrapper>
       )}

            {val.map(v => v.id).includes('functions')
        && (
          <TableWrapper>
            <Table
              buildings
              content={functionsMainContent(true)}
              expandable={functionsSubContent(true)}
              expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              gap='1rem'
              headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              headerTextAlign='right'
              labels={functionsLabels()}
              rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
            />
          </TableWrapper>
        )}

            {(!comparedMapVisible) && (
              <AddMapView
                buttonValue={0}
                description='placeme.add_buildings_map.description'
                onChange={() => handleAddMap(true)}
                title='placeme.add_buildings_map.title'
              />
            )}
            {(val.length > 0 && comparedMapVisible && data?.value?.comparedLocation) && (
              <>
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.buildings_tile.points_on_map')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                </StyledTitleFormSectionSubTitle>
                <MapWrapper>
                  {data?.value?.comparedLocation?.legend?.length
              && (
                <>
                  <LegendWrapper>
                    {data?.value?.comparedLocation?.legend?.map((item) => (
                      <div key={uuidv4()}>
                        <LegendColor color={item?.color} />
                        <p>{item?.label}</p>
                      </div>
                    ))}
                  </LegendWrapper>
                </>
              )}
                  <MapTile
                    dragging
                    height='100%'
                    layers={getLayers(true)}
                    location={comparedLocation?.location}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={comparedLocation?.location?.address}
                    setMapLocation={setComparedMapLocation}
                    showScaleControl
                    styleLayer={{
                      id: data?.value?.comparedLocation?.buildingsMap?.id,
                      styleId: data?.value?.comparedLocation?.buildingsMap?.styleId,
                      user: data?.value?.comparedLocation?.buildingsMap?.user,
                    }}
                    width='100%'
                    zoom={16}
                    zoomControl
                  />
                </MapWrapper>
              </>
            ) }
            {isComparedMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isComparedMapDisplayed}
                layers={getLayers(true)}
                legend={data?.value?.comparedLocation?.legend?.length
                  ? (
                    <LegendWrapper>
                      {data?.value?.comparedLocation?.legend?.map((item) => (
                        <div key={uuidv4()}>
                          <LegendColor color={item?.color} />
                          <p>{item?.label}</p>
                        </div>
                      ))}
                    </LegendWrapper>
                  )
                  : undefined}
                location={comparedLocation?.location}
                mapId={`buildings-tile-${values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === 'buildings')?.chosenRange?.catchmentId}_compared`}
                setIsDisplay={setIsComparedMapDisplayed}
                styleLayer={{
                  id: data?.value?.comparedLocation?.buildingsMap?.id,
                  styleId: data?.value?.comparedLocation?.buildingsMap?.styleId,
                  user: data?.value?.comparedLocation?.buildingsMap?.user,
                }}
                zoom={17}
              />
            )}
          </>
        )}
      </Wrapper>
    )
}
