import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Trigger = styled.div`
  margin-top: 0.5rem;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`

interface IBigBenRangeChartModalTrigger {
  onClick?(): unknown
}

export const BigBenRangeChartModalTrigger: React.FC<IBigBenRangeChartModalTrigger> = ({ onClick }): JSX.Element => {
  const { t } = useTranslation()
  return <Trigger onClick={onClick && onClick}>{t('placeme.big_ben.range_chart_modal.show_chain_ranking')}</Trigger>
}

BigBenRangeChartModalTrigger.defaultProps = {
  onClick: undefined,
}
