/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import styled, { css } from 'styled-components'
import { formatNumber } from '@dataplace.ai/functions/utils'

const Wrapper = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    > span:nth-child(1) {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
      margin-right: 0.5rem;
    }

    > span:nth-child(2) {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }
  `
})

export interface IPriceProps {
  value: string | number
  currency: string
  digits?: number,
}

export const Price = ({
  value, currency, digits = 2,
}: IPriceProps): JSX.Element => (
  <Wrapper>
    <span>{formatNumber(value, digits)}</span>
    <span>{currency}</span>
  </Wrapper>
)
