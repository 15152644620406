import React from 'react'
import styled from 'styled-components'
import Chart from 'react-chartjs-2'
import { Context } from 'chartjs-plugin-datalabels'
import { useTranslation } from 'react-i18next'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { IMixedChartProps } from './@types/IMixedChart.props'

const ChartWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #EDF0F5;
`

export const MixedChart = ({
  label, dataset,
}: IMixedChartProps): JSX.Element => {
  const { t } = useTranslation()

  const labels = [t(''), t(`${label || ''}`), t('')]

  const datasetKeyProvider = () => btoa(Math.random().toString()).substring(0, 12)

  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        borderColor: '#362FB7',
        borderWidth: 1,
        borderDash: [15, 15],
        fill: false,
        data: [dataset?.topLine?.value, dataset?.topLine?.value, dataset?.topLine?.value],
        pointBorderWidth: 0,
        pointRadius: 0,
        datalabels: {
          display: true,
          textAlign: 'end',
          anchor: 'start',
          align: '145',
          offset: '15',
          color: '#15181F',
          font: {
            size: `${useCheckForMobileDevice() ? '9' : '11'}`,
            weight: '400',
          },
          formatter(value: number, context: Context) {
            if (context.dataIndex === context.dataset.data.length - 1) {
              return [`${value} ${dataset?.topLine?.label1}`, dataset?.topLine?.label2]
            }
            return ''
          },
        },
      },
      {
        type: 'line' as const,
        borderColor: '#362FB7',
        borderWidth: 1,
        borderDash: [15, 15],
        fill: false,
        data: [dataset?.bottomLine?.value, dataset?.bottomLine?.value, dataset?.bottomLine?.value],
        pointBorderWidth: 0,
        pointRadius: 0,
        datalabels: {
          display: true,
          textAlign: 'end',
          anchor: 'start',
          align: '-145',
          offset: '15',
          color: '#15181F',
          font: {
            size: `${useCheckForMobileDevice() ? '9' : '11'}`,
            weight: '400',
          },
          formatter(value: number, context: Context) {
            if (context.dataIndex === context.dataset.data.length - 1) {
              return [`${value} ${dataset?.bottomLine?.label1}`, dataset?.bottomLine?.label2]
            }
            return ''
          },
        },
      },
      {
        type: 'bar' as const,
        backgroundColor: 'rgba(126, 122, 210, 0.8)',
        data: [0, dataset?.bar?.value, 0],
        borderRadius: 6,
        datalabels: {
          display: true,
          textAlign: 'center',
          color: 'white',
          font: {
            size: `${useCheckForMobileDevice() ? '18' : '26'}`,
            weight: '600',
          },
          formatter(value: number) {
            return value === 0 ? '' : [`${value} `, 'PLN']
          },
        },
      },
    ],
  }

  return (
    <ChartWrapper>
      <Chart
        data={data}
        datasetKeyProvider={datasetKeyProvider}
        options={{
          animation: {
            duration: 0,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                barPercentage: 0.75,
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  fontStyle: 'bold',
                },
                offset: false,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                },
                offset: true,
              },
            ],
          },
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        }}
        type='bar'
      />
    </ChartWrapper>
  )
}
