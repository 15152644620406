import React, { useContext, useEffect, useState } from 'react'

import { IChildrenProps } from '@dataplace.ai/types'
import {
  getFirstWorkspaceId,
  getValidWorkspace,
} from '@dataplace.ai/functions/utils/workspaceManageFuncs'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { saveTilesFromRemoteConfigAction, fetchTilesFromRemoteConfigAction } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { createFlashMessage, getWorkspaceIdFromLocalStorage, localStorageWorkspaceId, navbarNotificationsEventName, refreshWorkspaceId, setCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { AuthContext } from './AuthContext'

export const AppWrapper = ({ children }: IChildrenProps): JSX.Element => {
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')
  const [tempCurrentWorkspaceId, setTempCurrentWorkspaceId] = useState('')
  const [readyToLoadChildren, setReadyToLoadChildren] = useState(false)
  const dispatch = useAppDispatch()
  const [readyToLoadCookie, setReadyToLoadCookie] = useState(false)

  // get.length > 1 user token
  useEffect(() => {
    if (authContext.userData.userLoaded && !!authContext.userData.user) {
      authContext.userData?.user?.getIdToken()?.then(response => {
        setToken(response)
      })
    } else if (authContext.userData.userLoaded && !authContext.userData.user) {
      setToken('0') // for ensuring that token is loaded but undefined
    }
  }, [authContext.userData])

  useEffect(() => {
    sessionStorage.flashMessages = JSON.stringify([])
    const flashMessageEvent = new CustomEvent('flashMessagesUpdate')
    window.dispatchEvent(flashMessageEvent)
    const cookieValue = () => {
      let workspaceIdCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('workspaceId='))

      // eslint-disable-next-line prefer-destructuring
      if (workspaceIdCookie) workspaceIdCookie = workspaceIdCookie.split('=')[1]
      else workspaceIdCookie = ''
      return workspaceIdCookie === 'null' ? '' : workspaceIdCookie
    }
    window.localStorage.setItem(localStorageWorkspaceId, cookieValue())
    window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
    setReadyToLoadCookie(true)
  }, [])

  useEffect(() => {
    if (readyToLoadCookie) { setTempCurrentWorkspaceId(window?.localStorage?.workspaceId)
      window.localStorage.setItem(localStorageWorkspaceId, '')
      window.dispatchEvent(new CustomEvent(refreshWorkspaceId)) }
  }, [readyToLoadCookie])

  useEffect(() => {
    if (token.length > 1 && authContext.userData?.user?.emailVerified) {
      if (!tempCurrentWorkspaceId) {
        getFirstWorkspaceId(token)
          .then(_ => setTempCurrentWorkspaceId(''))
          .then(_ => setReadyToLoadChildren(true))
      } else {
        // set default workspace if doesn't exist in local storage or current one is invalid
        getValidWorkspace(token, tempCurrentWorkspaceId)
          .then(_ => setTempCurrentWorkspaceId(''))
          .then(_ => setReadyToLoadChildren(true))
      }
    } else if (
      (token.length > 1 && !authContext.userData?.user?.emailVerified)
      || (token === '0' && authContext.userData.userLoaded)) {
      window.localStorage.setItem(localStorageWorkspaceId, tempCurrentWorkspaceId)
      window.dispatchEvent(new CustomEvent(refreshWorkspaceId))
      setTempCurrentWorkspaceId('')
      setReadyToLoadChildren(true)
    }
  }, [token, authContext.userData])

  const workspaceId = getWorkspaceIdFromLocalStorage()

  useEffect(() => {
    if (token) {
      const placemeTiles = window?.localStorage?.getItem('placemeTiles')
      window?.localStorage?.removeItem('transportTypeError')
      window?.dispatchEvent(new CustomEvent('transportTypeError'))
      window?.localStorage?.removeItem('timeoutModal')
      window?.dispatchEvent(new CustomEvent('timeoutModal'))
      window?.localStorage?.removeItem('serverErrorModal')
      window?.dispatchEvent(new CustomEvent('serverErrorModal'))
      if (placemeTiles) {
        try {
          const placemeTilesJSON = JSON.parse(placemeTiles)
          dispatch(saveTilesFromRemoteConfigAction(placemeTilesJSON))
        } catch (e) {
          createFlashMessage({
            message: 'status.error.remoteConfig.tiles.parsing_failed',
          })
        }
      }
      if (!window?.location?.href?.includes('auth') && workspaceId) {
        sessionStorage.navbarNotifications = JSON.stringify([])
        window.dispatchEvent(new CustomEvent(navbarNotificationsEventName))
        dispatch(fetchTilesFromRemoteConfigAction(token))
      }
    }
  }, [token, workspaceId])

  useEffect(() => {
    const { language } = window.navigator
    if (language && !language.includes('pl')) {
      setCookieWithoutJSON('language', 'en')
    } else {
      setCookieWithoutJSON('language', 'pl')
    }
  }, [])

  return (
    <>
      {readyToLoadChildren && children}
    </>
  )
}

export default AppWrapper
