import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { IRangeChartProps } from '@dataplace.ai/ui-components/atoms/Charts/RangeChart/@types/IRangeChart.props'
import { ReactComponent as RangeChartImage } from 'libs/shared/assets/src/lib/icons/range.svg'
import { ReactComponent as Tick } from 'libs/shared/assets/src/lib/icons/indicator.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HelpCircle } from 'libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { Result, Results, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { BigBenRangeChartModalTrigger } from 'apps/placeme/src/features/Analyse/components/atoms/BigBen'
import { BigBenRangeChartModal } from 'apps/placeme/src/features/Analyse/components/molecules/BigBen'
import NumberFormat from 'react-number-format'

const RangeChartWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    > span {
      display: flex;
      align-items: flex-end;
      margin: 0 0.5rem;
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      white-space: nowrap;
    }

` })

const Wrapper = styled.div(() => css`
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80px;

  > svg {
    width: 100%;
  }
`)

const IndicatorWrapper = styled.div<{ value: number }>(({ value }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    left: calc(${value}% - 25px);
`)

const Value = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    > span {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      position: absolute;
      top: -30px;
      left: -25px;
      width: max-content;
      display: flex;
      align-items: center;

      > span {
        transform: scale(0.8);
      }
    }
  `
})

const Indicator = styled(Tick)<{ color: string }>(({ color }) => css`
    display: flex;
    width: 40px;

    path, rect {
      fill: ${color};
    }
  `)

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
    margin-bottom: -0.2rem;

    > path {
      fill: ${palette.blue};
    }`
})

export function RangeChart({
  max,
  value,
  label,
  modal,
  tooltip1,
  tooltip2,
  tooltip1Value,
  tooltip2Value,
}: IRangeChartProps): JSX.Element {
  const { t } = useTranslation()
  const [modalOpened, setModalOpened] = useState(false)

  const resolveColorAndTag = (value: number, max: number): {color: string, label: Result, value: string} => {
    if ((value / max) * 100 < 30) { return {
      color: '#70C43B',
      value: t('generic.high'),
      label: 'positive',
    } }
    if ((value / max) * 100 > 70) { return {
      color: '#D4450C',
      value: t('generic.low'),
      label: 'negative',
    } }
    return {
      color: '#456DCE',
      value: t('generic.average'),
      label: 'average',
    }
  }

  const resolveValueAndLabel = (max: number, value: number): {value: number, label: string} => {
    if (value > max) { return {
      value: 100,
      label: max?.toFixed(),
    } }
    if (value < 1) { return {
      value: 0,
      label: '1',
    } }
    return {
      value: (value / max) * 100,
      label: value?.toFixed(),
    }
  }

  return (
    <RangeChartWrapper>
      <span
        style={{
          marginRight: '1.5rem',
        }}
      >
        {`1 ${t(label || '')}`}
        <Tooltip
          content={(
            <>
              {t(tooltip1 ?? '')}
              {' '}
              <strong>
                <NumberFormat
                  displayType='text'
                  thousandSeparator=' '
                  value={tooltip1Value ?? ''}
                />
              </strong>
              {modal && <BigBenRangeChartModalTrigger onClick={() => setModalOpened(true)} />}
            </>
          )}
          display={modalOpened ? 'none' : 'inline-block'}
          maxWidth='200px'
          position='right center'
        >
          <StyledHelpCircle />
        </Tooltip>
      </span>
      <Wrapper>
        <RangeChartImage />
        <IndicatorWrapper value={resolveValueAndLabel(max, value)?.value}>
          <Value>
            <span>
              {resolveColorAndTag(value, max)?.label === 'negative' && (
                <Results
                  state={resolveColorAndTag(value, max)?.label}
                  value={resolveColorAndTag(value, max)?.value}
                />
              )}
              {`${resolveValueAndLabel(max, value)?.label} ${t(label || '')}`}
              {resolveColorAndTag(value, max)?.label === 'positive' && (
                <Results
                  state={resolveColorAndTag(value, max)?.label}
                  value={resolveColorAndTag(value, max)?.value}
                />
              )}
            </span>
          </Value>
          <Indicator
            color={resolveColorAndTag(value, max)?.color}
          />
        </IndicatorWrapper>
      </Wrapper>
      <span
        style={{
          marginLeft: '1.5rem',
        }}
      >
        {max?.toFixed()}
        {' '}
        {t(label || '')}
        <Tooltip
          content={(
            <>
              {t(tooltip2 ?? '')}
              {' '}
              <strong>
                <NumberFormat
                  displayType='text'
                  thousandSeparator=' '
                  value={tooltip2Value ?? ''}
                />
              </strong>
              {modal && <BigBenRangeChartModalTrigger onClick={() => setModalOpened(true)} />}
            </>
          )}
          display={modalOpened ? 'none' : 'inline-block'}
          maxWidth='200px'
          position='left center'
        >
          <StyledHelpCircle />
        </Tooltip>
      </span>
      {modal && (
        <BigBenRangeChartModal
          {...modal}
          opened={modalOpened}
          setOpened={setModalOpened}
        />
      )}
    </RangeChartWrapper>
  )
}
