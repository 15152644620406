
import { combineReducers } from 'redux'
import dashboardTargetSlice from '../features/Dashboard/slice/dashboardTargetSlice'
import analysisTargetSlice from '../features/Analysis/slice/analysisTargetSlice'

const appReducer = combineReducers({
  dashboardTarget: dashboardTargetSlice,
  analysisTarget: analysisTargetSlice,
})

export default appReducer
