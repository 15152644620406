import React, { useRef, useState, useEffect } from 'react'
import { DateRangePicker } from 'react-date-range'
import { Input } from '@dataplace.ai/ui-components/atoms/Inputs'
import { theme } from '@dataplace.ai/ui-components/themes'
import styled, { css } from 'styled-components'
import { IRangeFilter, validateDateField, formatISOdate, getDayjs } from '@dataplace.ai/functions/utils/filtering'
import { useTranslation } from 'react-i18next'
import { addDays, subDays } from 'date-fns'
import { useSpring, animated } from 'react-spring'
import * as locale from 'date-fns/locale'
import { FilterSection } from '../FilterSection'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'

const Wrapper = styled.div(() => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`)

const InputSeparator = styled.span(() => css`
  padding: 0 5px;
`)

const PickerWrapper = styled.div`
  > div > div:first-of-type {
    display: none;
  }
`

export const emptyRangeFilter: IRangeFilter = {
  from: '',
  to: '',
}

export interface IDateFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: IRangeFilter
  }
}

interface ISelection {
  startDate: Date | undefined,
  endDate: Date | undefined,
  key: string,
}

interface IDateFilterProps {
  filter: IDateFilter
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
  minDate: null | Date
  maxDate: null | Date
}

export const DateFilter = ({
  setFilterValue, header, filter, clearable, minDate, maxDate,
}: IDateFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const dateFromRef = useRef<HTMLInputElement>(null)
  const dateToRef = useRef<HTMLInputElement>(null)
  const { palette } = theme()
  const filterRange = filter[header.name]?.rule
  const [dateFilter, setDateFilter] = useState(filterRange || emptyRangeFilter)
  const [focused, setFocused] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [minRange, _setMinRange] = useState<Date>(minDate || subDays(new Date(), 7))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [maxRange, _setMaxRange] = useState<Date>(maxDate || new Date())

  const styles = useSpring({
    opacity: focused ? 1 : 0,
    display: focused ? 'block' : 'none',
  })

  const [ranges, setRanges] = useState<ISelection[]>([
    {
      startDate: minRange,
      endDate: maxRange,
      key: 'selection',
    },
  ])

  const setTableFilterValue = (value: IRangeFilter) => {
    if (!value.from && !value.to) {
      clearFields()
    } else {
      const newValue = {
        ...filter,
        [header.name]: {
          type: 'date',
          rule: value,
        },
      }
      setFilterValue(newValue)
      setDateFilter(value)
    }
  }

  const handleInputChange = (name: string, value: string) => {
    setTableFilterValue({
      ...dateFilter,
      [name]: value,
    })
  }

  const clearFields = () => {
    const clearedFilter = {
      ...filter,
    }
    delete clearedFilter[header.name]
    setFilterValue(clearedFilter)
    setDateFilter(emptyRangeFilter)
    setRanges([{
      startDate: minRange,
      endDate: maxRange,
      key: 'selection',
    }])
  }

  useEffect(() => {
    const filterRange = filter[header.name]?.rule
    setDateFilter(filterRange || emptyRangeFilter)
    if (filterRange) {
      const dayjsFrom = getDayjs(filterRange?.from)
      const dayjsTo = getDayjs(filterRange?.to)
      setRanges([
        {
          startDate: dayjsFrom.isValid() ? dayjsFrom.toDate() : undefined,
          endDate: dayjsTo.isValid() ? dayjsTo.toDate() : undefined,
          key: 'selection',
        },
      ])
    } else {
      setRanges([
        {
          startDate: minRange,
          endDate: maxRange,
          key: 'selection',
        },
      ])
    }
  }, [filter])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRangeSelect = ({ selection }: any) => {
    setRanges([selection])
    const fromISO = addDays(selection.startDate, 1).toISOString()
    const toISO = addDays(selection.endDate, 1).toISOString()
    const from = formatISOdate(fromISO)
    const to = formatISOdate(toISO)
    const newFilter = {
      from,
      to,
    }
    setTableFilterValue(newFilter)
  }

  return (
    <FilterSection
      clear={(clearFields)}
      clearable={clearable}
      header={`${t('account.table.filter.choose_period')}: ${t(header.label)}`}
    >
      <Wrapper>
        <Input
          ref={dateFromRef}
          error={validateDateField(dateFilter.from, minRange, maxRange) ? '' : 'error'}
          onChange={e => handleInputChange('from', e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder={minRange?.toLocaleDateString()}
          value={dateFilter.from}
        />
        <InputSeparator>-</InputSeparator>
        <Input
          ref={dateToRef}
          error={validateDateField(dateFilter.to, minRange, maxRange) ? '' : 'error'}
          onChange={e => handleInputChange('to', e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder={maxRange?.toLocaleDateString()}
          value={dateFilter.to}
        />
      </Wrapper>
      <animated.div style={styles}>
        <PickerWrapper>
          <DateRangePicker
            inputRanges={[]}
            locale={locale[navigator.language as keyof typeof locale]}
            maxDate={maxRange}
            minDate={minRange}
            months={1}
            onChange={onRangeSelect}
            rangeColors={[palette.blue]}
            ranges={ranges}
            showDateDisplay={false}
            staticRanges={[]}
          />
        </PickerWrapper>
      </animated.div>
    </FilterSection>
  )
}

DateFilter.defaultProps = {
  clearable: false,
}
