import { IPois } from './@types/IPoisTileData'

const categoriesOrder = [
  {
    id: 'grocery store',
    order: 1,
  },
  {
    id: 'butcher',
    order: 2,
  },
  {
    id: 'bakery',
    order: 3,
  },
  {
    id: 'restaurant',
    order: 4,
  },
  {
    id: 'fast food',
    order: 5,
  },
  {
    id: 'cafe',
    order: 6,
  },
  {
    id: 'pub',
    order: 7,
  },
  {
    id: 'drugstore',
    order: 8,
  },
  {
    id: 'pharmacy',
    order: 9,
  },
  {
    id: 'bank',
    order: 10,
  },
  {
    id: 'atm',
    order: 11,
  },
  {
    id: 'construction store',
    order: 12,
  },
  {
    id: 'jewellery store',
    order: 13,
  },
  {
    id: 'gym',
    order: 14,
  },
  {
    id: 'shopping center',
    order: 15,
  },
  {
    id: 'non food discount',
    order: 16,
  },
  {
    id: 'petrol station',
    order: 17,
  },
  {
    id: 'pudo',
    order: 18,
  },
  {
    id: 'book store',
    order: 19,
  },
  {
    id: 'furniture store',
    order: 20,
  },
  {
    id: 'nursery',
    order: 21,
  },
  {
    id: 'school',
    order: 22,
  },
  {
    id: 'kindergarten',
    order: 23,
  },
  {
    id: 'cultural place',
    order: 24,
  },
]

export const sortCategories = (rows: {[key: string]: IPois[] }) : {[key: string]: IPois[] } =>
  Object.fromEntries(Object.entries(rows).sort((a, b) => {
    const itemA = categoriesOrder?.find(item => item?.id === a[0]) || {
      id: a[0],
      order: 100,
    }
    const itemB = categoriesOrder?.find(item => item?.id === b[0]) || {
      id: b[0],
      order: 100,
    }
    return itemA?.order - itemB?.order
  }))

export const sortCategoriesNestedSelectList = (list: {
  id: string;
  content: string;
}[]): {
  id: string;
  content: string;
}[] => list.sort((a, b) => {
  const itemA = categoriesOrder?.find(item => item?.id === a.id) || {
    id: a.id,
    order: 100,
  }
  const itemB = categoriesOrder?.find(item => item?.id === b.id) || {
    id: b.id,
    order: 100,
  }
  return itemA?.order - itemB?.order
})
