import styled, { css } from 'styled-components'

export const Link = styled.a(({ theme }) => {
  const { palette } = theme
  return css`
    color: ${palette.blue};
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      font-weight: 800;
    }

    &:visited {
      color: ${palette.blue};
    }
  `
})
