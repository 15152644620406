import React from 'react'
import styled from 'styled-components'
import { Doughnut } from 'react-chartjs-2'
import { IDoughnutChartProps } from '@dataplace.ai/ui-components/atoms/Charts/DoughnutChart/@types/IDoughnutChart.props'

type DoughnutWrapperProps = {
  width?: number
}
const DoughnutWrapper = styled.div<DoughnutWrapperProps>`
  width: ${({ width }) => `${width}px`};
`

const backgroundColors = [
  '#362FB7',
  '#7E7AD2',
  '#ADAAE3',
  '#9D0584',
  '#C759B5',
  '#E1A8D8',
]

export function DoughnutChart({
  data,
  labels,
  width,
  radiansNumber,
  innerLabels,
  valueType,
}: IDoughnutChartProps): JSX.Element {
  return (
    <DoughnutWrapper width={width}>
      <Doughnut
        data={{
          labels,
          datasets: [
            {
              label: '',
              data,
              backgroundColor: backgroundColors,
              borderWidth: 0,
            },
          ],
        }}
        options={{
          cutoutPercentage: 50,
          plugins: {
            datalabels: {
              display: innerLabels || false,
              color: 'white',
              font: {
                size: '14',
                weight: 'bold',
              },
              formatter(value: number) {
                switch (valueType) {
                  case 'percentages':
                    return `${value}%`
                  default:
                    return value
                }
              },
            },
          },
          rotation: radiansNumber ? radiansNumber * Math.PI : 2 * Math.PI,
          circumference: radiansNumber ? radiansNumber * Math.PI : 2 * Math.PI,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        }}
      />
    </DoughnutWrapper>
  )
}

export default DoughnutChart
