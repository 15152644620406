import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { WarningModalProps } from './@types/WarningModalProps.props'

const Modal = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 10px;
    border-radius: 6px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: ${palette.black};

    h3 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 5px;
    }

    p {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
      margin-bottom: 5px;
      :first-of-type {
        color: ${palette.results.red.dark};
      }
    }
  `
})

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 23px;
`

export const WarningModal = ({
  handleCancel,
  handleAgreement,
  heading,
  redWarning,
  description,
  agreementButtonText,
  cancelButtonText,
}: WarningModalProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Modal>
      <h3>{heading}</h3>
      <p>{redWarning}</p>
      <p>{description}</p>
      <ModalButtonsWrapper>
        <ButtonProduct
          deleteButton
          margin='0 5px 0 0'
          onClick={handleAgreement}
          small
        >
          {agreementButtonText || t('generic.delete')}
        </ButtonProduct>
        <ButtonProduct
          onClick={handleCancel}
          small
        >
          {cancelButtonText || t('generic.cancel')}
        </ButtonProduct>
      </ModalButtonsWrapper>
    </Modal>
  )
}
