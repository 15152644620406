import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ResourcesSelector } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { IMainLayersBar } from './@types/IMainLayersBar'
import { layersContent } from './@constants/layersContent'

const Wrapper = styled.div(({ theme }) => {
  const {
    shadows, typography, palette,
  } = theme
  return css`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 100px;
  background-color: white;
  box-shadow: ${shadows.tiny.boxShadow};

  .label{ 
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    margin-right: 8px;
  }
`
})

export const MainLayersBar = ({
  style, handleSwitch,
}: IMainLayersBar): JSX.Element => {
  const { t } = useTranslation()
  const [layers, setLayers] = useState([''])

  return (
    <Wrapper style={style}>
      <p className='label'>{t('generic.show_colon')}</p>
      <ResourcesSelector
        maxWidth='100%'
        multiSelect
        noSelect
        onChange={(e) => {
          const chosenLayers : string[] = []
          e.forEach(item => chosenLayers.push(item))
          setLayers(e)
          handleSwitch(chosenLayers)
        }}
        resources={layersContent.map((layer => ({
          id: layer.id,
          content: <p>{layer.label}</p>,
        })))}
        value={layers}
        wrapperType='bean'
      />
    </Wrapper>
  )
}
