import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { SelectCaret } from '@dataplace.ai/ui-components/atoms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { INestedSelectProps } from './@types/INestedSelect.props'
import { ReactComponent as ActiveCheck } from '../../../../assets/src/lib/icons/checkbox/activeCheck.svg'
import { ReactComponent as NoActiveCheck } from '../../../../assets/src/lib/icons/checkbox/noAcitveCheck.svg'
import { ReactComponent as Close } from '../../../../assets/src/lib/icons/close.svg'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div<{width?: string}>(({ width }) => css`
    width: ${width || '100%'};
    cursor: pointer;
  `)

const Selected = styled.p(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    margin-right: 0.25rem;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  
    :last-of-type > p {
      display: none;
    }
  `
})

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const StyledDropDown = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    position: relative;
    color: ${palette.blue};
    border: 1px solid ${palette.light.darkest};
    border-radius: 0.25rem;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 2rem;
    font-weight: 400;
    font-size: 1rem;
    background: #ffffff;
    display: flex;
    align-items: center;

    > :first-child {
      position: absolute;
      right: 0;
      margin: 0 0.75rem;
    }

    :hover {
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    }

    :focus {
      margin: 0;
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
      outline: none;
    }
  `
})

const DropDownListContainer = styled.ul(() => css`
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    overflow: hidden;
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    left: 0;
    top: 2.5rem;
    z-index: 401;
    margin-bottom: 0.5rem;
  `)

const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
    padding: 0.5rem;
    list-style: none;
    color: ${palette.black};
    border-radius: ${corners.default.borderRadius} ;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    
    svg {
      min-width: 26px;
      margin-right: 10px;
    }
    
    :first-child {
      margin-top: 0.5rem;
    }

    :last-child {
      margin-bottom: 0.5rem;
    }
    
    :hover {
      background-color: ${palette.results.green.opaque};
      color: ${palette.results.green.dark};
    }

  `
})

const InputWrapper = styled.div`
  width: calc(100% - 1.5rem);
  display: flex;
  align-items: center;

> :first-child {
  position: absolute;
  right: 0;
  margin: 0 0.75rem;
}
`

const Placeholder = styled.span(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    padding: 0.5rem;
    list-style: none;
    color: ${palette.dark.lightest};
    border-radius: ${corners.default.borderRadius} ;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  `
})

const SelectedWrapper = styled.div(() => css`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 12px - 1.5rem);
  `)

const BlueBean = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    padding: 3px 10px;
    background: ${palette.blue};
    border: 1px solid ${palette.blue};
    box-sizing: border-box;
    border-radius: 6px;
    color: white;

    svg{
      margin-left: 5px;
      path{
        stroke: white;
      }
    }
  `
})

const CaretWrapper = styled.div`
padding: 2px 10px;
`

export const NestedSelect: React.FC<INestedSelectProps> = ({
  options, selected, onChange, width, label, additionalInfo, name, placeholder, removableBlueBeans, deleteItemFromList,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [array, setArray] = useState<ResourceWithId[]>(selected as ResourceWithId[])
  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: ResourceWithId) => () => {
    const newArray = array.map(item => item.id).includes(value.id)
      ? array.filter(item => item.id !== value.id)
      : [...array, value]
    setArray(newArray)
    onChange([...newArray])
  }

  return (
    <Wrapper
      width={width}
    >
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <StyledDropDown
        onBlur={() => {
          setIsOpen(false)
        }}
        onClick={() => {
          if (!isOpen) {
            toggling()
          }
        }}
        tabIndex={0}
      >
        <InputWrapper
          onClick={toggling}
        >
          <CaretWrapper
            onClick={toggling}
          >
            <SelectCaret
              active={isOpen}
            />
          </CaretWrapper>
          {(!!placeholder && !selected?.length) && <Placeholder>{placeholder}</Placeholder>}
          <SelectedWrapper>
            {selected?.map(s => (
              <Selected key={s.id}>
                {removableBlueBeans
                  ? (
                    <BlueBean>
                      {s?.content}
                      <Close
                        height='16px'
                        onClick={(e) => { if (deleteItemFromList) {
                          e.stopPropagation()
                          deleteItemFromList(s.id)
                        } }}
                        width='16px'
                      />
                    </BlueBean>
                  )
                  : (
                    <>
                      {s?.content}
                      <p>{' ,'}</p>
                    </>
                  )}
              </Selected>
            ))}
          </SelectedWrapper>
        </InputWrapper>
        {isOpen && (
          <DropDownListContainer>
            {options?.map((option) => (
              <ListItem
                key={uuidv4()}
                onClick={onOptionClicked(option)}
              >
                {array.map(i => i.id).includes(option.id) ? <ActiveCheck /> : <NoActiveCheck />}
                {option?.content}
              </ListItem>
            ))}
          </DropDownListContainer>
        )}
      </StyledDropDown>
    </Wrapper>
  )
}
