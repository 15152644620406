import styled, { css } from 'styled-components'
import { ReactComponent as Add } from '../../../../../assets/src/lib/icons/dataplace/check.svg'

export const CheckMarkIcon = styled(Add)(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.main};
  `
})
