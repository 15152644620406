import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NumberInput } from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'

const SelectedSection = styled.div<{ background: 'light' | 'dark' }>(
  ({
    theme, background,
  }) => {
    const {
      palette, typography, corners,
    } = theme
    return css`
      display: flex;
      flex-direction: column;
      border: 1.5px dashed ${palette.light.darkest};
      border-radius: ${corners.default.borderRadius};
      padding: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      background-color: ${background === 'light'
    ? palette.light.white
    : palette.light.main};

      > p {
        color: ${palette.dark.normal};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
      }
    `
  },
)

const TypeRangeWrapper = styled.div(
  ({ theme }) => {
    const {
      palette, typography,
    } = theme
    return css`
    display: flex;
    flex-direction: column;

    :not(:first-of-type) {
      border-top: 1px solid #EDF0F5;
      margin-top: 1.5rem;
    }
    
    > p {
      padding: 1.25rem 0 0.5rem 0;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};

      > span {
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      }
    }

    > button {
      margin: 0.5rem 0 0 0;
      padding: 0.5rem 0;
      width: fit-content;
    }

    .slider {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 10px;
      width: 100%;
      height: 10px;
      background: ${palette.light.darkest};
      outline: none;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: ${palette.blue};
      cursor: pointer;
      border-radius: 10px;
    }

    .slider::-moz-range-thumb {
      background: ${palette.blue};
    }
` },
)

const InputWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.75rem;
` })

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 2rem;
`

export type ShopType = '0' | '1'

export interface ITypeRange {
  type?: ShopType;
  cost?: string | number;
  area?: string | number;
  areaCompared?: string | number;
  roundTheClock?: 1|0;
  hoursPerWeek?: number;
  hoursOnSaturday?: number;
  hoursOnSunday?: number;
  priceConvenienceRatio?: string;
  retail?: string;
  addresses?: string[],
  date?: {
    startDate: number,
    endDate: number
  },
}

interface ITypeRangeSelectorProps {
  description: string
  onChange: (value: ITypeRange) => void
  accepted: boolean
}

export const TypeRangeSelector = ({
  onChange,
  description,
  accepted,
}: ITypeRangeSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)

  const [cost, setCost] = useState<number>(100)
  const [costError, setCostError] = useState()
  const [area, setArea] = useState<number>(100)
  const [areaError, setAreaError] = useState()
  const [areaCompared] = useState<number>(100)
  // const [areaComparedError, setAreaComparedError] = useState()
  const [roundTheClock, setroundTheClock] = useState<number>(0)
  const [roundTheClockError, setroundTheClockError] = useState()
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(12)
  const [hoursPerWeekError, setHoursPerWeekError] = useState()
  const [hoursOnSaturday, setHoursOnSaturday] = useState<number>(0)
  const [hoursOnSaturdayError, setHoursOnSaturdayError] = useState()
  const [hoursOnSunday, setHoursOnSunday] = useState<number>(0)
  const [hoursOnSundayError, setHoursOnSundayError] = useState()

  useEffect(() => {
    if (roundTheClock === 0 || roundTheClock === 1) {
      onChange({
        cost,
        area,
        areaCompared,
        roundTheClock,
        hoursPerWeek,
        hoursOnSaturday,
        hoursOnSunday,
      })
    }
  }, [cost, area, areaCompared, roundTheClock, hoursPerWeek, hoursOnSaturday, hoursOnSunday])

  return (
    <SelectedSection background={accepted ? 'dark' : 'light'}>
      <p>{description}</p>
      <TypeRangeWrapper>
        <GridWrapper>
          <InputWrapper>
            <span>{t('placeme.type_range_selector.input_label.cost')}</span>
            <NumberInput
              error={costError}
              onChange={(e) => {
                setCostError(undefined)
                const cost = parseInt(e, 10)
                setCost(cost)
                if (cost === 0) { setCostError(t('placeme.potential.error.cost')) }
              }}
              step={1}
              unit='PLN'
              value={cost}
            />
          </InputWrapper>
          <InputWrapper>
            <span>
              {t('placeme.type_range_selector.input_label.area')}
              {comparedLocation?.location?.address && comparedLocation?.generatedFromNow ? ` (${value?.address})` : null}

            </span>
            <NumberInput
              error={areaError}
              onChange={(e) => {
                setAreaError(undefined)
                const area = parseInt(e, 10)
                setArea(area)
                if (area === 0) {
                  setAreaError(t('placeme.potential.error.area'))
                }
              }}
              step={1}
              unit='m2'
              value={area}
            />
          </InputWrapper>
          <InputWrapper>
            <span>{t('placeme.type_range_selector.input_label.round_the_clock')}</span>
            <NumberInput
              error={roundTheClockError}
              noArrows
              onChange={(e) => {
                setroundTheClockError(undefined)
                const roundTheClock = parseInt(e, 10)
                setroundTheClock(roundTheClock)
                if (roundTheClock !== 0 && roundTheClock !== 1) {
                  if (e.length) { setroundTheClockError(t('placeme.potential.error.round_the_clock')) }
                }
              }}
              unit=''
              value={roundTheClock}
            />
          </InputWrapper>
          <InputWrapper>
            <span>{t('placeme.type_range_selector.input_label.hours_per_week')}</span>
            <NumberInput
              error={hoursPerWeekError}
              noArrows
              onChange={(e) => {
                setHoursPerWeekError(undefined)
                setHoursPerWeek(parseInt(e, 10))
                if (parseInt(e, 10) > 24) {
                  setHoursPerWeekError(t('placeme.potential.error.hours_per_week'))
                }
              }}
              step={1}
              unit=''
              value={hoursPerWeek}
            />
          </InputWrapper>
          <InputWrapper>
            <span>{t('placeme.type_range_selector.input_label.hours_on_saturday')}</span>
            <NumberInput
              error={hoursOnSaturdayError}
              noArrows
              onChange={(e) => {
                setHoursOnSaturdayError(undefined)
                setHoursOnSaturday(parseInt(e, 10))
                if (parseInt(e, 10) > 24) {
                  setHoursOnSaturdayError(t('placeme.potential.error.hours_on_saturday'))
                }
              }}
              step={1}
              unit=''
              value={hoursOnSaturday}
            />
          </InputWrapper>
          <InputWrapper>
            <span>{t('placeme.type_range_selector.input_label.hours_on_sunday')}</span>
            <NumberInput
              error={hoursOnSundayError}
              noArrows
              onChange={(e) => {
                setHoursOnSundayError(undefined)
                setHoursOnSunday(parseInt(e, 10))
                if (parseInt(e, 10) > 24) {
                  setHoursOnSundayError(t('placeme.potential.error.hours_on_sunday'))
                }
              }}
              step={1}
              unit=''
              value={hoursOnSunday}
            />
          </InputWrapper>

          {/* {comparedLocation?.location?.address && comparedLocation?.generatedFromNow && (
            <InputWrapper>
              <span>
                <strong>
                  {t('placeme.type_range_selector.input_label.area_compared')}
                  {' '}
                  (
                  {comparedLocation?.location?.address}
                  )
                </strong>
              </span>
              <NumberInput
                error={areaComparedError}
                onChange={(e) => {
                  setAreaComparedError(undefined)
                  const areaCompared = parseInt(e, 10)
                  setAreaCompared(areaCompared)
                  if (areaCompared === 0) {
                    setAreaComparedError(t('placeme.potential.error.area'))
                  }
                }}
                step={1}
                unit='m2'
                value={areaCompared}
              />
            </InputWrapper>
          )} */}
        </GridWrapper>
      </TypeRangeWrapper>
    </SelectedSection>
  )
}

