import React, { MutableRefObject, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { CloseIcon, Input, SearchIcon } from '@dataplace.ai/ui-components/atoms'
import { ITextInputProps } from '@dataplace.ai/ui-components/molecules/TextInput/@types/ITextInput.props'

const Wrapper = styled.div<Partial<ITextInputProps>>(({
  width, margin,
}) => css`
    margin: ${margin || '0.5rem 0'};
    width: ${width || '100%'};
  `)

const InputWrapper = styled.div<Partial<ITextInputProps>>(({
  reset, search,
}) => css`
    position: relative;
    width: 100%;

    > svg {
      display: none;
      ${search && css`
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        margin: 0.5rem;
      `}
    }

    ${reset && css`
      > input {
        padding-right: 2.375rem;
      }
    `}

    :focus-within > svg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      margin: 0.5rem;
    }

  `)

const ErrorSpan = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.results.red.dark};
  `
})

type IconProps = {
  onClick?: () => void
}

const Icon = styled.img<IconProps>`
  width: 1.5rem;
  position: absolute;
  right: 10px;
  top: 20%;
  cursor: pointer;
`

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
      margin-right: 1rem;
      white-space: nowrap;
    }

    > span {
      text-align: right;
    }
  `
})
const InputWithIconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg{
    margin-right: 10px;
  }

`
export const TextInput: React.FC<ITextInputProps> = ({
  name,
  reset,
  onBlur,
  onChange,
  value,
  error,
  placeholder,
  additionalInfo,
  label,
  width,
  margin,
  type,
  preview,
  search,
  icon,
  togglePasswordVisibility,
  disabled,
}) => {
  const input = useRef() as MutableRefObject<HTMLInputElement>

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(name, event.target.value)
    }
  }

  const handleReset = () => {
    if (onChange) {
      onChange(name, '')
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event)
  }

  useEffect(() => {
    input.current.blur()
  }, [input])

  return (
    <Wrapper
      margin={margin}
      width={width}
    >
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <InputWithIconWrapper>
        {icon}
        <InputWrapper
          reset={reset}
          search={search}
        >
          <Input
            ref={input}
            disabled={disabled}
            error={error}
            id={name}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            value={value}
          />
          {search && (
            <SearchIcon
              className='search'
              tabIndex={0}
            />
          )}
          {reset && (
            <CloseIcon
              name={name}
              onClick={handleReset}
              tabIndex={0}
            />
          )}
          {preview && (
            <Icon
              alt='preview'
              onClick={togglePasswordVisibility}
              src={type === 'password' ? 'assets/icons/viewPasswordEye.svg' : 'assets/icons/hidePasswordEye.svg'}
            />
          )}
        </InputWrapper>
      </InputWithIconWrapper>
      {error && (
        <ErrorSpan>
          {error}
        </ErrorSpan>
      )}
    </Wrapper>
  )
}
