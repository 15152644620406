import React from 'react'
import styled, { css } from 'styled-components'
import { RangeType } from '@dataplace.ai/types'
import { handleRangeIcon, handleRangeUnit, handleRangeValue } from '../../organisms/RangeTile'

const Wrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    svg {
      margin-right: 5px;
      width: 13px;
      height: 13px;
    }
  `
})

export type RangeContainerProps = {
  value?: number
  type?: RangeType
}

export const RangeContainer: React.FC<RangeContainerProps> = ({
  type, value,
}) => (
  <Wrapper>
    {handleRangeIcon(type)}
    <p>
      {handleRangeValue(type, value)}
      {handleRangeUnit(type, value)}
    </p>
  </Wrapper>
)
