import React, { Suspense, useContext, useEffect, useState } from 'react'
import {
  BrowserRouter,
  Route,
  useParams,
  Routes,
  Navigate,
} from 'react-router-dom'

import { LoadingPage, Page404 } from '@dataplace.ai/ui-components/pages'
import { RouteChildType, RoutePropsType, RouteType } from '@dataplace.ai/types'
import { Link } from '@dataplace.ai/ui-components/atoms'
import { redirectToSignOut } from '@dataplace.ai/functions/utils'
import { AuthContext } from './AuthContext'
import { FlashMessagesProvider } from './FlashMessages'

const renderRoutes = (routes: RouteType[]) => routes.map(({
  component: Component,
  guard,
  children,
  rootPath,
  id,
  redirect,
}) => {
  const ParentGuard = guard || React.Fragment

  const params = useParams()

  const parentRoute = redirect || Component
    ? (
      <Route
        key={`route-${id}`}
        element={(
          <ParentGuard>
            {Component && <Component {...params} />}
            {redirect && <Navigate to={redirect} />}
          </ParentGuard>
        )}
        path={rootPath}
      />
    )
    : null

  const childrenRoutes = children
    ? children.map((element: RouteChildType) => {
      const Guard = element.guard || ParentGuard || React.Fragment

      return (
        <Route
          key={`route-${id}-${element.name}`}
          element={(
            <Guard>
              {element.component && <element.component {...params} />}
              {element.redirect && <Navigate to={element.redirect} />}
              <FlashMessagesProvider />
            </Guard>
          )}
          path={`/${(rootPath + element.childPath).replace(/^\/+/, '')}`}
        />
      )
    })
    : []

  return [parentRoute, ...childrenRoutes]
})

export const CustomRoutes: React.FC<RoutePropsType> = (props): JSX.Element => {
  const {
    routes, product, children,
  } = props
  const authContext = useContext(AuthContext)
  const [loaderTimeout, setLoaderTimeout] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setLoaderTimeout(false), 5000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (authContext.userData.userLoaded && loaderTimeout) {
      const timer = setTimeout(() => setLoaderTimeout(false), 400)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [authContext.userData.userLoaded])

  return (
    <>
      {loaderTimeout && <LoadingPage product={product} />}
      <BrowserRouter>
        {children}
        <Suspense fallback={<LoadingPage product={product} />}>
          <Routes>
            {renderRoutes(routes)}

            <Route
              element={<Page404 />}
              path='*'
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {process.env.NX_ENV !== 'PROD' && (
        <AuthContext.Consumer>
          {(contextValues) => (
            <div
              style={{
                position: 'fixed',
                right: '30px',
                bottom: '100px',
                backgroundColor: 'rgba(255,239,196)',
                opacity: 0.55,
                padding: '10px',
                zIndex: 99999,
              }}
            >
              <span>
                {'logged as: '}
                {contextValues.userData?.user?.email}
              </span>
              <br />
              <span>
                {'is email verified: '}
                {contextValues.userData?.user?.emailVerified ? 'YES' : 'NO'}
              </span>
              <br />
              <span>
                {'is user data loaded: '}
                {contextValues.userData.userLoaded ? 'YES' : 'NO'}
              </span>
              <br />
              {contextValues?.userData?.user?.email
            && (
              <strong>
                <Link
                  onClick={() => {
                    redirectToSignOut()
                  }}
                >
                  Sign out
                </Link>
              </strong>
            )}
            </div>
          )}
        </AuthContext.Consumer>
      )}
    </>
  )
}
