export const defaultTheme = {
  name: 'default',
  palette:{
    // current colors
    blue: '#234596',
    black: '#15181F',
    grey: '#DDDDDD',
    gold: {
      normal: '#D8A800',
    },
    disabled: '#F6F6F6',
    dark: {
      main: '#001D62',
      normal: '#484E5E',
      lightest: '#95A3CC',
      opaque: 'rgba(0, 29, 98, 0.1)',
    },
    light: {
      main: '#FAFBFC',
      darker: '#EDF0F5',
      darkest: '#E1E2E3',
      lighter: '#FAFBFC',
      white: '#FFFFFF',
    },
    product: {
      location: {
        main: '#362FB7',
        light: '#ADAAE3',
        medium: '#7E7AD2',
      },
      marketing: {
        main: '#9D0584',
        light: '#E1A8D8',
        medium: '#C759B5',
      },
      navigation: {
        main: '#CC3C00',
        light: '#F1C9B8',
        medium: '#EDA282',
      },
      target: {
        light: '#FFE2B8',
      },
    },
    credits: '#C6EAE1',
    extra:{
      yellow: '#FED77C',
      orange: '#FB833C',
    },
    prices:{
      green: '#287662',
      violet: '#454276',
      gold: '#665A31',
    },
    pricesBackground: {
      green: '#7AC9B6',
      violet: '#ADAAE3',
      gold: '#E1B519',
    },
    results: {
      green: {
        light: '#70C43B',
        dark: '#348700',
        opaque: 'rgba(112, 196, 59, 0.2)',
      },
      red: {
        light: '#F08F7F',
        dark: '#D4450C',
        darkest: '#cb1111',
        opaque: 'rgba(240, 143, 127, 0.2)',
      },
      yellow: {
        dark: '#F79E1B',
        light:'rgb(254, 215, 124)',
        opaque: 'rgba(254, 215, 124, 0.2)',
      },
    },
    // old colors - should finally be removed
    // ###############
    main:{
      light: ' #FBF7F4',
    },
    gray:{
      dark: '#4B5E8E',
      lighter: '#B0B6C8',
      light: '#EFEAEB',
    },
    // ###############
  },
  typography: {
    big: {
      pt_28_semibold: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '38px',
      },
      pt_24_semibold: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
      },
      pt_22_semibold: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '31px',
      },
      pt_18_semibold: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '27px',
      },
    },
    main: {
      pt_15_semibold_upper: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
      },
      pt_15_semibold: {
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '23px',
      },
      pt_15_medium: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
      },
      pt_15_medium_upper: {
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0.06em',
        textTransform: 'uppercase',
      },
      pt_15_regular: {
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '23px',
      },
    },
    small: {
      pt_14_semibold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
      pt_14_regular: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      pt_13_semibold: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '20px',
      },
      pt_13_medium: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '21px',
      },
      pt_13_regular: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
    tiny: {
      pt_12_semibold_upper: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
      },
      pt_12_semibold: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
      },
      pt_12_medium_upper: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
      },
      pt_12_medium: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '19px',
      },
      pt_12_regular: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '19px',
      },
      pt_11_medium: {
        fontSize: '11px',
        fontWeight: 500,
        lineHeight: '19px',
      },
      pt_11_regular: {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '19px',
      },
      pt_10_regular: {
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '17px',
      },
    },
    primary: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '16px',
      lineHeight: 1.5,
    },
  },
  shadows: {
    tiny: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.07)',
    },
    hover: {
      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.08), 0px 0px 24px rgba(95, 82, 73, 0.08)',
    },
  },
  corners: {
    default: {
      borderRadius: '6px',
    },
    rounded: {
      borderRadius: '63px',
    },
  },
  overlay: {
    background: 'rgba(0,0,0,0.5)',
    zIndex: 1000,
  },
  newFeatureModalOverlay: {
    background: 'rgba(0,0,0,0.1)',
    zIndex: 1000,
  },
  overrides: {},
}

export type ThemeType = typeof defaultTheme;
