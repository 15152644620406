import styled, { css } from 'styled-components'

export type TileFormSpanProps = {
  isBold?: boolean;
  margin?: string;
}

export const TileFormSpan = styled.span<TileFormSpanProps>(({
  theme: {
    typography, palette,
  }, isBold, margin,
}) => css`
    margin: ${margin};
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${isBold ? typography.small.pt_13_semibold.fontWeight : typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > strong {
      margin: 5px;
    }
  `)
