import React from 'react'
import styled, { css } from 'styled-components'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    background-color: ${palette.light.white};
    width: 100%;
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin-bottom: 0.5rem;
  `
})

type ItemProps ={
  width?: number,
}

const Label = styled.span<ItemProps>(({
  theme, width,
}) => {
  const { typography } = theme
  return css`
    width: ${`${width}%`};
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      padding-left: 0.75rem;
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      padding-right: 0.75rem;
      margin-right: 0;
    }
  `
})

const Row = styled.span<ItemProps>(({
  theme, width,
}) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-right: ${corners.default.borderRadius};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
  
      margin: 0 1.25rem;
      width: ${`${width}%`};
    }

    > span:first-child {
      margin-left: 0;
      text-align: left;
    }

    > span:nth-child(2){
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
    

      > span {
        width: max-content;
      }
    }
  `
})

interface ITableProps {
  labels: string[]
  values: string[][]
  columnWidth: number
}

export function Table({
  labels, values, columnWidth,
}: ITableProps): JSX.Element {
  return (
    <Wrapper>
      <Header>
        {labels.map((label) => (
          <Label
            key={label + uuidv4()}
            width={columnWidth}
          >
            {label}
          </Label>
        ))}
      </Header>
      {values.map((value) => (
        <Row
          key={value[0] + uuidv4()}
          width={columnWidth}
        >
          {value.map((item) => <span key={item + uuidv4()}>{item}</span>)}
        </Row>
      ))}
    </Wrapper>
  )
}

export default Table
