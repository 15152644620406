import { compareAsc, compareDesc } from 'date-fns'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByDate = (array: any[], key: string, direction: string): any[] => {
  if (!direction) {
    return array
  }
  const mapped = [...array].map((element, index) => {
    const date = new Date(element[key])
    return ({
      index,
      date,
    })
  })
  if (direction === 'ASC') {
    mapped.sort((a, b) => compareAsc(a.date, b.date))
  }
  if (direction === 'DESC') {
    mapped.sort((a, b) => compareDesc(a.date, b.date))
  }
  return mapped.map(date => array[date.index])
}
