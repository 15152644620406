export const poisTableData = {
  nonGroupedLabels: [
    '',
    'placeme.pois_tile.table_label_1',
    'placeme.pois_tile.table_label_2',
  ],
  groupedLabels: [
    '',
    'placeme.pois_tile.table_label_3',
    'placeme.pois_tile.table_label_4',
  ],
}

export const dummyDataMap = {
  poisMap: {
    data: {
      features: [
        {
          geometry: {
            coordinates: [
              20.9928131,
              52.259903,
            ],
            type: 'Point',
          },
          properties: {
            pinnedItem: {
              class: 'brand-img',
              html: "<img src='https://dataplaceappstorage.blob.core.windows.net/images/example_logo.png' />",
            },
          },
          type: 'Feature',
        },
        {
          geometry: {
            coordinates: [
              20.992703,
              52.254377,
            ],
            type: 'Point',
          },
          properties: {
            pinnedItem: {
              class: 'brand-img',
              html: "<img src='https://dataplaceappstorage.blob.core.windows.net/images/example_logo.png' />",
            },
          },
          type: 'Feature',
        },
      ],
      type: 'FeatureCollection',
    },
    options: {
      type: 'geojson',
      id: 'pois',
    },
  },
}
