import React from 'react'
import { Header } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { DataSetUpdateInfoTile } from '../index'

const Wrapper = styled.div(({ theme }) => {
  const {
    corners, palette, shadows,
  } = theme
  return css`
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.tiny.boxShadow};
    width: 100%;
    margin: 3rem 0;
  `
})

const HeaderWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  align-items: center;
  background-color: ${palette.light.main};
  border-bottom: 1px solid ${palette.light.darker};
  padding: 1rem 1.5rem;
  > svg {
    margin-right: 0.5rem;
  }
`
})

const TilesWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    grid-template-columns: 1fr 1fr;

    > div:nth-child(odd) {
      border-right: 1px solid ${palette.light.darker};
    }

    > div:last-child {
      border-right: none;
      border-bottom: none;
    }

    > div:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }

    > div {
      border-bottom: 1px solid ${palette.light.darker};
    }

    @media (max-width: 1144px) {
      grid-template-columns: 1fr;

      > div {
        border-right: none;
      }

      > div:nth-child(odd) {
      border-right: none;
    }
    }
`
})

const Border = styled.div(() => css`
  padding: 2.5rem 0;
`)

interface IDataSetUpdateInfoCategoryTileProps {
  tile: ISectionTile
}

export const DataSetUpdateInfoCategoryTile = (
  { tile: categoryTile }: IDataSetUpdateInfoCategoryTileProps,
): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <HeaderWrapper>
        {categoryTile.icon}
        <Header type='h5'>{t(`${categoryTile.label}`)}</Header>
      </HeaderWrapper>
      <TilesWrapper>
        {categoryTile.tiles.map(tile => (
          <Border key={`border-${tile.id}`}>
            <DataSetUpdateInfoTile
              key={`info-tile-${tile.id}`}
              tile={tile}
            />
          </Border>
        ))}
      </TilesWrapper>
    </Wrapper>
  )
}
