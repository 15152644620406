import styled, { css } from 'styled-components'

interface IRcSlider {
  disabled?: boolean,
}

export const RcSliderWrapper = styled.div<IRcSlider>(({
  theme, disabled,
}) => {
  const { palette } = theme
  return css`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 251px;

    &.black-labels {
      .rc-slider {
        .rc-slider-value {
          color: #454545;
        }
      }

      .slider-label {
        color: #454545;
      }
    }

    .rc-slider {
      padding-top: 25px;

      &.disabled-range {
        opacity: 0.88;
      }
  
      .rc-slider-rail{
        background-color: ${palette.product.location.light};
      }

      .rc-slider-track {
        background-color: ${palette.results.green.light};
      }

      .rc-slider-value {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        transform: translateX(-50%);
        top: -5px;
        width: 60px;
        text-align: center;

        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
      }

      .rc-slider-handle {
        background-color: ${palette.results.green.light};
        border: none;
        width: 10px;
        height: 10px;
        margin-top: -3px;
        box-shadow: none !important;
      }
    }

    .slider-label {
      color: rgba(255, 255, 255, 0.69);
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 400;

      span {
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
      }
    }

      .rc-slider-disabled{
        background-color: transparent;

        .rc-slider-rail{
          background-color: ${palette.product.location.medium};
        }
      }

      ${disabled && css`
          .rc-slider-handle{
            display: none;
          }
          .rc-slider-value{
            display: none;
          }
        
      `}
  `
})
