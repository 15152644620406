import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IUseTemplateProps } from './@types/IUseTemplate.props'

const Title = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    cursor: pointer;
    // todo BRAK KOLORU W PALECIE!
    color: #423ab3;
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};

    > select {
      position: absolute;
      opacity: 0;
    }
  `
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const Choose = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > span {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    > select {
      border: none;
      background: none;
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

export const UseTemplate = ({
  selected,
  options,
  onChange,
}: IUseTemplateProps): JSX.Element => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }

  const { t } = useTranslation()

  return (
    <Wrapper>
      {selected
        ? (
          <Choose>
            <span>{t('placeme.sidebar.template')}</span>
            <select
              onBlur={() => {}}
              onChange={handleSelect}
              value={selected}
            >
              {options.map((element) => (
                <option key={element}>{element}</option>
              ))}
            </select>
          </Choose>
        )
        : (
          <Title>
            <select
              onBlur={() => {}}
              onChange={handleSelect}
              value={selected}
            >
              {options.map((element) => (
                <option key={element}>{element}</option>
              ))}
            </select>
            {t('placeme.sidebar.use_template')}
          </Title>
        )}
    </Wrapper>
  )
}
