
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import styled, { css } from 'styled-components'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
      border-radius: ${corners.default.borderRadius};
      background-color: ${palette.light.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      max-width: 400px;
    `
})

const PopupHeader = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      margin-bottom: 0.5rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_medium.fontSize};
      font-weight: ${typography.main.pt_15_medium.fontWeight};
      line-height: ${typography.main.pt_15_medium.lineHeight};
    `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
      margin-bottom: 0.5rem;
      color: ${palette.results.red.dark};
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    `
})

const Footer = styled.div(
  () => css`
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
    `,
)

interface IDoYouWantComparison {
  trigger: JSX.Element
  handleCompareLocation: (shouldBeCompare: boolean)=> void
}

export const DoYouWantComparison = ({
  trigger, handleCompareLocation,
}: IDoYouWantComparison) :JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={closeModal}
      onOpen={() => {
        setOpen(true)
      }}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<div>{trigger}</div>}
    >

      <Wrapper>
        <PopupHeader>{t('placeme.edit.comparison.header')}</PopupHeader>
        <Content>{t('placeme.edit.comparison.desc')}</Content>
        <Footer>
          <ButtonProduct
            margin='0 5px 0 0'
            onClick={() => {
              handleCompareLocation(true)
              closeModal()
            }}
            small
          >
            {t('generic.yes')}
          </ButtonProduct>
          <ButtonProduct
            deleteButton
            onClick={() => {
              handleCompareLocation(false)
              closeModal()
            }}
            small
          >
            {t('generic.no')}
          </ButtonProduct>
        </Footer>
      </Wrapper>

    </Popup>
  )
}
