import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
// import { dummy } from './dummy'
import { IEmployeesInRangeTileRowData } from './@types/IEmployeesInRangeTile'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin: 0.5rem 0 1rem;
  `
})

const Label = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    width: 50%;
    text-align: right;
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      margin-right: 0;
    }
  `
})

const Row = styled.span(({ theme }) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-radius: ${corners.default.borderRadius};
    background: ${palette.light.main};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 5px;

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
      display: flex;
      align-items: center;
      text-align: right;
      margin: 0 1.25rem;
      width: 50%;
    }

    > span:first-child {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: normal;
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-left: 0;
      text-align: left;
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      > span {
        width: max-content;
      }
    }
  `
})

const Description = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
 
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 10px;
    color: ${palette.black};
    margin-bottom: 2rem;
  
`
})

const labels = [
  'placeme.employees_in_range_tile.table_label_1',
  'placeme.employees_in_range_tile.table_label_2',
]

export const EmployeesInRangeTile: React.FC<{ data: IEmployeesInRangeTileRowData, tileId: string }> = ({ data }) => {
  // variables
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Description>{t('placeme.employees_in_range_tile.description')}</Description>
        <Header>
          {labels.map((label) => (
            <Label key={label}>{t(label)}</Label>
          ))}
        </Header>
        <Row>
          <span><b>{t('placeme.employees_in_range_tile.number_of_employees')}</b></span>
          <span>
            <b>{formatNumber(data?.value?.employeesInRange?.total)}</b>
            {data?.value?.comparedLocation?.employeesInRange?.total && (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(data?.value?.comparedLocation?.employeesInRange?.total)}
                difference={data?.value?.comparedLocation?.employeesInRange?.totalDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(data?.value?.employeesInRange?.total)}
                style={{
                  marginLeft: '8px',
                }}
                width='72px'
              />
            )}
          </span>
        </Row>
        <Row>
          <span>{t('placeme.employees_in_range.industry')}</span>
          <span>
            {formatNumber(data?.value?.employeesInRange?.industry)}
            {(data?.value?.comparedLocation?.employeesInRange?.industry
            || data?.value?.comparedLocation?.employeesInRange?.industry === 0) && (
              <ComparedLocationBean
                comparedAddress={comparedLocation?.location?.address}
                comparedValue={formatNumber(data?.value?.comparedLocation?.employeesInRange?.industry)}
                difference={data?.value?.comparedLocation?.employeesInRange?.industryDiff}
                mainAddress={value?.address}
                mainValue={formatNumber(data?.value?.employeesInRange?.industry)}
                style={{
                  marginLeft: '8px',
                }}
                width='72px'
              />
            )}
          </span>
        </Row>
        <Row>
          <span>{t('placeme.employees_in_range.public_sector')}</span>
          <span>
            {formatNumber(data?.value?.employeesInRange?.publicSector)}
            {(data?.value?.comparedLocation?.employeesInRange?.publicSector
             || data?.value?.comparedLocation?.employeesInRange?.publicSector === 0)
              && (
                <ComparedLocationBean
                  comparedAddress={comparedLocation?.location?.address}
                  comparedValue={formatNumber(data?.value?.comparedLocation?.employeesInRange?.publicSector)}
                  difference={data?.value?.comparedLocation?.employeesInRange?.publicSectorDiff}
                  mainAddress={value?.address}
                  mainValue={formatNumber(data?.value?.employeesInRange?.publicSector)}
                  style={{
                    marginLeft: '8px',
                  }}
                  width='72px'
                />
              )}
          </span>
        </Row>
        <Row>
          <span>{t('placeme.employees_in_range.services')}</span>
          <span>
            {formatNumber(data?.value?.employeesInRange?.services)}
            {(data?.value?.comparedLocation?.employeesInRange?.services
             || data?.value?.comparedLocation?.employeesInRange?.services === 0)
             && (
               <ComparedLocationBean
                 comparedAddress={comparedLocation?.location?.address}
                 comparedValue={formatNumber(data?.value?.comparedLocation?.employeesInRange?.services)}
                 difference={data?.value?.comparedLocation?.employeesInRange?.servicesDiff}
                 mainAddress={value?.address}
                 mainValue={formatNumber(data?.value?.employeesInRange?.services)}
                 style={{
                   marginLeft: '8px',
                 }}
                 width='72px'
               />
             )}
          </span>
        </Row>
        <Row>
          <span>{t('placeme.employees_in_range.office_workers')}</span>
          <span>
            {formatNumber(data?.value?.employeesInRange?.officeWorkers)}
            {(data?.value?.comparedLocation?.employeesInRange?.officeWorkers
             || data?.value?.comparedLocation?.employeesInRange?.officeWorkers === 0)
             && (
               <ComparedLocationBean
                 comparedAddress={comparedLocation?.location?.address}
                 comparedValue={formatNumber(data?.value?.comparedLocation?.employeesInRange?.officeWorkers)}
                 difference={data?.value?.comparedLocation?.employeesInRange?.officeWorkersDiff}
                 mainAddress={value?.address}
                 mainValue={formatNumber(data?.value?.employeesInRange?.officeWorkers)}
                 style={{
                   marginLeft: '8px',
                 }}
                 width='72px'
               />
             )}
          </span>
        </Row>

      </Wrapper>
    )
}
