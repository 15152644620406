import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Search } from '../../../../assets/src/lib/icons/dataplace/search.svg'

const Button = styled.button(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.625rem;
    height: 1.625rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.darker};

    > svg {
      width: 0.875rem;
    }

    :hover {
      background-color: ${palette.product.location.light};
    }

    :focus {
      background-color: ${palette.product.location.medium};
    }
  `
})

export const SearchButton = (): JSX.Element => (
  <Button>
    <Search />
  </Button>
)
