interface IConfig {
  PROJECT_ID: string;
  API_KEY: string;
  AUTH_SESSION_ENDPOINT: string;
}

const generateEnvConfig = () => {
  // // eslint-disable-next-line no-console
  // console.log(process.env.NX_FIREBASE_ENVIRONMENT !== 'undefined' ? process.env.NX_FIREBASE_ENVIRONMENT : process.env.NX_ENV)
  // // eslint-disable-next-line no-console
  // console.log(process.env.NX_FIREBASE_ENVIRONMENT)
  // // eslint-disable-next-line no-console
  // console.log(process.env.NX_ENV)

  switch (process.env.NX_FIREBASE_ENVIRONMENT !== 'undefined' ? process.env.NX_FIREBASE_ENVIRONMENT : process.env.NX_ENV) {
    case 'LOCAL':
      return {
        PROJECT_ID: 'dataplace-app-testing', // actually not necessary
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8', // actually not necessary
        AUTH_SESSION_ENDPOINT: 'http://localhost:5001/dataplace-app-testing/us-central1/session',
      }
    case 'ALPHA':
      return {
        PROJECT_ID: 'dataplace-app-testing',
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
        AUTH_SESSION_ENDPOINT: 'https://testing.session-management.dataplace.ai',
      }
    case 'BETA':
      return {
        PROJECT_ID: 'dataplace-app-testing',
        API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
        AUTH_SESSION_ENDPOINT: 'https://testing.session-management.dataplace.ai',
      }
    case 'PROD':
      return {
        PROJECT_ID: 'dataplace-app',
        API_KEY: 'AIzaSyDE30CPTypV6U-Mfb_zj-m2oRoQZ_VNZaw',
        AUTH_SESSION_ENDPOINT: 'https://session-management.dataplace.ai',
      }
    default: // beta
      return {
        PROJECT_ID: 'dataplace-app',
        API_KEY: 'AIzaSyDE30CPTypV6U-Mfb_zj-m2oRoQZ_VNZaw',
        AUTH_SESSION_ENDPOINT: 'https://session-management.dataplace.ai',
      }
      // return {
      //   PROJECT_ID: 'dataplace-app-testing',
      //   API_KEY: 'AIzaSyDZDue8RrNwzO7leY0n1-VhEFZhbYwX8-8',
      //   AUTH_SESSION_ENDPOINT: 'https://testing.session-management.dataplace.ai',
      // }
  }
}

export const firebaseConfig: IConfig = generateEnvConfig()

