/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AddMapView, MapVisualizationModal, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile, Checkbox,
  ImageWithFallback,
  Tooltip,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { poisTableData } from './data'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue, saveTileData, saveTiles } from '../../../../../slice/analysisSlice'
import { IPois, IPoisTileData } from './@types/IPoisTileData'
import { ReactComponent as ClockIcon } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/clock.svg'
import { ComparedLocationHeader } from '../../../../atoms'
import { sortCategories, sortCategoriesNestedSelectList } from './functions'
import { ReportOutOfDatePoiSection } from '../../../../molecules'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})
const NoItems = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
`

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

const CheckboxWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
    align-items: center;
      color: ${palette.blue};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      margin-top: .5rem;

      > :first-child {
        margin-right: 0.5rem
      }
    `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)

export const PoisTile: React.FC<{data: IPoisTileData, tileId: string}> = ({
  data, tileId,
}) => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const {
    nonGroupedLabels, groupedLabels,
  } = poisTableData

  const [grouped, setGrouped] = useState<boolean>(true)
  const [categories, setCategories] = useState<ResourceWithId[]>([])
  const [val, setValue] = useState<ResourceWithId[]>(categories)
  const [rowsToDisplay, setRowsToDisplay] = useState<{[key: string]: IPois[] }>({})
  const [comparedRowsToDisplay, setComparedRowsToDisplay] = useState<{[key: string]: IPois[] }>({})
  const labels = grouped ? groupedLabels : nonGroupedLabels
  const [layers, setLayers] = useState<IMapTile['layers']>()
  const [comparedLayers, setComparedLayers] = useState<IMapTile['layers']>()
  const [isMapModalDisplayed, setIsMapModalDisplayed] = useState(false)
  const [isComparedMapModalDisplayed, setIsComparedMapModalDisplayed] = useState(false)
  const [isMapVisible, setIsMapVisible] = useState(false)
  const [isComparedMapVisible, setIsComparedMapVisible] = useState(false)
  const countriesWithOnlyGroceryStores = ['CZ', 'PT']
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })
  // token
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  // functions

  const catchmentId = values?.find(c => c.id === 'market')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const handleMapOpen = (compared: boolean) => {
    if (compared) setIsComparedMapModalDisplayed(!isComparedMapModalDisplayed)
    else { setIsMapModalDisplayed(!isMapModalDisplayed) }
  }

  const handleAddMap = (compared: boolean) => {
    if (compared) setIsComparedMapVisible(true)
    else { setIsMapVisible(true) }
  }

  const getLayers = (compared: boolean) => {
    const items = compared ? data?.value?.comparedLocation : data?.value
    if (items?.pois?.length) {
      const tile = values?.find(c => c.id === 'market')?.tiles?.find(t =>
        t.id === tileId)
      const rangeCoords = compared
        ? tile?.comparedChosenRange?.geoJSON?.coordinates
        : tile?.chosenRange?.geoJSON?.coordinates
      const features : IFeatureCollection['data']['features'] = []
      const pois = items?.pois?.filter(poi => val?.map(v => v?.id).includes(poi.category))
      pois?.forEach(poi => features.push({
        geometry: {
          coordinates: [poi.lng, poi.lat],
          type: 'Point',
        },
        properties: {
          title: `<div style="display: flex; flex-direction: column;"><span>${poi?.brand}</span><span>${poi?.address}</span></div>`,
          pinnedItem: {
            iconAnchor:[11, 11],
            class: 'poi-img',
            html: `<img alt='' src='${poi.logo || 'assets/icons/logoPlaceholder.svg'}' style="width:22px; height: 22px;"/>`,
          },
        },
        type: 'Feature',
      }))

      const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
        {
          id: 'pois-layer',
          layer: {
            data: {
              features,
              type: 'FeatureCollection',
            },
            options: {
              type: 'geojson',
              id: 'pois',
            },
          },
        },
      ]
      // range layer
      if (rangeCoords) {
        layers.push({
          id: (compared ? `${tile?.id}-compared` : tile?.id) || '',
          layer: {
            data: {
              coordinates: (rangeCoords) as IGeojson['data']['coordinates'],
              type: 'Polygon',
              properties: {},
            },
            options:{
              type: 'geojson',
              id: compared ? 'pois_range_compared' : 'pois_range',
              style: {
                color: '#0000a2',
                fillColor:'#0000a2',
                weight: 1.5,
                fillOpacity: 0.05,
              },
            },
          },
        })
      }

      return layers
    }
    return undefined
  }

  // hooks

  useEffect(() => {
    if (token.length && !data?.mapLocation) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (data?.value?.pois?.length && mapLocation) {
      dispatch(saveTileData('market', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  useEffect(() => {
    if (data?.value?.pois?.length) {
      // save categories for pdf
      const toExclude = categories?.filter(cat =>
        !val?.map(item => item?.id).includes(cat?.id)).map(item => item?.id)
      if (catchmentId && toExclude?.length) {
        const pdfTiles = values?.map(cat => {
          if (cat?.id === 'market') {
            return {
              ...cat,
              tiles: cat?.tiles.map(tile => {
                if (tile?.chosenRange?.catchmentId === catchmentId) {
                  return {
                    ...tile,
                    pdfExcluded: toExclude,
                  }
                } return tile
              }),

            }
          }
          return cat
        })
        dispatch(saveTiles(pdfTiles))
      }

      // filtering results by selected categories
      const filteredValues = data?.value?.pois?.filter(value => val
        .map(c => c.id).includes(value.category)).sort((a, b) => (a.distance - b.distance))
      // parsing an array to object by category
      const assignedValues = filteredValues.reduce((acc, cur) => ({
        ...acc,
        [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
      }), ({} as {[key: string]: IPois[] }))
      // grouping results by brand and counting
      const groupedValues = Object.entries(assignedValues).reduce((acc, cur) => {
        const grouped = cur[1].reduce((acc: IPois[], cur: IPois) => {
          const exist = acc.find(v => v.brand === cur.brand)
          const reducedValue = {
            ...cur,
            count: exist?.count ? exist.count + 1 : 1,
            distance: exist?.distance ? Math.min(exist.distance, cur.distance) : cur.distance,
          }
          let newArray = []
          if (exist) {
            acc.forEach(item => {
              if (item !== exist) newArray.push(item)
            })
            newArray.push(reducedValue)
          } else {
            newArray = [...acc, reducedValue]
          }

          return newArray.sort((a, b) => (a.distance - b.distance))
        }, [])

        return {
          ...acc,
          [cur[0]]: grouped,
        }
      }, {})
      // comparedLocation
      if (data?.value?.comparedLocation) {
        // filtering results by selected categories
        const filteredComparedValues = data?.value?.comparedLocation?.pois?.filter(value => val
          .map(c => c.id).includes(value.category)).sort((a, b) => (a.distance - b.distance))
        // parsing an array to object by category
        const assignedComparedValues = filteredComparedValues.reduce((acc, cur) => ({
          ...acc,
          [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
        }), ({} as {[key: string]: IPois[] }))
        // grouping results by brand and counting
        const groupedValuesCompared = Object.entries(assignedComparedValues).reduce((acc, cur) => {
          const groupedCompared = cur[1].reduce((acc: IPois[], cur: IPois) => {
            const existCompared = acc.find(v => v.brand === cur.brand)
            const reducedValue = {
              ...cur,
              count: existCompared?.count ? existCompared.count + 1 : 1,
              distance: existCompared?.distance ? Math.min(existCompared.distance, cur.distance) : cur.distance,
            }
            let newArrayCompared = []
            if (existCompared) {
              acc.forEach(item => {
                if (item !== existCompared) newArrayCompared.push(item)
              })
              newArrayCompared.push(reducedValue)
            } else {
              newArrayCompared = [...acc, reducedValue]
            }

            return newArrayCompared.sort((a, b) => (a.distance - b.distance))
          }, [])

          return {
            ...acc,
            [cur[0]]: groupedCompared,
          }
        }, {})
        setComparedRowsToDisplay(grouped
          ? sortCategories(groupedValuesCompared)
          : sortCategories(filteredComparedValues.reduce((acc, cur) => ({
            ...acc,
            [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
          }), ({} as {[key: string]: IPois[] }))))
      }

      // setting the results to be displayed depending on whether they are to be grouped or not
      setRowsToDisplay(grouped
        ? sortCategories(groupedValues)
        : sortCategories(filteredValues.reduce((acc, cur) => ({
          ...acc,
          [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
        }), ({} as {[key: string]: IPois[] }))))
    }
  }, [JSON.stringify(data), grouped, val])

  useEffect(() => {
    if (data?.value?.pois?.length) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.pois?.forEach(investment => {
        const categoryId = investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.pois_tile.category.${categoryId.toLocaleLowerCase().split(/[_ -]+/)
              .join('-')}`),
          })
        }
      })
      // compared location
      if (data?.value?.comparedLocation) {
        data?.value?.comparedLocation?.pois?.forEach(investment => {
          const categoryId = investment?.category
          if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
            categoriesObjects.push({
              id: categoryId,
              content: t(`placeme.pois_tile.category.${categoryId.toLocaleLowerCase().split(/[_ -]+/)
                .join('-')}`),
            })
          }
        })
      }

      setCategories(sortCategoriesNestedSelectList(categoriesObjects))
      setValue(sortCategoriesNestedSelectList(categoriesObjects))
    }
  }, [data?.value])

  useEffect(() => {
    setLayers(getLayers(false))
    if (data?.value?.comparedLocation) {
      setComparedLayers(getLayers(true))
    }
  }, [data?.value, val])

  return !data || data?.loading
    ? (<Loader />)
    : (data?.value?.pois?.length
      ? (
        <Wrapper>

          {!countriesWithOnlyGroceryStores.includes(value?.country || ' ') && (
            <NestedSelectWrapper>
              <span>{t('placeme.pois_tile.points_categories')}</span>
              <NestedSelect
                name=''
                onChange={setValue}
                options={categories}
                selected={val}
                width='50%'
              />
            </NestedSelectWrapper>
          )}

          <CheckboxWrapper>
            <Checkbox
              checked={!val.length}
              onChange={(e) => {
                const newVal = e.target.checked ? [] : categories
                setValue(newVal)
              }}
            />
            <span>{t('placeme.pois_tile.categories_list.deselect_all')}</span>
          </CheckboxWrapper>

          <CheckboxWrapper>
            <Checkbox
              checked={grouped}
              onClick={() => setGrouped(state => !state)}
            />
            <span>{t('placeme.pois_tile.group')}</span>
          </CheckboxWrapper>

          {/* report outdated poi modal section */}
          {catchmentId && <ReportOutOfDatePoiSection catchmentId={catchmentId} />}
          {data?.value?.comparedLocation && (
            <ComparedLocationHeader
              style={{
                marginTop: '1.5rem',
              }}
            >
              <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
              {' '}
              <span>{value?.address}</span>
            </ComparedLocationHeader>
          )}
          {Object.entries(rowsToDisplay)?.map((entry) => (
            <Table
              key={entry[0]}
              content={entry[1].map(value => [
                <ImageWithFallback
                  key={value.logo}
                  alt={value.brand}
                  fallbackSrc='assets/icons/logoPlaceholder.svg'
                  height='22px'
                  src={value.logo}
                  width='22px'
                />,
                <span
                  key={value.brand + value.address}
                  className={value?.workingHours ? 'bank' : undefined}
                >
                  <Tooltip
                    content={value.brand}
                    position='right center'
                  >
                    {' '}
                    {value.brand}
                  </Tooltip>
                  {value?.workingHours
                    ? (
                      <Tooltip
                        content={(
                          <ul>
                            {Object.entries(value?.workingHours).map(([key, value]) => <li key={key}>{`${t(`placeme.pois.working_hours.day.${key}`)}: ${value}`}</li>)}
                          </ul>
                        )}
                        header={t('placeme.pois.working_hours')}
                        position='right center'
                      >
                        <ClockIcon
                          height='18px'
                          width='18px'
                        />
                      </Tooltip>
                    )
                    : null}
                </span>,
                <span key={value.address}>
                  {grouped
                    ? value.count
                    : (
                      <Tooltip
                        content={value.address}
                        position='right center'
                      >
                        {value.address}
                      </Tooltip>
                    )}
                </span>,
                <span
                  key={value.distance}
                  style={{
                    display: 'flex',
                    justifyContent:'flex-end',
                  }}
                >
                  {value.distance * 1000}
                </span>,
              ])}
              gap='1rem'
              headerTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
              labels={[
                <span
                  key={entry[0]}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t(`placeme.pois_tile.category.${entry[0]?.toLocaleLowerCase().split(/[_ -]+/)
                    .join('-')}`)}
                </span>,
                ...labels
                  .map((label: string) => (<span key={label}>{t(label)}</span>)),
              ]}
              pois={grouped}
              rowTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
            />
          ))}

          {(!isMapVisible) && (
            <AddMapView
              buttonValue={0}
              description='placeme.add_traffic_visualisation_map.description'
              onChange={() => { handleAddMap(false) }}
              title='placeme.add_pois_map.title'
            />
          )}
          {isMapVisible
          && (
            <>
              <StyledTitleFormSectionSubTitle>
                <span>{t('placeme.pois_tile.points_on_map')}</span>
                <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
              </StyledTitleFormSectionSubTitle>
              <MapWrapper>
                <MapTile
                  dragging
                  height='100%'
                  layers={layers}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={value?.address}
                  setMapLocation={setMapLocation}
                  showScaleControl
                  width='100%'
                  zoom={15}
                  zoomControl
                />
              </MapWrapper>
            </>
          )}
          {isMapModalDisplayed && (
            <MapVisualizationModal
              isDisplayed={isMapModalDisplayed}
              layers={layers}
              location={value}
              mapId={`pois-map-${values?.find(c => c.id === 'market')?.tiles?.find(t => t.id === 'pois')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapModalDisplayed}
              zoom={15}
            />
          )}

          {data?.value?.comparedLocation
          && (
            <>
              <ComparedLocationHeader
                second
                style={{
                  marginTop: '1.5rem',
                }}
              >
                <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                {' '}
                <span>{comparedLocation?.location?.address}</span>
              </ComparedLocationHeader>
              {Object.entries(comparedRowsToDisplay)?.map((entry) => (
                <Table
                  key={entry[0]}
                  content={entry[1].map(value => [
                    <ImageWithFallback
                      key={value.logo}
                      alt={value.brand}
                      fallbackSrc='assets/icons/logoPlaceholder.svg'
                      height='22px'
                      src={value.logo}
                      width='22px'
                    />,
                    <span
                      key={value.brand + value.address}
                      className={value?.workingHours ? 'bank' : undefined}
                    >
                      <Tooltip
                        content={value.brand}
                        position='right center'
                      >
                        {' '}
                        {value.brand}
                      </Tooltip>
                      {value?.workingHours
                        ? (
                          <Tooltip
                            content={(
                              <ul>
                                {Object.entries(value?.workingHours).map(([key, value]) => <li key={key}>{`${t(`placeme.pois.working_hours.day.${key}`)}: ${value}`}</li>)}
                              </ul>
                            )}
                            header={t('placeme.pois.working_hours')}
                            position='right center'
                          >
                            <ClockIcon
                              height='18px'
                              width='18px'
                            />
                          </Tooltip>
                        )
                        : null}
                    </span>,
                    <span key={value.address}>
                      {grouped
                        ? value.count
                        : (
                          <Tooltip
                            content={value.address}
                            position='right center'
                          >
                            {value.address}
                          </Tooltip>
                        )}
                    </span>,
                    <span
                      key={value.distance}
                      style={{
                        display: 'flex',
                        justifyContent:'flex-end',
                      }}
                    >
                      {value.distance * 1000}
                    </span>,
                  ])}
                  gap='1rem'
                  headerTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
                  labels={[
                    <span
                      key={entry[0]}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t(`placeme.pois_tile.category.${entry[0]?.toLocaleLowerCase().split(/[_ -]+/)
                        .join('-')}`)}
                    </span>,
                    ...labels
                      .map((label: string) => (<span key={label}>{t(label)}</span>)),
                  ]}
                  pois={grouped}
                  rowTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
                />
              ))}
              {(!isComparedMapVisible) && (
                <AddMapView
                  buttonValue={0}
                  description='placeme.add_traffic_visualisation_map.description'
                  onChange={() => handleAddMap(true)}
                  title='placeme.add_pois_map.title'
                />
              )}
              {isComparedMapVisible
            && (
              <>
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.pois_tile.points_on_map')}</span>
                  <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                </StyledTitleFormSectionSubTitle>
                <MapWrapper>
                  <MapTile
                    dragging
                    height='100%'
                    layers={comparedLayers}
                    location={comparedLocation?.location}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={comparedLocation?.location?.address}
                    setMapLocation={setComparedMapLocation}
                    showScaleControl
                    width='100%'
                    zoom={15}
                    zoomControl
                  />
                </MapWrapper>
              </>
            )}
              {isComparedMapModalDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isComparedMapModalDisplayed}
                  layers={comparedLayers}
                  location={comparedLocation?.location}
                  mapId={`pois-map-${values?.find(c => c.id === 'market')?.tiles?.find(t => t.id === 'pois')?.chosenRange?.catchmentId}_compared`}
                  setIsDisplay={setIsComparedMapModalDisplayed}
                  zoom={15}
                />
              )}
            </>
          )}

        </Wrapper>
      )
      : (
        <Wrapper>
          <NoItems>{t('placeme.pois.no_items')}</NoItems>
        </Wrapper>
      )
    )
}
