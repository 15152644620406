import { redirectToSignOut, setCookieWithoutJSON } from '../utils'

const fetchIdFromUrl = () => {
  const location = window.location.pathname.split('/')
  let id = ''
  for (const [index, elem] of location.entries()) {
    if (elem === 'analyse') {
      id = location?.[index + 1]
      break
    }
    id = ''
  }
  return id
}

export const handleTokenExpire = () :void => {
  if (!window.location.href.includes('auth') && !window.location.pathname.includes('sign')) {
    if (window.location.pathname.includes('analyse')) {
      setCookieWithoutJSON('analyseId', fetchIdFromUrl())
      if (window.localStorage.getItem('currentWorkspaceId') && window.localStorage.getItem('currentWorkspaceId') !== 'null') {
        setCookieWithoutJSON('workspaceId', window.localStorage.getItem('currentWorkspaceId'))
      }
      if (window.localStorage.getItem('comparedAnalyseId') && window.localStorage.getItem('comparedAnalyseId') !== 'null') {
        setCookieWithoutJSON('comparedAnalyseId', window.localStorage.getItem('comparedAnalyseId'))
      }
    }
    redirectToSignOut()
  }
}
