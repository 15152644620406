import { parseNumber, isNumber } from './index'

export const sortByText = <T>(array: T[], key: keyof T | string, direction: string): T[] => {
  if (direction === '') { return array }
  const sorted = [...array].sort((a, b) => {
    let num1 = a[key as keyof T]
    let num2 = b[key as keyof T]
    num1 = parseNumber(num1)
    num2 = parseNumber(num2)
    if (isNumber(num1) && isNumber(num2)) { return 0 }
    if (isNumber(num1)) { return direction === 'ASC' ? -1 : 1 }
    if (isNumber(num2)) { return direction === 'ASC' ? 1 : -1 }
    if (`${a[key as keyof T]}`.toLowerCase() > `${b[key as keyof T]}`.toLowerCase()) { return direction === 'ASC' ? 1 : -1 }
    if (`${a[key as keyof T]}`.toLowerCase() < `${b[key as keyof T]}`.toLowerCase()) { return direction === 'ASC' ? -1 : 1 }
    return 0
  })
  return sorted
}
