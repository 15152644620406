/* eslint-disable @nrwl/nx/enforce-module-boundaries,import/no-cycle */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ListSelector } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { ILocation } from '@dataplace.ai/types'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ILocalizationSelectorProps } from './@types/ILocalizationSelector.props'

export const LocalizationSelectorWrapper = styled.span(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: ${palette.light.white};
    border: 1px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    margin: 0.5rem 0;
    color: ${palette.dark.normal};
  `
})

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

// const Wrapper = styled.span(
//   () => css`
//     display: flex;
//     flex-direction: column;
//     margin-top: 1.25rem;
//   `,
// )

export const AddAnotherRange = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    margin-top: 1rem;
    align-items: center;
    background: ${palette.light.white};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 0.5rem;

    > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      > span:first-child {
        color: ${palette.blue};
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
      }

      > span:last-child {
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
      }
    }
  `
})

export const LocalizationSelector = ({
  max,
  onChange,
  localizations,
  localizationsLoading,
  tile,
  instantAdd,
  disabled,
}: ILocalizationSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const [localLocalizations, setLocalLocalizations] = useState<ILocation[]>([])

  useEffect(() => {
    onChange(localLocalizations)
  }, [localLocalizations])

  const displayInput = max ? localLocalizations.length < max : true

  return (
    <>

      <ListSelector
        disabled={disabled}
        displayInput={displayInput}
        instantAdd={instantAdd}
        items={localLocalizations}
        localizations={localizations}
        localizationsLoading={localizationsLoading}
        location={value}
        onChange={setLocalLocalizations}
        placeholder={t(
          'placeme.localization_selector.list_selector_placeholder',
        )}
        tile={tile}
        title={t('placeme.localization_selector.list_selector_title')}
      />

      {!displayInput
              && <NormalSpan>{`${t('placeme.localization_selector.list_selector_max_1')} ${max} ${t('placeme.localization_selector.list_selector_max_2')}`}</NormalSpan>}
    </>
  )
}
