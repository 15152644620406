import React from 'react'
import styled from 'styled-components'
import { Paragraph, Header } from '@dataplace.ai/ui-components/atoms'

const DataTileTextItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`
const LabelValueWrapper = styled.div`
  display:flex;
  align-items: center;
`
type ColorMarkProps = {
  bgColor?: string
}
const ColorMark = styled.div<ColorMarkProps>`
  width: 13px;
  height: 13px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${({ bgColor }) => (bgColor || 'grey')};
`

export interface IDataTileTextItemProps {
  label: string;
  description?: string;
  value?: string;
  coloredMark?: boolean;
  markColor?: string;
}

export function DataTileTextItem({
  label, value, description, coloredMark, markColor,
}: IDataTileTextItemProps):JSX.Element {
  return (
    <DataTileTextItemWrapper>
      <LabelValueWrapper>
        {coloredMark && <ColorMark bgColor={markColor} />}
        <Paragraph size='small'>
          {label}
        </Paragraph>
        <Header
          margin='0 0 0 0.5rem'
          type='h3'
        >
          {value}
        </Header>
      </LabelValueWrapper>
      <Paragraph
        maxWidth='4200px'
        size='verySmall'
      >
        {description}
      </Paragraph>
    </DataTileTextItemWrapper>
  )
}

export default DataTileTextItem
