import React from 'react'
import styled, { css } from 'styled-components'
import { IFormProps } from '@dataplace.ai/ui-components/molecules/Form/@types/IForm.props'
import { FProps } from '@dataplace.ai/ui-components/molecules/Form/@types/FProps.props'

const SectionWrapper = styled.form(() => css`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;
`)

const SectionTitle = styled.span<FProps>(({
  theme, centered,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 1rem;
    color: ${palette.black};
    font-weight: ${typography.big.pt_24_semibold.fontWeight};
    font-size: ${typography.big.pt_24_semibold.fontSize};
    line-height: ${typography.big.pt_24_semibold.lineHeight};
    ${centered && css`
      display: flex;
      justify-content: center;
    `}
  `
})

export const Form: React.FC<React.InputHTMLAttributes<HTMLFormElement> & IFormProps> = ({
  children, centered, title, ...restProps
}) => (
  <SectionWrapper {...restProps}>
    {title && (
      <SectionTitle centered={centered}>
        {title}
      </SectionTitle>
    )}
    {children}
  </SectionWrapper>
)

