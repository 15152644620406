import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import 'leaflet.heat'
import _ from 'lodash'
import { ILayer } from '../../@types/ILayer'

const L = require('leaflet')

interface IHeatmapLayer {
  layer: ILayer,
}

export const HeatmapLayer = ({ layer }: IHeatmapLayer) => {
  const map = useMap()
  const {
    data, options,
  } = layer

  useEffect(() => {
    const points = data
      ? data.map((p) =>
        [p[0], p[1], p[2]])
      : []

    const heatmap = L.heatLayer(points, {
      radius: options.style?.radius,
      blur: options.style?.blur,
      gradient: options.style?.gradient,
      minOpacity: options.style?.minOpacity,
      longitudeExtractor: (m: number[]) => m[1],
      latitudeExtractor: (m: number[]) => m[0],
      intensityExtractor: (m: string[]) => parseFloat(m[2]),
      max: points
        ? _.maxBy(points, (o) => parseFloat(o[2] as unknown as string))?.[2]
        : null
      ,
    })

    heatmap.addTo(map)

    return () => {
      map.removeLayer(heatmap)
    }
  }, [])

  return null
}

