import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../../../../assets/src/lib/icons/dataplace/back.svg'

interface IWrapper {
  isOpen?: boolean,
  margin?: string,
}

const Wrapper = styled.div<IWrapper>(

  ({
    isOpen, margin,
  }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: ${margin || '0'};

    svg {
      height: 0.75rem;
      margin-right: 0.25rem;
    }

    ${!isOpen
    && css`
      svg {
        padding: 0 1.25rem;
      }
    `}
  `,
)
const StyledNavLink = styled(NavLink)`
  :link {
  text-decoration: none;
  }

  :visited {
    text-decoration: none;
  }

  :hover {
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`
const Text = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
    text-decoration: none;
  `
})

interface IBackButton {
  isOpen?: boolean,
  margin?: string,
  handleClick?: ()=> void
  to?: string
  isEdit?: boolean
}

export const BackButton: React.FC<IBackButton> = ({
  isOpen, margin, handleClick, to, isEdit,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {
        to
          ? (
            <StyledNavLink to={to}>
              {' '}
              <Wrapper
                isOpen={isOpen}
                margin={margin}
                onClick={handleClick}
              >
                <BackIcon />
                {isOpen && <Text>{isEdit ? t('generic.finish_editing') : t('generic.back')}</Text>}
              </Wrapper>
            </StyledNavLink>
          )
          : (
            <Wrapper
              isOpen={isOpen}
              margin={margin}
              onClick={handleClick}
            >
              <BackIcon />
              {isOpen && <Text>{isEdit ? t('generic.finish_editing') : t('generic.back')}</Text>}
            </Wrapper>
          )

      }

    </>

  )
}

BackButton.defaultProps = {
  isOpen: true,
}
