import { IFlashMessageObject } from 'libs/shared/features/src/components/FlashMessages/@types'

const { v4: uuidv4 } = require('uuid')

export const createFlashMessage = (messageObject: IFlashMessageObject): void => {
  if (!messageObject?.message) { return }
  let updatedMessages = [] as IFlashMessageObject[]
  const { flashMessages } = sessionStorage
  if (flashMessages) {
    try {
      const parsedMessages = JSON.parse(flashMessages)
      updatedMessages = [...parsedMessages]
    } catch (error) {
      //
    }
  }
  const newMessage = {
    ...messageObject,
    id: uuidv4(),
    timeout: messageObject?.timeout || 5000,
    type: messageObject.message.split('.')[1],
  }
  updatedMessages.push(newMessage)
  sessionStorage.flashMessages = JSON.stringify(updatedMessages)
  const flashMessageEvent = new CustomEvent('flashMessagesUpdate')
  window.dispatchEvent(flashMessageEvent)
}
