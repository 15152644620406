export interface IPricingPlan {
  id: string
  weight: number
}

export const pricingPlans = [
  {
    id: 'bronze',
    price: 50,
  },
  {
    id: 'silver',
    price: 100,
  },
  {
    id: 'gold',
    price: 250,
  },
]
