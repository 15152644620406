import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import AnimatedNumber from 'react-animated-numbers'
import { ReactComponent as Credits } from 'libs/shared/assets/src/lib/icons/placeme/credits.svg'
import { ReactComponent as Stats } from 'libs/shared/assets/src/lib/icons/placeme/stats.svg'
import { useTranslation } from 'react-i18next'
import { getProperPlanName } from '@dataplace.ai/functions/utils'
import { Tooltip } from '..'
import { WrapperProps } from './@types/WrapperProps.props'
import { ICreditsCounter } from './@types/ICreditsCounter.props'

const Wrapper = styled.div<WrapperProps>(({
  theme, allCornersRounded, number, subscriptionType, animating, plans,
}) => {
  const {
    palette, corners, typography,
  } = theme

  return css`
    padding: .4rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${allCornersRounded ? corners.default.borderRadius : `0 0 ${corners.default.borderRadius} ${corners.default.borderRadius}`};
    transition: all 0.5s;
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    .coins-svg{
      margin-right: .5rem;
    }

    ${(() => {
    switch (number) {
      case 0:
        return css`
          color: ${palette.light.white};
          background-color: ${palette.results.red.dark};
          .coins-svg{
            path{
              fill: ${palette.light.white};
            }
          }
        `
      default:
        return css`
          color: ${animating ? palette.light.white : palette.results.green.dark};
          background-color: ${animating ? palette.results.green.dark : palette.results.green.opaque};
          .coins-svg{
            path{
              fill: ${animating ? palette.light.white : palette.results.green.dark};
            }
          }
        `
    }
  })()}

  ${(plans && subscriptionType === plans[0]?.id && number) && css`
    color: ${animating ? '#EEEEEE' : palette.dark.main};
    background-color: ${animating ? palette.dark.main : '#EEEEEE'};
    .coins-svg{
      path{
        fill: ${animating ? '#EEEEEE' : palette.dark.main};
      }
    }
  `}
`
})

const CurrentSubscription = styled.span`
  text-transform: capitalize;
  margin-left: .5rem;
`

export const CreditsCounter = ({
  number, allCornersRounded, subscription, plans, currentPlan, maxNumber,
}: ICreditsCounter):JSX.Element => {
  const { t } = useTranslation()
  const [animating] = useState(true)

  const isUnlimited = typeof number === 'string' && number.toLowerCase() === 'unlimited'
  if (!isUnlimited) {
    number = +(number)
  }

  return (
    <Tooltip
      content={maxNumber === 'unlimited' ? '' : `${t('placeme.credits_counter.hover_1')} ${number}/${maxNumber || 0} ${t('placeme.credits_counter.hover_2')}`}
      header={t(`placeme.generic.subscription.${getProperPlanName(currentPlan)}`)}
      position='bottom right'
    >
      <Wrapper
        allCornersRounded={allCornersRounded}
        animating={animating}
        number={number}
        plans={plans}
        subscriptionType={currentPlan}
      >
        {subscription ? <Stats className='coins-svg' /> : <Credits className='coins-svg' />}
        {maxNumber !== 'unlimited'
            && (
              <AnimatedNumber
                animateToNumber={+(number)}
                configs={[{
                  tension: 89,
                  friction: 40,
                  clamp: true,
                }]}
                includeComma={false}
              />
            )}
        <CurrentSubscription>{getProperPlanName(currentPlan)}</CurrentSubscription>
      </Wrapper>
    </Tooltip>
  )
}

CreditsCounter.defaultProps = {
  subscription: true,
}
