import React from 'react'
import { GeoJSON, WMSTileLayer } from 'react-leaflet'
import { GeoJsonObject } from 'geojson'
import L from 'leaflet'
import { HeatmapLayer } from './overlays/HeatmapLayer/HeatmapLayer'
import { ILayer } from './@types/ILayer'
import { IMapOverlay } from './@types/IMapOverlay'
import { IGeojson } from './@types/IGeojson'
import { IWMSLayer } from './@types/IWMSLayer'
import { IFeatureCollection } from './@types/IFeatureCollection'

const uuidv4 = require('uuid/v4')

export const MapOverlays = ({ layers }: IMapOverlay):JSX.Element => {
  const renderMapOverlay = (layerId : string, layer:ILayer | IGeojson | IWMSLayer|IFeatureCollection) => {
    let overlay = <div />
    switch (layer.options.type) {
      case 'geojson': {
        const typedLayer = layer as ILayer
        overlay = (
          <GeoJSON
            key={`map-overlays-${layerId}`}
            data={layer.data as GeoJsonObject}
            onEachFeature={(feature, layer) => {
              if (feature?.properties?.onClick) {
                layer.on('click', feature?.properties?.onClick)
              }
              if (feature?.properties?.title)
              { layer.bindTooltip(
                feature?.properties?.title,
                {
                  interactive: true,
                  direction: 'top',
                  className: 'leaflet-map-tooltip',
                },
              ) }
            }}
            pointToLayer={(feature, latlng) => (feature?.properties?.pinnedItem
              ? L.marker(
                latlng,
                {
                  icon: L.divIcon({
                    className: feature?.properties?.pinnedItem.class,
                    iconSize: feature?.properties?.pinnedItem.iconSize,
                    iconAnchor: feature?.properties?.pinnedItem?.iconAnchor,
                    html: feature?.properties?.pinnedItem.html,
                  }),
                },
              )
              : L.marker(latlng, {}))}
            style={feature => (typedLayer?.options?.style || feature?.properties?.style)}
          />
        )
        break }
      case 'heatmap':
        overlay = (
          <HeatmapLayer
            key={`map-overlays-${layerId}`}
            layer={layer as ILayer}
          />
        )
        break
      case 'WMSTileLayer': {
        const typedLayer = layer as IWMSLayer
        overlay = (
          <WMSTileLayer
            key={`map-overlays-${layerId}`}
            format={typedLayer.data.format ? typedLayer.data.format : ''}
            layers={typedLayer.data.layers ? typedLayer.data.layers : 'ne:ne'}
            transparent={typedLayer.data.transparent ? typedLayer.data.transparent : true}
            url={typedLayer.data.url ? typedLayer.data.url : 'ne:ne'}
          />
        )
        break
      }
      default:
        break
    }
    return overlay
  }

  return (
    <div
      className='leaflet-map-overlays'
    >
      {layers.map(l => l.options && renderMapOverlay(`${l.options.id}-${uuidv4()}`, l))}
    </div>

  )
}

