import React from 'react'
import styled, { css } from 'styled-components'
import { ITagProps } from './@types/ITag.props'
import { TagWrapperProps } from './@types/TagWrapper.props'

const TagWrapper = styled.div<TagWrapperProps>(
  ({
    color, borderColor, padding, theme, margin, borderRadius,
  }) => {
    const {
      typography, palette,
    } = theme
    return css`
      padding: ${padding || '0.25rem 0.625rem'};
      border-radius: ${borderRadius || '2.5rem'};
      background-color: ${color};
      border: ${borderColor ? `2px solid ${borderColor}` : 'none'};
      display: flex;
      width: min-content;
      flex-wrap: nowrap;
      white-space: nowrap;
      color: ${palette.black};
      margin: ${margin || '0'};

      > span,
      > strong {
        font-size: ${typography.small.pt_13_regular.fontSize};
        line-height: ${typography.small.pt_13_regular.lineHeight};
        margin: 0 0.125rem;
      }

      > span {
        font-weight: ${typography.small.pt_13_regular.fontWeight};
      }

      > strong {
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
      }
    `
  },
)

export const Tag: React.FC<ITagProps> = ({
  children,
  color,
  borderColor,
  padding,
  margin,
  borderRadius,
}) => (
  <TagWrapper
    borderColor={borderColor}
    borderRadius={borderRadius}
    color={color}
    margin={margin}
    padding={padding}
  >
    {children}
  </TagWrapper>
)
