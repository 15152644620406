import React, { useRef, useState, useEffect } from 'react'
import { Input } from '@dataplace.ai/ui-components/atoms/Inputs'
import styled, { css } from 'styled-components'
import { IRangeFilter } from '@dataplace.ai/functions/utils/filtering'
import { isNumber, parseNumber } from '@dataplace.ai/functions/utils'
import { FilterSection } from '../FilterSection'
import { ISortableTableHeaderProps, ITableFilter } from '../../@types'

const Wrapper = styled.div(() => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`)

const InputSeparator = styled.span(() => css`
  padding: 0 5px;
`)

const emptyRangeFilter: IRangeFilter = {
  from: '',
  to: '',
}

export interface INumberFilter extends ITableFilter {
  [key: string]: {
    type: string
    rule: IRangeFilter
  }
}

interface INumberFilterProps {
  filter: INumberFilter
  setFilterValue(filter: ITableFilter): React.Dispatch<React.SetStateAction<ITableFilter>> | void;
  header: ISortableTableHeaderProps
  clearable?: boolean
}

export const NumberFilter = ({
  setFilterValue, header, filter, clearable,
}: INumberFilterProps): JSX.Element => {
  const numberFromRef = useRef<HTMLInputElement>(null)
  const numberToRef = useRef<HTMLInputElement>(null)
  const [numberFilter, setNumberFilter] = useState(filter[header.name]?.rule || emptyRangeFilter)

  const setTableFilterValue = (value: IRangeFilter) => {
    if (!numberFromRef.current?.value && !numberToRef.current?.value) {
      clearFields()
    } else {
      const newValue = {
        ...filter,
        [header.name]: {
          type: 'number',
          rule: value,
        },
      }
      setFilterValue(newValue)
      setNumberFilter(value)
    }
  }
  const clearFields = () => {
    const clearedFilter = {
      ...filter,
    }
    delete clearedFilter[header.name]
    setFilterValue(clearedFilter)
    setNumberFilter(emptyRangeFilter)
  }
  useEffect(() => {
    setNumberFilter(filter[header.name]?.rule || emptyRangeFilter)
  }, [filter])
  return (
    <FilterSection
      clear={(clearFields)}
      clearable={clearable}
      header={header.label}
    >
      <Wrapper>
        <Input
          ref={numberFromRef}
          error={isNumber(+(parseNumber(numberFilter.from))) ? '' : 'error'}
          onChange={e => setTableFilterValue({
            from: e.target.value,
            to: numberToRef?.current?.value || '',
          })}
          value={numberFilter.from}
        />
        <InputSeparator>-</InputSeparator>
        <Input
          ref={numberToRef}
          error={isNumber(+(parseNumber(numberFilter.to))) ? '' : 'error'}
          onChange={e => setTableFilterValue({
            from: numberFromRef?.current?.value || '',
            to: e.target.value,
          })}
          value={numberFilter.to}
        />
      </Wrapper>
    </FilterSection>
  )
}

NumberFilter.defaultProps = {
  clearable: false,
}
