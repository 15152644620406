/* eslint-disable max-lines,import/no-cycle */
import React from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import {
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { BasicLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import moment from 'moment'
import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image'
import { browserName } from 'react-device-detect'
import { ReactComponent as MapIcon } from '../../../../assets/src/lib/icons/rangeIcons/map.svg'
import { IMapVisualizationModal } from './@types/IMapVisualizationModal'
import { TextButton } from '../../atoms/TextButton'
import { ReactComponent as VioletPin } from '../../../../assets/src/lib/markers/placemeMarker.svg'
import { ReactComponent as YellowPin } from '../../../../assets/src/lib/markers/yellowPin.svg'

const Wrapper = styled.div(({ theme }) => {
  const { shadows } = theme
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: ${shadows.hover.boxShadow};
    padding: 2rem;
    width: 85vw;
    height: 80vh;
  `
})
const TopWrapper = styled.div(({ theme }) => {
  const { palette } = theme

  return css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${palette.black};

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  `
})
const ChosenRangeWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.black};
    margin-left: 1.25rem;
    margin-bottom: .5rem;

    >div {
      display: flex;
      align-items: center;

      >svg{
        width: 12px;
        height: 16px;
        margin-left: 5px;
        position: relative;
        bottom: 4px;
      }
      
      >span{
        color: ${palette.dark.normal};
        font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
        margin-right: 5px;
      }

      >p{
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
      }
    }

   
  `
})
const MiddleWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme

  return css`
  width: 100%;
  margin-top: 23.5px;
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  border-radius: 0 0 12px 12px;

  city-pop-container {
 width: 22px;
 height: 22px;
 background-color: ${palette.results.green.dark};
 border-radius: 22px;
 overflow: hidden;
 display: flex;
 flex-direction: column;

}

.city-pop-container-zoomed {
  width: 145px;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 11px;

  .city-name {
    width: 100%;
    padding: 5px;
    color: #fff;
    background-color: ${palette.results.green.dark};
  }

  .city-pop {
    width: 100%;
    padding: 5px;
    font-weight: 400;
    background-color: white;
    color: ${palette.black};
  }

}

.city-tooltip{
  background-color: rgba(21, 24, 31, 0.8);
  padding: 5px 10px 5px 7px;
  display: flex;
  flex-direction: column;
  color: ${palette.light.white};
  border-radius: ${corners.default.borderRadius};
  box-shadow: ${shadows.hover.boxShadow};
}
`
})

const StyledTextButton = styled(TextButton)(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin-right: 16px;

    svg {
      margin-right: 5px;
      path {
        fill: ${palette.blue};
      }
    }

    p {
      color: ${palette.blue};
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
    }
  `
})
export const MapVisualizationModal = ({
  trigger, location, layers, isDisplayed, setIsDisplay, mapId, zoom, styleLayer, legend, style, comparedLocation,
  shouldMapStyleBeSave, setMapLocation,
}: IMapVisualizationModal) :JSX.Element => {
  const { t } = useTranslation()
  const closeModal = () => setIsDisplay(false)
  const openModal = () => setIsDisplay(true)

  const handleDownload = (dataUrl: string, mapId: string) => {
    const link = document.createElement('a')
    link.download = `placeme-map-${mapId}-${moment().format('DD-MM-YYYY--HH-mm-ss')}.png`
    link.href = dataUrl
    link.click()
  }

  const downloadMap = async (mapId: string) => {
    const mapContainer = document.getElementById(mapId)
    if (mapContainer) {
      if (browserName === 'Firefox') {
        const canvas = await html2canvas(mapContainer, {
          useCORS: true,
          width: mapContainer.offsetWidth,
          height: mapContainer.offsetHeight,
        })
        const data = canvas.toDataURL('image/jpg')
        handleDownload(data, mapId)
      } else {
        htmlToImage.toPng(mapContainer, {
          cacheBust: true,
        })
          .then((dataUrl) => {
            handleDownload(dataUrl, mapId)
          })
      }
    }
  }

  return (
    <Popup
      closeOnDocumentClick
      modal
      onClose={closeModal}
      onOpen={openModal}
      open={isDisplayed}
      overlayStyle={defaultTheme.overlay}
      trigger={trigger}
    >
      <Wrapper>
        <TopWrapper>
          <div className='left'>
            <ChosenRangeWrapper>
              <div>
                <p>
                  {location?.address}
                </p>
                <VioletPin />
              </div>
            </ChosenRangeWrapper>
            {comparedLocation && (
              <ChosenRangeWrapper>
                <div>
                  <span>{t('placeme.compared_location.comparing_with')}</span>
                  <p>
                    {comparedLocation?.address}
                  </p>
                  <YellowPin />
                </div>
              </ChosenRangeWrapper>
            )}
          </div>
          <div className='right'>
            <StyledTextButton onClick={() => downloadMap(mapId)}>
              <MapIcon
                className='mark-on-the-map-icon'
              />
              <p className='mark-on-the-map'>{t('generic.download_map_png')}</p>
            </StyledTextButton>
            <TextButton
              onClick={closeModal}
              style={{
                marginRight: '1.82rem',
              }}
            >
              <p>{t('generic.cancel')}</p>
            </TextButton>
          </div>
        </TopWrapper>
        <MiddleWrapper>
          {legend}
          <MapTile
            comparedLocation={comparedLocation}
            dragging
            height='100%'
            layers={layers as (BasicLayers | GeojsonLayers | WMSLayers)[]}
            location={location}
            mapId={mapId}
            mapTypeDropdown={!styleLayer}
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            popupHeadingCompared={`${t('generic.chosen_compared_location')}`}
            popupParagraph={location?.address}
            popupParagraphCompared={comparedLocation?.address}
            scroll
            setMapLocation={setMapLocation}
            shouldMapStyleBeSave={shouldMapStyleBeSave}
            showScaleControl
            style={style}
            styleLayer={styleLayer}
            width='100%'
            zoom={zoom}
            zoomControl
          />
        </MiddleWrapper>
      </Wrapper>
    </Popup>
  )
}
