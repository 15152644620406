import React from 'react'
import vectorUp from 'libs/shared/assets/src/lib/icons/vectorUp.svg'
import vectorDown from 'libs/shared/assets/src/lib/icons/vectorDown.svg'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ISortableTableHeaderProps } from '../../@types/ISortableTableHeader.props'

const Wrapper = styled.div<{ index: number}>(({ index }) => css`
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
justify-content: ${index === 0 ? 'flex-start' : 'flex-end'};

> span {
  margin-right: 0.5rem;
}
`)

const VectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > img:last-child {
    margin-top: 0.2rem;
  }
`

export const SortableTableHeader = ({
  label, setSorter, name, currentSorter, index, type,
}: ISortableTableHeaderProps): JSX.Element => {
  const { t } = useTranslation()
  const handleClick = () => {
    setSorter(name, type)
  }
  const shouldApplyFilter = (direction: string) => {
    if (currentSorter.direction === direction && currentSorter.name === name) {
      return true
    }
    return false
  }
  return (
    <Wrapper
      index={index}
      onClick={handleClick}
    >
      <span>{t(label)}</span>
      <VectorWrapper>
        <img
          alt='vector up'
          height={8}
          src={vectorUp}
          style={{
            filter: shouldApplyFilter('ASC') ? 'brightness(10%)' : 'initial',
          }}
          width={12}
        />
        <img
          alt='vector down'
          height={8}
          src={vectorDown}
          style={{
            filter: shouldApplyFilter('DESC') ? 'brightness(10%)' : 'initial',
          }}
          width={12}
        />
      </VectorWrapper>
    </Wrapper>
  )
}
