import { LinkWithIconDelete, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { getProperPlanName } from '@dataplace.ai/functions/utils'
import { ReactComponent as LockIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/lock.svg'
import { ReactComponent as Stats } from '../../../../../../../../libs/shared/assets/src/lib/icons/placeme/stats.svg'
import { ReactComponent as Exclamation } from '../../../../../../../../libs/shared/assets/src/lib/icons/placeme/exclamation.svg'
import { ITemplatesData } from '../../organisms/AnalyseTemplates/templatesData'
import { DeleteTemplateTileModal } from '../DeleteTemplateTileModal'

const HeaderWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      border-bottom: 1px solid ${palette.light.darker};
      background-color: ${palette.light.lighter};
  `
})

const ContentWrapper = styled.div<{isLongList?: boolean}>(({
  theme, isLongList,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
    padding: 20px;

    >h3{
        margin-bottom: .5rem;
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        font-size: ${typography.main.pt_15_semibold.fontSize};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    >span{
        color: ${palette.dark.normal};
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid ${palette.light.darker};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};

        >strong{
            font-size: ${typography.big.pt_18_semibold.fontSize};
            font-weight: ${typography.big.pt_18_semibold.fontWeight};
            line-height: ${typography.big.pt_18_semibold.lineHeight};
        }
    }

    >ul{
        color: ${palette.dark.normal};
        margin-left: 20px;

        ${isLongList && css`
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        `}

        >li{
            font-size: ${typography.tiny.pt_12_regular.fontSize};
            font-weight: ${typography.tiny.pt_12_regular.fontWeight};
            line-height: ${typography.tiny.pt_12_regular.lineHeight};
            >div{
              >svg{
                margin-left: 4px;
                position:relative;
                top: 1px;
                width:10px;
                height:10px;
              }
            }
        }
        .outsidePlan{
          font-weight: 700;

          >svg{
            margin-left: 8px;
          }
        }
      }
`
})

const HeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  >div:first-of-type{
    margin-right: .5rem;
  }
`

const AnimatedCoins = styled.div(({ theme }) => {
  const { palette } = theme
  return css`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 7px;
    border-radius: 1rem;
    background: #C6EAE1;
    color: ${palette.blue};
    min-width: 38px;

    svg {
        margin-right: 7px;
        path {
            fill: ${palette.blue};
        }
    }

    >p{
        margin-right: 4px;
    }

    .exclamation{
        margin: 0 5px -1px;
    }
    `
})

export const tilesWithDependentCredits = ['cannibalization', 'access_range']

export const creditsNumber = (tiles: string[], whiteTiles?: string[]) : number => {
  let creditsAmount = 0
  if (tiles.length) {
    tiles?.filter(tile => !tilesWithDependentCredits?.includes(tile))?.forEach(tile => {
      if (!whiteTiles?.includes(tile.split('-')[0])) {
        creditsAmount += 1
      }
    })
  }
  return creditsAmount
}

const checkIfAnyTileDependCredits = (tiles: string[]) : boolean =>
  !!tiles?.filter(tile => tilesWithDependentCredits?.includes(tile))?.length

export interface ITemplateTile {
  icon: JSX.Element
  isUsersTemplate: boolean
  title: string
  templatesTiles: ITemplatesData['tiles']
  templateId: string
  handleDeleteTemplate: (templateId: string) => void
}

export const TemplateTile = ({
  icon, templatesTiles, title, isUsersTemplate, handleDeleteTemplate, templateId,
}:ITemplateTile): JSX.Element => {
  const { t } = useTranslation()
  const { tiles } = useSelector((state: RootState) => state.analysis)

  const getWhiteTiles = () : string[] => {
    const whiteTiles: string[] = []
    tiles?.forEach(cat => cat?.tiles?.forEach(tile => (tile?.plan?.id === 'white'
      ? whiteTiles.push(tile?.id)
      : null)))
    return whiteTiles
  }

  const handleDelete = useCallback(() => handleDeleteTemplate(templateId), [templateId, handleDeleteTemplate])

  return (
    <>
      <HeaderWrapper>
        <>
          {icon}
          <HeaderRightWrapper>
            <AnimatedCoins>
              <Stats />
              <p>{creditsNumber(templatesTiles?.filter(item => item?.isInPlan)?.map(tile => tile?.id?.split('-')?.[0]), getWhiteTiles())}</p>
              {checkIfAnyTileDependCredits(templatesTiles?.map(tile => tile?.id?.split('-')?.[0]))
            && (
              <Tooltip
                content={t('placeme.templates.tiles_depended_credits.tooltip')}
                maxWidth='300px'
                position='left center'
              >
                <Exclamation className='helpCircle exclamation' />
              </Tooltip>
            )}
            </AnimatedCoins>
            {isUsersTemplate && (
              <DeleteTemplateTileModal
                handleDelete={handleDelete}
                trigger={<LinkWithIconDelete hideLabel />}
              />
            )}
          </HeaderRightWrapper>
        </>
      </HeaderWrapper>
      <ContentWrapper isLongList={templatesTiles?.length > 5}>
        <h3>{t(title)}</h3>
        <span>
          {t('placeme.templates.key_areas')}
          {' '}
          <strong>{templatesTiles?.length}</strong>
        </span>
        <ul>
          {templatesTiles?.map(tile => (
            <li
              key={tile?.id}
              className={tile?.isInPlan ? '' : 'outsidePlan'}
            >
              {!tile?.isInPlan
                ? (
                  <Tooltip
                    content={t('placeme.templates.tile_outside_plan.tooltip')}
                    maxWidth='300px'
                    position='left center'
                  >
                    <>
                      {t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}
                      {' '}
                      {tile?.plan?.id
                      && (
                        <>
                          (
                          {t(`placeme.generic.subscription.${getProperPlanName(tile?.plan?.id)}`)}
                          )
                        </>
                      )}
                      {!tile?.isInPlan && <LockIcon />}
                    </>
                  </Tooltip>
                )
                : <>{t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}</> }
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </>
  )
}
