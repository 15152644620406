import { getAxios } from '@dataplace.ai/functions/utils'
import { createSlice } from '@reduxjs/toolkit'
import { config } from 'apps/target/src/config'
import { ENDPOINTS } from 'apps/target/src/constants/endpoints'
import { AppThunk } from 'apps/target/src/redux/store'
import { IDashboardTarget } from './@types/IDashboardTarget'

const initialState: IDashboardTarget = {}

const dashboardTargetSlice = createSlice({
  name: 'dashboardTarget',
  initialState,
  reducers: {
    saveAnalyseId : (state, action) => {
      state.analyseId = action.payload
    },
  },
})

export const createNewAnalyse = (token: string): AppThunk => async (dispatch): Promise<void> => {
  const body = {
    visibility: false,
  }

  let data

  try {
    const axiosInstance = await getAxios(config.API_URL, token)
    const response = await axiosInstance.post(ENDPOINTS.CREATE_ANALYSIS || '', body)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  dispatch(saveAnalyseId(data?.value?.id))
}

export const { saveAnalyseId } = dashboardTargetSlice.actions

export default dashboardTargetSlice.reducer
