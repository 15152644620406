/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReactComponent as CloseIcon } from '../../../../../../../libs/shared/assets/src/lib/icons/dataplace/add.svg'

const Wrapper = styled.div<{maxWidth?: string}>(({
  theme, maxWidth,
}) => {
  const {
    palette, corners,
  } = theme
  return css`
    max-width: ${maxWidth};
    max-height: 95vh;
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 2.5rem 2.5rem 0.625rem 2.5rem;
  `
})

const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
`
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
`

const IconWrapper = styled.div(() => css`
    margin: 0 0 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    > svg, img {
      margin: 1.25rem auto;
    }
`)

const BottomWrapper = styled.div<{isOnlyOneChild?: boolean}>(({ isOnlyOneChild }) => css`
  display: flex;
  align-items: center;
  justify-content: ${isOnlyOneChild ? 'flex-end' : 'space-between'};
  width: 100%;
  margin: 0 1rem 1.25rem 0;
`)

const Heading = styled.h2<{list?: string}>(({
  theme, list,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};

    ${list && css`
      display: flex;

      >svg{
        margin-left: 12px;
      }
    `}
    `
})

const SecondHeader = styled.h3(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    text-transform: uppercase;
    font-size: ${typography.big.pt_22_semibold.fontSize};
    font-weight: ${typography.big.pt_22_semibold.fontWeight};  
    line-height: ${typography.big.pt_22_semibold.lineHeight};     
    color: ${palette.black};
    margin: 1.2rem 0;
    `
})
const Description = styled.h2<{list?: string, contact?: JSX.Element}>(({
  theme, list, contact,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
      max-width: ${list ? 'none' : '36rem'};
      width: ${list ? '92%' : 'none'};
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: .5rem;

      ${contact && css`
        max-width: none;
        width: 95%;
      `}

      >a {
        color: ${palette.blue};
        font-weight: 800;
        text-decoration: none;
        cursor: pointer;
        margin-left: 5px;
      }

      >ul{
        >li{
          margin-bottom: 1.5rem;
        }
      }
      `
})

const LeftWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
  color: ${palette.dark.normal};
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
  display: flex;
  align-items: center;
`
})

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ScrollWrapper = styled.div`
  margin-right: 1rem;
`
interface IFullscreenModal {
  trigger: boolean
  cancelButtonClick?: () => void
  agreeButtonClick?: ()=> void
  agreeButtonText?: string
  cancelButtonText?: string
  header: string | JSX.Element
  description?: string
  list?: string,
  secondHeader?: string,
  image: JSX.Element
  maxWidth?: string
  contact?: JSX.Element
  additionalInfo?: string
}

export const FullscreenModal = ({
  trigger, cancelButtonClick, agreeButtonClick, agreeButtonText, cancelButtonText,
  image, header, description, secondHeader, list, maxWidth, contact, additionalInfo,
}: IFullscreenModal):JSX.Element => {
  const [open, setOpen] = useState(false)
  const closeModal = () => {
    setOpen(false)
    if (cancelButtonClick) cancelButtonClick()
  }
  const agree = () => {
    setOpen(false)
    if (agreeButtonClick) agreeButtonClick()
  }

  useEffect(() => {
    setOpen(trigger)
  }, [trigger])

  return (
    <>
      <PopupWithOverlay
        closeOnDocumentClick={false}
        modal
        onClose={() => setOpen(false)}
        open={open}
      >

        <Wrapper maxWidth={maxWidth}>
          <PerfectScrollbar
            className='scroll'
          >
            <ScrollWrapper>
              <CloseIcon
                height='18'
                onClick={closeModal}
                style={{
                  transform:'rotate(45deg)',
                  cursor: 'pointer',
                  position: 'relative',
                  bottom: '1rem',
                  left: '1rem',
                }}
                width='18'
              />
              <TopWrapper>
                <HeaderWrapper>
                  <Heading list={list}>{header}</Heading>
                </HeaderWrapper>
                {secondHeader && (
                  <HeaderWrapper>
                    <SecondHeader>{secondHeader}</SecondHeader>
                  </HeaderWrapper>
                )}
                {description && <Description>{description}</Description>}
                {list && (
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: list,
                    }}
                    list={list}
                  />
                )}
                {contact && <Description contact={contact}>{contact}</Description>}
              </TopWrapper>
              <IconWrapper>
                {image}
              </IconWrapper>
              <BottomWrapper isOnlyOneChild={!additionalInfo}>
                {additionalInfo
            && (
              <LeftWrapper>
                {additionalInfo}
              </LeftWrapper>
            )}
                <RightWrapper>
                  {cancelButtonText && (
                    <Button
                      onClick={cancelButtonClick}
                      style={{
                        backgroundColor: '#F08F7F',
                        borderColor: '#d16b5b',
                      }}
                    >
                      {cancelButtonText}
                    </Button>
                  )}
                  {agreeButtonText && (
                    <Button
                      onClick={agree}
                    >
                      {agreeButtonText}
                    </Button>
                  )}
                </RightWrapper>
              </BottomWrapper>
            </ScrollWrapper>
          </PerfectScrollbar>
        </Wrapper>

      </PopupWithOverlay>

    </>
  )
}
