/* eslint-disable jsx-a11y/alt-text */
import React, { ImgHTMLAttributes, useState } from 'react'

interface IImageWithFallbackProps extends ImgHTMLAttributes<unknown> {
  fallbackSrc: string
  src: string
}

export const ImageWithFallback: React.FC<IImageWithFallbackProps> = ({
  fallbackSrc, src, ...props
}): JSX.Element => {
  const [imgSrc, setImgSrc] = useState(src)
  const onError = () => setImgSrc(fallbackSrc)

  return (
    <>
      <img
        onError={onError}
        src={imgSrc || fallbackSrc}
        {...props}
      />
    </>
  )
}
