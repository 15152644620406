
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  Price,
  TileFormRowWithData,
  TileFormSection,
  TileFormSpan,
  TileFormWrapper,
  TitleFormSectionSubTitle,
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { RootState } from '../../../../../../../redux/store'
import { CustomRangeModal } from '../../..'
import { IParkingTileData } from './@types/IParkingTileData'

const StyledTileFormRowWithData = styled(TileFormRowWithData)(({ theme }) => {
  const { palette } = theme
  return css`
    > svg > path {
      fill: ${palette.blue};
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

export const ParkingTile: React.FC<{data: IParkingTileData, tileId: string}> = ({
  data, tileId,
}) => {
  const { t } = useTranslation()
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const { value } = useSelector((state: RootState) => state.location)

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  return !data || data?.loading
    ? <Loader />
    : (
      <>
        {isMapDisplayed && (
          <CustomRangeModal
            handleClose={handleMapOpen}
            onlyMap
            tile={tileId}
          />
        )}
        <TileFormWrapper>
          <TileFormSection>
            <StyledTileFormRowWithData>
              <span>{t('placeme.parking_tile.row_with_data_1_span_1')}</span>
              <Price
                currency='m2'
                value={data?.value?.parkingLotsArea}
              />
            </StyledTileFormRowWithData>
            <TileFormSpan>{t('placeme.parking_tile.form_span_1')}</TileFormSpan>
            <StyledTileFormRowWithData>
              <span>{t('placeme.parking_tile.row_with_data_2_span_1')}</span>
              <Price
                currency=''
                digits={0}
                value={data?.value?.parkingLotsNumber}
              />
            </StyledTileFormRowWithData>
            <TileFormSpan>{t('placeme.parking_tile.form_span_2')}</TileFormSpan>
            <TitleFormSectionSubTitle>
              <TitleFormSectionSubTitle>
                <span>{t('placeme.parking_tile.section_sub_title_1_span_1')}</span>
              </TitleFormSectionSubTitle>
              <LinkWithIconCheckOnMap onClick={handleMapOpen} />
            </TitleFormSectionSubTitle>
            <MapWrapper>
              <MapTile
                dragging
                height='100%'
                layers={[{
                  id: data?.value?.parkingLotsMap?.catchmentId,
                  layer:{
                    data: data?.value?.parkingLotsMap?.geoJson,
                    options:{
                      type:'geojson',
                      id: data?.value?.parkingLotsMap?.catchmentId,
                      style: {
                        color: '#0000a2',
                        fillColor: '#0000a2',
                        weight: 0,
                        fillOpacity: 0.3,
                      },
                    },
                  },
                }]}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                scroll
                width='100%'
              />
            </MapWrapper>
          </TileFormSection>
        </TileFormWrapper>
      </>
    )
}
