import { Button, TextButton } from '@dataplace.ai/ui-components/atoms'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { INavbarNotification,
  navbarNotificationsEventName,
  sessionStorageNavbarNotifications,
  cookieClosedNavbarNotifications } from 'libs/shared/functions/src/utils/createNavbarNotification'
import { animated, useSpring } from '@react-spring/web'
import { getCookie, setCookie } from '@dataplace.ai/functions/utils'
import { emails } from '@dataplace.ai/constants'
import { ReactComponent as CloseIcon } from 'libs/shared/assets/src/lib/icons/dataplace/add.svg'
import { ReactComponent as ErrorIcon } from 'libs/shared/assets/src/lib/icons/errorNotification.svg'

const Wrapper = styled(animated.div)<{top: number, error?: boolean}>(({
  theme: { palette }, top, error,
}) => css`
  color: ${error ? palette.black : palette.blue};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: stretch;
  padding: 1rem 2rem;
  position: absolute;
  top: ${top}px;
  left: 0;
  z-index: 800; // less than navbar
  background: ${error ? palette.extra.orange : palette.extra.yellow};
`)

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TextWrapper = styled.div(({ theme: { typography } }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  >svg{
    margin-right: 16px;
  }

  > p {
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    margin-right: 1rem;
  }
  > span {
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  }
`)

const TextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`

interface INavbarNotificationProps {
  top?: number
}

export const NavbarNotification: React.FC<INavbarNotificationProps> = ({ top }): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [navbarNotifications, setNavbarNotifications] = useState<INavbarNotification[] | null>(null)
  const [newestNotification, setNewestNotification] = useState<INavbarNotification | null>(null)

  useEffect(() => {
    if (navbarNotifications && Array.isArray(navbarNotifications) && navbarNotifications.length) {
      setNewestNotification(navbarNotifications[navbarNotifications.length - 1])
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [navbarNotifications])

  const getNotifications = (): INavbarNotification[] | null => {
    const notificationsJSON = sessionStorage.getItem(sessionStorageNavbarNotifications)
    if (notificationsJSON) {
      try {
        return JSON.parse(notificationsJSON)
      } catch (error) {
        return null
      }
    }
    return null
  }

  const deleteNotification = (id: string) => {
    const notifications = getNotifications()
    if (notifications) {
      const updatedNotifications = notifications.filter((notification: INavbarNotification) => notification.id !== id)
      setNavbarNotifications(updatedNotifications)
      sessionStorage.setItem(sessionStorageNavbarNotifications, JSON.stringify(updatedNotifications))
      const closedNotifications = getCookie(cookieClosedNavbarNotifications)
      const newClosedNotifications = closedNotifications ? [...closedNotifications, id] : [id]
      setCookie(cookieClosedNavbarNotifications, newClosedNotifications)
      setOpen(false)
    }
  }

  const updateNotifications = () => {
    let notifications = getNotifications()
    const closedNotifications = getCookie(cookieClosedNavbarNotifications)
    if (notifications) {
      notifications.forEach(({
        timeout, id,
      }: INavbarNotification) => {
        if (timeout) {
          setTimeout(() => deleteNotification(id), timeout)
        }
      })
      if (closedNotifications) {
        notifications = notifications.filter(notification => !closedNotifications.includes(notification.id))
      }
      setNavbarNotifications(notifications)
    }
  }

  useEffect(() => {
    updateNotifications()
    window.addEventListener(navbarNotificationsEventName, updateNotifications)

    return () => window.removeEventListener(navbarNotificationsEventName, updateNotifications)
  }, [])

  const style = useSpring({
    transform: open ? 'translateY(0%)' : 'translateY(-200%)',
    opacity: open ? 1 : 0,
    config: {
      mass: 2,
      clamp: true,
    },
  })
  return newestNotification
    ? (
      <Wrapper
        error={newestNotification?.error}
        style={style}
        top={top || -9999}
      >
        <TextWrapper>
          {newestNotification?.error && <ErrorIcon />}
          <p>
            {newestNotification?.translate
              ? t(newestNotification?.text)
              : newestNotification?.text}

          </p>
          {newestNotification?.subText && (
            <span>
              {newestNotification?.translate
                ? t(newestNotification?.subText)
                : newestNotification?.subText}
            </span>
          )}
          {newestNotification?.showArrangeMeetingButton
          && (
            <TextButtonWrapper>
              <TextButton>
                <a
                  href='https://meetings.hubspot.com/bartosz-bukalo'
                  rel='noreferrer'
                  target='_blank'
                >
                  {t('placeme.arrange_meeting')}
                </a>
              </TextButton>
            </TextButtonWrapper>
          )}
        </TextWrapper>
        <ButtonsWrapper>
          {newestNotification?.showContactButton && (
            <Button
              href={`mailto:${emails.customer_service}`}
              style={{
                marginRight: '8px',
              }}
            >
              {t('generic.contact_us')}
            </Button>
          )}
          {newestNotification?.showCloseButton && !newestNotification?.xButton && (
            <TextButton
              onClick={() => {
                setOpen(false)
                // set local storage tag for not showing Notification
                if (newestNotification?.id.includes('placeme_white_started')) {
                  window?.localStorage?.setItem('whiteDeleted', 'true')
                }
                deleteNotification(newestNotification?.id)
              }}
            >
              {t('generic.got_it')}
            </TextButton>
          )}
          {newestNotification?.xButton && (
            <CloseIcon
              height='18'
              onClick={() => {
                setOpen(false)
                // set local storage tag for not showing Notification
                if (newestNotification?.id.includes('placeme_trial_ended')) {
                  window?.localStorage?.setItem('whiteDeleted', 'true')
                }
                deleteNotification(newestNotification?.id)
              }}
              style={{
                transform:'rotate(45deg)',
                cursor: 'pointer',
              }}
              width='18'
            />
          )}
        </ButtonsWrapper>
      </Wrapper>
    )
    : <></>
}
