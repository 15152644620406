import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types.d'
import { useTranslation } from 'react-i18next'
import { Button } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as FilterIcon } from 'libs/shared/assets/src/lib/icons/filter.svg'
import { ReactComponent as CloseIcon } from 'libs/shared/assets/src/lib/icons/closeIconBlue.svg'
import { filterText, filterByDate, filterByNumber, validateDateField, filterTextByArray } from '@dataplace.ai/functions/utils/filtering'
import { isNumber, parseNumber } from '@dataplace.ai/functions/utils'
import { TextFilter, DateFilter, IDateFilter, ITextFilter, INumberFilter, NumberFilter, PeopleFilter, IPeopleFilter, TileFilter, ITileFilter } from '../../atoms'
import { ITableFilter, ITableFilterObject, ITableFilterProps } from '../../@types'

const Container = styled.div(({ theme }) => {
  const {
    corners, palette,
  } = theme
  return css`
    width: min-content;
    min-width: 396px;
    padding: 20px;
    background: ${palette.light.white};
    border: 2px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius} ${corners.default.borderRadius} 0 0;
    box-sizing: border-box;

    > div:last-child {
      padding-bottom: 0;
    }
  `
})

const HeaderWrapper = styled.div(() => css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  > svg {
    cursor: pointer;
  }
`)

const MainHeader = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};
  `
})

const ControlsWrapper = styled(Container)`
  background: ${props => props.theme.palette.light.main};
  border-radius: 0 0 ${props => props.theme.corners.default.borderRadius} ${props => props.theme.corners.default.borderRadius};
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
`

const FilterCountWrapper = styled.div(() => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 2;
`)

const FilterCount = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
  color: ${palette.black};
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
  `
})

const CancelButton = styled(Button)`
  color: ${({ theme: { palette } }) => palette.blue};
  background: transparent;
  margin-right: 10px;
  border: none;

  :hover {
    background: transparent;
  }
  :focus {
    background: transparent;
  }
  :active {
    border: none;
  }
`

export const TextButton = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    cursor: pointer;
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})

const FilterPopupButton = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    padding: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > span {
      margin-left: 8px;
      font-size: ${typography.small.pt_13_medium.fontSize};
    }
  `
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ActiveFilter = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: ${palette.light.white};
  background: ${palette.blue};
  font-size: ${typography.small.pt_13_semibold.fontSize};
  width: min-content;
  padding: .2rem .6rem;
  margin-left: 1rem;

  > span:first-of-type {
    text-transform: capitalize;
    white-space: nowrap;
  }

  > span:last-of-type {
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    margin-left: .5rem;
    white-space: nowrap;
  }

  > svg {
    cursor: pointer;
    margin-left: .4rem;
    padding: .1rem;
    path {
      stroke: ${palette.light.white};
    }
  }
`)

const NoInputAutoFocus = styled.input`
  display: none;
`

const emptyTableFilter: ITableFilter = {}

export const TableFilter = ({
  headers, setData, data,
}: ITableFilterProps): JSX.Element => {
  const { t } = useTranslation()
  const popupRef = useRef<PopupActions>(null)
  const closePopup = () => popupRef?.current?.close()
  const [allFieldsValid, setAllFieldsValid] = useState(true)
  const [activeFilters, setActiveFilters] = useState(0)
  const [filter, setFilter] = useState(emptyTableFilter)
  const [tempFilter, setTempFilter] = useState(emptyTableFilter)
  const [minDateRange, setMinDateRange] = useState<null | Date>(null)
  const [maxDateRange, setMaxDateRange] = useState<null | Date>(null)

  const filters = headers.map(header => {
    if (header.filter?.enabled) {
      switch (header.type) {
        case 'date':
          return (
            <DateFilter
              key={header.name}
              clearable={header.filter.clearable}
              filter={tempFilter as IDateFilter}
              header={header}
              maxDate={maxDateRange}
              minDate={minDateRange}
              setFilterValue={setTempFilter}
            />
          )
        case 'text':
          return (
            <TextFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              filter={tempFilter as ITextFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'number':
          return (
            <NumberFilter
              key={header.name}
              clearable={header.filter.clearable}
              filter={tempFilter as INumberFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'person':
          return (
            <PeopleFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter as IPeopleFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'tile':
          return (
            <TileFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter as ITileFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        default:
          return null
      }
    }
    return null
  })

  useEffect(() => {
    filterData()
  }, [data, filter])

  useEffect(() => {
    setAllFieldsValid(validateFields())
    countActiveFilters()
  }, [tempFilter])

  const filteringFunctions = {
    date: filterByDate,
    text: filterText,
    number: filterByNumber,
  }

  const limitDates = () => {
    const dateRange = data.map(item => item.date).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    if (dateRange.length) {
      setMinDateRange(new Date(new Date(dateRange[0]).setHours(0, 0, 0, 0)))
      setMaxDateRange(new Date(new Date(dateRange[dateRange.length - 1]).setHours(0, 0, 0, 0)))
    }
  }

  useEffect(() => {
    limitDates()
  }, [])

  const validateFields = () => {
    const outcomes = []
    for (const field in tempFilter) {
      const {
        type, rule,
      } = tempFilter[field]
      if (typeof rule !== 'string' && !Array.isArray(rule)) {
        switch (type) {
          case 'date':
            outcomes.push(validateDateField(rule.from, minDateRange, maxDateRange))
            outcomes.push(validateDateField(rule.to, minDateRange, maxDateRange))
            break
          case 'number':
            outcomes.push(isNumber(+(parseNumber(rule.from))))
            outcomes.push(isNumber(+(parseNumber(rule.to))))
            break
          default:
            //
        }
      }
    }
    return !outcomes.includes(false)
  }

  const filterData = () => {
    if (!Object.keys(filter).length) {
      setData(data)
      return
    }
    if (!validateFields()) { return }
    const newData = [...data].filter(row => {
      for (const field in filter) {
        if (row[field]) {
          const {
            type, rule,
          } = filter[field]
          const filteringFunction = filteringFunctions[type as keyof typeof filteringFunctions]
          if (type === 'person' && Array.isArray(rule)) {
            const nameIncludes = filterTextByArray(row[field].firstAndLastName, rule)
            const emailIncludes = filterTextByArray(row[field].email, rule)
            if (!nameIncludes && !emailIncludes) { return false }
          } else if (Array.isArray(rule)) {
            if (!filterTextByArray(row[field], rule)) { return false }
          } else if (!Array.isArray(rule)) {
            if (!filteringFunction(row[field], rule)) { return false }
          }
        }
      }
      return true
    })
    setData(newData)
    setFilter(tempFilter)
  }

  const applyFiltersAndClose = () => {
    countActiveFilters()
    setFilter(tempFilter)
    closePopup()
  }

  const restorePrevState = () => setTempFilter({
    ...filter,
  })

  const handleCancelClick = () => {
    restorePrevState()
    closePopup()
  }

  const clearAllFilters = () => setTempFilter(emptyTableFilter)
  const countActiveFilters = () => {
    setActiveFilters(Object.values(tempFilter).reduce((acc, curr) =>
      (Array.isArray(curr.rule) ? acc + curr.rule.length : acc + 1), 0))
  }

  const getActiveFilterValue = (filter: ITableFilterObject) => {
    if (typeof filter.rule === 'string') {
      return filter.rule
    }
    if (Array.isArray(filter.rule)) {
      if (filter.rule.length > 1) { return `${filter.rule[0]}, +${filter.rule.length - 1}` }
      return filter.rule[0]
    }
    if (!filter.rule.from) { return `${t('generic.range.to')} ${filter.rule.to}` }
    if (!filter.rule.to) { return `${t('generic.range.from')} ${filter.rule.from}` }
    if (filter.rule.from === filter.rule.to) { return filter.rule.from }
    return `${t('generic.range.from')} ${filter.rule.from} ${t('generic.range.to')} ${filter.rule.to}`
  }

  const deleteActiveFilter = (name: string) => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[name]
    setFilter(newFilter)
    setTempFilter(newFilter)
  }

  return (
    <Wrapper>
      <Popup
        ref={popupRef}
        onOpen={restorePrevState}
        position='bottom left'
        trigger={(
          <FilterPopupButton>
            <FilterIcon />
            <span>{t('generic.filter')}</span>
          </FilterPopupButton>
        )}
      >
        <Container>
          <HeaderWrapper>
            <MainHeader>{t('generic.filters')}</MainHeader>
            <CloseIcon onClick={handleCancelClick} />
          </HeaderWrapper>
          <NoInputAutoFocus />
          {filters}
        </Container>
        <ControlsWrapper>
          <FilterCountWrapper>
            <FilterCount>{`${t('account.table.filter.selected_filters')}: ${activeFilters}`}</FilterCount>
            <TextButton onClick={clearAllFilters}>{t('account.table.filter.clear_all')}</TextButton>
          </FilterCountWrapper>
          <CancelButton onClick={handleCancelClick}>{t('generic.cancel')}</CancelButton>
          <Button
            disabled={!allFieldsValid}
            onClick={applyFiltersAndClose}
          >
            {t('generic.apply')}
          </Button>
        </ControlsWrapper>
      </Popup>
      {Object.entries(filter).map(([key, filter]) => (
        <ActiveFilter key={key}>
          <span>
            {t(headers.find(header => header.name === key)?.label || 'generic.filter')}
            :
          </span>
          <span>
            {getActiveFilterValue(filter)}
          </span>
          <CloseIcon
            height='13px'
            onClick={() => deleteActiveFilter(key)}
            width='13px'
          />
        </ActiveFilter>
      ))}
    </Wrapper>
  )
}
