import { getCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { Button } from '@dataplace.ai/ui-components/atoms'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ReactComponent as CookieIcon } from '../../../../assets/src/lib/icons/cookieIcon.svg'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    align-items: center;
    background-color: ${palette.light.white};
    bottom: 0;
    padding: 30px 20px;
    color: #001d62;
    display: flex;
    flex-wrap: wrap;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    justify-content: space-between;
    left: 0;
    position: fixed;
    width: calc(100% - 40px);
    z-index: 999;
`
})

const LeftWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;

    >svg{
        margin-right: 16px;
    }
    
    >a{
        color: ${palette.blue};
        font-weight: 800;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 5px;
    }
`
})

const RightWrapper = styled.div`
    display: flex;
    margin-right: 100px;
`

export const cookiesAccepted = 'cookiesAccepted'

export const CookiesBar = () :JSX.Element => {
  const { t } = useTranslation()
  const [display, setDisplay] = useState(false)

  const getLanguage = () => {
    const language = getCookieWithoutJSON('language')
    if (language) {
      if (language !== 'pl') return 'en-pl'
    }
    return 'pl'
  }

  const setCookiesAccepted = () => {
    window?.localStorage?.setItem(cookiesAccepted, 'true')
    window.dispatchEvent(new CustomEvent(cookiesAccepted))
  }

  const handleCookieAccepted = () => {
    if (window?.localStorage?.getItem(cookiesAccepted) === 'true') setDisplay(false)
    else setDisplay(true)
  }

  useEffect(() => {
    // detect updating cookies accepted
    window.addEventListener(cookiesAccepted, handleCookieAccepted)
    // remove listener
    return () => window.removeEventListener(cookiesAccepted, handleCookieAccepted)
  }, [])

  useEffect(() => {
    handleCookieAccepted()
  }, [])

  return (
    <>
      { display
    && (
      <Wrapper>
        <LeftWrapper>
          <CookieIcon />
          {t('generic.cookies_bar.text')}
          <a href={`https://dataplace.ai/${getLanguage()}/privacy-policy/`}>{t('generic.cookies.privacy_policy')}</a>
        </LeftWrapper>
        <RightWrapper><Button onClick={setCookiesAccepted}>{t('generic.agree')}</Button></RightWrapper>
      </Wrapper>
    )}
    </>
  )
}
