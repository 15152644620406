export interface INavbarNotification {
  id: string // this should be constant for objects of same type to prevent spamming
  text: string
  subText?: string
  timeout?: number
  showCloseButton?: boolean
  translate?: boolean
  showContactButton?: boolean
  xButton?: boolean
  showArrangeMeetingButton?: boolean
  error?: boolean
}

export const sessionStorageNavbarNotifications = 'navbarNotifications'
export const cookieClosedNavbarNotifications = 'closedNavbarNotifications'

export const navbarNotificationsEventName = 'navbarNotificationsUpdate'

export const createNavbarNotification = (newNotificationObject: INavbarNotification): void => {
  if (!newNotificationObject) { return }
  let updatedNotifications = [] as INavbarNotification[]
  const { navbarNotifications } = sessionStorage
  if (navbarNotifications) {
    try {
      const parsedMessages = JSON.parse(navbarNotifications)
      updatedNotifications = [...parsedMessages]
    }
    catch (error) {
    //
    }
  }
  const newNotification = {
    ...newNotificationObject,
    timeout: newNotificationObject.timeout || 0,
    showCloseButton: newNotificationObject.showCloseButton === undefined ? true : newNotificationObject.showCloseButton,
    showContactButton:  newNotificationObject.showContactButton === undefined
      ? true
      : newNotificationObject.showContactButton,
    translate: newNotificationObject.translate === undefined ? true : newNotificationObject.translate,
  }
  const notificationIndex = updatedNotifications.findIndex(notification => notification.id === newNotification.id)
  if (notificationIndex === -1) {
    updatedNotifications = [...updatedNotifications, newNotification]
  } else {
    updatedNotifications[notificationIndex] = newNotification
  }
  sessionStorage.navbarNotifications = JSON.stringify(updatedNotifications)
  window.dispatchEvent(new CustomEvent(navbarNotificationsEventName))
}
