import { formatNumber } from '@dataplace.ai/functions/utils'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { RootState } from 'apps/placeme/src/redux/store'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { IEstimates } from '../../organisms/Tiles/Potential/SalesPotential/@types/ISalesPotentialTileData'

const EstimationsWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    border-bottom: 1px solid #EDF0F5;
    margin-bottom: 30px;

    > div {
      width: 50%;
      padding: 1rem 0;

      > span {
        display: block;
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        font-size: ${typography.main.pt_15_semibold.fontSize};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
        color: ${palette.black};
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        .currency {
          text-transform: none;
          margin-left: 5px;
        }

        > strong {
          font-weight: ${typography.big.pt_28_semibold.fontWeight};
          font-size: ${typography.big.pt_28_semibold.fontSize};
          line-height: ${typography.big.pt_28_semibold.lineHeight};
          color: ${palette.black};
          margin-left: 0.5rem;
        }
      }
    }
  `
})

export const Estimations: React.FC<{translation: string, val: IEstimates, valueCompared?:IEstimates}> = ({
  translation, val, valueCompared,
}) => {
  const { t } = useTranslation()
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  return (
    <div>
      <h4>{t(`placeme.sales_potential_tile.estimated_${translation}`)}</h4>
      <span>
        {t('generic.daily')}
        {' '}
        <strong>{formatNumber(val?.daily)}</strong>
        {' '}
        {translation === 'income' && <span className='currency'>zł</span>}
        {valueCompared?.daily
        && (
          <ComparedLocationBean
            comparedAddress={comparedLocation?.location?.address}
            comparedValue={formatNumber(valueCompared?.daily)}
            difference={valueCompared?.dailyDiff}
            mainAddress={value?.address}
            mainValue={formatNumber(val?.daily)}
            style={{
              marginLeft: '8px',
            }}
          />
        )}
      </span>
      <span>
        {t('generic.yearly')}
        {' '}
        <strong>{formatNumber(val?.yearly)}</strong>
        {' '}
        {translation === 'income' && <span className='currency'>zł</span>}
        {valueCompared?.yearly
        && (
          <ComparedLocationBean
            comparedAddress={comparedLocation?.location?.address}
            comparedValue={formatNumber(valueCompared?.yearly)}
            difference={valueCompared?.yearlyDiff}
            mainAddress={value?.address}
            mainValue={formatNumber(val?.yearly)}
            style={{
              marginLeft: '8px',
            }}
          />
        )}
      </span>
    </div>
  )
}

export const EstimationsBox: React.FC<{
  value1: IEstimates, value2: IEstimates, value1Compared?: IEstimates, value2Compared?: IEstimates
}> = ({
  value1, value2, value1Compared, value2Compared,
}) => (
  <EstimationsWrapper>
    <Estimations
      translation='transactions'
      val={value1}
      valueCompared={value1Compared}
    />
    <Estimations
      translation='income'
      val={value2}
      valueCompared={value2Compared}
    />
  </EstimationsWrapper>
)
