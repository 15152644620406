import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ButtonProduct, CloseIcon, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { RootState } from 'apps/placeme/src/redux/store'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { saveIsMaxTilesNumberExceeded } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'

const Wrapper = styled.div(({
  theme: {
    palette, typography, corners,
  },
}) => css`
    max-width: 50vw;
    max-height: 78vh;
    padding: 2rem;
    overflow: hidden;
    background-color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
  
    .header {
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }
  
    .header_wrapper {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
    }
  
    .description {
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }
  
    .content {
      padding-bottom: 2rem;
    }
  `)

const Footer = styled.div(
  () => css`
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
    `,
)

export const ExceededNumberOfTilesModal = () :(JSX.Element | null) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // state
  const {
    isMaxTilesNumberExceeded, values,
  } = useSelector((state: RootState) => state.analysis)

  const handleDemoModalClose = useCallback(() => {
    dispatch(saveIsMaxTilesNumberExceeded(false))
  }, [])

  const isAnyTileWithoutData = useMemo(() => !!values?.map(item => item?.tiles)?.flat()
    .find(item => !item?.data), [values])

  if (!isMaxTilesNumberExceeded) { return null }

  return (
    <PopupWithOverlay
      onClose={handleDemoModalClose}
      open={isMaxTilesNumberExceeded}
    >
      <Wrapper>
        <div className='header_wrapper'>
          <span className='header'>
            {t('placeme.exceeded_tiles_number.header')}
          </span>
          <CloseIcon onClick={handleDemoModalClose} />
        </div>
        <div className='content'>
          <span className='description'>
            {isAnyTileWithoutData && t('placeme.exceeded_tiles_number.desc_1')}
            {t('placeme.exceeded_tiles_number.desc_2')}
          </span>
        </div>
        <Footer>
          <ButtonProduct
            onClick={handleDemoModalClose}
            small
          >
            {t('generic.ok')}
          </ButtonProduct>
        </Footer>
      </Wrapper>
    </PopupWithOverlay>
  )
}

