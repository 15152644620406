export const customerOriginTableData = {
  labels: [
    'placeme.customer_origin_tile.table_label_1',
    'placeme.customer_origin_tile.table_label_2',
    'placeme.customer_origin_tile.table_label_3',
  ],
}

export const dummy = {
  sources: {
    catchmentId: 'e6c0a987-7e6d-476a-9dba-aa09cf869311',
    geoJSON: {
      coordinates: [
        [
          [
            21.23037099838257,
            52.134357333592725,
          ],
          [
            21.233396530151367,
            52.13604318647317,
          ],
          [
            21.23431921005249,
            52.134897340288525,
          ],
          [
            21.236164569854736,
            52.135858799421804,
          ],
        ],
        [
          [
            21.233632564544678,
            52.13304021663755,
          ],
          [
            21.23509168624878,
            52.13476563194192,
          ],
          [
            21.236743927001953,
            52.134910511101765,
          ],
        ],
      ],
      type:'MultiLineString',
    },
  },
  statistics: {
    averageDistance: 5.97,
    maxDistance: 24.1,
    top5: [{
      address: 'Centrum Medyczne ALFA, 25, Henryka Sienkiewicza, Gdynia, Micha\u0142owo, Brodnica, powiat brodnicki, wojew\u00f3dztwo kujawsko-pomorskie, 87-300, Polska',
      distance: 366.4,
      lat: 52.134982,
      lng: 21.233709,
      pointId: 1,
    }, {
      address: 'Szpital Powiatowy, 15, Grunwald Trzeci, Brodnica, powiat brodnicki, wojew\u00f3dztwo kujawsko-pomorskie, 87-300, Polska',
      distance: 316.2,
      lat: 52.136713,
      lng: 21.234514,
      pointId: 2,
    }, {
      address: 'Dekada, 1, S\u0105dowa, Gdynia, Micha\u0142owo, Brodnica, powiat brodnicki, wojew\u00f3dztwo kujawsko-pomorskie, 87-300, Polska',
      distance: 145.9,
      lat: 53.25965881347656,
      lng: 19.397048950195312,
      pointId: 3,
    }, {
      address: '31, Du\u017cy Rynek, Stare Miasto, Micha\u0142owo, Brodnica, powiat brodnicki, wojew\u00f3dztwo kujawsko-pomorskie, 87-300, Polska',
      distance: 773.0,
      lat: 53.25553894042969,
      lng: 19.402542114257812,
      pointId: 4,
    }, {
      address: '1, Genera\u0142a J\u00f3zefa Wybickiego, Gdynia, Micha\u0142owo, Brodnica, powiat brodnicki, wojew\u00f3dztwo kujawsko-pomorskie, 87-300, Polska',
      distance: 1098.6,
      lat: 53.25416564941406,
      lng: 19.398422241210938,
      pointId: 5,
    }],
  },
}
