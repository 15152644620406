/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'

const Trigger = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
)

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 360px;
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const Footer = styled.div(
  () => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  `,
)

export interface ITilesWithSameRangeAndType {
  label: string
  amount: number
}

export interface ISameRangeForSameTile {
  trigger: JSX.Element
  tilesWithSameRangeAndType: ITilesWithSameRangeAndType[]
}

export const SameRangeForSameTile = ({
  trigger, tilesWithSameRangeAndType,
}: ISameRangeForSameTile): JSX.Element => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)
  return (
    <Popup
      modal
      onClose={closeModal}
      onOpen={() => {
        setOpen(true)
      }}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >
      <Wrapper>
        <Header>{t('placeme.template.same_range_for_same_tile.header')}</Header>
        <Content>
          {t('placeme.template.same_range_for_same_tile.desc')}
          {' '}
          {tilesWithSameRangeAndType?.map((item, index) => (
            <span key={item.label}>
              {' '}
              <b>{t(item.label)}</b>
              {' - '}
              {' '}
              {item.amount}
              {' '}
              {t('generic.analyzes')}
              {index === tilesWithSameRangeAndType?.length - 1 ? '. ' : ','}
            </span>
          ))}
        </Content>
        <Footer>
          <ButtonProduct
            onClick={closeModal}
            small
          >
            {t('generic.ok')}
          </ButtonProduct>
        </Footer>
      </Wrapper>

    </Popup>
  )
}
