import React from 'react'
import styled, { css } from 'styled-components'
import { mediaMax as media } from '@dataplace.ai/ui-components/themes'
import { Icon, Link } from '@dataplace.ai/ui-components/atoms'
import { emails } from '@dataplace.ai/constants'
import { useTranslation } from 'react-i18next'

const SupportWidgetWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    justify-content: center;
    border-left: solid 1px ${palette.light.darkest};
  `
})

const InfoWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.dark.normal};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};

    ${media.mobile`
      font-size: 10px;
    `}

    img {
      margin-right: 0.375rem;
    }
  `
})

const StyledLink = styled(Link)(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.blue};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};
    margin-left: 22px;

    :hover {
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
    }

    ${media.mobile`
      font-size: 10px;
    `}
  `
})

export const SupportWidget: React.FC = () => {
  const { t } = useTranslation()
  return (
    <SupportWidgetWrapper>
      <InfoWrapper>
        <Icon
          alt='Support'
          height='1rem'
          src='assets/icons/lifebelt.svg'
          width='1rem'
        />
        <span>{t('generic.do_you_need_help')}</span>
      </InfoWrapper>
      <StyledLink href={`mailto:${emails.customer_service}`}>{t('generic.contact_us')}</StyledLink>
    </SupportWidgetWrapper>
  )
}
