import React from 'react'
import styled, { css } from 'styled-components'

interface ICheckboxWrapper {
  error: boolean
}

const RadioWrapper = styled.div<ICheckboxWrapper>(({
  theme, error,
}) => {
  const { palette } = theme

  return css`
    display: flex;
    position: relative;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 11px;

    > input[type='radio'] {
      z-index: 1;
      opacity: 0;
      cursor: pointer;
    }

    > input[type='radio']:disabled {
      cursor: default;
    }

    > input[type='radio'],
    > input[type='radio'] + div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > input[type='radio']:checked + div {
      background-image: url('assets/icons/radio-button-on.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 140%;
      border: 1px solid ${palette.results.green.dark};
    }

    > input[type='radio'] + div {
      z-index: 0;
      background-color: ${palette.light.white};
      border: 1px solid ${palette.blue};
      box-sizing: border-box;
      border-radius: 1rem;
    }

    > input[type='radio']:hover + div {
      background-color: ${palette.light.white};
      border: 2px solid ${palette.dark.main};
    }

    > input[type='radio']:checked:hover + div {
      background-color: ${palette.light.white};
      border: 1px solid ${palette.results.green.dark};
    }

    > input[type='radio']:focus:checked + div {
      background-color: ${palette.results.green.opaque};
      border: 1px solid ${palette.results.green.dark};
    }

    > input[type='radio']:focus + div {
      border: 2px solid ${palette.blue};
    }

    > input[type='radio']:checked + div {
    > input[type='radio']:active + div {
      background-color: ${palette.results.green.dark};
      border: 1px solid ${palette.results.green.dark};
    }

    ${error && css`
      > input[type='radio'] + div {
        border: 1px solid ${palette.results.red.dark};
      }
    `}
  `
})

const ErrorSpan = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.results.red.dark};
    position: relative;
    top: 1.2rem;
    margin-left: 2rem;
    white-space: nowrap;
  `
})

interface IRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string
}

export const RadioButton: React.FC<IRadioButtonProps> = ({
  error, ...restProps
}) => (
  <RadioWrapper error={!!error}>
    <input
      {...restProps}
      type='radio'
    />
    <div />
    {error && (
      <ErrorSpan>
        {error}
      </ErrorSpan>
    )}
  </RadioWrapper>
)
