import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IHubspotModalProps } from './@types/IHubspotModal.props'
import { IModalProps } from './@types/IModal.props'

const Modal = styled.div<IModalProps>(
  ({
    show, top, left,
  }) => css`
    width: 750px;
    background: white;
    position: fixed;
    top: ${top || '10vh'};
    left: ${left || '25vw'};
    z-index: ${show ? '2000' : '-2000'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    display: flex;

    span {
      display: inline-block;
      position: relative;
      left: 45%;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      padding-top: 1em;

      :hover {
        opacity: 1;
      }

      ::before,
      ::after {
        position: absolute;

        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
      }

      ::before {
        transform: rotate(45deg);
      }

      ::after {
        transform: rotate(-45deg);
      }
    }
  `,
)

export function HubspotMeetingModal({
  show,
  closeMeeting,
  top,
  left,
}: IHubspotModalProps): JSX.Element {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    script.type = 'text/javascript'
    document.body.appendChild(script)
    script.onload = () => {
      setIsScriptLoaded(true)
    }
  }, [])

  return (
    <>
      {isScriptLoaded && (
        <Modal
          left={left}
          show={show}
          top={top}
        >
          <span
            aria-hidden='true'
            onClick={closeMeeting}
          />
          <div>
            <iframe
              height='700'
              src='https://meetings.hubspot.com/aleksandrataraszka?embed=true'
              style={{
                border: 'none',
              }}
              title='hubspot'
              width='700'
            />
          </div>
        </Modal>
      )}
    </>
  )
}
