import React from 'react'
import { useMapEvents } from 'react-leaflet'
import { IMapLocationProps } from '../MapTile'

interface ICurrentMapState {
  setMapLocation: React.Dispatch<React.SetStateAction<IMapLocationProps>>;
}

export const CurrentMapState = ({ setMapLocation }: ICurrentMapState):JSX.Element => {
  const map = useMapEvents({
    drag: () => {
      setMapLocation({
        zoom: map.getZoom(),
        bbox:{
          northEastLat: map.getBounds().getNorthEast().lat,
          northEastLng: map.getBounds().getNorthEast().lng,
          southWestLat: map.getBounds().getSouthWest().lat,
          southWestLng: map.getBounds().getSouthWest().lng,
        },
        center: map.getCenter(),
      })
    },
    zoom: () => {
      setMapLocation({
        zoom: map.getZoom(),
        bbox:{
          northEastLat: map.getBounds().getNorthEast().lat,
          northEastLng: map.getBounds().getNorthEast().lng,
          southWestLat: map.getBounds().getSouthWest().lat,
          southWestLng: map.getBounds().getSouthWest().lng,
        },
        center: map.getCenter(),
      })
    },
  })
  return <></>
}
