import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ReactComponent as LoadFileIcon } from '../../../../assets/src/lib/icons/loadFile.svg'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    border: 2px dashed #EDF0F5;
    border-radius: 6px;
    cursor: pointer;
    padding: 20px 35px 20px 20px;
`

const TextWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    >p{
        color: ${palette.blue};
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    .error{
        color:#F08F7F;
        overflow-wrap: break-word;
    }
    
    >span{
        color:${palette.black};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
    }
`
})

export interface IDropZone {
  accept: string
  handleFileSave: (file: File) => void
}

export const DropZone:React.FC<IDropZone> = ({
  accept, handleFileSave,
}) => {
  const { t } = useTranslation()
  const [error, setError] = useState<string>('')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const dragOver = (e :React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const dragLeave = (e:React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const { files } = e.dataTransfer
    setError('')
    if (accept?.includes(files[0].type)) {
      handleFileSave(files[0])
    }
    else {
      setError('generic.drop_zone.error.wrong_file_type')
    }
  }

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const filesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      handleFileSave(e.target.files[0])
    }
  }
  return (
    <Wrapper
      onClick={handleClick}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={fileDrop}
    >
      <LoadFileIcon />
      <TextWrapper>
        <p>{t('shared.drop_zone.title')}</p>
        <span>{t('shared.drop_zone.desc')}</span>
        {error && <span className='error'>{t(error)}</span>}
      </TextWrapper>
      <input
        ref={fileInputRef}
        accept={accept}
        className='file-input'
        onChange={filesSelected}
        style={{
          display: 'none',
        }}
        type='file'
      />
    </Wrapper>
  )
}
