import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { ResourceWrapper } from '@dataplace.ai/ui-components/atoms'
import { AnalyticsContext } from '@dataplace.ai/features'
import { ResourceWithId } from './@types/ResourceWithId'
import { IResourcesSelectorProps } from './@types/IResourceSelector.props'

type WrapperProps = {
  flexDirection?: 'row' | 'column',
  displayType?: 'flex' | 'grid' | 'inline-grid',
  maxWidth?: string
  alignItems?: 'flex-end'| 'flex-start' |'center'
}
const Wrapper = styled.span<WrapperProps>(({
  flexDirection, displayType, maxWidth, alignItems,
}) => css`
    max-width: ${maxWidth};
    display:  ${displayType || 'flex'};
    flex-direction: ${flexDirection || 'row'};
    align-items: ${alignItems || 'normal'};
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    grid-column-gap: ${(displayType === 'grid' || displayType === 'inline-grid') ? '0.5rem' : '0'};

    > * {
      margin: ${flexDirection === 'column' ? '0.25rem 0' : '0.25rem 0.25rem 0 0'};
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  `)

export const ResourcesSelector: React.FC<IResourcesSelectorProps> = ({
  value = [], resources, multiSelect, noSelect, onChange, flexDirection, displayType, maxWidth, alignItems,
  wrapperType, tile, small,
}) => {
  const { analytics } = useContext(AnalyticsContext)
  const handleAll = () => {
    if (value.length !== resources.length) {
      onChange(resources?.map(resource => resource.id))
    }
    else {
      onChange(noSelect ? [] : [resources?.map(resource => resource.id).find(r => r !== 'all') || resources[0].id])
    }
  }

  const handleMultiSelect = (selected: ResourceWithId, isSelected: boolean) => {
    if (selected.id === 'all') {
      handleAll()
    } else if (isSelected) {
      const newArray = value.filter((resource) => resource !== selected.id && resource !== 'all')
      if (newArray.length || (!newArray.length && noSelect)) {
        onChange(resources?.map(resource => resource.id).filter(resource => newArray?.includes(resource)))
      }
    } else {
      onChange([...value, selected.id])
    }
  }

  const handleSingleSelect = (selected: ResourceWithId, isSelected: boolean) => {
    onChange((noSelect && isSelected) ? [] : [selected.id])
  }

  const handleMultiClick = (selected: ResourceWithId, isSelected: boolean) => {
    if (multiSelect) {
      handleMultiSelect(selected, isSelected)
    } else {
      handleSingleSelect(selected, isSelected)
    }
  }

  return (
    <Wrapper
      alignItems={alignItems}
      displayType={displayType}
      flexDirection={flexDirection}
      maxWidth={maxWidth}
    >
      {resources?.map((resource) => {
        const isSelected = !!value.find((selectedResource) => selectedResource === resource.id)

        return (
          <ResourceWrapper
            key={resource.id}
            onClick={() => {
              analytics?.track('Range Type Selected', {
                range: resource?.id?.split('-')[0],
                tile: tile?.split('-')[0],
              })
              handleMultiClick(resource, isSelected)
            }}
            selected={isSelected}
            small={small}
            type={wrapperType}
          >
            {resource.content}
          </ResourceWrapper>
        )
      })}
    </Wrapper>
  )
}

