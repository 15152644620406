import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SpinnerIcon } from 'libs/shared/assets/src/lib/icons/spinner.svg'
import { IButtonProps } from './@types/IButton.props'

const StyledButton = styled.button<IButtonProps>(({
  theme, small, margin, loading, minWidth,
}) => {
  const {
    palette, corners, typography, shadows,
  } = theme

  return css`
    margin: ${margin};
    color: ${palette.light.white};
    background: ${palette.blue};
    border-radius: ${corners.default.borderRadius};
    outline: none;
    border: 1px solid ${palette.dark.main};
    padding: ${small ? '5px 15px' : '9px 18px'};
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    line-height: ${typography.small.pt_13_semibold.lineHeight};
    transition: all 0.5s;
    min-width: ${minWidth};

    :hover {
      background: ${palette.dark.main};
      box-shadow: ${shadows.hover.boxShadow};
      cursor: pointer;
    }
    :focus {
        background-color: ${palette.dark.lightest};
    }
    :active {
        background-color: ${palette.light.main};
        color: ${palette.blue};
        border: 1px solid #e1e2e3;
    }
    :disabled {
        background-color: ${palette.dark.lightest};
        border: 1px solid #e1e2e3;
        color: white;
        cursor: default;
    }

    ${loading && `
      display: grid;
      grid-template-columns: auto 1.3rem;
      align-items: center;

      :disabled {
        background-color: ${palette.light};
      }

      > span {
        margin-left: 2.6rem;
        margin-right: 1.3rem;
      }
    `}
  `
})

const Spinner = styled(SpinnerIcon)`
  width: 1.3rem;
  height: 1.3rem;
  opacity: .5;

  animation: rotation 0.8s infinite linear;
  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
  }
`

export const Button: React.FC<IButtonProps> = ({
  children, onClick, as, to, loading, loadingText, href, target, style, ...restProps
}) => {
  const { t } = useTranslation()

  const buttonComp = (
    <StyledButton
      style={style}
      {...restProps}
      disabled={restProps?.disabled || !!loading}
      loading={loading ? 1 : 0}
      onClick={onClick}
    >
      {loading
        ? (
          <>
            <span>{loadingText || t('generic.loading')}</span>
            <Spinner />
          </>
        )
        : children}
    </StyledButton>
  )

  return (
    <>
      {as === 'navLink' && to
        ? (
          <NavLink
            to={to}
          >
            {buttonComp}
          </NavLink>
        )
        : (href
          ? (
            <a
              href={href}
              target={target}
            >
              {buttonComp}
            </a>
          )
          : (
            <>
              {buttonComp}
            </>
          ))}
    </>
  )
}

Button.defaultProps = {
  loading: false,
}
