/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import styled, { css } from 'styled-components'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { spendingTableData } from './data'
import { ISpendingRow, ISpendingTileData } from './@types/ISpendingTileData'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const CategorySpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    line-height: ${typography.small.pt_13_semibold.lineHeight};

  `
})

const RegularSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    text-align: right;

    .comparison{
      margin-left: 5px;
    }
    
  `
})

export const SpendingTile: React.FC<{data: ISpendingTileData, }> = ({ data }) => {
  const { t } = useTranslation()
  const isMobileDevice = useCheckForMobileDevice()
  const { labels } = spendingTableData
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  const currency = data?.value?.currency

  const content = Array.isArray(data?.value?.spending)
    ? data?.value?.comparedLocation?.spending
      ? (data?.value?.spending?.map((val: ISpendingRow) => (
        [
          <CategorySpan key={val.categoryName}>{t(`spending.category.${val.categoryName.split(' ').join('_')}`)}</CategorySpan>,
          <RegularSpan
            key={val.perCapita}
          >
            {formatNumber(val.perCapita)}
            <ComparedLocationBean
              className='comparison'
              comparedAddress={comparedLocation?.location?.address}
              comparedValue={formatNumber(data?.value?.comparedLocation?.spending?.find(item =>
                item?.categoryName === val?.categoryName)?.perCapita || '')}
              difference={data?.value?.comparedLocation?.spending?.find(item =>
                item?.categoryName === val?.categoryName)?.perCapitaDiff}
              mainAddress={value?.address}
              mainValue={formatNumber(val.perCapita)}
              width='64.72px'
            />
          </RegularSpan>,
          <RegularSpan key={val.catchmentSum}>
            {formatNumber(val.catchmentSum)}
            <ComparedLocationBean
              className='comparison'
              comparedAddress={comparedLocation?.location?.address}
              comparedValue={formatNumber(data?.value?.comparedLocation?.spending?.find(item =>
                item?.categoryName === val?.categoryName)?.catchmentSum || '')}
              difference={data?.value?.comparedLocation?.spending?.find(item =>
                item?.categoryName === val?.categoryName)?.catchmentSumDiff}
              mainAddress={value?.address}
              mainValue={formatNumber(val.catchmentSum)}
              width='64.72px'
            />
          </RegularSpan>,
        ]
      ))
      )
      : (data?.value?.spending?.map((value: ISpendingRow) => (
        [
          <CategorySpan key={value.categoryName}>{t(`spending.category.${value.categoryName.split(' ').join('_')}`)}</CategorySpan>,
          <RegularSpan key={value.perCapita}>{formatNumber(value.perCapita)}</RegularSpan>,
          <RegularSpan key={value.catchmentSum}>{formatNumber(value.catchmentSum)}</RegularSpan>,
        ]
      ))
      )
    : []

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Table
          buildings
          content={content}
          gap={!isMobileDevice ? '1rem' : '0.5rem'}
          headerTemplate={!isMobileDevice ? '2fr 2fr 2fr' : '2fr 2fr 2fr'}
          labels={labels.map(label => (label === 'placeme.spending_tile.table_label_2' || label === 'placeme.spending_tile.table_label_3'
            ? <span key={label}>{`${t(label)}[${currency || 'PLN'}]`}</span>
            : <span key={label}>{t(label)}</span>))}
          rowTemplate={!isMobileDevice ? '2fr 2fr 2fr' : '2fr 2fr 2fr'}
        />
      </Wrapper>
    )
}
