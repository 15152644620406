import React from 'react'
import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { ReactComponent as Bulb } from 'libs/shared/assets/src/lib/icons/bulb.svg'
import { ReactComponent as ArrLeft } from 'libs/shared/assets/src/lib/icons/arrows/arrLeft.svg'
import { ReactComponent as ArrRight } from 'libs/shared/assets/src/lib/icons/arrows/arrRight.svg'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0.5rem;
    border-bottom: 1px solid ${palette.light.darker};
  `
})

const Header = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    padding: 0 0 1rem 0;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};

    > span {
      margin-left: 5px;

      @media (max-width: 1200px){
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
      }
    }
    
    > svg{
        @media (max-width:1200px){
          width: 18px;
          height:18px;
        }
    
    }
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 1rem;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};

    > strong {
      margin-right: 0.25rem;
    }
  `
})

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  > svg {
    margin-left: .5rem;
    cursor: pointer;

    @media (max-width:1200px){
      width: 12px;
      height: 12px;
      margin-left: .3rem;
    }
  }
`

interface IHintProps {
  content: string,
  example: string,
  prevPage?(): void
  nextPage?(): void
  pagesAmount: number
}

export const Hint: React.FC<IHintProps> = ({
  content, example, prevPage, nextPage, pagesAmount,
}): JSX.Element => {
  const { t } = useTranslation()
  const style = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      duration: 300,
    },
  })

  const iconLeft = () => <ArrLeft onClick={prevPage && prevPage} />
  const iconRight = () => <ArrRight onClick={nextPage && nextPage} />

  return (
    <Wrapper>
      <Header>
        <Bulb />
        <span>{t('placeme.hint.think_about')}</span>
        { pagesAmount > 1 && (
          <ControlsWrapper>
            {iconLeft()}
            {iconRight()}
          </ControlsWrapper>
        )}
      </Header>
      <animated.div style={style}>
        <ContentWrapper>
          <Content>{t(content)}</Content>
          <Content>
            <strong>{t('placeme.hint.example')}</strong>
            {t(example)}
          </Content>
        </ContentWrapper>
      </animated.div>
    </Wrapper>
  )
}
