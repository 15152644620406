import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WarningModal } from '@dataplace.ai/ui-components/molecules'
import { ReactComponent as Trash } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/trash.svg'
import { RootState } from '../../../../../redux/store'
import { ITransportRow } from '../../organisms/Tiles/AccessLogistics/Transport/@types/ITransportTileData'

const Wrapper = styled.span(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0.25rem 0;
    background: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    > div {
      width: 45%;

      > span:last-child {
        margin-top: 0.25rem;
        color: ${palette.dark.normal};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
      }
    }
  `
})

const Value = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > span:nth-child(1) {
      color: ${palette.black};
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
      margin-right: 0.25rem;
    }

    > span:nth-child(2) {
      margin-bottom: 0.125rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }
  `
})

export interface IChosenLocationTransportTileProps {
  chosenLocalization: ITransportRow
  handleDelete: (localizationId: string) => void
  canBeSave: boolean
}

export const getFormatTime = (time: number): string => {
  const hours = Math.trunc(time / 60)
  const minutes = Math.round(time) % 60
  if (hours) return `${hours} h ${minutes} min`
  return `${minutes} min`
}

export const ChosenLocationTransportTile = ({
  chosenLocalization,
  handleDelete,
  canBeSave,
}: IChosenLocationTransportTileProps): JSX.Element => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const fromAddress = value?.address

  return (
    <Wrapper>
      <Header>
        <span>{chosenLocalization.address}</span>
        { canBeSave && (
          <PopupWithOverlay
            trigger={(
              <IconButton>
                <Trash />
              </IconButton>
            )}
          >
            <>
              {(close: () => void) => (
                <WarningModal
                  handleAgreement={() => {
                    handleDelete(chosenLocalization.id)
                    close()
                  }}
                  handleCancel={close}
                  heading={t('placeme.cannibalization.chosen_location.delete_location.heading')}
                  redWarning={t('placeme.cannibalization.chosen_location.delete_location.red_warning')}
                />
              )}
            </>
          </PopupWithOverlay>
        )}
      </Header>
      <Content>
        <div>
          <Value>
            <span>{chosenLocalization?.distance}</span>
            <span>km</span>
          </Value>
          <span>
            {t('placeme.chosen_localization_transport_tile.distance', {
              fromAddress,
              toAddress: chosenLocalization?.address,
            })}
          </span>
        </div>
        <div>
          <Value>
            <span>{getFormatTime(chosenLocalization?.time)}</span>
          </Value>
          <span>
            {t('placeme.chosen_localization_transport_tile.time', {
              fromAddress,
              toAddress: chosenLocalization?.address,
            })}
          </span>
        </div>
      </Content>
    </Wrapper>
  )
}

