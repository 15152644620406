import React from 'react'
import Popup from 'reactjs-popup'
import { PopupProps } from 'reactjs-popup/dist/types'
import { theme } from '@dataplace.ai/ui-components/themes'

export const PopupWithOverlay: React.FC<PopupProps> = ({
  children, ...restProps
}) => (
  <Popup
    {...restProps}
    overlayStyle={theme().overlay}
  >
    {children}
  </Popup>
)

PopupWithOverlay.defaultProps = {
  modal: true,
}
