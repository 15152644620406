import { getAxios, getResponseDataObject } from '@dataplace.ai/functions/utils'
import { createSlice } from '@reduxjs/toolkit'
import { config } from 'apps/target/src/config'
import { ENDPOINTS } from 'apps/target/src/constants/endpoints'
import { AppThunk } from 'apps/target/src/redux/store'
import { ICatchmentMode, ICategoriesData, ICriteria } from '../utils/categoriesData'
import { IAnalysisTarget, IAnalysisTargetResponse, IAnalysisTargetStepOneData, IAnalysisTargetStepTwoData, ICriteriaImportancy } from './@types/IAnalysisTarget'

const initialState: IAnalysisTarget = {
  analysisTargetDbInfo: null,
  analysisValue: {
    thirdStep: {
      criteriaSettings: [],
    },
  },
}

const analysisTargetSlice = createSlice({
  name: 'analysisTarget',
  initialState,
  reducers: {
    saveAnalysisTargetDbInfo: (state, action) => {
      state.analysisTargetDbInfo = action.payload
    },
    saveAnalysisValue: (state, action) => {
      state.analysisValue = action.payload
    },
    saveCriteriaValues:(state, action) => {
      state.analysisValue.thirdStep.criteriaSettings = action.payload
    },
    saveReportId:(state, action) => {
      state.reportId = action.payload
    },
    saveCriteriaImportancy:(state, action) => {
      state.analysisValue.thirdStep.criteriaImportancy = action.payload
    },
    saveStepData: (state, action) => {
      const oldData = state?.analysisValue?.[action.payload.step as keyof typeof state.analysisValue]
      if (oldData) {
        state.analysisValue = {
          ...state.analysisValue,
          [action.payload.step]: {
            ...oldData,
            ...action.payload.data,
          },
        }
      } else {
        state.analysisValue = {
          ...state.analysisValue,
          [action.payload.step]: action.payload.data,
        }
      }
    },
    saveResult:(state, action) => {
      state.analysisValue.result = action.payload
    },
  },
})

export const {
  saveAnalysisTargetDbInfo, saveAnalysisValue, saveCriteriaValues, saveStepData,
  saveReportId, saveCriteriaImportancy, saveResult,
} = analysisTargetSlice.actions

export const openAnalyse = (
  token: string,
  id: string,
): AppThunk => async (dispatch): Promise<IAnalysisTargetResponse> => {
  let data: IAnalysisTargetResponse

  try {
    const axiosInstance = await getAxios(config.API_URL, token)
    const response = await axiosInstance.get(`${ENDPOINTS.CREATE_ANALYSIS}/${id}`)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  if (data?.value?.data) {
    saveAnalysisValue({
      ...data?.value?.data,
    })
  }
  if (data?.value) {
    const { ...dbData } = data.value
    dispatch(saveAnalysisTargetDbInfo(dbData))
  } else {
    dispatch(saveAnalysisTargetDbInfo(undefined))
  }

  return data
}
export const addCriteriaAction = (
  category: ICategoriesData,
  criteria: ICriteria,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysisTarget } = getState()
  const categoryAlreadyExist = analysisTarget?.analysisValue?.thirdStep?.criteriaSettings?.find((value) =>
    value.criteriaId === category.criteriaId)

  if (categoryAlreadyExist) {
    dispatch(
      saveCriteriaValues(
        analysisTarget?.analysisValue?.thirdStep?.criteriaSettings?.map((value) =>
          (value.criteriaId === category.criteriaId
            ? {
              ...value,
              subcriteria: value.subcriteria.map(sub =>
                (sub.name === criteria.name
                  ? {
                    ...criteria,
                  }
                  : sub)),
            }
            : value
          )),
      ),
    )
  } else {
    dispatch(saveCriteriaValues([
      ...analysisTarget?.analysisValue?.thirdStep?.criteriaSettings,
      category,
    ]))
  }
}

export const deleteCriteriaAction = (
  category: ICategoriesData,
  criteriaToDelete: ICriteria,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysisTarget } = getState()
  const oldCriteria = analysisTarget?.analysisValue?.thirdStep?.criteriaSettings
  const oldCategory = oldCriteria?.find(criteriaCategory => criteriaCategory.criteriaId === category.criteriaId)
  const newSubcriteria = oldCategory?.subcriteria?.filter(criteria => criteria.name !== criteriaToDelete.name) || []
  let newCriteriaSettings
  if (newSubcriteria.length) {
    newCriteriaSettings = oldCriteria?.map(criteria => (criteria.criteriaId === category.criteriaId
      ? {
        ...criteria,
        subcriteria: newSubcriteria,
      }
      : criteria))
  } else {
    newCriteriaSettings = oldCriteria?.filter(criteria => criteria.criteriaId !== category.criteriaId)
  }
  dispatch(saveCriteriaValues(newCriteriaSettings))
}

export const deleteCriteriaImportancyAction = (
  criteriaToDelete: string,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysisTarget } = getState()
  const criteria = Object.freeze(analysisTarget?.analysisValue?.thirdStep?.criteriaImportancy)
  let newCriteria = {}
  if (criteria) {
    Object.entries(criteria).forEach(([key, value]) => {
      if (key !== criteriaToDelete) {
        newCriteria = {
          ...newCriteria,
          [key]: value,
        }
      }
    })
  }

  dispatch(saveCriteriaImportancy(newCriteria))
}

export const setCriteriaImportancy = (criteria: ICriteriaImportancy): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { analysisTarget } = getState()

    let newCriteria = Object.freeze(analysisTarget?.analysisValue?.thirdStep?.criteriaImportancy)

    if (newCriteria) {
      Object.entries(criteria).forEach(([key, value]) => {
        newCriteria = {
          ...newCriteria,
          [key]: value / 100,
        }
      })
    }

    dispatch(saveCriteriaImportancy(newCriteria))
  }

export const setCatchmentMode = (category: string, catchmentMode: ICatchmentMode): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { analysisTarget } = getState()
    dispatch(saveCriteriaValues(analysisTarget?.analysisValue?.thirdStep?.criteriaSettings?.map(criteria =>
      (criteria.criteriaId === category
        ? {
          ...criteria,
          catchmentMode,
        }
        : criteria
      ))))
  }

export const fetchStepOneOptions = (token: string): AppThunk =>
  async (dispatch, getState): Promise<IAnalysisTargetStepOneData> => {
    const { analysisTarget } = getState()
    const body = {
      reportId: analysisTarget?.reportId,
    }
    const data = await getResponseDataObject(token, ENDPOINTS.FIRST_STEP, body, config)
    const options = (data?.value as { firstStep: unknown }).firstStep
    if (data?.value) {
      let newData = {
        options,
      }
      if (analysisTarget?.analysisValue?.firstStep) {
        newData = {
          ...analysisTarget?.analysisValue?.firstStep,
          ...newData,
        }
      }
      dispatch(saveStepData({
        step: 'firstStep',
        data: newData,
      }))
    }
    return data?.value as IAnalysisTargetStepOneData
  }

export const fetchStepTwoOptions = (token: string): AppThunk =>
  async (dispatch, getState): Promise<IAnalysisTargetStepTwoData> => {
    const { analysisTarget } = getState()
    const body = {
      reportId: analysisTarget?.reportId,
      types: analysisTarget?.analysisValue?.firstStep?.selectedValues?.map(value => ({
        category: value?.selectedValues?.generalCategory?.value,
        subcategory: value?.selectedValues?.type?.map(subCat => subCat?.value),
        subtype: value?.selectedValues?.subtype,
      })) || [], // TODO: usunac --> || [] przy release
    }
    const data = await getResponseDataObject(token, ENDPOINTS.SECOND_STEP, body, config)
    const dataValue = data?.value as {secondStep: unknown}
    const dropdownOptions = dataValue?.secondStep
    if (dropdownOptions) {
      let newData = {
        options: {
          dropdown:  dropdownOptions,
        },
      }
      if (analysisTarget?.analysisValue?.secondStep) {
        newData = {
          ...analysisTarget?.analysisValue?.secondStep,
          ...newData,
        }
      }
      dispatch(saveStepData({
        step: 'secondStep',
        data: newData,
      }))
    }
    return data?.value as IAnalysisTargetStepTwoData
  }

export const fetchResults = (token: string): AppThunk =>
  async (dispatch, getState) => {
    const { analysisTarget } = getState()
    const {
      firstStep, secondStep, thirdStep,
    } = analysisTarget.analysisValue
    const body = {
      reportId: analysisTarget?.reportId,
      stage_1: firstStep?.selectedValues?.map(val => ({
        generalCategory: val?.selectedValues?.generalCategory?.value,
        type: val?.selectedValues?.type?.map(t => t?.value),
        subtype: val?.selectedValues?.subtype,
      })),
      stage_2:{
        catchment: secondStep?.selectedValues?.map(val => ({
          name: val?.name,
          structure: 'województwo', // for mvp
        })),
      },
      stage_3: thirdStep?.criteriaSettings?.map(val => ({
        criteriaId: val?.criteriaId,
        catchmentMode: val?.catchmentMode,
        subcriteria: val?.subcriteria?.map(criteria => ({
          name: criteria?.name,
          importancy: criteria?.importancy,
        })),
      })),
      criteriaImportancy: thirdStep?.criteriaImportancy,
    }
    const data = await getResponseDataObject(token, ENDPOINTS.RESULT, body, config)
    const dataValue = data?.value
    // eslint-disable-next-line no-console
    // for mvp
    if (dataValue) { dispatch(saveResult({
      createdAd: '',
      value: dataValue,
      resultId: '',
    })) }
    return data?.value
  }

export default analysisTargetSlice.reducer
