import styled, { css } from 'styled-components'

export const ComparedLocationHeader = styled.div<{second?:boolean}>(({
  theme, second,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
  display: flex;
  align-items: center;
  color: ${palette.black};
  padding: 1rem 0;

 ${second && css`
    border-top: 1px solid #EDF0F5;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
  `}
  >h5{
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
  }
  >span{
      font-size: ${typography.big.pt_18_semibold.fontSize};
      font-weight: ${typography.big.pt_18_semibold.fontWeight};
      line-height: ${typography.big.pt_18_semibold.lineHeight};
      margin-left: 5px;
  }
  `
})
