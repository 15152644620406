import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Pin } from '../../../../../assets/src/lib/icons/dataplace/pin.svg'
import { ILinkWithIconCheckOnMap } from './@types/ILinkWithIconCheckOnMap.props'

const Button = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    cursor: pointer;
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

    :focus {
      color: ${palette.light.white};
      background-color: ${palette.product.location.main};
    }

    > span {
      margin-left: 0.25rem;
    }
  `
})

export const LinkWithIconCheckOnMap = ({
  onClick, showLabel = true, style,
}:ILinkWithIconCheckOnMap): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      style={style}
    >
      <Pin />
      {showLabel && <span>{t('generic.see_on_map')}</span>}
    </Button>
  )
}
