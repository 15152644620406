import styled, { css } from 'styled-components'

interface ITileFormRowWithDataProps {
  legend?: string
}

export const TileFormRowWithData = styled.span<ITileFormRowWithDataProps>(
  ({
    theme, legend,
  }) => {
    const {
      palette, typography,
    } = theme
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${palette.black};
      margin: 0.5rem 0;

      > * {
        margin-right: 0.375rem;
      }

      > span:nth-child(1) {
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
      }

      > span:nth-child(2) {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
      }

      ${legend
      && css`
        border-left: 10px solid ${legend};
        padding-left: 10px;
      `}
    `
  },
)
