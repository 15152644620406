import styled, { css } from 'styled-components'

export const TileFormSection = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    border-bottom: 1px solid ${palette.light.darker};

    :first-child {
      padding-top: 0;
    }

    :last-child {
      border: none;
    }
  `
})
