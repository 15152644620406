import styled, { css } from 'styled-components'

export const TileFormParagraph = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-top: 0.5rem;
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > strong {
      margin: 5px;
    }

    > svg {
      min-width: 1rem;
      width: 1rem;
      margin-bottom: -0.2rem;
    }

    > svg > path {
      fill: ${palette.blue};
    }
  `
})
