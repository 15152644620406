import React from 'react'
import styled, { css } from 'styled-components'
import { CloseIcon, Icon } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { IFlashMessage } from './@types'

const Wrapper = styled.div(({
  theme: {
    palette, typography, corners,
  },
}) => css`
  background: ${palette.light.white};
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: ${corners.default.borderRadius};
  width: max-content;
  min-height: 60px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  font-size: ${typography.tiny.pt_12_semibold.fontSize};
  font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
  line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  color: ${palette.black};
`)

const IconWrapper = styled.div(() => css`
  display: flex;
  align-items: center;
  margin: .5rem .5rem .5rem 1rem;
`)

const CloseIconWrapper = styled(IconWrapper)`
  > svg {
    path {
      stroke: #999999;
    }
  }
`

export const FlashMessage: React.FC<IFlashMessage> = ({
  type, message, onClose, id,
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <Wrapper>
        <CloseIconWrapper>
          <CloseIcon onClick={() => onClose(id)} />
        </CloseIconWrapper>
        {t(message)}
        <IconWrapper>
          <Icon
            height={24}
            src={(() => {
              switch (type) {
              // case 'info' = default
                case 'error':
                  return 'assets/icons/error.svg'
                case 'warning':
                  return 'assets/icons/warning.svg'
                case 'success':
                  return 'assets/icons/success.svg'
                default:
                  return 'assets/icons/info.svg'
              }
            })()}
            width={24}
          />
        </IconWrapper>
      </Wrapper>
    </>
  ) }
