import React, { useState } from 'react'
import styled, { css } from 'styled-components'

type ViewSwitcherWrapperProps = {
  underlinedStyle?: boolean
}
const ViewSwitcherWrapper = styled.div<ViewSwitcherWrapperProps>(({ underlinedStyle }) => css`
  display: flex;
  border: ${underlinedStyle ? 'none' : '1.5px solid #DDDDDD'};
  border-radius: 40px;
`)
type StyledSwitcherItemProps ={
  isChosen: boolean,
  id: string,
  underlinedStyle?: boolean,
}
const StyledSwitcherItem = styled.p<StyledSwitcherItemProps>(({
  underlinedStyle, isChosen, theme,
}) => {
  const { palette } = theme
  return css`
  margin:0.1em;
  padding: 0.5em 1.5em;
  background-color: ${(isChosen && !underlinedStyle) ? '#423AB3' : 'white'};
  color:${isChosen && !underlinedStyle ? 'white' : palette.dark.main};
  border-radius: ${underlinedStyle ? '0' : '30px'};
  font-weight: ${underlinedStyle ? '700' : '500'};
  border-bottom:${isChosen && underlinedStyle ? `1px solid ${palette.dark.main}` : 'none'};
  font-size: 14px;

span{
  color:${isChosen ? 'rgba(254, 215, 124, 1)' : 'rgba(59, 171, 120, 1)'};
}
cursor: pointer;
`
})

type SwitcherItemProps = {
  underlinedStyle: boolean,
  children: string | React.ReactNode,
  isChosen: boolean,
  id: string,
  handleClick: (id: string) => void
}

const SwitcherItem = ({
  children, isChosen, id, handleClick, underlinedStyle,
}:SwitcherItemProps) => (
  <StyledSwitcherItem
    id={id}
    isChosen={isChosen}
    onClick={() => handleClick(id)}
    underlinedStyle={underlinedStyle}
  >
    {children}
  </StyledSwitcherItem>
)

/* eslint-disable-next-line */
export interface IViewSwitcherProps {
  underlinedStyle?: boolean,
  items: {
    id: string,
    isChosen: boolean,
    textContent: string | JSX.Element,
  }[],
  handleViewSwitch?: (id: string) => void
}

export function ViewSwitcher({
  items, handleViewSwitch, underlinedStyle,
}: IViewSwitcherProps):JSX.Element {
  const [switchItems, setSwitchItems] = useState(items)

  const handleItemClick = (id:string) => {
    const newSwitchItems: {id: string, isChosen: boolean, textContent: string | JSX.Element, }[] = []
    switchItems.forEach((item) => (item.id === id
      ? newSwitchItems.push({
        id: item.id,
        isChosen: true,
        textContent: item.textContent,
      })
      : newSwitchItems.push({
        id: item.id,
        isChosen: false,
        textContent: item.textContent,
      })))
    setSwitchItems(newSwitchItems)
    if (handleViewSwitch) {
      handleViewSwitch(id)
    }
  }
  return (
    <ViewSwitcherWrapper underlinedStyle={underlinedStyle}>
      {switchItems.map((item) => (
        <SwitcherItem
          key={item.id}
          handleClick={handleItemClick}
          id={item.id}
          isChosen={item.isChosen}
          underlinedStyle={underlinedStyle || false}
        >
          {item.textContent}
        </SwitcherItem>
      ))}
    </ViewSwitcherWrapper>

  )
}

export default ViewSwitcher
