import styled, { css } from 'styled-components'
import { TextButtonProps } from './@types/TextButtonProps.props'

export const TextButton = styled.button<TextButtonProps>(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    border: none;
    background: none;
    cursor: pointer;

    p {
      display: flex;
      align-items: center;
      color: ${palette.blue};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
    }
    a{
      display: flex;
      align-items: center;
      color: ${palette.blue};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      text-decoration: none;
    }

    :disabled {
      > p {
        color: ${palette.gray.light};
      }
      cursor: default;
    }
    
  `
})
