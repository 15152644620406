import React from 'react'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { BarChartWrapperProps } from './@types/BarChartWrapper.props'
import { IBarChartProps } from './@types/IBarChart.props'

const BarChartWrapper = styled.div<BarChartWrapperProps>`
  width: ${({ width }) => `${width}px`};
`
const colors = [
  '#362FB7',
  '#7E7AD2',
  '#D8A800',
  '#F5D564',
  '#C759B5',
  '#E1A8D8',
]

const visitingHoursColors = [
  '#362FB7',
  '#D8A800',
]

export function BarChart({
  labels,
  width,
  dataset,
  axisLabels,
  innerLabels,
  valueType,
  gridLines,
  displayLegend,
  barWidth,
  borderRadius,
  beginsAtZero,
  precision,
  visitingHours,
}: IBarChartProps): JSX.Element {
  const assignColorToData = (
    dataset: { label?: string; data: number[] }[],
    colors: string[],
  ) => {
    const newDataset: {
      label: string
      data: number[]
      backgroundColor: string
      borderRadius?: number
    }[] = []
    let counter = 0
    dataset.forEach((item) => {
      newDataset.push({
        label: item.label || '',
        data: item.data,
        backgroundColor: colors[counter],
        borderRadius: borderRadius || 0,
      })
      counter += 1
    })
    return newDataset
  }

  return (
    <BarChartWrapper width={width}>
      <Bar
        data={{
          labels,
          datasets: assignColorToData(dataset, visitingHours ? visitingHoursColors : colors),
        }}
        options={{
          plugins: {
            datalabels: {
              display: innerLabels || false,
              color: 'white',
              font: {
                size: '16',
                weight: 'bold',
              },
              formatter(value: number) {
                switch (valueType) {
                  case 'percentages':
                    return `${value}%`
                  default:
                    return value
                }
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: axisLabels?.xAxis,
                  labelString: axisLabels?.xAxis,
                  fontStyle: 'bold',
                },
                gridLines: {
                  display: gridLines || false,
                },
                barPercentage: barWidth || 0.9,
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: axisLabels?.yAxis,
                  labelString: useCheckForMobileDevice() ? (axisLabels?.yAxis)?.substring(0, 8) : axisLabels?.yAxis,
                  font: {
                    size: useCheckForMobileDevice() ? 12 : 16,
                    weight: useCheckForMobileDevice() ? 'normal' : 'bold',
                  },
                },
                gridLines: {
                  display: gridLines || false,
                },
                ticks: {
                  beginAtZero: beginsAtZero,
                  precision: precision ? 0 : 1,
                },
              },
            ],
          },
          legend: {
            align: 'start',
            display: displayLegend,
            labels: {
              boxWidth: 8,
            },
          },
        }}
      />
    </BarChartWrapper>
  )
}

export default BarChart
