// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isNumber = (value: any): boolean => !Number.isNaN(+(value))

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const parseNumber = (value: any) => {
  if (typeof value === 'string') {
    if (!isNumber(value)) {
      value = value.replace(/\s/g, '').replace(',', '.')
    }
  }
  return value
}

export const sortByNumber = <T>(array: T[], key: keyof T, direction: string): T[] => {
  if (direction === '') { return array }
  const sorted = [...array].sort((a, b) => {
    let num1 = a[key]
    let num2 = b[key]
    num1 = parseNumber(num1)
    num2 = parseNumber(num2)
    if (!isNumber(num1) && !isNumber(num2)) { return 0 }
    if (!isNumber(num1)) { return direction === 'ASC' ? -1 : 1 }
    if (!isNumber(num2)) { return direction === 'ASC' ? 1 : -1 }
    return direction === 'ASC' ? +(num1) - +(num2) : +(num2) - +(num1)
  })
  return sorted
}
