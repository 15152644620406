import { IMaxRanges, IRange } from '@dataplace.ai/types'

export const handleMaxRanges = (ranges: IRange[], maxRanges?:IMaxRanges, isComparedLocation?: boolean) : IRange[] => {
  const newRanges : IRange[] = []
  if (maxRanges) {
    ranges.forEach(range => {
      if (range.type === 'walk' && range.value <= maxRanges?.walk) newRanges.push(range)
      if (range.type === 'car' && range.value <= maxRanges?.car) newRanges.push(range)
      if (range.type === 'line' && range.value <= maxRanges?.line) newRanges.push(range)
      if (range.type === 'bike' && range.value <= maxRanges?.bike) newRanges.push(range)
      if (range.type === 'custom' && !isComparedLocation) newRanges.push(range)
    })
    return newRanges
  } return ranges
}
