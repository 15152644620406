import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Button } from '../atoms/Buttons'

const Page404Wrapper = styled.div`
 width: 100vw;
 height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
`

const ContentWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  display: flex;
  flex-direction: column;

  >h1{
    color: ${palette.dark.main};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    margin-bottom: 2rem;
  }

  >div{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;

    :last-of-type{
      flex-direction: row;

      >div{
        >div{
          display: flex;
        }
        display: flex;
        flex-direction: column;
      }
    }
  }
`
})

const ColoredNumber = styled.p(({ color }: {color: 'orange'|'blue'|'violet'}) => css`
    color: ${() => {
    switch (color) {
      case 'orange': return '#CB4027'
      case 'blue': return '#0275A7'
      default: return '#9A2484'
    }
  }};
    font-size: 90px;
    font-weight: 700;
  `)

export const Page404 = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Page404Wrapper>
      <ContentWrapper>
        <h1>{t('placeme.404.header')}</h1>
        <div>
          <div>
            <img
              alt='404 img'
              src='https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/404%2F404.svg?alt=media&token=7eccb0d0-6655-4de8-91ff-0db8a18e7896'
            />
          </div>
          <div>
            <div>
              <ColoredNumber color='orange'>4</ColoredNumber>
              <ColoredNumber color='blue'>0</ColoredNumber>
              <ColoredNumber color='violet'>4</ColoredNumber>
            </div>
            <Button href='choose-location'>{t('placeme.404.button')}</Button>
          </div>
        </div>
      </ContentWrapper>
    </Page404Wrapper>
  ) }
