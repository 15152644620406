import React from 'react'

interface IconProps
  extends React.FC<React.ImgHTMLAttributes<HTMLImageElement>> {
  width?: string
  height?: string
}

export const Icon: IconProps = ({
  alt, src, height = 20, width = 20,
}) => (
  <img
    alt={alt}
    src={src}
    style={{
      width,
      height,
    }}
  />
)
