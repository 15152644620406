import styled, { css } from 'styled-components'
import { OutlinedButtonProps } from './@types/OutlinedButtonProps.props'

export const OutlinedButton = styled.button<OutlinedButtonProps>(({
  theme, margin, border,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px 5px 7px;
    background: #FFFFFF;
    border: ${border || '1px solid #234596'};
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    cursor: pointer;
    margin: ${margin};
  
    p{
      color:${palette.blue};
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }
  
    svg{
      margin-right: 5px;
    }
  `
})
