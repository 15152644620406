import React from 'react'
import styled, { css } from 'styled-components'
import { CloseIcon } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { IAnalyseInfoModalHeaderProps } from './@types/IAnalyseInfoModalHeader.props'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.5rem 2.5rem 0;

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }

    > svg {
      cursor: pointer;
    }
  `
})

export const AnalyseInfoModalHeader = ({ close }: IAnalyseInfoModalHeaderProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <span>{t('placeme.analyse_info_modal.title')}</span>
      <CloseIcon onClick={close} />
    </Wrapper>
  ) }
