import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@dataplace.ai/features'
import {
  redirectToSignIn,
  redirectToSignUp,
} from '@dataplace.ai/functions/utils'
import { IChildrenProps } from '@dataplace.ai/types'
import { useLocation } from 'react-router-dom'

export const HasToBeAuthenticated = ({ children }: IChildrenProps): JSX.Element => {
  const authContext = useContext(AuthContext)
  const location = useLocation()
  const [didRedirectTrigger, setRedirectTriggerFlag] = useState(false)

  useEffect(() => {
    if (parseInt(process.env.NX_AUTH_ENABLED || '0', 10) !== 0 && authContext.userData.userLoaded && !didRedirectTrigger) {
      setRedirectTriggerFlag(true) // prevents repeating redirect
      if (!authContext.userData?.user?.emailVerified) {
        if (authContext.userData?.user?.email) { // means that user is authenticated but not verified
          redirectToSignUp()
        } else { // means that user is not authenticated at all
          // eslint-disable-next-line no-lonely-if
          redirectToSignIn()
        }
      }
    }
  }, [authContext.userData.userLoaded, location.pathname])

  return (
    <>
      {children}
    </>
  )
}

export default HasToBeAuthenticated
