import React from 'react'
import { IRange } from '@dataplace.ai/types'
import { IPricingPlan } from '@dataplace.ai/constants'
import { ReactComponent as GastroIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/gastroIcon.svg'
import { ReactComponent as BillboardIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/bilboardIcon.svg'
import { ReactComponent as GroceryIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/groceryIcon.svg'
import { ReactComponent as NetworkIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/networkIcon.svg'
import { ReactComponent as ResearchIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/researchIcon.svg'

export interface IUserTemplatesResponseData {
  id: string,
  date: string,
  title: string,
  tiles: {
    id: string,
    range?: {
      length: number | null,
      mode: IRange['type'] | null,
    }
  }[]
}
export interface ITemplatesData{
  id: string
  title: string
  icon?: JSX.Element
  tiles: {
    id: string
    suggestedRange?:{
      value: number | null
      type: IRange['type'] | null
      geoJSON?: {
        type: 'Polygon',
        coordinates: number[][][]
      }
    }
    isInPlan?: boolean,
    plan?: IPricingPlan
  }[]
}

export const templatesData : ITemplatesData[] = [
  {
    id: 'gastro',
    title: 'placeme.templates.title.gastro',
    icon: <GastroIcon />,
    tiles: [
      {
        id: 'people',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'demography',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'spending',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'purchasing_power',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'pois',
        suggestedRange: {
          value: 1000,
          type: 'line',
        },
      },
      {
        id: 'traffic_visualisation',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'buildings',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'investments',
        suggestedRange: {
          value: 10,
          type: 'car',
        },
      },
    ],
  },
  {
    id: 'network',
    title: 'placeme.templates.title.network',
    icon: <NetworkIcon />,
    tiles: [
      {
        id: 'cannibalization',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'pois',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'access_range',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      // {
      //   id: 'local_market_share',
      //   suggestedRange: {
      //     value: 10,
      //     type: 'walk',
      //   },
      // },

    ],
  },
  {
    id: 'research',
    title: 'placeme.templates.title.research',
    icon: <ResearchIcon />,
    tiles: [
      {
        id: 'gravity_model',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'potential_model',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },

    ],
  },
  {
    id: 'billboard',
    title: 'placeme.templates.title.billboard',
    icon: <BillboardIcon />,
    tiles: [
      {
        id: 'people',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'demography',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'spending',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'purchasing_power',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'transport',
        suggestedRange: {
          value: null,
          type: null,
        },
      },

      {
        id: 'buildings',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'investments',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'traffic_visualisation',
        suggestedRange: {
          value: 15,
          type: 'walk',
        },
      },
      {
        id: 'customer_origin',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
    ],
  },
  {
    id: 'grocery',
    title: 'placeme.templates.title.grocery',
    icon: <GroceryIcon />,
    tiles: [
      {
        id: 'people',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'demography',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'spending',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'purchasing_power',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'pois',
        suggestedRange: {
          value: 1000,
          type: 'line',
        },
      },
      {
        id: 'cannibalization',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'transport',
        suggestedRange: {
          value: null,
          type: null,
        },
      },
      {
        id: 'access_range',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'traffic_visualisation',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },

      {
        id: 'customer_origin',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'gravity_model',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'potential_model',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
    ],
  },
]

// it's dummy
export const templatesDataDummyUsers : ITemplatesData[] = [
  {
    id: '73y8er78929eyw9397',
    title: 'Mój customowy szablonik',
    tiles: [
      {
        id: 'demography',
      },
      {
        id: 'spending',
        suggestedRange: {
          value: 5,
          type: 'car',
        },
      },
      {
        id: 'people',
        suggestedRange: {
          value: 10,
          type: 'walk',
        },
      },
      {
        id: 'people-2',
        suggestedRange: {
          value: 5,
          type: 'walk',
        },
      },
    ],
  },
]
