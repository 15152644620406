export const investmentsTableData = {
  labels: [
    'placeme.investmentstile.tablelabel_1',
    'placeme.investmentstile.tablelabel_2',
    'placeme.investmentstile.tablelabel_3',
    ' ',
  ],
  values: [
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
    ['Nazwa inwestycji', 'Kwiatowa 4', '10m'],
  ],
}
