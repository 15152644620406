import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { SelectCaret } from '@dataplace.ai/ui-components/atoms'

const Wrapper = styled.div<{ active: boolean }>(({
  theme, active,
}) => {
  const {
    shadows, corners, palette,
  } = theme

  return css`
    padding-bottom: 0.5rem;
    
    // :nth-child(even) {
    //   background-color: ${palette.light.main};
    // }
    
    ${active && css`
      display: flex;
      flex-direction: column;
      border-radius: ${corners.default.borderRadius};
      box-shadow: ${shadows.tiny.boxShadow};
    `}
  `
})

const GridRow = styled.div<{ rowTemplate: string, gap?: string, whiteSpaces?: string, pois?: boolean }>(({
  theme, rowTemplate, gap, whiteSpaces, pois,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    grid-template-columns: ${rowTemplate};
    grid-gap: ${gap};
    display: grid;
    padding: 0.5rem;
    align-items: center;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      display: inline-block;
      white-space: ${whiteSpaces || 'nowrap'};
      text-overflow: ellipsis;
      overflow: hidden; 
      

    ${pois && css`

      :nth-of-type(2){
        display: flex;
        justify-content: flex-end;
      }
      :nth-of-type(3){
        display: flex;
        justify-content: flex-end;
      }
    `}
    }

    .bank{
        display: flex;
        align-items: center;

        >div{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-left: 5px;

          >svg{
            path{
              stroke: #909090;
            }
          }
        }
      }
  `
})

export interface ITableRowProps {
  content: JSX.Element[];
  expandable?: JSX.Element[][] | null;
  rowTemplate: string;
  gap?: string;
  expandableComponent?: JSX.Element | null;
  subRow?: JSX.Element[] | null;
  whiteSpaces?: string
  pois?:boolean
  expandableRowTemplate?: string,
}

export const TableRow = ({
  content, rowTemplate, gap, expandable, expandableComponent, subRow, whiteSpaces, pois, expandableRowTemplate,
}: ITableRowProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false)

  return (
    <Wrapper active={active}>
      <GridRow
        gap={gap}
        pois={pois}
        rowTemplate={rowTemplate}
        whiteSpaces={whiteSpaces}
      >
        {content}
        {(expandable || expandableComponent) && (
          <SelectCaret
            active={active}
            onClick={() => setActive(!active)}
          />
        )}
      </GridRow>
      {(active && expandable)
      && (
        <GridRow
          gap={gap}
          rowTemplate={expandableRowTemplate || rowTemplate}
          whiteSpaces={whiteSpaces}
        >
          {expandable}
        </GridRow>
      )}
      {(active && expandableComponent)}

      {(subRow && subRow)}
    </Wrapper>
  )
}

