export const gravityModelTableData = {
  labels: [
    '',
    'placeme.gravitymodeltile.tablelabel_1',
    'placeme.gravitymodeltile.tablelabel_2',
    'placeme.gravitymodeltile.tablelabel_4',
  ],
}

export const legend = [
  {
    color: '#8abd6a',
    label: 'placeme.gravity_model.label.green',
  },
  {
    color: '#eac74d',
    label: 'placeme.gravity_model.label.yellow',
  },
  {
    color: '#f08f7f',
    label: 'placeme.gravity_model.label.red',
  },
]
