import styled, { css } from 'styled-components'
import { mediaMax as media } from '@dataplace.ai/ui-components/themes'
import { ITileFormRowProps } from './@types/ITileFormRow.props'

export const TileFormRow = styled.div<ITileFormRowProps>(
  ({
    width, align, justify, padding, textAlign, wrap,
  }) => css`
    width: ${width || '100%'};
    display: flex;
    align-items: ${align || 'center'};
    justify-content: ${justify};
    text-align: ${textAlign};
    padding: ${padding};
    flex-direction: row;
    flex-wrap: ${wrap};

    > div {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    > div:first-child {
      margin-left: 0;
      ${media.mobile`
        margin-left: 0.25rem;
      `}
    }

    > div:last-child {
      margin-right: 0;
      ${media.mobile`
        margin-right: 0.25rem;
        // margin-top: 1.25rem;
        > div {
          // margin: 0 auto;
        }
      `}
    }
  `,
)
