import Geonames from 'geonames.js'
import { createFlashMessage } from '@dataplace.ai/functions/utils'
import { ILocation } from '@dataplace.ai/types'

export const getCurrentCountryId = async (location: ILocation):Promise<(string|undefined)> => {
  const geonames = Geonames({
    username: 'dataplace',
    lan: 'pl',
    encoding: 'JSON',
  })

  try {
    const country = await geonames.countryCode({
      lat: location.lat,
      lng: location.lng,
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return country?.countryCode
  } catch (e) {
    createFlashMessage({
      message: 'placeme.error.country_check',
    })
    return undefined
  }
}
