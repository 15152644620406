import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Paragraph } from '@dataplace.ai/ui-components/atoms'
import { IMutableList, MutableListProps } from './@types/IMutableListProps.props'
import { WrapperProps } from './@types/MutableProps.props'

const Wrapper = styled.div<WrapperProps>(({
  isAnyItem, minWidth,
}) => css`
    /* border: 0.065rem solid #DDDDDD;
    border-radius: 0.625rem; */
    display: ${isAnyItem ? 'grid' : 'none'};
    font-size: 1rem;
    min-width: ${minWidth || '25rem'};
    min-height: 3rem;
  `)
type RowWrapperProps = {
  columnsNumber?: number,
  gridFlow?: string
}
const RowWrapper = styled.div<RowWrapperProps>(({
  columnsNumber, gridFlow,
}) => css`
    display: grid;
    grid-auto-flow: ${gridFlow || 'column'};
    align-items: center;
    grid-template-columns: ${() => {
    switch (columnsNumber) {
      case 3:
        return 'auto max-content 20px' // 16px
      default:
        return 'auto 20px' // 18px
    }
  }};
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 1rem;

    :first-child {
      margin-top: 1rem;
    }

    :last-child {
      margin-bottom: 1rem;
    }
  `)

export const MutableList: React.FC<MutableListProps> = ({
  valuesList, columnsNum = 2, handleMutableItemDelete, deleteOpt, minWidth, gridFlow,
}) => {
  const [currentList, handleCurrentList] = useState<IMutableList[]>(valuesList || [])

  useEffect(() => {
    handleCurrentList(valuesList)
  }, [valuesList])

  const deleteListElement = (id: string, value?: number): void => {
    handleCurrentList(currentList.filter((element) => element.id !== id))
    if (handleMutableItemDelete && value) {
      handleMutableItemDelete(value, id)
    }
  }

  return (
    <Wrapper
      isAnyItem={currentList.length !== 0}
      minWidth={minWidth}
    >
      {currentList.map((item) => (
        <RowWrapper
          key={item.id}
          columnsNumber={columnsNum}
          gridFlow={gridFlow}
        >
          {item.content}
          {deleteOpt && (
            <>
              <Paragraph
                margin='0 0.5rem'
                onClick={() => {
                  deleteListElement(item.id, item.value)
                }}
              >
                <img
                  alt='small coins'
                  src='assets/icons/closeIcon.svg'
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Paragraph>
            </>
          )}

        </RowWrapper>
      ))}
    </Wrapper>
  )
}
