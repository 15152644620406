// tiles id from which you can make pdf
export const tilesPdfList = [
  // [population]
  'people',
  'demography',
  'purchasing_power',
  'municipality_population',
  'spending',
  // [market]
  'cannibalization',
  'pois',
  // [access logistics]
  'transport',
  'access_range',
  // [potential]
  'gravity_model',
  'potential_model',
  // 'local_market_share', na ten czas wyłączamy, brak schematu i brak TotalAPI
  // [surroundings]
  'buildings',
  'investments',
  'employees_in_range',
  // [traffic]
  'visiting_hours',
  'traffic_visualisation',
  'customer_origin',
  // [visualization]
  'traffic_visualisation_map',
  // 'cities_population', na ten czas wyłączamy
  'satellite',
  // [big ben]
  'look_alike_big_ben',
  'population_big_ben',
  'scoring_model_big_ben',
  'traffic_big_ben',
  'pois_big_ben',
]
