
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Caret } from '@dataplace.ai/ui-components/atoms/Caret'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { IDropdownTileProps } from './@types/IDropdownTile.props'
import { TileSelect } from '../TileSelect'
import { DoYouWantComparison } from './components'
import { checkIfDisabledByPricingPlan } from '../../../functions/checkIfDisabledByPricingPlan'

type IDropdownTileWrapper ={
  disabled: boolean,
}
const DropdownTileWrapper = styled.div<IDropdownTileWrapper>`
  width: 100%;
  display: flex;
  flex-direction: column;
`
type IHeaderProps = {
  isOpen?: boolean
  disabled: boolean
}

const Header = styled.div<IHeaderProps>(({
  theme, isOpen, disabled,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    >svg{
      margin: 0 0.625rem 0 0;
    }

    :hover {
      cursor: pointer;
    }
    ${disabled && css`
      :hover{
        cursor: default;
      }
    `}

    > .caret {
      margin: 0 0.625rem;
      width: 1rem;
      transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};

      path {
        stroke: ${palette.blue};
      }
    }

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_medium.fontSize};
      font-weight: ${typography.main.pt_15_medium.fontWeight};
      line-height: ${typography.main.pt_15_medium.lineHeight};

      @media (max-width: 1200px){
        font-size: ${typography.small.pt_13_medium.fontSize};
        font-weight: ${typography.small.pt_13_medium.fontWeight};
        line-height: ${typography.small.pt_13_medium.lineHeight};
    }

      ::first-letter {
        text-transform: capitalize;
      }
    }

    ${disabled && css`
      >svg{
          path{
            fill:${palette.light.darkest};
          }
        }
      >.traffic-icon{
        path{
          stroke:${palette.light.darkest};
        }
      }
      >.caret{
        path{
          fill: transparent;
          stroke:${palette.light.darkest};
        }
      }
      >span{
        color:${palette.light.darkest};
      }
    `}
  `
})
type IIconWrapper = {
  disabled: boolean
}
const IconWrapper = styled.div<IIconWrapper>(({
  disabled, theme,
}) => {
  const { palette } = theme
  return css`
  padding: 1rem 1.25rem;
  > svg {
    margin: 0;
  }
  ${disabled && css`
    >svg{
      path{
          fill:${palette.light.darkest};
        }
    }
    >.traffic-icon{
      path{
        stroke:${palette.light.darkest};
      }
    }
  `}
`
})

type TopicsWrapperProps = {
  isOpen?: boolean
}
const TilesWrapper = styled.div<TopicsWrapperProps>(
  ({ isOpen }) => css`
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    margin-bottom: 0.75rem;
    cursor: default;
  `,
)

export const DropdownTile = ({
  sectionTile,
  full,
  disabled,
  isCategoryOpen,
  handleCategoryOpen,
  subscriptionType,
  showDisabledByPricingPlan,
  handleCompareLocation,
}: IDropdownTileProps): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const {
    values, canBeSave, plans, isEdit, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const {
    icon, label, tiles, id,
  } = sectionTile

  // functions
  const handleLabelClick = () => {
    if (!disabled) {
      handleCategoryOpen(sectionTile.id)
    }
  }

  const handleTileDisabled = (tileId: string) => {
    let isDisabled = true
    if (!canBeSave) {
      if (values.length) {
        values.find((item) => item.id === id)?.tiles.forEach(tile => {
          if (tile.id.split('-')[0] === tileId) {
            isDisabled = false
          }
        })
      }
      return isDisabled
    }
    isDisabled = false
    return isDisabled
  }

  const resolveTiles = () => {
    let resolvedTiles = tiles
    if (!showDisabledByPricingPlan) {
      resolvedTiles = tiles.filter(tile => !checkIfDisabledByPricingPlan(plans, tile.plan, subscriptionType))
    }
    return resolvedTiles
  }

  // hooks
  useEffect(() => {
    resolveTiles()
  }, [tiles, showDisabledByPricingPlan])

  if (resolveTiles().length) {
    return (full
      ? (
        <DropdownTileWrapper
          disabled={disabled}
        >
          <Header
            disabled={disabled}
            isOpen={isCategoryOpen}
            onClick={handleLabelClick}
          >
            {icon}
            <span>{t(`${label}`)}</span>
            <Caret className='caret' />
          </Header>
          <TilesWrapper isOpen={isCategoryOpen}>
            {showDisabledByPricingPlan
              ? [...tiles].sort((a, b) => a.plan.weight - b.plan.weight).map((tile) => (
                isEdit && !(localStorage.getItem('isEditCompare')) && comparedLocation?.location
                  ? (
                    <DoYouWantComparison
                      handleCompareLocation={handleCompareLocation}
                      trigger={(
                        <TileSelect
                          key={`dropdown-tile-${tile.id}`}
                          disabled={handleTileDisabled(tile.id)}
                          disabledByPricingPlan={checkIfDisabledByPricingPlan(plans, tile.plan, subscriptionType)}
                          sectionTile={sectionTile}
                          tile={tile}
                        />
                      )}
                    />
                  )
                  : (
                    <TileSelect
                      key={`dropdown-tile-${tile.id}`}
                      disabled={handleTileDisabled(tile.id)}
                      disabledByPricingPlan={checkIfDisabledByPricingPlan(plans, tile.plan, subscriptionType)}
                      sectionTile={sectionTile}
                      tile={tile}
                    />
                  )
              ))
              : [...tiles]
                .filter(tile => !checkIfDisabledByPricingPlan(plans, tile.plan, subscriptionType))
                .sort((a, b) => a.plan.weight - b.plan.weight)
                .map((tile) => (
                  isEdit && !(localStorage.getItem('isEditCompare')) && comparedLocation?.location
                    ? (
                      <DoYouWantComparison
                        handleCompareLocation={handleCompareLocation}
                        trigger={(
                          <TileSelect
                            key={`dropdown-tile-${tile.id}`}
                            disabled={handleTileDisabled(tile.id)}
                            sectionTile={sectionTile}
                            tile={tile}
                          />
                        )}
                      />
                    )
                    : (
                      <TileSelect
                        key={`dropdown-tile-${tile.id}`}
                        disabled={handleTileDisabled(tile.id)}
                        sectionTile={sectionTile}
                        tile={tile}
                      />
                    )
                ))}
          </TilesWrapper>
        </DropdownTileWrapper>
      )
      : (
        <IconWrapper disabled={disabled}>
          {icon}
        </IconWrapper>
      )
    )
  } return <div />
}

DropdownTile.defaultProps = {
  full: true,
}
