
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  AnalyseInfoModalNav,
} from '../../molecules'
import { AnalyseInfoModalFooter, AnalyseInfoModalSearch, AnalyseInfoModalHeader } from '../../atoms'
import { AnalyseInfoModalContent } from '../../molecules/AnalyseInfoModalContent'
import { IAnalyseInfoModalProps } from './@types/IAnalyseInfoModal.props'
import { ISectionTile } from '../../../slice/@types/ISectionTile'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, shadows,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    background-color: ${palette.light.white};
    box-shadow: ${shadows.hover.boxShadow};
    overflow-y: auto;

    // todo - uncomment if needed
    //-ms-overflow-style: none;
    //scrollbar-width: none;
    //::-webkit-scrollbar {
    //  display: none;
    //}
  `
})

export const AnalyseInfoModal = ({ close }: IAnalyseInfoModalProps): JSX.Element => {
  const [activeSection, setActiveSection] = useState<string>('')
  const [searchSection, setSearchSection] = useState<ISectionTile[]>([])
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  return (
    <>
      <Wrapper>
        <AnalyseInfoModalHeader close={close} />
        <AnalyseInfoModalNav
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          setSearchPhrase={setSearchPhrase}
        />
        <AnalyseInfoModalSearch
          searchPhrase={searchPhrase}
          setActiveSection={setActiveSection}
          setSearchPhrase={setSearchPhrase}
          setSearchSection={setSearchSection}
        />
        <AnalyseInfoModalContent
          activeSection={activeSection}
          searchSection={searchSection}
        />
      </Wrapper>
      <AnalyseInfoModalFooter close={close} />
    </>
  )
}
