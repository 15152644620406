import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IDataSetUpdate } from '../../../utils/dataSetUpdates'

const Wrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;

  > :first-child {
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
  }
  > :last-child {
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
  }
`
})

interface IDataSetUpdateInfoTileRowProps {
  row: IDataSetUpdate
}

export const DataSetUpdateInfoTileRow = ({ row }: IDataSetUpdateInfoTileRowProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <span>{t(`${row.label}`)}</span>
      <span>{t(`${row.updated}`)}</span>
    </Wrapper>
  )
}
