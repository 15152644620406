import { DataType } from './@types/ISalesPotentialTileData'

export type SalesPotentialLabels = {key: DataType, value: string, unit?: string, step?: number,
  min?: number, max?: number, valueForChart?: string}[];

export const labels: SalesPotentialLabels = [
  {
    key: 'popSumTotal100M500',
    value: 'placeme.sales_potential_tile.potential.radar.popSumTotal100M500',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.popSumTotal100M500.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.popSumTotal100M500',
    step: 50,
  },
  {
    key: 'avgTraffic25',
    value: 'placeme.sales_potential_tile.potential.radar.avgTraffic25',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.avgTraffic25.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.avgTraffic25',
    step: 2,
  },
  {
    key: 'poiTrafficSignalsMinDistance',
    value: 'placeme.sales_potential_tile.potential.radar.poiTrafficSignalsMinDistance',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.poiTrafficSignalsMinDistance.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.poiTrafficSignalsMinDistance',
    step: 10,
  },
  {
    key: 'cafeCountTotal250',
    value: 'placeme.sales_potential_tile.potential.radar.cafeCountTotal250',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.cafeCountTotal250.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.cafeCountTotal250',
    step: 1,
  },
  {
    key: 'trzylubwiecejCubage250',
    value: 'placeme.sales_potential_tile.potential.radar.trzylubwiecejCubage250',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.trzylubwiecejCubage250.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.trzylubwiecejCubage250',
    step: 5,
  },
  {
    key: 'jednorodzinneCubage500',
    value: 'placeme.sales_potential_tile.potential.radar.jednorodzinneCubage500',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.jednorodzinneCubage500.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.jednorodzinneCubage500',
    step: 5,
  },
  {
    key: 'officeCubage500',
    value: 'placeme.sales_potential_tile.potential.radar.officeCubage500',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.officeCubage500.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.officeCubage500',
    step: 5,
  },
  {
    key: 'popFeminization250',
    value: 'placeme.sales_potential_tile.potential.radar.popFeminization250',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.popFeminization250.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.popFeminization250',
    step: 5,
  },
  {
    key: 'sdv',
    value: 'placeme.sales_potential_tile.potential.radar.sdv',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.sdv.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.sdv',
    step: 1,
  },
  {
    key: 'carrefourCountTotal250',
    value: 'placeme.sales_potential_tile.potential.radar.carrefourCountTotal250',
    valueForChart: 'placeme.sales_potential_tile.potential.radar.carrefourCountTotal250.chart_label',
    unit: 'placeme.sales_potential_tile.potential.unit.carrefourCountTotal250',
    step: 1,
  },

]

