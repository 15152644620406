/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import { CloseIcon, Button, TextButton } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { Form, FormSection, Select, TextInput } from '@dataplace.ai/ui-components/molecules'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import { Navigate, useParams } from 'react-router-dom'
import { PATHS } from 'apps/placeme/src/constants/paths'
import { IDownloadAnalyseInitialValues, initialValues } from './@constants/initialValues'
import { createPDFAction, saveAnalyse, savePdfState, saveSavingState } from '../../../slice/analysisSlice'
import { ITile } from '../../../slice/@types/ITile'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { tilesPdfList } from '../../../utils/tilesPdfList'
import { ReactComponent as FolderIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/profile/folder.svg'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'

const { v4: uuidv4 } = require('uuid')

export interface IDownloadAnalyseModalProps {
  trigger: JSX.Element
  analyseId: string
}

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 2.5rem 1.5rem;
    width: 460px;

    form {
      margin: 0;
    }
  `
})

const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;

    h3 {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }
  `
})
const Trigger = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
)

const AnalysisWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    width: 100%;
    margin-bottom: 0.8rem;
    overflow-y: scroll;
    scrollbar-color: rgb(210, 210, 210) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      display: block;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #f1f1f1;
      border-radius: 8px;
    }

    p {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    }
  `
})

const InputWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    max-height: 100px;

    > .formik-radio {
      display: flex;
      position: relative;
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 11px;

      > input[type='radio'] {
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }

      > input[type='radio']:disabled {
        cursor: default;
      }

      > input[type='radio'], > input[type='radio'] + div {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      > input[type='radio']:checked + div {
        background-image: url('assets/icons/radio-button-on.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 140%;
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio'] + div {
        z-index: 0;
        background-color: ${palette.light.white};
        border: 1px solid ${palette.blue};
        box-sizing: border-box;
        border-radius: 1rem;
      }

      > input[type='radio']:hover + div {
        background-color: ${palette.light.white};
        border: 2px solid ${palette.dark.main};
      }

      > input[type='radio']:checked:hover + div {
        background-color: ${palette.light.white};
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio']:focus:checked + div {
        background-color: ${palette.results.green.opaque};
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio']:focus + div {
        border: 2px solid ${palette.blue};
      }

      > input[type='radio']:active + div {
        background-color: ${palette.results.green.dark};
        border: 1px solid ${palette.results.green.dark};
      }
    }

    span {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }
  `
})

const FormikCheckbox = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    position: relative;
    width: 1.25rem;
    height: 1.25rem;

    > input[type='checkbox'] {
      cursor: pointer;
    }

    > input[type='checkbox']:disabled {
      cursor: default;
    }

    > input[type='checkbox'], > input[type='checkbox'] + div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > input[type='checkbox'] {
      z-index: 1;
      opacity: 0;
    }

    > input[type='checkbox']:checked + div {
      background-image: url('assets/icons/checkMark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }

    > input[type='checkbox'] + div {
      z-index: 0;
      background-color: ${palette.light.white};
      border: 1px solid ${palette.blue};
      box-sizing: border-box;
      border-radius: 0.25rem;
    }

    > input[type='checkbox']:hover + div {
      background-color: ${palette.light.white};
      border: 2px solid ${palette.dark.main};
    }

    > input[type='checkbox']:focus:checked + div {
      border: 2px solid ${palette.results.green.dark};
    }

    > input[type='checkbox']:focus + div {
      border: 2px solid ${palette.blue};
    }

    > input[type='checkbox']:checked + div {
      background-color: ${palette.results.green.dark};
      border: 2px solid ${palette.results.green.dark};
    }
  `
})

const CheckboxWrapper = styled.div`
  margin-left: 1rem;
  width: calc(100% - 2rem);

  svg {
    cursor: pointer;
  }

  span {
    margin-left: 11px;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Description = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  `
})

const AdditionalDescription = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.dark.normal};
    margin: 1rem 0;
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const LanguagesWrapper = styled.div`
  display:flex;
  width: 50%;
  justify-content: space-between;
`

const projectTypes = [
  'placeme.save_analyse.private',
  'placeme.save_analyse.public',
]

export const DownloadAnalyseModal = ({
  trigger,
  analyseId,
}: IDownloadAnalyseModalProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { reportId } = useParams<{ reportId: string}>()
  const [open, setOpen] = useState(false)
  const [chooseTiles, setChooseTiles] = useState(false)
  const [existingProjects, setProjects] = useState<({ folderId: string; folderName: string } | null)[]
  >([])
  const [newProject, setNewProject] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [chosenTiles, setChosenTiles] = useState<ITile[]>([])

  const {
    values, pdfState, savingState, canBeSave, isEdit,
  } = useSelector((state: RootState) => state.analysis)
  const { data } = useSelector(
    (state: RootState) => state.analysesAndReports,
  )
  const address = useSelector((state: RootState) => state.location?.value?.address || '')

  // the initial name of the pdf is the address being analyzed
  initialValues.reportName = address || ''

  const projectsData: IAnalysesData = data?.value as IAnalysesData
  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }

  const getAvailableTilesArray = (values: ISectionTile[]): ITile[] => {
    const array: ITile[] = []
    const tilesListFiltered: string[] = []

    values.map(value => value.tiles.map(item => item.id))
      .map(item => item.forEach(key => tilesPdfList.some(tile => new RegExp(`${tile}.*`, 'g').test(key)) && tilesListFiltered?.push(key)))

    values?.forEach(sectionTile => sectionTile?.tiles
      ?.forEach(tile => (!array.includes(tile) && tilesListFiltered.includes(tile.id)) && array?.push(tile)))

    return array
  }

  const allSectionTiles: ISectionTile[] = values?.map(sectionTile => ({
    ...sectionTile,
    tiles: sectionTile?.tiles?.filter(tile => typeof tile?.data !== 'undefined'),
  }))
  const availableTiles: ITile[] = getAvailableTilesArray(allSectionTiles)

  // functions
  const openModal = () => {
    setOpen(true)
    setChooseTiles(false)
  }

  const addNewProject = () => {
    setNewProject(!newProject)
  }

  const fetchProjectId = (projectName: string) => {
    let projectId = ''
    if (existingProjects.length) {
      existingProjects.forEach((project) => {
        if (project?.folderName === projectName) {
          projectId = project.folderId
        }
      })
    }

    return projectId
  }

  const fetchExistingReports = (folderId?: string) => {
    if (data) {
      const reports = projectsData.folders.map((folder) =>
        (!folder.folderId.toLocaleLowerCase().includes('all')
          ? {
            folderId: folder.folderId,
            reports: folder.values.map((report =>
              report.id
            )),
          }
          : null))
      return reports.find((elem) => elem?.folderId === folderId)?.reports
    }
    return []
  }

  const folderNames = () => {
    const returnProjects: string[] = []
    if (existingProjects.length) {
      existingProjects.forEach((project) => {
        if (project !== null && project?.folderName) {
          returnProjects.push(project.folderName)
        }
      })
    }
    return returnProjects
  }

  const handleSubmittedData = (e: IDownloadAnalyseInitialValues) => {
    // generates pdf
    let tiles: {
      catchmentId:string,
      excluded?: string[],
      bBox?: number[],
      zoom?: number,
      comparedBBox?: number[],
      comparedZoom?: number,
    }[] = []
    if (e.data === 'all') {
      availableTiles?.forEach(tile => {
        if (tile?.chosenRange?.catchmentId) {
          const bBox = tile?.data?.mapLocation?.bbox
          const comparedBBox = tile?.data?.comparedMapLocation?.bbox
          if (tile?.pdfExcluded?.length) {
            tiles.push({
              catchmentId: tile?.chosenRange?.catchmentId,
              excluded: tile?.pdfExcluded,
              bBox: bBox ? [bBox?.southWestLng, bBox?.southWestLat, bBox?.northEastLng, bBox?.northEastLat] : undefined,
              zoom: tile?.data?.mapLocation?.zoom,
              comparedBBox: comparedBBox
                ? [
                  comparedBBox?.southWestLng,
                  comparedBBox?.southWestLat,
                  comparedBBox?.northEastLng,
                  comparedBBox?.northEastLat,
                ]
                : undefined,
              comparedZoom: tile?.data?.comparedMapLocation?.zoom,

            })
          }
          else {
            tiles.push({
              catchmentId: tile?.chosenRange?.catchmentId,
              bBox: bBox ? [bBox?.southWestLng, bBox?.southWestLat, bBox?.northEastLng, bBox?.northEastLat] : undefined,
              zoom: tile?.data?.mapLocation?.zoom,
              comparedBBox: comparedBBox
                ? [
                  comparedBBox?.southWestLng,
                  comparedBBox?.southWestLat,
                  comparedBBox?.northEastLng,
                  comparedBBox?.northEastLat,
                ]
                : undefined,
              comparedZoom: tile?.data?.comparedMapLocation?.zoom,
            })
          }
        }
      })
    } else {
      tiles = chosenTiles?.map(item => {
        const bBox = item?.data?.mapLocation?.bbox
        const comparedBBox = item?.data?.comparedMapLocation?.bbox
        if (item?.pdfExcluded?.length) {
          return {
            catchmentId: item?.chosenRange?.catchmentId || '',
            excluded: item?.pdfExcluded,
            bBox: bBox ? [bBox?.southWestLng, bBox?.southWestLat, bBox?.northEastLng, bBox?.northEastLat] : undefined,
            zoom: item?.data?.mapLocation?.zoom,
            comparedBBox: comparedBBox
              ? [
                comparedBBox?.southWestLng,
                comparedBBox?.southWestLat,
                comparedBBox?.northEastLng,
                comparedBBox?.northEastLat,
              ]
              : undefined,
            comparedZoom: item?.data?.comparedMapLocation?.zoom,
          }
        }

        return {
          catchmentId: item?.chosenRange?.catchmentId || '',
          bBox: bBox ? [bBox?.southWestLng, bBox?.southWestLat, bBox?.northEastLng, bBox?.northEastLat] : undefined,
          zoom: item?.data?.mapLocation?.zoom,
          comparedBBox: comparedBBox
            ? [
              comparedBBox?.southWestLng,
              comparedBBox?.southWestLat,
              comparedBBox?.northEastLng,
              comparedBBox?.northEastLat,
            ]
            : undefined,
          comparedZoom: item?.data?.comparedMapLocation?.zoom,
        }
      })
    }

    dispatch(savePdfState({
      loading: true,
      error: null,
      value: null,
    }))

    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')

    dispatch(createPDFAction(
      token,
      reportId || analyseId,
      e.reportLanguage,
      tiles,
      e.reportName,
      comparedAnalyseId || undefined,
    ))

    // saves analyse
    if (canBeSave && !isEdit) {
      dispatch(saveSavingState({
        loading: true,
        error: null,
        value: null,
      }))

      const status = e.projectType.toLowerCase().includes('private')
        ? 'private'
        : 'view'
      if (!e.newProjectName.length) {
        const projectId = fetchProjectId(e.projectName)
        dispatch(saveAnalyse(
          token,
          e.reportName,
          status,
          undefined,
          projectId,
          analyseId,
          fetchExistingReports(projectId),
        ))
      } else {
        dispatch(saveAnalyse(token, e.reportName, status,
          e.newProjectName, undefined, analyseId, fetchExistingReports(undefined)))
      }
    }
  }

  const handleRedirectToAnalysisList = () => {
    exitFromAnalyseAction(dispatch, isEdit, analyseId)
    setRedirect(true)
  }

  const handleUnderstand = () => {
    dispatch(savePdfState({
      loading: false,
      error: null,
      value: null,
    }))

    if (canBeSave && !isEdit) {
      dispatch(saveSavingState({
        loading: false,
        error: null,
        value: null,
      }))
      if (savingState?.value) {
        handleRedirectToAnalysisList()
      }
    } else if (pdfState?.value) {
      handleRedirectToAnalysisList()
    }
    setOpen(false)
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // gets existing projects list
  useEffect(() => {
    if (projectsData) {
      const projects = (projectsData?.folders || []).map((folder) =>
        (!folder.folderId.toLocaleLowerCase().includes('all')
          ? {
            folderId: folder.folderId,
            folderName: folder.folderName,
          }
          : null))
      setProjects(projects)
    }
  }, [data?.loading])

  if (redirect) {
    return (
      <Navigate
        to={`../../${PATHS.ANALYSE_REPORTS}`}
      />
    )
  }

  return (

    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={handleUnderstand}
      onOpen={openModal}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >

      <Wrapper>
        <TopWrapper>
          {/* before request */}
          {((!pdfState?.value && !pdfState?.error) || (!savingState?.value
          && !savingState?.error && canBeSave && !isEdit))
            ? <h3>{t('placeme.download_analyse_modal.title')}</h3>
            : (
              <>
                {/* success */}
                {pdfState?.value && !pdfState?.error && (canBeSave && !isEdit ? savingState?.value && !savingState?.error : true) && <h3>{t('placeme.pdf_generation')}</h3>}
                {/* error */}
                {((!pdfState?.value && pdfState?.error) || (canBeSave && !isEdit ? !savingState?.value && savingState?.error : false)) && <h3>{t('placeme.pdf_generation_error')}</h3>}
              </>
            )}
        </TopWrapper>
        {/* before request */}
        {((!pdfState?.value && !pdfState?.error)
              || (canBeSave && !isEdit ? (!savingState?.value && !savingState?.error) : false))
               && (
                 <Formik
                   initialValues={initialValues}
                   isInitialValid={!canBeSave || isEdit}
                   onSubmit={handleSubmittedData}
                   validateOnBlur={false}
                   validationSchema={Yup.object().shape({
                     newProjectName: ((folderNames().length === 0 || newProject) && canBeSave && !isEdit)
                       ? Yup.string().max(50)
                         .notOneOf(folderNames(), t('placeme.create_folder.name_is_taken'))
                         .required(t('generic.field_is_required'))
                       : Yup.string().max(50)
                         .notOneOf(folderNames(), t('placeme.create_folder.name_is_taken')),
                     projectName: (folderNames().length > 0 && !newProject && canBeSave && !isEdit)
                       ? Yup.string().max(50)
                         .required(t('generic.field_is_required'))
                       : Yup.string().max(50),
                     reportLanguage: Yup.string().required(t('generic.field_is_required')),
                     reportName: Yup.string()
                       .required(t('generic.field_is_required'))
                       .max(100),
                     tiles: Yup.array().when('data', {
                       is: 'choosen',
                       then: Yup.array().min(1),
                     }),
                   })}
                 >
                   {({
                     errors,
                     handleSubmit,
                     handleBlur,
                     values,
                     setFieldValue,
                     touched,
                     isValid,
                   }) => (
                     <Form
                       centered
                       onSubmit={handleSubmit}
                     >
                       <FormSection>
                         <TextInput
                           error={touched.reportName && errors.reportName ? errors.reportName : undefined}
                           label={t('placeme.download_analyse_modal.label')}
                           margin='0 0 1rem'
                           name='reportName'
                           onBlur={handleBlur}
                           onChange={setFieldValue}
                           placeholder={t('placeme.download_analyse_modal.type_report_name')}
                           reset
                           value={values.reportName}
                         />
                         <AnalysisWrapper>
                           <p>
                             {t('placeme.download_analyse_modal.include_analysis')}
                           </p>
                           <InputWrapper>
                             <div className='formik-radio'>
                               <Field
                                 checked={values.data === 'all'}
                                 name='data'
                                 onChange={() => {
                                   setFieldValue('data', 'all')
                                   setChooseTiles(false)
                                 }}
                                 type='radio'
                                 value='all'
                               />
                               <div />
                             </div>
                             <span>{t('placeme.download_analyse_modal.all_data')}</span>
                           </InputWrapper>
                           <InputWrapper>
                             <div className='formik-radio'>
                               <Field
                                 checked={values.data !== 'all'}
                                 name='data'
                                 onChange={() => {
                                   setFieldValue('data', 'choosen')
                                   setChooseTiles(true)
                                 }}
                                 type='radio'
                                 value='choosen'
                               />
                               <div />
                             </div>
                             <span>{t('placeme.download_analyse_modal.chosen_data')}</span>
                             { chooseTiles && (
                               <FieldArray
                                 name='tiles'
                                 render={() => (
                                   <CheckboxWrapper>
                                     {availableTiles.map(tile => (
                                       <InputWrapper key={tile?.id + uuidv4()}>
                                         <FormikCheckbox>
                                           <Field
                                             checked={chosenTiles.includes(tile)}
                                             name='tiles'
                                             onChange={() => {
                                               if (chosenTiles.includes(tile)) {
                                                 const idx = chosenTiles.indexOf(tile)
                                                 setChosenTiles(chosenTiles?.filter((t, index) => index !== idx))
                                                 setFieldValue('tiles', chosenTiles?.filter((t, index) => index !== idx))
                                               } else {
                                                 setChosenTiles([...chosenTiles, tile])
                                                 setFieldValue('tiles', [...chosenTiles, tile])
                                               }
                                             }}
                                             type='checkbox'
                                             value={tile}
                                           />
                                           <div />
                                         </FormikCheckbox>
                                         <span>{t(`${tile?.label}`)}</span>
                                       </InputWrapper>
                                     ))}
                                   </CheckboxWrapper>
                                 )}
                               />
                             )}
                           </InputWrapper>
                         </AnalysisWrapper>
                         <AnalysisWrapper>
                           <p>{t('placeme.download_analyse_modal.language')}</p>
                           <LanguagesWrapper>
                             <InputWrapper>
                               <div className='formik-radio'>
                                 <Field
                                   checked={values.reportLanguage === 'pl'}
                                   name='reportLanguage'
                                   onChange={() =>
                                     setFieldValue('reportLanguage', 'pl')}
                                   type='radio'
                                   value='pl'
                                 />
                                 <div />
                               </div>
                               <span>{t('placeme.download_analyse_modal.polish')}</span>
                             </InputWrapper>
                             <InputWrapper>
                               <div className='formik-radio'>
                                 <Field
                                   checked={values.reportLanguage === 'en'}
                                   name='reportLanguage'
                                   onChange={() =>
                                     setFieldValue('reportLanguage', 'en')}
                                   type='radio'
                                   value='en'
                                 />
                                 <div />
                               </div>
                               <span>{t('placeme.download_analyse_modal.english')}</span>
                             </InputWrapper>
                           </LanguagesWrapper>
                         </AnalysisWrapper>
                         <>
                           {isEdit
                             && (
                               <AdditionalDescription>
                                 {t('placeme.save_analyse.is_edit.desc')}
                               </AdditionalDescription>
                             )}
                           { canBeSave && !isEdit
                         && (
                           <>
                             <Select
                               label={t('generic.save_analyse')}
                               name='projectType'
                               onChange={setFieldValue}
                               options={projectTypes}
                               placeholder={t(
                                 'placeme.save_analyse.choose_project_type',
                               )}
                               selected={t(values.projectType)}
                             />
                             <>
                               {folderNames().length === 0 && (
                                 <>
                                   <Description
                                     style={{
                                       marginTop: '0.5rem',
                                     }}
                                   >
                                     {t('placeme.save_analyse.create_first_project')}
                                   </Description>
                                   <TextInput
                                     error={
                                       touched.newProjectName && errors.newProjectName
                                         ? errors.newProjectName
                                         : undefined
                                     }
                                     icon={<FolderIcon />}
                                     label={t('placeme.save_analyse.project_name')}
                                     margin='10px 0 0'
                                     name='newProjectName'
                                     onBlur={handleBlur}
                                     onChange={setFieldValue}
                                     placeholder={t(
                                       'placeme.save_analyse.type_project_name',
                                     )}
                                     reset
                                     value={values.newProjectName}
                                   />
                                 </>
                               )}
                             </>
                             <>
                               {folderNames().length > 0 && !newProject && (
                                 <>
                                   <Description
                                     style={{
                                       marginTop: '0.5rem',
                                     }}
                                   >
                                     {t('placeme.save_analyse.choose_project')}
                                   </Description>
                                   <Select
                                     error={
                                       touched.projectName && errors.projectName
                                         ? errors.projectName
                                         : undefined
                                     }
                                     icon={<FolderIcon />}
                                     label={t('placeme.save_analyse.project_name')}
                                     maxHeight='155px'
                                     name='projectName'
                                     onChange={setFieldValue}
                                     options={folderNames()}
                                     placeholder={t(
                                       'placeme.save_analyse.choose_project',
                                     )}
                                     selected={values.projectName}
                                     withScroll
                                   />
                                   <div
                                     style={{
                                       marginTop: '8px',
                                     }}
                                   >
                                     <TextButton onClick={addNewProject}>
                                       <p
                                         style={{
                                           display: 'flex',
                                           alignItems: 'center',
                                         }}
                                       >
                                         <CloseIcon
                                           style={{
                                             transform: 'rotate(45deg)',
                                             cursor: 'pointer',
                                             marginRight: '5px',
                                           }}
                                         />
                                         {t('placeme.save_analyse.add_new_project')}
                                       </p>
                                     </TextButton>
                                   </div>
                                 </>
                               )}
                             </>
                             <>
                               {newProject && (
                                 <>
                                   <Description
                                     style={{
                                       marginTop: '0.5rem',
                                     }}
                                   >
                                     {t('placeme.save_analyse.add_new_project')}
                                   </Description>
                                   <TextInput
                                     error={
                                       touched.newProjectName && errors.newProjectName
                                         ? errors.newProjectName
                                         : undefined
                                     }
                                     icon={<FolderIcon />}
                                     label={t('placeme.save_analyse.project_name')}
                                     margin='10px 0'
                                     name='newProjectName'
                                     onBlur={handleBlur}
                                     onChange={setFieldValue}
                                     placeholder={t(
                                       'placeme.save_analyse.type_project_name',
                                     )}
                                     reset
                                     value={values.newProjectName}
                                   />
                                   <TextButton onClick={addNewProject}>
                                     <p>{t('placeme.save_analyse.choose_project')}</p>
                                   </TextButton>
                                 </>
                               )}
                             </>
                           </>
                         )}
                         </>
                         <BottomWrapper>
                           <TextButton
                             onClick={() => setOpen(false)}
                             type='button'
                           >
                             <p>{t('generic.cancel')}</p>
                           </TextButton>
                           <Button
                             disabled={newProject && !isValid}
                             loading={pdfState.loading || savingState.loading}
                             loadingText={`${t('placeme.download_analyse_modal.button.loading')}...`}
                             margin='0 0 0 20px'
                             type='submit'
                           >
                             {t('placeme.download_analyse_modal.button')}
                           </Button>
                         </BottomWrapper>
                       </FormSection>
                     </Form>
                   )}
                 </Formik>
               )}
        {/* request has been made */}
        {!(!pdfState?.value && !pdfState?.error)
             && !(canBeSave && !isEdit ? (!savingState?.value && !savingState?.error) : false)
             && (
               <>
                 {/* error */}
                 {!pdfState?.value && pdfState?.error && <Description>{t('placeme.pdf_generation_infoview1_error')}</Description>}
                 {canBeSave && !isEdit && !savingState?.value && savingState?.error && <Description>{t('placeme.save_infoview1_error')}</Description>}
                 {/* success */}
                 {pdfState?.value && !pdfState?.error && <Description>{t('placeme.pdf_generation_infoview1')}</Description>}
                 {canBeSave && !isEdit && savingState?.value && !savingState?.error && <Description>{t('placeme.save_infoview1')}</Description>}
                 <ImageWrapper>
                   <img
                     alt='pdf info view'
                     src='assets/images/pdfInfoView.svg'
                   />
                 </ImageWrapper>
                 <BottomWrapper>
                   <Button
                     margin='0 0 0 20px'
                     onClick={handleUnderstand}
                     type='button'
                   >
                     {t('generic.understand')}
                   </Button>
                 </BottomWrapper>
               </>
             )}
      </Wrapper>

    </Popup>

  ) }

