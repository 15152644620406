export interface IDataSetUpdate {
  label: string
  updated: string
}

export const TrafficDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.traffic.label',
  updated: 'placeme.dataset.traffic.updated',
}

export const InhabitantsDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.inhabitants.label',
  updated: 'placeme.dataset.inhabitants.updated',
}

export const PopulationHeatmapDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.population_heatmap.label',
  updated: 'placeme.dataset.population_heatmap.updated',
}

export const SpendingDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.spending.label',
  updated: 'placeme.dataset.spending.updated',
}

export const PurchasingPowerDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.purchasing_power.label',
  updated: 'placeme.dataset.purchasing_power.updated',
}

export const MunicipalityPopulationDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.municipality_population.label',
  updated: 'placeme.dataset.municipality_population.updated',
}

export const PoisDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.pois.label',
  updated: 'placeme.dataset.pois.updated',
}

export const SalesPotentialDataSet: IDataSetUpdate = {
  label: 'placeme.dataset.sales_potential.label',
  updated: 'placeme.dataset.sales_potential.updated',
}
