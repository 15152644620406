import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ISimpleSelectProps } from './@types/ISimpleSelect.props'
import { SelectCaret } from '../../Icons/SelectCaret'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div<{width?: string}>(({ width }) => css`
    margin: 0.5rem 0;
    width: ${width || '100%'};
  `)

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const StyledDropDown = styled.div<{ isOpen: boolean }>(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    position: relative;
    color: ${palette.black};
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 2rem;
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
    background: #ffffff;
    margin: 10px 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    > :first-child {
      position: absolute;
      right: 0;
      margin: 0 0.5rem;
    }

    :focus {
      margin: 0;
      outline: none;
    }

  `
})

const DropDownListContainer = styled.ul(() => css`
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    overflow: hidden;
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    left: 0;
    top: 2.5rem;
    z-index: 10;
    margin-bottom: 0.5rem;
  `)

const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 0.5rem 1rem;
    list-style: none;
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    :hover {
      background-color: ${palette.light.main};
    }
  `
})

export const SimpleSelect: React.FC<ISimpleSelectProps> = ({
  options, selected, onChange, width, label, additionalInfo, name,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: string) => () => {
    setIsOpen(false)
    onChange(name, value)
  }

  return (
    <Wrapper width={width}>
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <StyledDropDown
        isOpen={isOpen}
        onBlur={() => {
          setIsOpen(false)
        }}
        onClick={toggling}
        tabIndex={0}
      >
        <SelectCaret active={isOpen} />
        {selected}
        {isOpen && (
          <DropDownListContainer>
            {options?.map((option) => (
              <ListItem
                key={uuidv4()}
                onClick={onOptionClicked(option)}
              >
                {option}
              </ListItem>
            ))}
          </DropDownListContainer>
        )}
      </StyledDropDown>
    </Wrapper>

  )
}
